import React from 'react';
import TextField from 'components/TextField';

const PatentProofLink = ({ state, setState }) => (
  <TextField
    label='Copyright / Trademark Proof Link'
    fullWidth
    size='small'
    value={state?.patentProofLink || ''}
    onChange={(patentProofLink) => {
      setState((prevState) => ({
        ...prevState,
        patentProofLink,
      }));
    }}
  />
);

export default PatentProofLink;
