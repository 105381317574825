import styled from "styled-components";

const Container = styled.div`
  background: #ffd9d9;
  border-radius: 10px;
  color: #a00000;
  font-size: 16px;
  font-family: "Inter400";
  padding: 20px;
  margin: 20px 0;
  p {
    margin-top: 0;
    :last-child {
      margin-bottom: 0;
    }
    a {
      color: inherit;
      font-weight: bold;
    }
  }
  &.warning {
    color: #fff;
    background: #000;
  }
  &.success {
    color: #fff;
    background: #000;
  }
`;

export { Container };
