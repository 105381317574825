import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  syncedProducts,
  syncedProduct,
  getThisProduct,
} from "actions/products";
import SubHeader from "components/SubHeader";
import Tabs from "components/Tabs";
import { ROUTES } from "shared/constants/routes";
import ProductDetail from "pages/sync-product/ProductDetail";
import ProductInformation from "pages/sync-product/ProductInformation";
import { Container, NoProductFound } from "styles/pages/sync-product";

const SyncProduct = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state?.auth?.profile?.emailid);
  const [product, setProduct] = useState(undefined);
  const { productId, marketPlaceType, shopName } = useParams();

  useEffect(() => {
    const getData = async () => {
      // let products =
      //   marketPlaceType === 'Shopify'
      //     ? await dispatch(
      //         getShopifyProducts({
      //           user: email,
      //           shopName,
      //         })
      //       )
      //     : await dispatch(
      //         getWooProducts({
      //           user: email,
      //           shopName,
      //         })
      //       );

      // products = products?.products || products;

      // const selectedProduct = products?.find(
      //   (p) => p?.id === Number(productId)
      // );

      const syncedP = await dispatch(
        syncedProducts({
          shopName,
          emailid: email,
        })
      );

      const synced = !!syncedP?.find((s) => Number(productId) === s?.id);

      const productData = await dispatch(
        getThisProduct({
          marketPlace: marketPlaceType,
          user: email,
          shopName,
          id: productId,
        })
      );

      const productDataVariants =
        productData?.variants || productData?.variations;

      if (synced) {
        const p = await dispatch(
          syncedProduct({
            emailId: email,
            productId: Number(productId),
          })
        );

        const pVariants = p[0]?.variants || p[0]?.variations;

        setProduct(() => ({
          ...p[0],
          synced,
          variants: _.uniqBy(_.union(pVariants, productDataVariants), "id"),
        }));
      } else {
        setProduct(() => ({
          ...productData,
          synced,
          variants: productDataVariants,
        }));
      }
    };
    if (!product) {
      getData();
    }
  }, [marketPlaceType, productId, product, productId, shopName]);

  if (!product || !product?.variants?.length) {
    return <NoProductFound>No Product Found!</NoProductFound>;
  }

  return (
    <Container>
      <SubHeader
        isDark
        title={ROUTES.MY_STORES_SYNC_PRODUCT.TITLE}
        links={[
          {
            title: ROUTES.MY_STORES_SYNC_PRODUCTS.TITLE,
            to: ROUTES.MY_STORES_SYNC_PRODUCTS.LINK,
          },
        ]}
        currentPageTitle={ROUTES.MY_STORES_SYNC_PRODUCT.TITLE}
      />
      <ProductInformation marketPlaceType={marketPlaceType} product={product} />

      <Tabs
        dark
        tabs={[
          {
            title: "Variants",
            children: (
              <ProductDetail
                product={product}
                setProduct={setProduct}
                marketPlaceType={marketPlaceType}
                shopName={shopName}
              />
            ),
          },
        ]}
      />
    </Container>
  );
};

export default SyncProduct;
