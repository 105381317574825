import * as React from 'react';

const OutlineTShirt = () => {
  return (
    <svg width={36} height={36} fill='none'>
      <path
        d='M.006 14.407l3.757-9.6a5.305 5.305 0 013.61-3.196L13.635 0h8.74l6.252 1.61a5.305 5.305 0 013.607 3.196l3.757 9.6-6.34 2.16V36H6.36V16.567l-6.355-2.16zM15.005 2.109a3.187 3.187 0 003.001 2.127c1.385 0 2.565-.89 3.001-2.127h-6.002zM8.47 33.891h19.072V9.527h2.11v4.81l3.572-1.216-2.954-7.546a3.19 3.19 0 00-2.169-1.921l-4.97-1.281a5.3 5.3 0 01-5.125 3.973 5.3 5.3 0 01-5.125-3.974L7.898 3.654a3.19 3.19 0 00-2.17 1.922L2.775 13.12l3.586 1.22V9.526h2.11v24.364z'
        fill='#2789C0'
      />
      <path
        d='M11.869 15.456a3.16 3.16 0 01.899-2.697 3.16 3.16 0 012.697-.899 3.16 3.16 0 012.542-1.27 3.16 3.16 0 012.543 1.27 3.16 3.16 0 012.696.9 3.16 3.16 0 01.9 2.696 3.16 3.16 0 011.27 2.542 3.16 3.16 0 01-1.27 2.543 3.16 3.16 0 01-.9 2.697 3.161 3.161 0 01-2.696.899 3.16 3.16 0 01-2.543 1.27 3.16 3.16 0 01-2.542-1.27 3.16 3.16 0 01-2.697-.9 3.16 3.16 0 01-.9-2.696 3.16 3.16 0 01-1.27-2.543 3.16 3.16 0 011.27-2.542zm1.58 3.556l1.196.379-.578 1.113a1.06 1.06 0 00.192 1.242c.33.33.83.407 1.242.192l1.114-.578.378 1.196c.14.444.548.742 1.014.742.466 0 .874-.298 1.014-.742l.378-1.196 1.114.578a1.06 1.06 0 001.241-.192c.33-.33.407-.829.193-1.242l-.578-1.113 1.196-.378a1.06 1.06 0 00.742-1.015 1.06 1.06 0 00-.742-1.014l-1.196-.378.578-1.113a1.06 1.06 0 00-.192-1.242 1.06 1.06 0 00-1.242-.192l-1.114.578-.378-1.196a1.059 1.059 0 00-1.014-.742c-.466 0-.874.298-1.014.742l-.378 1.196-1.114-.579a1.06 1.06 0 00-1.242.193 1.06 1.06 0 00-.192 1.242l.578 1.113-1.196.378a1.06 1.06 0 00-.742 1.014c0 .467.298.874.742 1.014z'
        fill='#2789C0'
      />
      <path
        d='M18.007 14.826a3.176 3.176 0 013.173 3.172 3.176 3.176 0 01-3.173 3.173A3.176 3.176 0 0114.834 18a3.176 3.176 0 013.173-3.173zm0 4.236a1.065 1.065 0 10-1.064-1.064c0 .587.478 1.064 1.064 1.064z'
        fill='#2789C0'
      />
    </svg>
  );
};

export default OutlineTShirt;
