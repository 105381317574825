import React from 'react';
import FiltersHeader from 'pages/browse-catalog/FiltersHeader';
import FiltersAccordion from 'pages/browse-catalog/FiltersAccordion';
import { Container, Wrapper } from 'styles/pages/browse-catalog/sideBar.js';

const Filters = ({
  resetFilters,
  promos,
  updatePromos,
  categories,
  updateCategories,
  stylesFilter,
  updateStylesFilter,
  brands,
  updateBrandsFilter,
  colorsFilter,
  updateColorsFilter,
  fulfillmentPlants,
  updateFulfillmentPlants,
  setDecoratingTechFilter,
  decoratingTechFilter,
  premiumFilter,
  updatePremiumFilter,
}) => (
  <Container>
    <Wrapper>
      <FiltersHeader resetFilters={resetFilters} />
      <FiltersAccordion
        categories={categories}
        updateCategories={updateCategories}
        stylesFilter={stylesFilter}
        updateStylesFilter={updateStylesFilter}
        brands={brands}
        updateBrandsFilter={updateBrandsFilter}
        colorsFilter={colorsFilter}
        updateColorsFilter={updateColorsFilter}
        updatePromos={updatePromos}
        promos={promos}
        fulfillmentPlants={fulfillmentPlants}
        updateFulfillmentPlants={updateFulfillmentPlants}
        setDecoratingTechFilter={setDecoratingTechFilter}
        decoratingTechFilter={decoratingTechFilter}
        premiumFilter={premiumFilter}
        updatePremiumFilter={updatePremiumFilter}
      />
    </Wrapper>
  </Container>
);

export default Filters;
