import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloudinaryWidget from "components/CloudinaryWidget";
import NewProduct from "pages/home/RegisterBanner";
import TextField from "components/TextField";
import Switch from "components/Switch";
import Select from "components/Select";
import { updateSection } from "actions/webPages";
import RichTextEditor, {
  getHtml,
  initiateEditorState,
} from "components/RichTextEditor";
import {
  Card,
  CardTitle,
  Row,
  AddSectionButton,
  AddSectionHeader,
  TextAreaWrapper,
  SectionNavbar,
  TwoBoxWrapper,
  OneBoxWrapper,
} from "styles/pages/web-pages";

const WhatsNew = ({ data }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    if (data && data?.find((d) => d?.name === "What’s New")) {
      const d = data?.find((d) => d?.name === "What’s New");

      setBannerData(() => ({
        ...d,
        components: {
          ...d?.components,
          Description: initiateEditorState(d?.components?.Description),
        },
      }));
    }
  }, [data]);

  const { components, navVisibility, name } = bannerData || {};
  const {
    BackgroundImage = "",
    Image = "",
    Title = "",
    BackgroundColor = "",
    Description,
    Buttons = [],
    SubTitle = "",
  } = components || {};

  return (
    <Card>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999999,
          }}
        ></div>
        <NewProduct
          data={{
            ...bannerData,
            components: {
              ...bannerData?.components,
              Description: bannerData?.components?.Description
                ? getHtml(bannerData?.components?.Description)
                : "",
            },
          }}
        />
      </div>
      <SectionNavbar>
        <CardTitle style={{ border: "none" }}>{name}</CardTitle>
        <Switch
          handleChange={(e) =>
            setBannerData({
              ...bannerData,
              navVisibility: e.target.checked,
            })
          }
          checked={navVisibility || false}
        />
      </SectionNavbar>

      <TwoBoxWrapper>
        <CloudinaryWidget
          label="Background Image"
          value={BackgroundImage}
          setValue={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                BackgroundImage: v,
              },
            })
          }
          folderPath="Shirtly/Web_Pages/LandingPage/WhatsNew"
        />
        <CloudinaryWidget
          label="Banner Image"
          value={Image}
          setValue={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Image: v,
              },
            })
          }
          folderPath="Shirtly/Web_Pages/LandingPage/WhatsNew"
        />
      </TwoBoxWrapper>

      <TwoBoxWrapper>
        <TextField
          label="Background Color"
          size="small"
          value={BackgroundColor}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                BackgroundColor: v,
              },
            })
          }
        />
        <TextField
          label="Title"
          size="small"
          value={Title}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Title: v,
              },
            })
          }
        />
      </TwoBoxWrapper>

      <OneBoxWrapper>
        <TextField
          label="Sub Title"
          size="small"
          value={SubTitle}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                SubTitle: v,
              },
            })
          }
        />
      </OneBoxWrapper>

      <CardTitle style={{ marginTop: 10 }}>Button Settings</CardTitle>
      {Buttons?.map((r, i) => (
        <Row key={`button${i}`}>
          <Switch
            handleChange={(e) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Buttons: bannerData.components.Buttons?.map((b, j) =>
                    i === j ? { ...b, Show: e.target.checked } : b
                  ),
                },
              })
            }
            checked={r?.Show}
          />

          <TextField
            label="Button Name"
            size="small"
            value={r?.ButtonName || ""}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Buttons: bannerData.components.Buttons?.map((b, j) =>
                    i === j ? { ...b, ButtonName: v } : b
                  ),
                },
              })
            }
          />
          <Select
            label="Link To"
            value={{ value: r?.Modal }}
            labelKey="label"
            valueKey="value"
            options={[
              { label: "SignIn", value: "SignIn" },
              { label: "SignUp", value: "SignUp" },
            ]}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Buttons: bannerData.components.Buttons?.map((b, j) =>
                    i === j ? { ...b, Modal: v } : b
                  ),
                },
              })
            }
          />
        </Row>
      ))}
      <TextAreaWrapper>
        <RichTextEditor
          value={Description}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Description: v,
              },
            })
          }
          title="Description"
        />
      </TextAreaWrapper>

      <AddSectionHeader>
        <AddSectionButton
          title="Update"
          containerStyle={{
            backgroundColor: "#5c92e2",
            color: "#fff",
            fontWeight: "bold",
          }}
          onClick={async () => {
            const payload = {
              ...{
                ...bannerData,
                components: {
                  ...bannerData?.components,
                  Description: getHtml(bannerData?.components?.Description),
                },
              },
              page: "LandingPage",
            };

            await dispatch(updateSection({ data: payload, token }));
          }}
        />
      </AddSectionHeader>
    </Card>
  );
};

export default WhatsNew;
