import { USD_TO_CAD_CONVERSION_RATE } from 'constants/currency';
import moment from 'moment';
import { row } from 'pages/sales-report/table/row';

const getFulfillmentCost = (order = {}) => {
  const { OrderLines } = order;
  if (OrderLines?.length) {
    const { OrderLineItems } = OrderLines[0];
    let fulfillmentCost = 0;

    for (let i = 0; i < OrderLineItems?.length; i++) {
      fulfillmentCost += OrderLineItems[i]?.FulfillmentCost;
    }

    return Number(fulfillmentCost.toFixed(2)) || 0;
  }
};

const getActualPayment = (data, currency) => {
  if (data?.StripeChargeInfo) {
    const amount = data?.StripeChargeInfo?.amount || 0;

    if (currency === 'usd') {
      return Number(amount / 100).toFixed(2);
    } else if (currency === 'cad') {
      return Number((amount / 100) * USD_TO_CAD_CONVERSION_RATE).toFixed(2);
    }
  } else if (data?.PaypalChargeInfo) {
    const amount =
      data?.PaypalChargeInfo?.purchase_units[0]?.amount?.value || 0;
    if (currency === 'cad') {
      return Number(amount * USD_TO_CAD_CONVERSION_RATE).toFixed(2);
    } else if (currency === 'usd') {
      return amount;
    }
  }

  return 0;
};

const rows = (data, isCSV = false, taxInfo) => {
  let items = [];

  for (let i = 0; i < data.length; i++) {
    const ShirtlyOrderData = data[i]?.ShirtlyOrderData;
    const { ID } = ShirtlyOrderData || {};

    const totalInUsd = Number(
      data[i]?.grandTotalInUsd ||
        ShirtlyOrderData?.grandTotalInUSD ||
        getActualPayment(data[i], 'usd')
    );

    const totalInCad = Number(
      data[i]?.grandTotalInCan ||
        ShirtlyOrderData?.grandTotalInCAD ||
        getActualPayment(data[i], 'cad')
    );

    const shippingCost = Number(
      data[i]?.finalShippingCost || ShirtlyOrderData?.ShippingCost
    );

    const finalFulfillmentCost = Number(
      data[i]?.fulfillmentCost || getFulfillmentCost(ShirtlyOrderData)
    );

    const taxValue = () => {
      if (
        data[i]?.ShirtlyOrderData?.CountryString === 'CA' &&
        data[i]?.ShirtlyOrderData?.PrintProvider === 'CA'
      ) {
        const v =
          finalFulfillmentCost +
          (data[i]?.shippingWithoutTax || ShirtlyOrderData?.ShippingCost);

        const c =
          !data[i]?.PaypalChargeInfo && !data[i]?.StripeChargeInfo
            ? 0
            : data[i]?.PaypalChargeInfo
            ? Number(
                data[i]?.PaypalChargeInfo?.purchase_units[0]?.amount?.value || 0
              )
            : (data[i]?.StripeChargeInfo?.amount || 0) / 100;

        let taxCharges = taxInfo?.find(
          (c) => c?.PrintProvider === 'CA'
        )?.TaxCharges;

        taxCharges = taxCharges?.find(
          (c) => c?.ShipppingDestinationCountry === 'CA'
        )?.TaxCharges;

        taxCharges = taxCharges?.find(
          (c) => c?.ShippingState === data[i]?.ShirtlyOrderData?.StateProvince
        )?.TaxCharge;

        return Number((c * taxCharges) / 100).toFixed(2);
      }
      return 0;
    };

    const totalTax = data[i]?.totalTax
      ? Number(data[i]?.totalTax?.toFixed(2))
      : totalInUsd
      ? Number((totalInUsd - (shippingCost + finalFulfillmentCost)).toFixed(2))
      : taxValue();

    items.push(
      row(
        moment(data[i]?.OrderSubmitToProductionDate).format('DD MMM YYYY'),
        moment(data[i]?.OrderShippedDate).format('DD MMM YYYY'),
        ShirtlyOrderData?.MasterUserEmail,
        ID,
        isCSV ? finalFulfillmentCost : `$${finalFulfillmentCost} USD`,
        isCSV
          ? data[i]?.shippingWithoutTax || ShirtlyOrderData?.ShippingCost
          : `$${
              data[i]?.shippingWithoutTax || ShirtlyOrderData?.ShippingCost
            } USD`,
        isCSV ? totalTax || 0 : `$${totalTax || 0} USD`,
        isCSV ? totalInUsd || 0 : `$${totalInUsd || 0} USD`,
        isCSV ? totalInCad || 0 : `$${totalInCad || 0} CAD`,
        ShirtlyOrderData?.PrintProvider,
        ShirtlyOrderData?.CountryString,
        data[i].userCountry
      )
    );
  }
  return items;
};

export { rows };
