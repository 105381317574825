import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/routes';
import Button from 'components/Button';
import { StyledHeader } from 'styles/pages/dashboard';

const Header = () => {
  const history = useHistory();
  return (
    <StyledHeader>
      <Button
        title={ROUTES.MY_STORES.TITLE}
        onClick={() => history.push(ROUTES.MY_STORES.LINK)}
      />
      <Button
        title={ROUTES.ORDERS.TITLE}
        onClick={() => history.push(ROUTES.ORDERS.LINK)}
      />
      <Button
        title={ROUTES.CATALOG.TITLE}
        onClick={() => history.push(ROUTES.CATALOG.LINK)}
      />
      <Button
        title={ROUTES.MY_STORES_PRODUCTS.TITLE}
        onClick={() => history.push(ROUTES.MY_STORES_PRODUCTS.LINK)}
      />
      <Button
        title={ROUTES.BACKGROUND_BURNER.TITLE}
        onClick={() => history.push(ROUTES.BACKGROUND_BURNER.LINK)}
      />
      <Button
        title={ROUTES.USER_SALES_REPORT.TITLE}
        onClick={() => history.push(ROUTES.USER_SALES_REPORT.LINK)}
      />
      <Button
        title={ROUTES.INVENTORY.TITLE}
        onClick={() => history.push(ROUTES.INVENTORY.LINK)}
      />
      <Button
        title={ROUTES.ACCOUNT_SETTINGS.TITLE}
        onClick={() => history.push(ROUTES.ACCOUNT_SETTINGS.LINK)}
      />
    </StyledHeader>
  );
};

export default Header;
