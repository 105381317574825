import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getWebPage } from 'actions/webPages';
import Header from 'pages/home/Header';
import Footer from 'pages/home/Footer';
import { Container } from 'styles/pages/home';

const Support = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getWebPageData = async () => {
      const data = await dispatch(getWebPage({ page: 'LandingPage' }));
      setData(() => data);
    };

    getWebPageData();
  }, []);

  return (
    <Container>
      <Header
        showNavMenu={false}
        data={data?.find((d) => d?.name === 'Header')}
      />

      <h1
        style={{
          textAlign: 'center',
          borderBottom: '1px solid #a1a1a1',
          paddingBottom: 10,
          margin: 20,
        }}
      >
        Announcements
      </h1>

      <p style={{ textAlign: 'center', fontSize: 14 }}>Coming soon..</p>
      <Footer />
    </Container>
  );
};

export default Support;
