import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Select from 'components/Select';

const CategoriesSelect = ({ variant, onChange, product }) => {
  const categories = useSelector((state) => state?.categories?.data);

  return (
    <Select
      label='Category'
      disabled={!variant?.selected}
      options={categories}
      value={{ CatId: variant?.syncData?.categoryId || '' }}
      valueKey='CatId'
      labelKey='DisplayName'
      onChange={(CatId, e) => {
        const c = _.find(categories, (o) => o?.CatId === CatId);
        const variants = product?.variants?.map((v) =>
          v?.id === variant?.id || !v?.syncData?.categoryId
            ? {
                ...v,
                syncData: {
                  ...v?.syncData,
                  categoryId: c?.CatId,
                  categoryName: c?.Name,
                  ProductID: '',
                  productName: '',
                  sizeId: '',
                  sizeName: '',
                  ColorID: '',
                  colorName: '',
                },
              }
            : v
        );

        onChange(variants, e);
      }}
    />
  );
};

export default CategoriesSelect;
