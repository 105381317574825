import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Select from "components/Select";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import { clearMocks } from "actions/designer";
import { getSSActiveWearProductData, getAUSplantStock } from "actions/orders";
import { getAllScreenPrinting } from "actions/products";
import TextField from "components/TextField";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AddIcon from "@material-ui/icons/Add";
import ArtWork from "components/ArtWork";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DtgPlus from "pages/order-details/DtgPlus";
import {
  getProductsByPrintProvider,
  getDesignerSettingsFromProductId,
  getAdditionalTechniquesFromProductId,
  isSub,
  isAOP,
  getDTGPlusFromProductId,
  getArtworkSettingsProductId,
} from "selectors/products";
import { showLoader, hideLoader } from "actions/loader";
import { getProductsByCategoryId } from "selectors/inventory";
import Tooltip from "@material-ui/core/Tooltip";
import MarketPlaceDialog from "pages/order-details/MarketPlaceOrderDialog";
import AOP from "pages/order-details/AOP";
// import CopyArtwork from 'pages/order-details/CopyArtwork';
import ProductStock from "pages/order-details/ProductStock";
import {
  Container,
  Wrapper,
  Summary,
  Title,
  ItemList,
  ItemText,
  LineItem,
  Form,
  Cost,
  FieldsGroup,
  ActionSection,
  ButtonSection,
  BlankArea,
  CircleIcon,
  ProblemIcon,
  CostError,
} from "styles/pages/order-details/itemsSummary";
import { USD_TO_CAD_CONVERSION_RATE } from "constants/currency";

const pantoneColorOptions = [
  {
    name: "White",
    pmscode: "000c",
    hexcode: "#FFFFFF",
  },
  {
    name: "Lemon Yellow",
    pmscode: "109c",
    hexcode: "#FFE812",
  },
  {
    name: "Lakers Gold",
    pmscode: "123c",
    hexcode: "#FFB30F",
  },
  {
    name: "Chrome Yellow",
    pmscode: "137c",
    hexcode: "#FFA814",
  },
  {
    name: "Old Gold",
    pmscode: "139c",
    hexcode: "#C47900",
  },
  {
    name: "Bright Orange",
    pmscode: "1505c",
    hexcode: "#FE9E32",
  },
  {
    name: "Dolphin Orange",
    pmscode: "172c",
    hexcode: "#F6591E",
  },
  {
    name: "Rust",
    pmscode: "167c",
    hexcode: "#D15400",
  },
  {
    name: "Flag Red",
    pmscode: "186c",
    hexcode: "#ED1820",
  },
  {
    name: "Pink",
    pmscode: "210c",
    hexcode: "#F993BC",
  },
  {
    name: "Hot Pink",
    pmscode: "806",
    hexcode: "#EE208A",
  },
  {
    name: "Fuchsia",
    pmscode: "240c",
    hexcode: "#B51485",
  },
  {
    name: "Levander",
    pmscode: "264c",
    hexcode: "#B8A2CE",
  },
  {
    name: "Violet",
    pmscode: "268c",
    hexcode: "#1E0A63",
  },
  {
    name: "Baby Blue",
    pmscode: "2905c",
    hexcode: "#92CAE0",
  },
  {
    name: "Brite Blue",
    pmscode: "2995c",
    hexcode: "#0192BF",
  },
  {
    name: "Columbia Blue",
    pmscode: "279c",
    hexcode: "#537CB6",
  },
  {
    name: "Medium Blue",
    pmscode: "301c",
    hexcode: "#194C88",
  },
  {
    name: "Royal Blue",
    pmscode: "286c",
    hexcode: "#0B3D91",
  },
];

const onloadImageProcess = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const addImageProcess = (artwork, isBack = false) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = isBack
      ? artwork?.BackArtWorkCoordinates?.width
      : artwork?.ArtWorkCoordinates?.width;
    canvas.height = isBack
      ? artwork?.BackArtWorkCoordinates?.height
      : artwork?.ArtWorkCoordinates?.height;
    const image = new Image();

    image.onload = function (e) {
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const reSampledImage = new Image();
      reSampledImage.src = canvas.toDataURL();
      resolve(canvas.toDataURL());
    };
    image.src = isBack ? artwork?.BackArtwork : artwork?.Artwork;
  });
};

const orderLineEditInitiate = (orderLine, products) => {
  let printAreas = orderLine?.printAreas;

  if (printAreas?.length > 0) {
    printAreas = orderLine?.printAreas?.map((p, j) => ({
      ...p,
      selected: j === 0,
    }));
  } else {
    printAreas = products?.find(
      (p) => p?.Product?.ID === orderLine?.ProductID
    )?.PrintAreas;

    printAreas = printAreas?.map((p, j) => {
      const printAreaName = p?.printAreaName;
      if (printAreaName === "Front") {
        return {
          ...p,
          selected: j === 0,
          artwork: orderLine?.Artwork,
          artworkId: orderLine?.ArtworkId,
          previewImage: orderLine?.ArtworkId ? true : false,
          MockupSrc: orderLine?.MockupSrc,
          printAreaMarketingImage: orderLine?.mainImage,
          coordinates: {
            ...p?.coordinates,
            ...(orderLine?.ArtWorkCoordinates || {}),
          },
        };
      } else if (printAreaName === "Back") {
        return {
          ...p,
          selected: j === 0,
          artwork: orderLine?.BackArtwork,
          artworkId: orderLine?.BackArtworkId,
          MockupSrc: orderLine?.MockupBackSrc,
          previewImage: orderLine?.BackArtworkId ? true : false,
          printAreaMarketingImage: orderLine?.BackImage,
          coordinates: {
            ...p?.coordinates,
            ...(orderLine?.BackArtWorkCoordinates || {}),
          },
        };
      }

      return {
        ...p,
      };
    });
  }

  return {
    ...orderLine,
    printAreas,
  };
};

const OrderDetails = ({
  order = {},
  onUpdateOrderLineItem,
  onRemoveLineItem,
  onShip,
}) => {
  const dispatch = useDispatch();
  const { OrderLines = [] } = order;
  const [selectedOrderLine, setOrderLine] = useState(undefined);
  const [orderLineItems, setOrderLineItems] = useState(undefined);
  const [marketplaceOrderDetail, setMarketplaceOrder] = useState(undefined);
  const [screenPrinting, setScreenPrinting] = useState();

  const allProducts = useSelector((state) => state?.products?.data);
  const categories = useSelector((state) => state?.categories?.data);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const currency = useSelector((state) => state?.currency?.value);

  let isFulfillmentPending = false;

  const printAreas = selectedOrderLine?.printAreas;
  const artWorkUploaded =
    !!printAreas?.find((p) => p?.artwork) ||
    !!printAreas?.find((p) => p?.canvasJSON);

  const fetchScreenPrinting = async () => {
    const data = await dispatch(getAllScreenPrinting());
    setScreenPrinting(data);
  };

  useEffect(() => {
    const data = OrderLines?.length ? OrderLines[0]?.OrderLineItems : [];
    if (data?.length) {
      setOrderLineItems(data);
      setOrderLine(() => orderLineEditInitiate(data[0], allProducts));
    }
  }, [setOrderLineItems, OrderLines, allProducts]);

  useEffect(() => {
    if (!screenPrinting) fetchScreenPrinting();
  }, [screenPrinting]);

  if (orderLineItems?.length) {
    isFulfillmentPending = !!_.find(orderLineItems, (c) => !c.FulfillmentCost);
  }

  return (
    <Container>
      <Wrapper>
        <Summary>
          {order?.Status === "AwaitingPayment" && !isFulfillmentPending ? (
            <Button
              containerStyle={{
                backgroundColor: "#5c92e2",
                color: COLORS.WHITE,
                fontSize: "16px",
                borderRadius: "6px",
                padding: "9px 10px",
                textTransform: "initial",
                border: "1px solid #e7eaec",
                boxShadow: "none",
                width: "100%",
                marginBottom: "14px",
                fontFamily: "Inter500",
              }}
              title=" Continue to shipping"
              onClick={onShip}
              startIcon={<LocalShippingIcon />}
            />
          ) : null}
          <ItemList>
            <Title>Items Summary</Title>
            {orderLineItems?.map((orderLine, index) => {
              const disabled =
                !selectedOrderLine ||
                orderLine.ID !== selectedOrderLine?.ID ||
                orderLine.tempId !== selectedOrderLine?.tempId;

              const categoryId = disabled
                ? orderLine.CategoryID
                : selectedOrderLine?.CategoryID;

              let products = getProductsByCategoryId(allProducts, categoryId);

              if (order?.PrintProvider) {
                products = getProductsByPrintProvider(
                  products,
                  order?.PrintProvider
                );
              }

              if (products?.length > 0) {
                let productOptions = [];
                for (let i = 0; i < products.length; i++) {
                  productOptions.push({
                    ...products[i],
                    ...products[i].Product,
                  });
                }
                products = productOptions;
              }

              const productId = disabled
                ? orderLine.ProductID
                : selectedOrderLine?.ProductID;

              products = products?.filter(
                (p) => p?.visible === undefined || p?.visible === true
              );

              let sizes = _.find(products, (p) => p.ID === productId)?.Sizes;
              sizes = sizes?.filter((s) => s?.visible !== false);

              let colors = _.find(products, (p) => p.ID === productId)?.Colors;
              colors = colors?.filter((s) => s?.visible !== false);

              const sizeId = disabled
                ? orderLine?.SizeID
                : selectedOrderLine?.SizeID;

              const colorId = disabled
                ? orderLine?.ColorID
                : selectedOrderLine?.ColorID;

              const quantity = disabled
                ? orderLine?.OliQuantity
                : selectedOrderLine?.OliQuantity;

              const artworkId = disabled
                ? orderLine?.ArtworkId
                : selectedOrderLine?.ArtworkId;

              const backArtworkId = disabled
                ? orderLine?.BackArtworkId
                : selectedOrderLine?.BackArtworkId;

              const dtgPlusOptions = disabled
                ? getDTGPlusFromProductId(products, orderLine.ProductID)
                : getDTGPlusFromProductId(
                    products,
                    selectedOrderLine?.ProductID
                  );

              const dtgPlus = disabled
                ? orderLine?.dtgPlus
                : selectedOrderLine?.dtgPlus;

              const plus = disabled ? orderLine?.plus : selectedOrderLine?.plus;

              const dtgProcessName = disabled
                ? orderLine?.dtgProcessName
                : selectedOrderLine?.dtgProcessName;

              const dtgColorName = disabled
                ? orderLine?.dtgColorName
                : selectedOrderLine?.dtgColorName;

              const dtgVectorFile = disabled
                ? orderLine?.dtgVectorFile
                : selectedOrderLine?.dtgVectorFile;

              const aopVectorFile = disabled
                ? orderLine?.aopVectorFile
                : selectedOrderLine?.aopVectorFile;

              const dtgPlusMockFile = disabled
                ? orderLine?.dtgPlusMockFile
                : selectedOrderLine?.dtgPlusMockFile;

              const isProductAop = isAOP(
                products,
                disabled ? orderLine.ProductID : selectedOrderLine?.ProductID
              );

              const remainingDecorativeTechniques = disabled
                ? orderLine?.RemainingDecorativeTechniques
                : selectedOrderLine?.RemainingDecorativeTechniques;

              const isScreenPrinting = remainingDecorativeTechniques?.find(
                (x) => x?.DisplayName === "ScreenPrint" && x?.selected
              );

              const pantoneColorNumber = disabled
                ? orderLine?.pantoneColorNumber
                : selectedOrderLine?.pantoneColorNumber;

              const pantoneHexColorCode = disabled
                ? orderLine?.pantoneHexColorCode
                : selectedOrderLine?.pantoneHexColorCode;

              const saveDisabled =
                !productId ||
                !sizeId ||
                !colorId ||
                !categoryId ||
                // (!artworkId && !backArtworkId && !isProductAop) ||
                (!artWorkUploaded && !isProductAop) ||
                (dtgPlus &&
                  (!dtgVectorFile ||
                    !dtgProcessName ||
                    !dtgColorName ||
                    !dtgPlusMockFile)) ||
                (plus &&
                  // (!dtgVectorFile || !dtgProcessName || !dtgColorName)) ||
                  (!dtgProcessName || !dtgColorName)) ||
                (isProductAop && !aopVectorFile) ||
                (!!isScreenPrinting && !pantoneHexColorCode);

              const isPreDesign = orderLine?.Artwork?.includes("Pre-designs");
              const isShirtlyPhrases =
                orderLine?.Artwork?.includes("Shirtly-Phrases");

              const marketplace = order?.Marketplace;

              return (
                <ItemText
                  key={`ItemText${index}`}
                  disabled={disabled}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (
                      (selectedOrderLine &&
                        selectedOrderLine?.ID &&
                        orderLine?.ID &&
                        selectedOrderLine?.ID === orderLine?.ID) ||
                      (selectedOrderLine &&
                        selectedOrderLine?.tempId &&
                        orderLine?.tempId &&
                        selectedOrderLine?.tempId === orderLine?.tempId)
                    ) {
                      return;
                    }

                    const data = orderLineEditInitiate(orderLine, allProducts);
                    setOrderLine(() => data);
                  }}
                >
                  {saveDisabled ? (
                    <Tooltip
                      title={
                        <React.Fragment>
                          {!productId && <p>Select product</p>}
                          {!sizeId && <p>Select size</p>}
                          {!colorId && <p>Select color</p>}
                          {!categoryId && <p>Select category</p>}
                          {!artworkId && <p>Select artwork</p>}
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <ProblemIcon></ProblemIcon>
                    </Tooltip>
                  ) : (
                    <CircleIcon></CircleIcon>
                  )}

                  <LineItem>
                    Line Item {index + 1}
                    {orderLine?.Details && (
                      <span>&nbsp;({orderLine?.Details})</span>
                    )}
                    {order?.Marketplace !== "Shirtly" && orderLine?.Details && (
                      <span
                        className="more-details"
                        onClick={() => {
                          const payload = {
                            user: emailid,
                            marketPlace: order?.Marketplace,
                            productId: orderLine?.StoreProductID,
                            lineItemArrId: orderLine?.ID,
                            shopName: order?.Store?.ShopName,
                            details: orderLine?.Details,
                            ListingID:
                              marketplace === "Etsy"
                                ? orderLine?.MarketplaceDetails?.listing_id
                                : OrderLines[0]?.ListingID,
                            orderId: order?.ID,
                          };
                          setMarketplaceOrder(payload);
                        }}
                      >
                        More Details
                      </span>
                    )}
                  </LineItem>
                  <Form>
                    <FieldsGroup>
                      <Select
                        disabled={disabled}
                        label="Category"
                        options={categories}
                        value={{ CatId: categoryId || "" }}
                        valueKey="CatId"
                        labelKey="DisplayName"
                        onChange={(CategoryID) => {
                          const c = _.find(
                            categories,
                            (o) => o.CatId === CategoryID
                          );

                          setOrderLine((state) => ({
                            ...state,
                            Category: c.Name,
                            RemainingDecorativeTechniques: null,
                            CategoryID,
                            Product: undefined,
                            ProductID: undefined,
                            Size: undefined,
                            templateUrl: null,
                            SizeID: undefined,
                            Color: undefined,
                            ColorID: undefined,
                            MockupSrc: undefined,
                            MockupBackSrc: undefined,
                            ArtworkId: null,
                            Artwork: null,
                            previewImage: false,
                            previewBackImage: false,
                            dtgPlus: null,
                            plus: null,
                            ArtWorkCoordinates: {
                              x: 0,
                              y: 0,
                            },
                            BackImage: null,
                            BackArtworkId: null,
                            BackArtwork: null,
                            mainBackImage: null,
                            artWorkBackImage: null,
                            BackArtWorkCoordinates: {
                              x: 0,
                              y: 0,
                            },
                            printAreas: [],
                          }));
                        }}
                      />
                    </FieldsGroup>
                    <FieldsGroup>
                      <Select
                        disabled={disabled}
                        label="Product"
                        options={products}
                        value={{ ID: productId || "" }}
                        valueKey="ID"
                        labelKey="DisplayName"
                        onChange={async (ProductID) => {
                          const c = _.find(
                            products,
                            (o) => o?.ID === ProductID
                          );

                          const productStock =
                            order?.PrintProvider !== "AU"
                              ? await dispatch(
                                  getSSActiveWearProductData({
                                    id: ProductID,
                                    printProvider: order?.PrintProvider,
                                  })
                                )
                              : null;

                          setOrderLine((state) => ({
                            ...state,
                            productStock,
                            Product: c?.Name,
                            MarketingImagePath: c?.MarketingImagePath,
                            BackImage: c?.MarketingBackImagePath,
                            MarketingImageBase64: c?.MarketingImageBase64,
                            ProductID,
                            Sizes: c?.Sizes?.filter(
                              (s) => s?.visible !== false
                            ),
                            Colors: c?.Colors?.filter(
                              (s) => s?.visible !== false
                            ),
                            Size: undefined,
                            SizeID: undefined,
                            Color: undefined,
                            ColorID: undefined,
                            MockupSrc: undefined,
                            MockupBackSrc: undefined,
                            previewImage: false,
                            previewBackImage: false,
                            ArtworkId: null,
                            Artwork: null,
                            BackArtworkId: null,
                            BackArtwork: null,
                            mainBackImage: null,
                            artWorkBackImage: null,
                            templateUrl: null,
                            PrintType: c?.PrintType,
                            dtgPlus: null,
                            plus: null,
                            RemainingDecorativeTechniques:
                              c?.RemainingDecorativeTechniques?.map((v) => ({
                                ...v,
                                selected: false,
                              })),
                            BackArtWorkCoordinates: {
                              x: 0,
                              y: 0,
                            },
                            ArtWorkCoordinates: {
                              x: 0,
                              y: 0,
                            },
                            printAreas: c?.PrintAreas?.map((p, i) => ({
                              ...p,
                              selected: i === 0,
                            })),
                          }));
                        }}
                      />
                    </FieldsGroup>
                    <FieldsGroup>
                      <Select
                        disabled={disabled}
                        label="Size"
                        options={sizes}
                        value={{ ID: sizeId || "" }}
                        valueKey="ID"
                        labelKey="Name"
                        onChange={async (SizeID) => {
                          const c = _.find(sizes, (o) => o.ID === SizeID);

                          let productStock = selectedOrderLine?.productStock;

                          if (!productStock && order?.PrintProvider !== "AU") {
                            productStock = await dispatch(
                              getSSActiveWearProductData({
                                id: selectedOrderLine?.ProductID,
                                printProvider: order?.PrintProvider,
                              })
                            );
                          }

                          setOrderLine((state) => ({
                            ...state,
                            templateUrl: c?.templateUrl,
                            Size: c?.Name,
                            SizeID,
                            productStock,
                          }));
                        }}
                      />
                    </FieldsGroup>
                    <FieldsGroup>
                      <Select
                        disabled={disabled}
                        label="Color"
                        options={colors}
                        value={{ ID: colorId || "" }}
                        valueKey="ID"
                        labelKey="Name"
                        onChange={async (ColorID) => {
                          const c = _.find(colors, (o) => o.ID === ColorID);
                          const image = _.find(colors, (c) => c.ID === ColorID);

                          let productStock = selectedOrderLine?.productStock;

                          if (order?.PrintProvider === "AU") {
                            const selectedProduct = _.find(
                              products,
                              (o) => o?.ID === selectedOrderLine?.ProductID
                            );
                            const skuData = selectedProduct?.SKUdata;
                            const sku = skuData?.find(
                              (s) =>
                                s?.colorName === c?.Name &&
                                s?.sizeName === selectedOrderLine?.Size
                            );

                            productStock = await dispatch(
                              getAUSplantStock({
                                sku: sku?.sku,
                              })
                            );
                          } else if (!productStock) {
                            productStock = await dispatch(
                              getSSActiveWearProductData({
                                id: selectedOrderLine?.ProductID,
                                printProvider: order?.PrintProvider,
                              })
                            );
                          }

                          const remainingDecorativeTechniques =
                            selectedOrderLine?.RemainingDecorativeTechniques;

                          const screenPrintSelected =
                            remainingDecorativeTechniques?.find(
                              (a) =>
                                a?.DisplayName === "ScreenPrint" && a?.selected
                            );

                          const selectedScreenPrint =
                            screenPrinting?.find(
                              (d) => d?.colorName === c?.Name
                            ) ||
                            screenPrinting?.find(
                              (d) => d?.colorName === "Any Color"
                            );

                          setOrderLine((state) => ({
                            ...state,
                            productStock,
                            Color: c?.Name,
                            ColorID,
                            MarketingImagePath: image?.Image,
                            BackImage: image?.BackImage,
                            OliQuantity:
                              !!selectedScreenPrint && !!screenPrintSelected
                                ? Number(selectedScreenPrint?.minQuantity)
                                : 1,
                            printAreas: state?.printAreas?.map((p, i) => {
                              return {
                                ...p,
                                printAreaMarketingImage:
                                  p?.printAreaName === "Front"
                                    ? image?.Image
                                    : image[`${p?.printAreaName}Image`],
                              };
                            }),
                          }));
                        }}
                      />
                    </FieldsGroup>

                    <FieldsGroup>
                      <TextField
                        disabled={disabled}
                        fullWidth
                        size="small"
                        label="Quantity"
                        type="number"
                        value={quantity > 0 ? String(quantity) : "1"}
                        onChange={(OliQuantity) => {
                          const remainingDecorativeTechniques =
                            selectedOrderLine?.RemainingDecorativeTechniques;

                          const screenPrintSelected =
                            remainingDecorativeTechniques?.find(
                              (a) =>
                                a?.DisplayName === "ScreenPrint" && a?.selected
                            );

                          const selectedScreenPrint =
                            screenPrinting?.find(
                              (d) => d?.colorName === selectedOrderLine?.Color
                            ) ||
                            screenPrinting?.find(
                              (d) => d?.colorName === "Any Color"
                            );

                          if (!!selectedScreenPrint && !!screenPrintSelected) {
                            setOrderLine((state) => ({
                              ...state,
                              OliQuantity: Number(
                                Number(OliQuantity) <
                                  Number(selectedScreenPrint?.minQuantity)
                                  ? Number(selectedScreenPrint?.minQuantity)
                                  : OliQuantity
                              ),
                            }));
                          } else {
                            setOrderLine((state) => ({
                              ...state,
                              OliQuantity: Number(
                                OliQuantity == 0 ? 1 : OliQuantity
                              ),
                            }));
                          }
                        }}
                      />
                    </FieldsGroup>
                  </Form>

                  {!!isScreenPrinting && (
                    <FieldsGroup
                      style={{
                        marginTop: 20,
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                      }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        disabled={true}
                        label="Pantone Color Number"
                        value={pantoneColorNumber || ""}
                        onChange={(pantoneColorNumber) =>
                          setOrderLine((state) => ({
                            ...state,
                            pantoneColorNumber,
                          }))
                        }
                      />

                      <div style={{ width: "100%", display: "flex" }}>
                        {!!pantoneHexColorCode && (
                          <div
                            style={{
                              border: "1px solid #ccc",
                              width: "40px",
                              height: "38px",
                              marginRight: "10px",
                              backgroundColor: pantoneColorOptions?.find(
                                (c) => c?.hexcode === pantoneHexColorCode
                              )?.hexcode,
                            }}
                          ></div>
                        )}
                        <Select
                          label="Pantone Hex Color Code"
                          valueKey="hexcode"
                          labelKey="name"
                          options={pantoneColorOptions}
                          value={{ hexcode: pantoneHexColorCode || "" }}
                          onChange={(pantoneHexColorCode) => {
                            const a = pantoneColorOptions?.find(
                              (c) => c?.hexcode === pantoneHexColorCode
                            );
                            setOrderLine((state) => ({
                              ...state,
                              pantoneHexColorCode: a?.hexcode,
                              pantoneColorNumber: a?.pmscode,
                            }));
                          }}
                        />
                      </div>
                    </FieldsGroup>
                  )}

                  {isProductAop && (
                    <AOP
                      setOrderLine={setOrderLine}
                      selectedOrderLine={selectedOrderLine}
                      aopVectorFile={aopVectorFile}
                    />
                  )}

                  {/* <CopyArtwork
                    orderLineItems={orderLineItems}
                    orderLine={selectedOrderLine}
                    setOrderLine={setOrderLine}
                  /> */}

                  {selectedOrderLine?.ProductID &&
                    selectedOrderLine?.ColorID &&
                    selectedOrderLine?.SizeID &&
                    !disabled && (
                      <ProductStock
                        order={order}
                        productStock={selectedOrderLine?.productStock}
                        color={selectedOrderLine?.Color}
                        size={selectedOrderLine?.Size}
                        orderLine={selectedOrderLine}
                        setOrderLine={setOrderLine}
                      />
                    )}

                  {(order?.Marketplace === "Shirtly" ||
                    order?.Marketplace === "Shopify" ||
                    order?.Marketplace === "Etsy" ||
                    order?.Marketplace === "BigCommerce" ||
                    order?.Marketplace === "WooCommerce" ||
                    order?.Marketplace === "BigCartel") && (
                    <DtgPlus
                      dtgPlusOptions={dtgPlusOptions}
                      dtgPlusMockFile={dtgPlusMockFile}
                      dtgPlus={dtgPlus}
                      plus={plus}
                      dtgProcessName={dtgProcessName}
                      setOrderLine={setOrderLine}
                      dtgColorName={dtgColorName}
                      dtgVectorFile={dtgVectorFile}
                      selectedOrderLine={selectedOrderLine}
                      saveDisabled={!artworkId && !backArtworkId}
                      orderLine={orderLine}
                    />
                  )}

                  {order?.Status !== "Shipped" &&
                  order?.Status !== "Cancelled" &&
                  order?.Status !== "InProduction" ? (
                    <ActionSection>
                      {!disabled && (
                        <ButtonSection>
                          <Button
                            title="Save"
                            disabled={saveDisabled}
                            onClick={async () => {
                              const data = {
                                ...selectedOrderLine,
                                imagePreview: selectedOrderLine?.ArtworkId
                                  ? true
                                  : false,
                                previewBackImage:
                                  selectedOrderLine?.BackArtworkId
                                    ? true
                                    : false,
                              };

                              const isProductSub = isSub(
                                products,
                                selectedOrderLine?.ProductID
                              );

                              const widthInRound = Math.round(
                                data?.ArtWorkCoordinates?.width
                              );

                              const backWidthInRound = Math.round(
                                data?.BackArtWorkCoordinates?.width
                              );

                              const heightInRound = Math.round(
                                data?.ArtWorkCoordinates?.height
                              );

                              const backHeightInHeight = Math.round(
                                data?.BackArtWorkCoordinates?.height
                              );

                              if (data?.MarketingImagePath && data?.ArtworkId) {
                                const marketingImageSplit =
                                  data?.MarketingImagePath.split("/upload/");

                                if (isProductSub) {
                                  data.mainImage = `${marketingImageSplit[0]}/upload/w_565,h_565/${marketingImageSplit[1]}`;
                                } else {
                                  data.mainImage = `${marketingImageSplit[0]}/upload/w_565,h_565/${marketingImageSplit[1]}`;
                                }
                              }

                              if (data?.BackImage && data?.BackArtworkId) {
                                const backImageSplit =
                                  data?.BackImage.split("/upload/");

                                data.mainBackImage = `${backImageSplit[0]}/upload/w_565,h_565/${backImageSplit[1]}`;
                              }

                              if (data?.Artwork || data?.BackArtwork) {
                                const artworkImageSplit = data?.Artwork
                                  ? data?.Artwork.split("/upload/")
                                  : null;

                                const artworkBackImageSplit =
                                  data?.BackArtworkId
                                    ? data?.BackArtwork?.split("/upload/")
                                    : null;

                                if (artworkImageSplit) {
                                  if (isProductSub) {
                                    data.artWorkImage = `${artworkImageSplit[0]}/upload/w_${widthInRound},h_${heightInRound}/${artworkImageSplit[1]}`;
                                  } else {
                                    data.artWorkImage = `${artworkImageSplit[0]}/upload/w_${widthInRound},h_${heightInRound}/${artworkImageSplit[1]}`;
                                  }
                                } else {
                                  data.artWorkImage = null;
                                }

                                data.artWorkBackImage = artworkBackImageSplit
                                  ? `${artworkBackImageSplit[0]}/upload/w_${backWidthInRound},h_${backHeightInHeight}/${artworkBackImageSplit[1]}`
                                  : null;

                                const artImageDataUrl =
                                  data?.Artwork?.indexOf("base64") > 0
                                    ? await addImageProcess(data)
                                    : null;

                                const backArtImageDataUrl =
                                  data?.BackArtwork?.indexOf("base64") > 0
                                    ? await addImageProcess(data, true)
                                    : null;

                                data.artWorkImage = artImageDataUrl
                                  ? artImageDataUrl
                                  : data.artWorkImage;

                                data.artWorkBackImage = backArtImageDataUrl
                                  ? backArtImageDataUrl
                                  : data.artWorkBackImage;

                                let designerSettings =
                                  getDesignerSettingsFromProductId(
                                    allProducts,
                                    selectedOrderLine?.ProductID
                                  );

                                let backDesignerSettings =
                                  getDesignerSettingsFromProductId(
                                    allProducts,
                                    selectedOrderLine?.ProductID,
                                    true
                                  );

                                if (data?.PrintType !== "DTG") {
                                  designerSettings =
                                    getAdditionalTechniquesFromProductId(
                                      allProducts,
                                      selectedOrderLine?.ProductID,
                                      false,
                                      data?.PrintType
                                    ) || designerSettings;

                                  backDesignerSettings =
                                    getAdditionalTechniquesFromProductId(
                                      allProducts,
                                      selectedOrderLine?.ProductID,
                                      true,
                                      data?.PrintType
                                    ) || backDesignerSettings;
                                }

                                if (isProductSub) {
                                  data.artCord = {
                                    x: Math.round(
                                      designerSettings?.left / 2 +
                                        data?.ArtWorkCoordinates?.x +
                                        (565 - designerSettings?.width) / 2
                                    ),
                                    y: parseInt(
                                      designerSettings?.top +
                                        data?.ArtWorkCoordinates?.y
                                    ),
                                  };
                                } else {
                                  data.artCord = {
                                    x: data?.ArtworkId
                                      ? Math.round(
                                          (565 - designerSettings?.width) / 2 +
                                            data?.ArtWorkCoordinates?.x +
                                            designerSettings.left / 2
                                        )
                                      : 0,
                                    y: data?.ArtworkId
                                      ? designerSettings?.top +
                                        data?.ArtWorkCoordinates?.y
                                      : 0,

                                    xBack: data?.BackArtworkId
                                      ? Math.round(
                                          (565 - backDesignerSettings?.width) /
                                            2 +
                                            data?.BackArtWorkCoordinates?.x +
                                            backDesignerSettings.left / 2
                                        )
                                      : 0,
                                    yBack: data?.BackArtworkId
                                      ? backDesignerSettings?.top +
                                        data?.BackArtWorkCoordinates?.y
                                      : 0,
                                  };
                                }
                              }

                              data.RemainingDecorativeTechniques =
                                data?.RemainingDecorativeTechniques?.map(
                                  (r) => ({
                                    ...r,
                                    selected: r.DisplayName === data?.PrintType,
                                  })
                                );

                              data.DtgPlus = dtgPlus
                                ? {
                                    DtgPlusProcess: dtgProcessName?.DisplayName,
                                    DtgPlusProcessColor:
                                      dtgColorName?.ColorName,
                                    DtgPlusProcessColorImage:
                                      dtgColorName?.ColorImagePath,
                                    DtgPlusVectorFile: dtgVectorFile,
                                    dtgPlusMockFile: dtgPlusMockFile,
                                  }
                                : null;

                              data.Plus = plus
                                ? {
                                    DtgPlusProcess: dtgProcessName?.DisplayName,
                                    DtgPlusProcessColor:
                                      dtgColorName?.ColorName,
                                    // DtgPlusProcessColorImage:
                                    //   dtgColorName?.ColorImagePath,
                                    DtgPlusVectorFile: dtgVectorFile,
                                    dtgPlusMockFile: dtgPlusMockFile,
                                  }
                                : null;

                              setOrderLine(undefined);

                              if (data?.productStock) {
                                delete data.productStock;
                              }
                              _.isFunction(onUpdateOrderLineItem) &&
                                onUpdateOrderLineItem(data, index);
                            }}
                          />
                          <Button
                            title="Cancel"
                            onClick={() => setOrderLine(undefined)}
                          />
                          <Button
                            title="Delete"
                            onClick={() => {
                              if (orderLine.ID) {
                                setOrderLine(null);
                                onRemoveLineItem(orderLine.ID);
                              } else if (orderLine?.tempId) {
                                const data = _.filter(
                                  orderLineItems,
                                  (c) => c?.tempId !== orderLine?.tempId
                                );
                                setOrderLineItems(data);
                              }
                            }}
                          />
                        </ButtonSection>
                      )}
                      {orderLine?.FulfillmentCost ? (
                        <Cost>
                          <div>
                            Fulfillment Cost:&nbsp;
                            <span>
                              $
                              {currency === "USD"
                                ? orderLine?.FulfillmentCost?.toFixed(2)
                                : (
                                    Number(orderLine?.FulfillmentCost) *
                                    USD_TO_CAD_CONVERSION_RATE
                                  ).toFixed(2)}
                              &nbsp;{currency}
                            </span>
                          </div>
                          {isPreDesign && (
                            <div>
                              (Including ${orderLine.OliQuantity}&nbsp; for
                              using Shirtly Pre-design)
                            </div>
                          )}
                          {isShirtlyPhrases && (
                            <div>
                              (Including ${orderLine.OliQuantity}&nbsp; for
                              using Shirtly Phrases)
                            </div>
                          )}
                        </Cost>
                      ) : (
                        <CostError>Please Save Line Item</CostError>
                      )}
                    </ActionSection>
                  ) : (
                    order?.Status != "InProduction" && (
                      <ActionSection>
                        <ButtonSection>
                          <Button
                            title="View"
                            onClick={() => setOrderLine(orderLine)}
                          />
                        </ButtonSection>
                      </ActionSection>
                    )
                  )}
                </ItemText>
              );
            })}

            {order?.Status != "InProduction" && (
              <Button
                containerStyle={{
                  fontSize: "16px",
                  borderRadius: "6px",
                  padding: "9px 10px",
                  fontWeight: "normal",
                  textTransform: "initial",
                  boxShadow: "none",
                  width: "100%",
                  marginBottom: "14px",
                }}
                title="Add Line Item"
                startIcon={<AddIcon />}
                onClick={() => {
                  if (orderLineItems?.length) {
                    setOrderLineItems([
                      ...orderLineItems,
                      {
                        OliQuantity: 1,
                        tempId: orderLineItems.length + 1,
                      },
                    ]);
                  } else {
                    setOrderLineItems([
                      {
                        ColorID: null,
                        Color: null,
                        SizeID: null,
                        Size: null,
                        ProductID: null,
                        MarketingImagePath: "",
                        BackImage: "",
                        Product: null,
                        CategoryID: null,
                        Category: null,
                        ProductSizeColorID: null,
                        ProductSizeColor: null,
                        Details: null,
                        MockupSrc: null,
                        MockupBackSrc: null,
                        Position: null,
                        ArtworkId: null,
                        Artwork: null,
                        FulfillmentCost: 0,
                        FulfillmentPlusShipping: 0,
                        TrackingNumber: null,
                        Artworks: null,
                        OliQuantity: 1,
                        tempId: 1,
                      },
                    ]);
                  }
                }}
              />
            )}
          </ItemList>
        </Summary>
        {selectedOrderLine && selectedOrderLine.ProductID && (
          <ArtWork
            order={order}
            printType={selectedOrderLine?.PrintType}
            product={_.find(
              allProducts,
              (o) => o?.Product?.ID === selectedOrderLine?.ProductID
            )}
            setOrderLine={setOrderLine}
            plus={selectedOrderLine?.plus}
            dtgPlusProcess={selectedOrderLine?.dtgProcessName}
            onFabricDrop={(canvasJSON, customGraphicsdData) => {
              setOrderLine((prevState) => ({
                ...prevState,
                printAreas: prevState?.printAreas?.map((p) => {
                  if (!p?.selected) {
                    return p;
                  }

                  const customGraphics = p?.customGraphicsdData || [];

                  return {
                    ...p,
                    canvasJSON,
                    customGraphicsdData: customGraphicsdData
                      ? _.isArray(customGraphicsdData)
                        ? [...customGraphics, ...customGraphicsdData]
                        : [...customGraphics, customGraphicsdData]
                      : customGraphics,
                  };
                }),
              }));
            }}
            onDrop={async (artwork) => {
              const greyOut = getArtworkSettingsProductId(
                allProducts,
                selectedOrderLine?.ProductID
              )?.greyOut;

              let secureUrl = artwork.secure_url;
              let secureUrlSplit = secureUrl.split("/upload/");
              const API_ENDPOINT =
                "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

              if (secureUrlSplit?.length === 1) {
                dispatch(showLoader());
                const fileData = new FormData();
                fileData.append("file", secureUrl);
                fileData.append("upload_preset", "tnedst8q");
                fileData.append(
                  "folder",
                  `Shirtly/${emailid}/CustomGraphics/${artwork?.user}`
                );
                const data = await fetch(API_ENDPOINT, {
                  method: "post",
                  body: fileData,
                });
                const json = await data?.json();
                secureUrl = json.secure_url;
                secureUrlSplit = secureUrl.split("/upload/");
                await onloadImageProcess(secureUrl);
                artwork.public_id = json?.public_id;
                artwork.height = json?.height;
                artwork.width = json?.width;
                dispatch(hideLoader());
              }

              if (greyOut) {
                secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:aaa9ad/${secureUrlSplit[1]}`;

                dispatch(showLoader());
                await fetch(secureUrl);
                dispatch(hideLoader());
              } else if (
                selectedOrderLine?.plus &&
                !!selectedOrderLine?.dtgColorName
              ) {
                const selectedColor =
                  selectedOrderLine?.dtgProcessName?.Colors?.find(
                    (c) =>
                      c?.ColorName ===
                      selectedOrderLine?.dtgColorName?.ColorName
                  );

                if (secureUrl) {
                  const canvas = document.createElement("canvas");
                  const ctx = canvas.getContext("2d");

                  const secureUrlSplit = secureUrl.split("/upload/");
                  secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;

                  dispatch(showLoader());

                  const img1 = await onloadImageProcess(secureUrl);
                  const img2 = await onloadImageProcess(
                    selectedColor?.ColorImagePath
                  );

                  canvas.width = img1.width * 0.5;
                  canvas.height = img1.height * 0.5;

                  ctx.fillStyle = ctx.createPattern(img2, "repeat");

                  // fill canvas with pattern
                  ctx.fillRect(0, 0, canvas.width, canvas.height);

                  // use blending mode multiply
                  ctx.globalCompositeOperation = "multiply";

                  // draw sofa on top
                  ctx.drawImage(
                    img1,
                    0,
                    0,
                    img1.width * 0.5,
                    img1.height * 0.5
                  );

                  // change composition mode
                  ctx.globalCompositeOperation = "destination-in";

                  // draw to cut-out sofa
                  ctx.drawImage(
                    img1,
                    0,
                    0,
                    img1.width * 0.5,
                    img1.height * 0.5
                  );

                  const dataURL = canvas.toDataURL("image/png");
                  const API_ENDPOINT =
                    "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

                  const fileData = new FormData();
                  fileData.append("file", dataURL);
                  fileData.append("upload_preset", "tnedst8q");
                  fileData.append("folder", `Shirtly/${emailid}/PlusArtFiles`);

                  const data = await fetch(API_ENDPOINT, {
                    method: "post",
                    body: fileData,
                  });

                  dispatch(hideLoader());

                  const json = await data?.json();
                  secureUrl = json?.secure_url;
                  artwork.public_id = json?.public_id;
                }

                // const secureUrlSplit = secureUrl.split('/upload/');
                // secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:${selectedOrderLine?.dtgColorName?.ColorCode}/${secureUrlSplit[1]}`;
                // dispatch(showLoader());
                // await fetch(secureUrl);
                // dispatch(hideLoader());
              }

              const selectedPrintArea = selectedOrderLine?.printAreas?.find(
                (p) => p?.selected
              );

              const designerSettings = selectedPrintArea?.designerSettings;

              const ArtWorkCoordinates = selectedPrintArea?.coordinates || {};

              const { width: fileWidth, height: fileHeight } = artwork;

              const { width: designedWidth, height: designedHeight } =
                designerSettings;

              if (designedWidth > designedHeight) {
                const aspectRatio = fileWidth / fileHeight;
                if (fileWidth > fileHeight) {
                  if (fileWidth > designedWidth) {
                    ArtWorkCoordinates.height = Math.round(
                      designedWidth / aspectRatio
                    );
                    ArtWorkCoordinates.width = designedWidth;

                    if (ArtWorkCoordinates.height > designedHeight) {
                      ArtWorkCoordinates.height = designedHeight;
                    }
                  } else {
                    ArtWorkCoordinates.height = designedHeight;
                    ArtWorkCoordinates.width = Math.round(
                      designedHeight * aspectRatio
                    );
                  }
                } else {
                  ArtWorkCoordinates.height = designedHeight;
                  ArtWorkCoordinates.width = Math.round(
                    designedHeight * aspectRatio
                  );
                }
              } else if (
                fileWidth <= designedWidth &&
                fileHeight <= designedHeight
              ) {
                ArtWorkCoordinates.width = fileWidth;
                ArtWorkCoordinates.height = fileHeight;
              } else if (fileWidth > fileHeight) {
                const aspectRatio = fileWidth / fileHeight;
                ArtWorkCoordinates.width = designedWidth;
                ArtWorkCoordinates.height = Math.round(
                  designedWidth / aspectRatio
                );
              } else if (fileWidth < fileHeight) {
                const aspectRatio = fileWidth / fileHeight;

                if (designedHeight * aspectRatio > designedWidth) {
                  ArtWorkCoordinates.width = designedWidth;
                  ArtWorkCoordinates.height = Math.round(
                    designedWidth / aspectRatio
                  );
                } else {
                  ArtWorkCoordinates.height = designedHeight;
                  ArtWorkCoordinates.width = Math.round(
                    designedHeight * aspectRatio
                  );
                }
              } else if (fileWidth === fileHeight) {
                if (designedWidth > designedHeight) {
                  ArtWorkCoordinates.height = designedHeight;
                  ArtWorkCoordinates.width = designedHeight;
                } else {
                  ArtWorkCoordinates.height = designedWidth;
                  ArtWorkCoordinates.width = designedWidth;
                }
              }

              setOrderLine((prevState) => ({
                ...prevState,
                printAreas: prevState?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        artwork: secureUrl,
                        artworkId: artwork.public_id,
                        coordinates: ArtWorkCoordinates,
                        customGraphicsdData: artwork?.Art_Url
                          ? [
                              {
                                thumbnailUrl: artwork?.Thumbnail_Url,
                                productionFile: secureUrl,
                                chargeAmountInUSD:
                                  artwork["Charge_Amount(USD)"],
                                shirtlyChargeAmountInUsd:
                                  artwork["Shirtly_Amount(USD)"],
                                sku: artwork["Art_SKU"],
                                user: artwork["user"],
                                fileName: artwork["FileName"],
                              },
                            ]
                          : null,
                      }
                    : p
                ),
              }));
            }}
            onRemove={async () => {
              const selectedPrintArea = selectedOrderLine?.printAreas?.find(
                (p) => p?.selected
              );

              if (selectedPrintArea?.artworkId?.includes("/CustomGraphics/")) {
                await dispatch(
                  clearMocks({
                    publicId: selectedPrintArea?.artworkId,
                  })
                );
              }

              setOrderLine((prevState) => ({
                ...prevState,
                printAreas: prevState?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        artwork: null,
                        artworkId: null,
                        coordinates: {},
                        customGraphicsdData: null,
                      }
                    : p
                ),
              }));
            }}
            setCoordinates={(coordinates) =>
              setOrderLine((prevState) => ({
                ...prevState,
                printAreas: prevState?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        coordinates,
                      }
                    : p
                ),
              }))
            }
            orderLine={selectedOrderLine}
            onFabricRemove={async () => {
              const selectedPrintArea = selectedOrderLine?.printAreas?.find(
                (p) => p?.selected
              );

              if (selectedPrintArea?.canvasJSON) {
                const objects = selectedPrintArea?.canvasJSON?.objects;
                for (let i = 0; i < objects?.length; i++) {
                  const element = objects[i];
                  if (element?.type !== "image") {
                    continue;
                  }

                  if (element?.src?.includes("/CustomGraphics/")) {
                    const publicId = decodeURIComponent(
                      `Shirtly/${element?.src?.split("/Shirtly/")[1]}`
                    );
                    await dispatch(
                      clearMocks({
                        publicId: publicId?.split(".png")[0],
                      })
                    );
                  }
                }
              }

              setOrderLine((prevState) => ({
                ...prevState,
                printAreas: prevState?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        canvasJSON: null,
                        customGraphicsdData: null,
                      }
                    : p
                ),
              }));
            }}
            onPreview={(previewImage) =>
              setOrderLine((prevState) => ({
                ...prevState,
                printAreas: prevState?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        previewImage,
                      }
                    : p
                ),
              }))
            }
          />
        )}

        {!selectedOrderLine ||
          (!selectedOrderLine?.ProductID && (
            <BlankArea>
              <div>
                <ArrowBackIcon />
                Select a Category and Product to Preview
              </div>
            </BlankArea>
          ))}
      </Wrapper>
      {marketplaceOrderDetail && (
        <MarketPlaceDialog
          payload={marketplaceOrderDetail}
          open={!!marketplaceOrderDetail}
          setOpen={() => {
            setMarketplaceOrder(undefined);
          }}
        />
      )}
    </Container>
  );
};

export default OrderDetails;
