import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #000;
`;
const ItemGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 0;
  transition: all ease-out 0.25s;
  grid-gap: 20px;
  border-radius: 10px;
  @media (max-width: 826px) {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
`;

const AdminItemGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 0;
  transition: all ease-out 0.25s;
  grid-gap: 20px;
  border-radius: 4px;
  @media (max-width: 829px) {
    grid-template-columns: repeat(auto-fit, minmax(183px, 1fr));
  }
  /* 
  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  } */
`;
const Colors = styled.div`
  position: absolute;
  bottom: 0;
  left: 1em;
  right: 1em;
  display: grid;
  align-items: center;
  padding: 10px 0;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(24px, 24px));
  column-gap: 8px;
  row-gap: 8px;
  opacity: 0;
  background: ${(props) => (props?.premium ? "#c1c1c1" : "#fff")};
  transition: all ease-out 0.25s;
  min-height: 16%;
  justify-content: center;
  > div {
    width: 100%;
    height: 24px;
  }
`;
const Item = styled.div`
  border-radius: 10px;
  transform: scale(1);
  padding: 20px;
  position: relative;
  background: ${(props) => (props?.premium ? "#a1a1a1" : "#fff")};
  /* box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff; */
  transition: all ease-out 0.25s;
  cursor: pointer;

  border: ${(props) => (props?.premium ? "2px solid #a1a1a1" : "none")};
  &:hover {
    ${Colors} {
      opacity: 1;
    }
  }

  ${(props) =>
    props?.premium &&
    `&:before {
    content: '';
    position: absolute;
    width: 150%;
    height: 150%;
    top: 30%;
    left: -50%;
    background: rgba(255, 255, 255, 0.3);
    background: linear-gradient(
      top,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.3)
    );
    transform: rotate(40deg);
    border-bottom-left-radius: 200px 20px;
    border-bottom-right-radius: 200px 10px;
    border-top-right-radius: 5px 100px;
  }`};
`;

const ItemWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: inline-block;

  .MuiAccordion-rounded:last-child {
    font-size: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .MuiAccordion-root:before {
    background-color: transparent;
  }
  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

const ProductLink = styled.div``;
const ProductImage = styled.div`
  color: transparent;
  display: inline-block;
  width: 100%;
  margin: 1em 0;
  img {
    border-radius: ${(props) => (props?.premium ? "10px" : 0)};
  }
`;
const Img = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin: 0.5em auto;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #ccc;
`;
const Description = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
  flex-direction: column;
`;
const Text = styled.p`
  margin: 0 0 2px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Inter600";
  color: ${(props) => (props?.premium ? "white" : "#283948")};
`;
const Amount = styled.p`
  color: #2280c1;
  margin: 0;
  font-weight: 600;
  font-size: 13px;
  font-family: "Inter600";
  color: ${(props) => (props?.premium ? "white" : "#283948")};
`;

const Category = styled.div`
  position: absolute;
  top: 3px;
  left: 0;
  font-family: "Inter600";
`;

const Popular = styled.span`
  background: #f9682a;
  margin-left: 5px;
  color: #ffffff;
  font-size: 12px;
  min-width: 10px;
  padding: 3px 12px;
  border-radius: 12px;
`;
const New = styled.span`
  background: #c5e599;
  margin-left: 5px;
  color: #ffffff;
  font-size: 12px;
  min-width: 10px;
  padding: 3px 12px;
  border-radius: 12px;
`;

const NoProdcuts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  text-align: center;
  svg {
    font-size: 50px;
  }
`;

const DialogTitle = styled.div`
  font-weight: 100;
  font-size: 24px;
  margin-bottom: 20px;
  color: ${COLORS.BODY_TEXT};
  border-bottom: 2px solid #666;
  padding-bottom: 10px;
`;

const SearchWrapper = styled.div`
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;

  input {
    /* height: 22px !important; */
  }
`;

const FlagWrapper = styled.div`
  display: flex;
  /* margin-left: 10px; */
  align-items: center;
  position: absolute;
  right: 6px;
  top: 3px;
  z-index: 1;
  img {
    margin-left: 7px;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Premium = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0;
  img {
    width: 80px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;
    color: #5c92e2;
    font-size: 30px;
  }
`;

const NotVisibleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  flex: 1;
`;

const NotVisbleColors = styled.div`
  text-align: right;
`;

export {
  Container,
  ItemGroup,
  Item,
  ProductLink,
  ProductImage,
  Img,
  Description,
  Text,
  Amount,
  Colors,
  Category,
  Popular,
  New,
  NoProdcuts,
  DialogTitle,
  AdminItemGroup,
  SearchWrapper,
  FlagWrapper,
  HeaderWrapper,
  Premium,
  ItemWrapper,
  IconWrapper,
  NotVisibleWrapper,
  NotVisbleColors,
};
