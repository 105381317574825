import React from 'react';
import Shopify from 'assets/images/shopify.png';
import WooCommerceLogo from 'assets/images/woo-commerce.png';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { ProductInfo } from 'styles/pages/sync-product';

const ProductInformation = ({ marketPlaceType, product }) => {
  let productImage;
  let title;
  let description;

  if (marketPlaceType === 'Shopify') {
    productImage = product?.image?.src;
    title = product?.title;
    description = product?.body_html;
  } else if (marketPlaceType === 'WooCommerce') {
    productImage = product?.images[0]?.src;
    title = product?.name;
    description = product?.description;
  }

  return (
    <ProductInfo>
      <div>
        {productImage ? (
          <img className='product-image' src={productImage} alt='product' />
        ) : (
          <WallpaperIcon style={{ fontSize: '10rem' }} />
        )}
      </div>

      <div>
        <div>
          <span className='key'>Title:</span>
          <span className='value'>{title}</span>
        </div>
        <div>
          <span className='key'>Description:</span>
          <span
            className='value'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      <img
        className='logo'
        src={marketPlaceType == 'WooCommerce' ? WooCommerceLogo : Shopify}
        alt='marketplace'
      />
    </ProductInfo>
  );
};

export default ProductInformation;
