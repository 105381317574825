import React, { useState } from "react";
import _ from "lodash";
import {
  Container,
  SearchBarIcon,
  StyledCloseIcon,
} from "styles/components/SearchBar";
import TextField from "components/TextField";

const SearchBar = ({
  searchById,
  fetchAllOrders,
  placeholder = "Search",
  searchOnEnter = false,
  isDark = true,
}) => {
  const [search, setSearch] = useState(undefined);

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && !!search?.trim()?.length) {
      searchById(search);
    }
  };

  return (
    <Container>
      <TextField
        fullWidth
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        value={search || ""}
        onChange={(v) => {
          setSearch(v);
          if (!searchOnEnter) {
            searchById(v);
          }
        }}
      />
      {!!search?.trim().length && (
        <StyledCloseIcon
          onClick={() => {
            setSearch(undefined);
            _.isFunction(fetchAllOrders) && fetchAllOrders();
          }}
        />
      )}
      <SearchBarIcon
        isDark={isDark}
        onClick={() => !!search?.trim()?.length && searchById(search)}
      />
    </Container>
  );
};

export default SearchBar;
