import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ModalContainer } from 'styles/pages/dashboard';

const EmailConfirmationDialog = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalContainer>
        <DialogTitle>Email Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Verification email has been sent to the registered Email address.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </ModalContainer>
    </Dialog>
  );
};

export default EmailConfirmationDialog;
