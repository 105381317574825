import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
  > div {
    @media (min-width: 992px) {
      :first-child {
        grid-column: 1 / span 2;
        position: sticky;
        top: 0.5em;
      }
      :nth-child(2),
      :nth-child(3),
      :nth-child(4) {
        grid-column: 3 / span 2;
      }
    }
  }
`;
const OrderDetail = styled.div`
  border-radius: 5px;
  background-color: ${COLORS.WHITE};
  margin-bottom: 20px;
  padding: 0 30px;
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2789c0;
  font-size: 17px;
  font-family: "Inter600";
  padding: 14px 0;
`;

const Panel = styled.div`
  /* padding: 0 15px; */
`;
const MoreDetail = styled.div`
  > h4 {
    color: #6d6e71;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    text-align: center;
    padding: 3px;
    border: 1px solid #efefef;
    max-width: fit-content;
    margin: 0 auto;
  }

  .parent {
    border: 1px solid #efefef;
    padding: 0 12px;
    margin-bottom: 12px;
    p {
      margin-top: 0;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 10px;

    @media (min-width: 650px) {
      grid-template-columns: 1fr 1fr;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: 4px;
      background: #fbfbfb;
      @media (min-width: 992px) {
        /* height: 150px;
        width: 150px; */
      }
    }
    ul {
      padding: 0;
      li {
        display: flex;
        padding: 5px 0;
        flex-wrap: wrap;
        span {
          font-family: "Inter400";
          font-size: 14px;
          line-height: 31px;
          :first-child {
            width: auto;
            font-family: "Inter600";
            margin-right: 8px;
            line-height: 32px;
          }
          :last-child {
            flex: 1;
          }
        }
      }
    }
  }
`;
const OrderTotal = styled.div`
  min-height: 150px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px;
  margin-bottom: 20px;

  &.capture-images {
    > div {
      display: block;
    }
    button {
      width: auto;
      padding: 10px;
      :hover {
        background-color: #ffd363;
        color: #35495e;
      }
    }
  }

  .order-summary {
    color: #2789c0;
    font-size: 17px;
    font-family: "Inter600";
    padding-bottom: 14px;
    border-bottom: 1px solid #efefef;
  }
  button {
    font-family: "Inter600" !important;
    background-color: #ffd363;
    color: #35495e;
    padding: 10px 5px;
    border-radius: 6px;
    text-transform: capitalize;
    font-size: 18px;
    width: 100%;
  }

  > div {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 12.5px;
    font-family: "Inter600";
    span {
      font-size: 14px;
      :last-child {
        justify-self: end;
      }
    }
    b {
      color: rgb(153, 153, 153);
      font-weight: 700;
    }
  }
`;
const Total = styled.span`
  font-size: 19px;
`;

const ProductImage = styled.div`
  width: 70%;
  margin: auto;
`;

const SlickContainer = styled.div`
  width: 200px;
  margin: 10px auto 30px;
  .slick-dots {
    display: flex !important;
    justify-content: center;
  }
`;

const PackingOptions = styled.div`
  border-top: 1px solid #ccc;
  grid-template-columns: ${(props) =>
    props?.singleBox ? "1fr auto !important" : "1fr 1fr !important"};
  gap: ${(props) => (props?.singleBox ? 0 : "20px")};
  padding: 10px 0;
`;

const PackingOption = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  gap: 4px;
  border: ${(props) =>
    props?.selected
      ? `2px dashed ${props?.eco ? "green" : "#ffd363"}`
      : "1px solid #ccc"};
  color: ${(props) =>
    props?.selected ? (props?.eco ? "green" : "inherit") : "#a1a1a1"};

  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
`;

const MessageTextarea = styled.textarea`
  width: 100%;
`;

const AddMessageButtonWrapper = styled.div`
  button {
    width: 100px;
    float: right;
  }
`;

export {
  Container,
  OrderDetail,
  Heading,
  Panel,
  MoreDetail,
  OrderTotal,
  Total,
  ProductImage,
  SlickContainer,
  PackingOptions,
  PackingOption,
  AddMessageButtonWrapper,
  MessageTextarea,
};
