import styled, { css } from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-bottom: 1em;
  border-radius: 2px;
  width: 100%;
`;

const Img = styled.img`
  width: 50px;
  height: 50px;
`;
const Title = styled.p`
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;
  font-size: 11px;
  padding: 0.5em;
  transition: all ease-out 0.25s;
  color: #666;
`;
const Label = styled.label`
  padding: 0;
  text-align: center;
  margin: 0;
  display: inline-block;
  transition: all ease-out 0.25s;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Item = styled.div`
  width: 100%;
  padding: 0;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
  justify-content: center;
  display: flex;
  min-height: 80px;
  align-items: center;

  ${(props) =>
    props.selected &&
    css`
      ${Img} {
        opacity: 0;
      }
      ${Label} {
        background: #2280c1;
      }
      ${Title} {
        transform: translateY(-20px);
        color: #fff;
      }
    `};

  &:hover {
    ${Img} {
      opacity: 0;
    }
    ${Label} {
      background: #2280c1;
    }
    ${Title} {
      transform: translateY(-20px);
      color: #fff;
    }
  }
`;

export { Container, Item, Img, Title, Label };
