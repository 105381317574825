import React from 'react';
import TextField from 'components/TextField';
import Button from 'components/Button';
import {
  FieldsContainer,
  FieldWrapper,
  FieldTitle,
  Title,
  SubmitButton,
} from 'styles/pages/fulfillment-days';

const PrintTypeFields = ({
  setDecoratingTech,
  decoratingTech,
  products,
  handleSubmit,
}) => {
  const disabled = !(
    !!decoratingTech?.find(
      (d) => d?.maxFulfillmentDays > 0 || d?.minFulfillmentDays > 0
    ) && !!products?.find((d) => d?.selected)
  );

  return (
    <FieldsContainer>
      <Title>Decoration Techniques Fulfillment Days</Title>
      {decoratingTech?.map((d) => (
        <FieldWrapper key={d?.DisplayName}>
          <FieldTitle>
            <img src={d?.ImageUrl} /> {d?.DisplayName}
          </FieldTitle>
          <TextField
            label='Minimum Fulfillment Days'
            type='number'
            size='small'
            value={d?.minFulfillmentDays}
            onChange={(v) =>
              setDecoratingTech(
                decoratingTech?.map((m) =>
                  m?.DisplayName === d?.DisplayName
                    ? { ...m, minFulfillmentDays: Number(v < 0 ? 0 : v) }
                    : m
                )
              )
            }
          />

          <TextField
            label='Maximum Fulfillment Days'
            type='number'
            size='small'
            value={d?.maxFulfillmentDays}
            onChange={(v) =>
              setDecoratingTech(
                decoratingTech?.map((m) =>
                  m?.DisplayName === d?.DisplayName
                    ? { ...m, maxFulfillmentDays: Number(v < 0 ? 0 : v) }
                    : m
                )
              )
            }
          />
        </FieldWrapper>
      ))}

      <SubmitButton>
        <Button title='Submit' disabled={disabled} onClick={handleSubmit} />
      </SubmitButton>
    </FieldsContainer>
  );
};

export default PrintTypeFields;
