import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloudinaryWidget from "components/CloudinaryWidget";
import TextField from "components/TextField";
import Switch from "components/Switch";
import Works from "pages/home/Works";
import { updateSection } from "actions/webPages";
import {
  Card,
  CardTitle,
  AddSectionButton,
  AddSectionHeader,
  SectionNavbar,
  TwoBoxWrapper,
  OneBoxWrapper,
  ThreeBoxWrapper,
} from "styles/pages/web-pages";

const HowItWorks = ({ data }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    if (data && data?.find((d) => d?.name === "How It Works")) {
      const d = data?.find((d) => d?.name === "How It Works");
      setBannerData(() => d);
    }
  }, [data]);

  const { components, navVisibility, name } = bannerData || {};
  const {
    Title = "",
    BackgroundColor = "",
    SubTitle = "",
    Cards = [],
  } = components || {};

  return (
    <Card>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999999,
          }}
        ></div>
        <Works data={bannerData || {}} />
      </div>
      <SectionNavbar>
        <CardTitle style={{ border: "none" }}>{name}</CardTitle>
        <Switch
          handleChange={(e) =>
            setBannerData({
              ...bannerData,
              navVisibility: e.target.checked,
            })
          }
          checked={navVisibility || false}
        />
      </SectionNavbar>

      <TwoBoxWrapper>
        <TextField
          label="Title"
          size="small"
          value={Title}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Title: v,
              },
            })
          }
        />
        <TextField
          label="Sub Title"
          size="small"
          value={SubTitle}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                SubTitle: v,
              },
            })
          }
        />
      </TwoBoxWrapper>

      <OneBoxWrapper>
        <TextField
          label="Background Color"
          size="small"
          value={BackgroundColor}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                BackgroundColor: v,
              },
            })
          }
        />
      </OneBoxWrapper>

      <CardTitle style={{ marginTop: 10 }}>Cards Settings</CardTitle>
      {Cards?.map((r, i) => (
        <ThreeBoxWrapper key={`card${i}`}>
          <TextField
            label="Title"
            size="small"
            value={r?.CardTitle}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardTitle: v } : b
                  ),
                },
              })
            }
          />
          <TextField
            label="Description"
            size="small"
            value={r?.CardSubTitle}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardSubTitle: v } : b
                  ),
                },
              })
            }
          />

          <CloudinaryWidget
            label="Image"
            value={r?.CardImage}
            setValue={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardImage: v } : b
                  ),
                },
              })
            }
            folderPath="Shirtly/Web_Pages/LandingPage/HowItWorks"
          />
        </ThreeBoxWrapper>
      ))}

      <AddSectionHeader style={{ marginTop: 20 }}>
        <AddSectionButton
          title="Update"
          containerStyle={{
            backgroundColor: "#5c92e2",
            color: "#fff",
            fontWeight: "bold",
          }}
          onClick={async () => {
            const payload = {
              ...bannerData,
              page: "LandingPage",
            };

            await dispatch(updateSection({ data: payload, token }));
          }}
        />
      </AddSectionHeader>
    </Card>
  );
};

export default HowItWorks;
