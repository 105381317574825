import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIcon from '@material-ui/icons/Assignment';

const stepsData = [
  {
    icon: <InfoIcon />,
    title: 'Order Info',
  },
  {
    icon: <AssignmentIcon />,
    title: 'Order Details',
  },
  {
    icon: <LocalShippingIcon />,
    title: 'Shipping',
  },
  {
    icon: <LocalShippingIcon />,
    title: 'Order Summary',
  },
];

const stepperData = [
  'Order Info',
  'Order Details',
  'Shipping',
  'Order Summary',
];

export { stepsData, stepperData };
