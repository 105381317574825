import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Link } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import CancelIcon from '@material-ui/icons/Cancel';
import { ROUTES } from 'shared/constants/routes';

const Modal = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalWrapper>
        <CancelIcon className='close-icon' onClick={handleClose} />
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            There is some issue while fetching data from Shopify. <br />
            <Link to={ROUTES.MY_STORES.LINK}>
              Please remove and re-connect your shopify store again.
            </Link>
          </DialogContentText>
        </DialogContent>
      </ModalWrapper>
    </Dialog>
  );
};

export default Modal;
