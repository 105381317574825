import React, { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Facebook from 'assets/svg/FacebookIcon';
import InstagramIcon from '@material-ui/icons/Instagram';
import Youtube from 'assets/svg/YoutubeIcon';
import TermsAndConditionDialog from 'components/TermsAndCondition';
import LINKS from 'pages/home/Footer/data';
import { ROUTES } from 'shared/constants/routes';
import {
  Container,
  Wrapper,
  Address,
  LogoSection,
  AddressBox,
  Links,
  SocialLinks,
  Terms,
} from 'styles/pages/home/footer';

const Footer = ({ links = LINKS, isHomePage = true }) => {
  const [termsDialog, setTermsDialog] = useState(false);
  const homeWebPage = useSelector((state) => state?.homeWebPage);
  const footerData = homeWebPage?.find((p) => p?.name === 'Footer') || {};
  const { components } = footerData;
  const {
    Cards = [],
    BackgroundImage,
    BackgroundColor,
    Image,
    Email,
    PrintProviders,
  } = components || {};

  if (!Image) {
    return null;
  }

  return (
    <Container
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundColor: BackgroundColor,
      }}
    >
      <Wrapper>
        <div>
          <AddressBox>
            {PrintProviders?.map((p, i) => (
              <Address key={`printProvider${i}`}>
                <p dangerouslySetInnerHTML={{ __html: p?.PlantAddress }} />
                <p>
                  {p?.PlantCountry}&nbsp;
                  {/* <img src={p?.PlantFlag} alt='country' /> */}
                </p>
              </Address>
            ))}
          </AddressBox>
          {!!links?.length && (
            <Links>
              {isHomePage
                ? links?.map((l) => (
                    <a key={l?.title} href={l?.link}>
                      {l?.title}
                    </a>
                  ))
                : links?.map((l) => (
                    <ReactRouterLink key={l?.title} to={l?.link}>
                      {l?.title}
                    </ReactRouterLink>
                  ))}
              <ReactRouterLink to={ROUTES.NEWS_ROOM.LINK}>
                Newsroom
              </ReactRouterLink>
              <a
                rel="noreferrer"
                href="https://www.yoti.com/blog/our-approach-to-security-and-privacy/"
                target="_blank"
              >
                FAQ’s and Security using Yoti
              </a>
            </Links>
          )}
        </div>
        <LogoSection>
          <img src={Image} alt="logo" />
          <a href={`mailto:${Email}`}>{Email}</a>

          <SocialLinks>
            {Cards?.map((p, i) => (
              <span key={`card${i}`}>
                <a href={p?.CardLink} target="_blank" rel="noopener noreferrer">
                  {p?.CardTitle === 'Facebook' ? (
                    <Facebook />
                  ) : p?.CardTitle === 'Instagram' ? (
                    <InstagramIcon fontSize="large" />
                  ) : (
                    <Youtube fontSize="large" />
                  )}
                </a>
              </span>
            ))}
          </SocialLinks>
        </LogoSection>
      </Wrapper>
      <Terms>
        <div>
          <p>
            © {moment().format('YYYY')} Design by Shirtly /
            <ReactRouterLink to={ROUTES.TERMS_AND_SERVICES.LINK}>
              Terms of Service / Privacy Policy
            </ReactRouterLink>
            <ReactRouterLink to={ROUTES.SUPPORT.LINK}>
              &nbsp;/ Support
            </ReactRouterLink>
          </p>
        </div>
      </Terms>
      {termsDialog && (
        <TermsAndConditionDialog
          open={termsDialog}
          setOpen={(v) => setTermsDialog(v)}
        />
      )}
    </Container>
  );
};

export default Footer;
