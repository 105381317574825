import styled from "styled-components";
const Container = styled.div`
  padding: 0 20px 20px;
  background: #000;
  display: flex;
  flex-direction: column;
`;

const FieldsContainer = styled.div`
  background-color: white;
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;
`;

const Title = styled.h2`
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 15px;
`;

const FieldTitle = styled.h2`
  align-items: center;
  display: flex;
  img {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
`;

const SubmitButton = styled.div`
  display: grid;
  justify-content: flex-end;
  margin: 20px 0 0 0;
`;
const ProductCheckBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
export {
  Container,
  FieldsContainer,
  FieldWrapper,
  FieldTitle,
  Title,
  SubmitButton,
  ProductCheckBoxWrapper,
};
