import styled from "styled-components";

const SupportContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 20px;
`;

const Wrapper = styled.div`
  .flag {
    width: 100px;
    margin-bottom: 5px;
  }

  iframe {
    width: 100%;
    height: 400px;
    border: 0px;
  }

  .iframe {
    margin-top: 19px;
  }
`;

const Address = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 20px;
  margin-bottom: 20px;
  grid-gap: 10px;
  p {
    margin: 0 0 5px 0;
    font-family: "Inter600";
    font-size: 14px;
    a {
      font-family: "Inter600";
      color: inherit;
    }
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #5c92e2;
  font-family: "Inter800";
  margin: 0;
`;

const SubTitle = styled.h1`
  text-align: center;
  font-family: "Inter600";
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  font-size: 16px;
  border-bottom: 1px solid #18191f;
`;

export { SupportContainer, Wrapper, Address, Title, SubTitle };
