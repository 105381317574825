/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { showLoader, hideLoader } from 'actions/loader';
import { useParams } from 'react-router-dom';
import { fabric } from 'fabric';
import { useSelector, useDispatch } from 'react-redux';
import { checkUserStatus } from 'actions/user';
import Loader from 'components/Loader';
import moment from 'moment';
import { getShipstationRates, getAllScreenPrinting } from 'actions/products';
import {
  getOrderDetail,
  addLineItem,
  removeLineItem,
  getFulfillmentCost,
  updateOrderCost,
  machineOrderDetails,
  updateOrderStatus,
  updateSavedOrderData,
  uploadArtToCloudinary,
  findGlobalOrder,
  bcThisOrder,
  saveAdvanceArtFiles,
} from 'actions/orders';
import { AUTH_LOGOUT } from 'actions/auth';
import { INITIAL_SYNC_PRODUCTS } from 'actions/sync-products';
import { INITIATE_STORES } from 'actions/stores';
import { getProdShipCostByDestAndPP } from 'selectors/products';
import Header from 'pages/order-details/Header';
import OrderDetailsDrawer from 'pages/order-details/OrderDetailsDrawer';
import OrdersStep from 'pages/order-details/OrderSteps';
import OrderInfo from 'pages/order-details/OrderInfo';
import OrderDetails from 'pages/order-details/OrderDetails';
import ShippingInfo from 'pages/order-details/ShippingInfo';
import OrderSummary from 'pages/order-details/OrderSummary';
import { Container } from 'styles/pages/order-details';
import { getCountries } from 'actions/countries';
import {
  getDesignerSettingsFromProductId,
  productHasBackDesignerSettings,
  getAdditionalTechniquesFromProductId,
} from 'selectors/products';
import { generateMocks } from 'actions/orders';
const XMLParser = require('react-xml-parser');

const addImageProcess = (artwork) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = artwork?.coordinates?.width;
    canvas.height = artwork?.coordinates?.height;
    const image = new Image();

    image.onload = function (e) {
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const reSampledImage = new Image();
      reSampledImage.src = canvas.toDataURL();
      resolve(canvas.toDataURL());
    };
    image.src = artwork?.artwork;
  });
};

const OrderDetailsPage = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  let { shopName } = useParams();
  shopName = decodeURIComponent(shopName);
  const [order, setOrder] = useState(undefined);
  const [detailDrawer, setDetailDrawer] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(1);
  const [firstTimeOrderLoad, setFirstTimeOrderLoad] = useState(true);
  const [machineStatus, setMachineStatus] = useState(undefined);
  const [updateCost, setUpdateCost] = useState(false);
  const [orderPrintProviders, setOrderPrintProviders] = useState({
    index: -1,
    printProviders: [],
  });

  const auth = useSelector((state) => state?.auth);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const products = useSelector((state) => state?.products?.data);
  const countries = useSelector((state) => state?.countries?.data);
  const [shippingCost, setShippingCost] = useState(0);

  const uploadArtImage = async (canvas, element) =>
    new Promise(
      async (resolve) =>
        await canvas.loadFromJSON(element?.canvasJSON, async () => {
          dispatch(showLoader());
          const data = canvas.toDataURL({
            format: 'png',
            multiplier: element?.designerSettings?.multiplier,
          });

          const API_ENDPOINT =
            'https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload';

          const fileData = new FormData();
          fileData.append('file', data);
          fileData.append('upload_preset', 'tnedst8q');
          fileData.append('folder', `Shirtly/${emailid}/AdvanceArtFiles`);

          const r = await fetch(API_ENDPOINT, {
            method: 'post',
            body: fileData,
          });
          const response = await r?.json();
          dispatch(hideLoader());
          resolve(response);
        })
    );

  const getTotalFulfillmentCost = () => {
    const orderLines = order?.OrderLines;

    if (!!orderLines?.length) {
      const orderLineItems = orderLines[0]?.OrderLineItems;

      if (orderLineItems?.length) {
        let fulfillmentCost = 0;
        for (let i = 0; i < orderLineItems?.length; i++) {
          fulfillmentCost += orderLineItems[i]?.FulfillmentCost;
        }
        return fulfillmentCost;
      }
    }
  };

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  useEffect(() => {
    const getUserStatusData = async () => {
      const data = await dispatch(checkUserStatus(emailid));
      if (data?.Status === 'In-Active') {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
    };

    if (auth?.authenticated) {
      getUserStatusData();
    }
  }, [auth, dispatch]);

  useEffect(() => {
    const loadOrderData = async () => {
      if (!order) {
        const order = await dispatch(
          getOrderDetail({
            orderId,
            emailid,
            shopName,
            token,
          })
        );

        const globalData = await dispatch(
          findGlobalOrder({ orderId: Number(orderId) })
        );

        if (order?.error) return;

        if (!order?.PrintProvider && order?.Status === 'AwaitingPayment') {
          await dispatch(
            updateOrderStatus({
              user: emailid,
              orderId: order?.ID,
              status: 'PendingDetails',
            })
          );
          loadOrderData();
          return;
        }

        if (order?.Status === 'AwaitingPayment') {
          order.OrderLines[0].OrderLineItems =
            order?.OrderLines[0]?.OrderLineItems?.map((o) => ({
              ...o,
              RemainingDecorativeTechniques:
                o?.RemainingDecorativeTechniques?.map((r) => ({
                  ...r,
                  selected: r?.DisplayName === o?.PrintType,
                })),
            }));
        }

        if (
          order &&
          order?.Marketplace === 'BigCommerce' &&
          !order?.OrderLines[0]?.OrderLineItems?.length
        ) {
          await dispatch(bcThisOrder(emailid, order?.RetailerName, order?.ID));
          setOrder(null);
        }

        setOrder({ ...order, captureImages: globalData?.captureImages });

        if (!order?.PrintProvider) {
          setActiveStepIndex(0);
        }

        const { OrderLines } = order;

        if (OrderLines?.length) {
          const { OrderLineItems } = OrderLines[0];

          if (OrderLineItems?.length) {
            if (!OrderLineItems[0].ProductID) {
              setDetailDrawer(true);
            } else {
              setDetailDrawer(false);
            }
          } else {
            setDetailDrawer(true);
          }
        }

        if (
          (order?.Status === 'InProduction' || order?.Status === 'Shipped') &&
          !machineStatus
        ) {
          const orderLineItems = order?.OrderLines[0]?.OrderLineItems;

          const isEmb = !!orderLineItems?.find((o) => o?.PrintType === 'EMB');
          const machineStatusData = await dispatch(
            machineOrderDetails({
              PrintProvider: isEmb ? 'CA' : order?.PrintProvider,
              orderPo: order?.ReceiptID,
            })
          );

          if (
            order?.PrintProvider !== 'EU' &&
            order?.PrintProvider !== 'AU' &&
            order?.PrintProvider !== 'NY'
          ) {
            const isOrderPushedToMachine =
              !machineStatusData.string._text.includes('No Order found');

            if (isOrderPushedToMachine) {
              const parsedMachineStatusData = new XMLParser().parseFromString(
                machineStatusData.string._text
              );

              const shipMethod =
                parsedMachineStatusData.getElementsByTagName('ShipMethod');

              const barcodePrinted =
                parsedMachineStatusData.getElementsByTagName('BarcodePrinted');

              const printed =
                parsedMachineStatusData.getElementsByTagName('Printed');

              const shipped =
                parsedMachineStatusData.getElementsByTagName('Shipped');

              const trackingNumber =
                parsedMachineStatusData.getElementsByTagName('trackingNumber');
              const shipDate =
                parsedMachineStatusData.getElementsByTagName('shipDate');

              const statusData = {
                barcodePrinted: barcodePrinted.length
                  ? barcodePrinted[0]?.value
                  : false,
                shipMethod:
                  shipMethod?.length > 0 ? shipMethod[0].value : false,
                printed: printed.length ? printed[0]?.value : false,
                shipped: shipped.length ? shipped[0]?.value : false,
                trackingNumber: trackingNumber.length
                  ? trackingNumber[0]?.value
                  : false,
                shipDate: shipDate.length
                  ? moment(shipDate[0]?.value).format('YYYY-MM-DD')
                  : false,
              };

              setMachineStatus(statusData);

              if (
                shipped[0]?.value === 'true' &&
                order?.Status === 'InProduction'
              ) {
                await dispatch(
                  updateOrderStatus({
                    user: emailid,
                    orderId: order?.ID,
                    status: 'Shipped',
                    trackingNumber: trackingNumber[0]?.value,
                    shipDate: moment(shipDate[0]?.value).format('YYYY-MM-DD'),
                  })
                );

                await dispatch(
                  updateSavedOrderData({
                    orderId: order?.ID,
                    trackingNumber: trackingNumber[0]?.value,
                    shipDate: moment(shipDate[0]?.value).format('YYYY-MM-DD'),
                  })
                );

                setOrder(undefined);
                setShippingCost(0);
                setOrderPrintProviders({
                  index: -1,
                  printProviders: [],
                });
              }
            }
          } else {
            const status =
              machineStatusData?.status ||
              machineStatusData?.msg[0]?.order_status;
            const statusData = {
              barcodePrinted:
                status === 'New' ||
                status === 'waiting' ||
                status === 'created' ||
                status === 'Order Received'
                  ? 'false'
                  : 'true',
              shipMethod: 'false',
              printed:
                status === 'Ready for shipment' ||
                status === 'sent' ||
                status === 'shipped' ||
                status === 'Ready to Ship'
                  ? 'true'
                  : 'false',
              shipped:
                status === 'sent' ||
                status === 'Sent' ||
                status === 'shipped' ||
                status === 'Shipped'
                  ? 'true'
                  : 'false',
              trackingNumber:
                order?.PrintProvider === 'EU'
                  ? machineStatusData?.tracking_number
                  : order?.PrintProvider === 'NY'
                  ? machineStatusData?.msg[0]?.tracking_number
                  : machineStatusData?.events?.find(
                      (e) => e?.action === 'shipped'
                    )?.trackingNumber,
              shipDate:
                order?.PrintProvider === 'AU'
                  ? moment(
                      machineStatusData?.events?.find(
                        (e) => e?.action === 'shipped'
                      )?.time
                    ).format('MM/DD/YYYY')
                  : order?.PrintProvider === 'NY'
                  ? machineStatusData?.msg[0]?.completed_on
                  : 'false',

              trackingUrl:
                order?.PrintProvider === 'AU'
                  ? machineStatusData?.events?.find(
                      (e) => e?.action === 'shipped'
                    )?.trackingUrl
                  : order?.PrintProvider === 'NY'
                  ? machineStatusData?.msg[0]?.tracking_url
                  : 'false',
            };

            setMachineStatus(statusData);

            if (status === 'cancelled' || status === 'Cancelled') {
              setOrder(undefined);
              setShippingCost(0);
              setOrderPrintProviders({
                index: -1,
                printProviders: [],
              });
              await dispatch(
                updateOrderStatus({
                  user: emailid,
                  orderId: order?.ID,
                  status: 'Cancelled',
                })
              );
              await dispatch(
                updateSavedOrderData({
                  orderId: order?.ID,
                  status: 'Cancelled',
                })
              );
              window.location.reload();
            } else if (
              (status === 'shipped' ||
                status === 'Shipped' ||
                status === 'sent' ||
                status === 'Sent') &&
              order?.Status === 'InProduction'
            ) {
              const shipDate =
                machineStatusData?.ship_date ||
                moment(machineStatusData?.msg[0]?.completed_on).format(
                  'YYYY-MM-DD'
                ) ||
                moment().format('YYYY-MM-DD');

              await dispatch(
                updateOrderStatus({
                  user: emailid,
                  orderId: order?.ID,
                  status: 'Shipped',
                  trackingNumber:
                    machineStatusData?.tracking_number ||
                    machineStatusData?.msg[0]?.tracking_number,
                  shipDate,
                })
              );

              await dispatch(
                updateSavedOrderData({
                  orderId: order?.ID,
                  trackingNumber:
                    machineStatusData?.tracking_number ||
                    machineStatusData?.msg[0]?.tracking_number,
                  shipDate,
                })
              );

              setOrder(undefined);
              setShippingCost(0);
              setOrderPrintProviders({
                index: -1,
                printProviders: [],
              });
            }
          }
        }
      }
    };
    loadOrderData();
  }, [
    dispatch,
    emailid,
    orderId,
    order,
    shopName,
    machineStatus,
    setMachineStatus,
  ]);

  useEffect(() => {
    const OrderLines = order?.OrderLines;
    const { OrderLineItems } = OrderLines?.length ? OrderLines[0] : {};

    const isFulfillmentPending = !!_.find(
      OrderLineItems,
      (c) => !c.FulfillmentCost
    );

    if (
      (order?.Status === 'AwaitingPayment' ||
        order?.Status === 'InProduction' ||
        order?.Status === 'Shipped' ||
        order?.Status === 'Cancelled') &&
      firstTimeOrderLoad &&
      !isFulfillmentPending
    ) {
      setActiveStepIndex(3);
    }
    if (order?.Status) {
      setFirstTimeOrderLoad(false);
    }
  }, [dispatch, order, firstTimeOrderLoad, setFirstTimeOrderLoad]);

  useEffect(async () => {
    if (updateCost && order) {
      setUpdateCost(false);
      const data = {
        user: emailid,
        orderId: order?.ID,
        ShippingCost: shippingCost?.cost,
        TotalCost: shippingCost?.cost + getTotalFulfillmentCost(),
      };
      const response = await dispatch(updateOrderCost(data));
      if (response.error) {
        return;
      }

      setOrder(undefined);
      setShippingCost(0);
      setOrderPrintProviders({
        index: -1,
        printProviders: [],
      });
    }
  }, [
    order,
    updateCost,
    setUpdateCost,
    dispatch,
    emailid,
    getTotalFulfillmentCost,
    shippingCost,
    setOrder,
  ]);

  useEffect(() => {
    const changeOrderStatus = async () => {
      if (order?.Status === 'AwaitingPayment') {
        const orderLineItems = order?.OrderLines[0]?.OrderLineItems;
        let setStatusPendingDetails = false;

        for (let i = 0; i < orderLineItems?.length; i++) {
          const row = orderLineItems[i];
          const remainingTechniques = row?.RemainingDecorativeTechniques;
          if (remainingTechniques?.length) {
            const productRemainingTechs =
              _.find(products, (p) => p?.Product?.ID === row?.ProductID)
                ?.RemainingDecorativeTechniques || [];

            if (productRemainingTechs?.length) {
              for (let j = 0; j < remainingTechniques?.length; j++) {
                const isExist = !!_.find(
                  productRemainingTechs,
                  (p) => p?.DisplayName === remainingTechniques[j]?.DisplayName
                );

                if (!isExist) {
                  setStatusPendingDetails = true;
                  break;
                }
              }
            } else if (remainingTechniques?.length) {
              setStatusPendingDetails = true;
            }

            if (setStatusPendingDetails) {
              await dispatch(
                updateOrderStatus({
                  user: emailid,
                  orderId: order?.ID,
                  status: 'PendingDetails',
                })
              );
              const data = await dispatch(
                getOrderDetail({
                  orderId,
                  emailid,
                  shopName,
                  printTypeDeleted: true,
                  token,
                })
              );

              if (data?.error) {
                return;
              }

              setOrder(data);
              setActiveStepIndex(1);
            }
          }
        }
      }
    };
    changeOrderStatus();
  }, [dispatch, products]);

  useEffect(() => {
    const shipMethod = machineStatus?.shipMethod;
    const printProviders = orderPrintProviders?.printProviders;
    let index = -1;
    if (shipMethod && printProviders?.length > 1) {
      for (let i = 0; i < printProviders.length; i++) {
        const element = printProviders[i];
        const shippingCosts = element?.ShippingCosts;
        const exist = !!shippingCosts?.find((s) => s?.Title === shipMethod);

        if (exist) {
          index = i;
          break;
        }
      }
    }

    setOrderPrintProviders((prevState) => ({
      ...prevState,
      index,
    }));
  }, [machineStatus]);

  // useEffect(() => {
  //   calculateShippingCost();
  // }, [products]);

  const getOrderLineItemsStatus = (skipIndex = -1) => {
    const OrderLineItems = order?.OrderLines[0]?.OrderLineItems;

    for (let i = 0; i < OrderLineItems?.length; i++) {
      const printAreas = OrderLineItems[i]?.printAreas;
      const availableArtsLength = printAreas?.filter(
        (p) => p?.artwork || p?.canvasJSON
      )?.length;

      if (
        (!OrderLineItems[i].ProductID ||
          !OrderLineItems[i].SizeID ||
          !OrderLineItems[i].ColorID ||
          !OrderLineItems[i].CategoryID ||
          availableArtsLength === 0) &&
        i !== skipIndex &&
        OrderLineItems[i]?.PrintType !== 'AOP'
      ) {
        return 'PendingDetails';
      }
    }
    return 'AwaitingPayment';
  };

  const fetchShipstationRates = async (payload) => {
    const response = await dispatch(getShipstationRates(payload));

    if (!response?.ExceptionMessage) {
      setShippingCost({
        ...shippingCost,
        cost: Number(
          (
            Number(response?.otherCost) + Number(response?.shipmentCost)
          ).toFixed(2)
        ),
      });
    }
  };

  const calculateShippingCost = (carrierCode, shipCostIndex) => {
    const index =
      shipCostIndex >= 0 ? shipCostIndex : orderPrintProviders?.index;
    const orderLineItems = order?.OrderLines[0]?.OrderLineItems;
    if (orderLineItems?.length > 0) {
      const majorProductIds = [1, 2, 446, 450, 454, 455, 457, 459, 1614798309];
      const subMajorIds = [2001];
      let product = orderLineItems[0];
      let quantity = 0;

      let orderLineItmsHasWeight = true;

      for (let i = 0; i < orderLineItems.length; i++) {
        const weight = _.find(
          products,
          (c) => c?.Product?.ID === orderLineItems[i]?.ProductID
        )?.Product?.weight;
        if (!weight) {
          orderLineItmsHasWeight = false;
          break;
        }
      }

      if (!orderLineItmsHasWeight) {
        for (let i = 0; i < orderLineItems.length; i++) {
          if (_.includes(subMajorIds, orderLineItems[i].ProductID)) {
            product = orderLineItems[i];
            break;
          }

          if (_.includes(majorProductIds, orderLineItems[i].ProductID)) {
            product = orderLineItems[i];
            break;
          }
        }
      } else {
        for (let i = 0; i < orderLineItems.length; i++) {
          if (i === 0) product = orderLineItems[i];

          const quantity1 = Number(orderLineItems[i]?.OliQuantity);
          const weight1 = _.find(
            products,
            (c) => c?.Product?.ID === orderLineItems[i]?.ProductID
          )?.Product?.weight;

          for (let j = i + 1; j < orderLineItems.length; j++) {
            const quantity2 = Number(orderLineItems[j]?.OliQuantity);
            const weight2 = _.find(
              products,
              (c) => c?.Product?.ID === orderLineItems[j]?.ProductID
            )?.Product?.weight;

            if (quantity2 * weight2 > quantity1 * weight1) {
              product = orderLineItems[j];
            }
          }
        }
      }

      for (let i = 0; i < orderLineItems.length; i++) {
        quantity += Number(orderLineItems[i]?.OliQuantity) || 0;
      }

      const pp = _.find(
        products,
        (c) => c?.Product?.ID === product?.ProductID
      )?.PrintProviders;

      if (pp?.length > 1 && index === -1) {
        const printProviders = _.filter(
          pp,
          (c) => c?.ProviderName === order?.PrintProvider
        );

        setOrderPrintProviders({
          index: 0,
          printProviders,
        });
      }

      setShippingCost(
        getProdShipCostByDestAndPP(
          products,
          order?.PrintProvider,
          order?.CountryString,
          product,
          quantity,
          index,
          countries
        )
      );

      if (orderLineItmsHasWeight) {
        let totalWeight = 0;

        for (let i = 0; i < orderLineItems.length; i++) {
          const quantity = Number(orderLineItems[i]?.OliQuantity);
          const weight = _.find(
            products,
            (c) => c?.Product?.ID === orderLineItems[i]?.ProductID
          )?.Product?.weight;
          totalWeight += weight * quantity;
        }

        const payload = {
          printProvider: order?.PrintProvider,
          carrierCode: carrierCode
            ? carrierCode
            : order?.PrintProvider === 'CA'
            ? 'canada_post'
            : order?.PrintProvider === 'US'
            ? 'stamps_com'
            : '',
          toCountry: order?.CountryString,
          toPostalCode: order?.ZipPostal?.toString()?.replace(/\s/g, ''),
          totalWeight: totalWeight,
        };
        fetchShipstationRates(payload);
      }
    }
  };

  useEffect(() => {
    if (!shippingCost && order && products?.length) calculateShippingCost();
  }, [shippingCost, order, products]);

  if (!products?.length || !order) return <Loader open={true} />;

  if (order && order?.OrderLines?.length) {
    const onUpdateOrderLineItem = async (payload, index = 0) => {
      const {
        Color: color,
        PrintType: printType,
        OliQuantity,
        Product: displayName,
        printAreas,
      } = payload;
      const quantity = OliQuantity || 1;
      if (payload?.Artwork?.includes('data:image/png;base64')) {
        let designerSettings = getDesignerSettingsFromProductId(
          products,
          payload?.ProductID
        );

        if (printType !== 'DTG') {
          designerSettings =
            getAdditionalTechniquesFromProductId(
              products,
              payload?.ProductID,
              false,
              printType
            ) || designerSettings;
        }

        const width = parseFloat(
          payload?.ArtWorkCoordinates?.width * designerSettings?.multiplier
        );

        const height = parseFloat(
          payload?.ArtWorkCoordinates?.height * designerSettings?.multiplier
        );

        const a = await dispatch(
          uploadArtToCloudinary({
            user: emailid,
            base64: payload?.Artwork,
            width,
            height,
          })
        );
        payload.Artwork = a;
      }

      if (payload?.BackArtwork?.includes('data:image/png;base64')) {
        let backDesignerSettings = productHasBackDesignerSettings(
          products,
          payload?.ProductID
        );

        if (printType !== 'DTG') {
          backDesignerSettings =
            getAdditionalTechniquesFromProductId(
              products,
              payload?.ProductID,
              true,
              printType
            ) || backDesignerSettings;
        }
        const width = parseFloat(
          payload?.BackArtWorkCoordinates?.width *
            backDesignerSettings?.multiplier
        );

        const height = parseFloat(
          payload?.BackArtWorkCoordinates?.height *
            backDesignerSettings?.multiplier
        );
        const a = await dispatch(
          uploadArtToCloudinary({
            user: emailid,
            base64: payload?.BackArtwork,
            width,
            height,
          })
        );
        payload.BackArtwork = a;
      }

      const fulfillmentCost = await dispatch(
        getFulfillmentCost({
          prodId: payload.ProductID,
          prodSize: payload.Size,
        })
      );

      const OrderLineItems = order?.OrderLines[0]?.OrderLineItems[index];

      const { ColoredPrice: coloredPrice, WhitePrice: whitePrice } =
        fulfillmentCost;
      const availableArts = printAreas?.filter((p) => p?.artwork);
      const availableCanvasJSON = printAreas?.filter(
        (p) => p?.canvasJSON?.objects?.length > 0
      );

      const availableArtsLength = availableArts?.length;
      const availableCanvasJSONLength = availableCanvasJSON?.length;

      const printTypePrice =
        (fulfillmentCost[`${printType}Price`] || 0) *
        (availableArtsLength || availableCanvasJSONLength);

      if (availableArtsLength > 0) {
        for await (let row of availableArts) {
          await new Promise(async (resolve, reject) => {
            const {
              coordinates,
              artwork,
              designerSettings,
              printAreaMarketingImage,
              printAreaName,
            } = row;

            const { width, height, x = 0, y = 0 } = coordinates;
            const {
              left,
              top,
              width: designerSettingsWidth,
            } = designerSettings;

            const artworkImageSplit = artwork?.split('/upload/');
            const artImageDataUrl =
              artwork?.indexOf('base64') > 0
                ? await addImageProcess(row)
                : null;

            const isSub = printType === 'SUB';

            const mainImageSplit = printAreaMarketingImage?.split('/upload/');

            const mockUpPayload = {
              mainImages: [
                `${mainImageSplit[0]}/upload/w_565,h_565/${mainImageSplit[1]}`,
              ],
              user: emailid,
              productName: displayName,
              artImage: artImageDataUrl
                ? artImageDataUrl
                : artworkImageSplit
                ? isSub
                  ? `${artworkImageSplit[0]}/upload/w_${Math.round(
                      parseInt(width)
                    )},h_${Math.round(parseInt(height))}/${
                      artworkImageSplit[1]
                    }`
                  : `${artworkImageSplit[0]}/upload/w_${Math.round(
                      width
                    )},h_${Math.round(height)}/${artworkImageSplit[1]}`
                : null,

              x: artwork
                ? isSub
                  ? Math.round(left / 2 + x + (565 - designerSettingsWidth) / 2)
                  : Math.round((565 - designerSettingsWidth) / 2 + x + left / 2)
                : 0,

              y: artwork ? (isSub ? parseInt(top + y) : top + y) : 0,
            };

            const response = await dispatch(generateMocks(mockUpPayload));

            if (response?.error) {
              return;
            }

            payload.printAreas = payload.printAreas?.map((p) => {
              if (p?.printAreaName !== printAreaName) {
                return p;
              }
              return {
                ...p,
                MockupSrc: response[0],
              };
            });

            resolve();
          });
        }
      } else if (availableCanvasJSONLength > 0) {
        for await (let row of availableCanvasJSON) {
          await new Promise(async (resolve, reject) => {
            const {
              designerSettings,
              printAreaMarketingImage,
              printAreaName,
              canvasJSON,
            } = row;

            if (!canvasJSON?.objects?.length) {
              payload.printAreas = payload.printAreas?.map((p) => {
                if (p?.printAreaName !== printAreaName) {
                  return p;
                }
                return {
                  ...p,
                  MockupSrc: null,
                };
              });
              return resolve();
            }

            const { width, height, left, top } = designerSettings;

            const canvas = new fabric.Canvas('order-details-canvas', {
              width,
              height,
            });
            canvas.clear();

            const data = await uploadArtImage(canvas, row);

            await dispatch(
              saveAdvanceArtFiles({
                ArtId: moment().unix(),
                user: emailid,
                FileName: `${moment().unix()}_${printAreaName}`,
                AdvanceArtData: {
                  CloudinaryObject: data,
                  CanvasJsonObject: canvasJSON,
                },
              })
            );

            const artwork = data?.secure_url;

            const artworkImageSplit = artwork?.split('/upload/');

            const isSub = printType === 'SUB';

            const mainImageSplit = printAreaMarketingImage?.split('/upload/');

            const mockUpPayload = {
              mainImages: [
                `${mainImageSplit[0]}/upload/w_565,h_565/${mainImageSplit[1]}`,
              ],
              user: emailid,
              productName: displayName,
              artImage: artworkImageSplit
                ? isSub
                  ? `${artworkImageSplit[0]}/upload/w_${Math.round(
                      parseInt(width)
                    )},h_${Math.round(parseInt(height))}/${
                      artworkImageSplit[1]
                    }`
                  : `${artworkImageSplit[0]}/upload/w_${Math.round(
                      width
                    )},h_${Math.round(height)}/${artworkImageSplit[1]}`
                : null,
              x: isSub
                ? Math.round(left / 2 + (565 - width) / 2)
                : Math.round((565 - width) / 2 + left / 2),
              y: top,
            };

            const response = await dispatch(generateMocks(mockUpPayload));

            if (response?.error) {
              return;
            }

            payload.printAreas = payload.printAreas?.map((p) => {
              if (p?.printAreaName !== printAreaName) {
                return p;
              }
              return {
                ...p,
                MockupSrc: response[0],
                canvasArtBoardImage: artwork,
              };
            });

            resolve();
          });
        }
      }

      const productSizes = _.find(
        products,
        (p) => p?.Product?.ID === payload?.ProductID
      )?.Sizes;

      const selectedSize = productSizes?.find((s) => payload?.SizeID === s.ID);

      let price = 0;

      /* Set price according to the white or colored product */
      price += color !== 'White' ? coloredPrice : whitePrice || coloredPrice;

      /* Adding Print type price */
      price += printTypePrice;

      /* Adding $1 if user used pre-designs */
      for (let i = 0; i < availableArts?.length; i++) {
        const p = availableArts[i];
        const isPreOrPhases =
          p?.artwork?.includes('Pre-designs') ||
          p?.artwork?.includes('Shirtly-Phrases');

        if (isPreOrPhases) {
          const a = quantity * 1;
          price += a;
        }
      }

      /* If selected plus print type */

      if (payload?.Plus) {
        for (let i = 0; i < availableArts?.length; i++) {
          const p = availableArts[i];
          if (p?.designerSettings?.artboardPrice) {
            price += p?.designerSettings?.artboardPrice;
          } else {
            price += 6;
          }
        }
      }

      /* If selected DTG plus print type */
      if (payload?.DtgPlus) {
        price += payload?.dtgProcessName?.Price || 0;
        const b = selectedSize?.DTGPrice
          ? selectedSize?.DTGPrice * availableArtsLength
          : 0;
        price += b;
      }

      price *= quantity;

      const remainingDecorativeTechniques =
        payload?.RemainingDecorativeTechniques;

      const isScreenPrinting = remainingDecorativeTechniques?.find(
        (x) => x?.DisplayName === 'ScreenPrint' && x?.selected
      );

      if (!!isScreenPrinting) {
        const screenPrintData = await dispatch(getAllScreenPrinting());

        const selectedScreenPrinting =
          screenPrintData?.find((x) => x?.colorName === payload?.Color) ||
          screenPrintData?.find((x) => x?.colorName === 'Any Color');

        price += Number(selectedScreenPrinting?.filmPrice);
      }

      const data = {
        orderId: order.ID,
        Marketplace: order?.Marketplace,
        PrintProvider: order?.PrintProvider,
        user: emailid,
        orderLineItemData: {
          ...(OrderLineItems || {}),
          ...payload,
          FulfillmentCost: price,
          OliQuantity: quantity,
        },
      };

      if (
        data?.orderLineItemData?.BackArtworkId &&
        (!data?.orderLineItemData?.BackArtWorkCoordinates?.width ||
          !data?.orderLineItemData?.BackArtWorkCoordinates?.height)
      ) {
        alert(
          'Something went wrong. Please reload the page and add line item again.'
        );
        return;
      }

      if (
        data?.orderLineItemData?.ArtworkId &&
        (!data?.orderLineItemData?.ArtWorkCoordinates?.width ||
          !data?.orderLineItemData?.ArtWorkCoordinates?.height)
      ) {
        alert(
          'Something went wrong. Please reload the page and add line item again.'
        );
        return;
      }

      const response = await dispatch(addLineItem(data));

      if (response.error) {
        return;
      }

      setOrder(response);
      setUpdateCost(true);
      setDetailDrawer(false);
    };

    const onRemoveLineItem = async (lineItemId) => {
      const data = {
        orderId: order.ID,
        user: emailid,
        lineItemId,
      };
      const response = await dispatch(removeLineItem(data));
      if (response.error) {
        return;
      }
      setOrder(undefined);
      setShippingCost(0);
      setOrderPrintProviders({
        index: -1,
        printProviders: [],
      });
    };

    return (
      <Container>
        <Header />
        <OrdersStep
          activeStepIndex={activeStepIndex}
          updateActiveIndex={(i) => setActiveStepIndex(i)}
          order={order}
        />

        {activeStepIndex === 0 && (
          <OrderInfo
            orderDetail={order}
            refresh={() => {
              setActiveStepIndex(1);
              setOrder(undefined);
              setShippingCost(0);
            }}
          />
        )}
        {activeStepIndex === 1 && order && (
          <OrderDetails
            order={order}
            onRemoveLineItem={async (lineItemId) => {
              const response = await onRemoveLineItem(lineItemId);
              setOrder(response);
              setUpdateCost(true);
              setShippingCost(0);
            }}
            onUpdateOrderLineItem={async (payload, index) => {
              await onUpdateOrderLineItem(
                {
                  ...payload,
                  setStatus: getOrderLineItemsStatus(index),
                },
                index
              );
              setOrder(undefined);
              setShippingCost(0);
              setOrderPrintProviders({
                index: -1,
                printProviders: [],
              });
            }}
            onShip={async () => {
              setActiveStepIndex(2);
            }}
          />
        )}
        {activeStepIndex === 2 && (
          <ShippingInfo
            order={order}
            shippingCostInfo={shippingCost}
            onOrderSummary={async () => setActiveStepIndex(3)}
            orderPrintProviders={orderPrintProviders}
            onPrintProviderChange={(f, carrierCode) => {
              setOrderPrintProviders({
                ...orderPrintProviders,
                index: f,
              });

              calculateShippingCost(carrierCode, f);
            }}
          />
        )}
        {activeStepIndex === 3 && (
          <OrderSummary
            order={order}
            shippingCostInfo={shippingCost}
            addMessage={async (message) => {
              const data = {
                orderId: order.ID,
                user: emailid,
                message,
              };

              const response = await dispatch(addLineItem(data));

              if (response.error) {
                return;
              }

              setOrder(undefined);
            }}
            refresh={() => {
              setOrder(undefined);
              setShippingCost(0);
              setOrderPrintProviders({
                index: -1,
                printProviders: [],
              });
            }}
            machineStatus={machineStatus}
            orderPrintProviders={orderPrintProviders}
          />
        )}

        {activeStepIndex === 1 && (
          <OrderDetailsDrawer
            order={order}
            open={detailDrawer}
            onClose={() => setDetailDrawer(false)}
            onUpdateOrderLineItem={async (payload) =>
              await onUpdateOrderLineItem({
                ...payload,
                setStatus: 'PendingDetails',
              })
            }
          />
        )}
        <div style={{ display: 'none' }}>
          <canvas
            id="order-details-canvas"
            style={{ display: 'none' }}
          ></canvas>
        </div>
      </Container>
    );
  }
};

export default OrderDetailsPage;
