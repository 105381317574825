import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getProductsByPromos,
  getProductsByCategoryIds,
  getProductsByFulfillmentPlants,
  getProductsByDecoratingTech,
  getProductsByType,
} from "selectors/products";
import Loader from "components/Loader";
import Header from "pages/browse-catalog/Header";
import Filters from "pages/browse-catalog/Filters";
import Products from "pages/browse-catalog/Products";
import { Container, Wrapper } from "styles/pages/browse-catalog";

const BrowseCatalog = () => {
  const { state: locationState } = useLocation();
  const loaderVisibility = useSelector((state) => state?.loader?.visibility);
  const allProducts = useSelector((state) => state?.products?.data);
  const [products, setProducts] = useState(undefined);
  const [promos, setPromos] = useState([]);
  const [premium, setPremium] = useState([]);
  const [fulfillmentPlants, setFulfillmentPlants] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [decoratingTechFilter, setDecoratingTechFilter] = useState([]);
  const [stylesFilter, setStylesFilter] = useState([]);
  const [premiumFilter, setPremiumFilter] = useState([]);

  const [brands, setBrands] = useState([]);
  const [colorsFilter, setColorsFilter] = useState([]);

  const resetFilters = () => {
    setPromos([]);
    setPremium([]);
    setCategoriesFilter([]);
    setStylesFilter([]);
    setBrands([]);
    setColorsFilter([]);
    setFulfillmentPlants([]);
    setProducts(
      allProducts?.filter(
        (p) => p?.Product?.visible === true || p?.Product?.visible === undefined
      )
    );
  };

  useEffect(() => {
    let products = [];
    products = getProductsByPromos(allProducts, promos);
    products = getProductsByFulfillmentPlants(products, fulfillmentPlants);
    products = getProductsByCategoryIds(products, categoriesFilter);
    products = getProductsByPromos(products, stylesFilter);
    products = getProductsByDecoratingTech(products, decoratingTechFilter);
    products = getProductsByType(products, premiumFilter);

    setProducts(
      products?.filter(
        (p) => p?.Product?.visible === true || p?.Product?.visible === undefined
      )
    );
  }, [
    allProducts,
    promos,
    fulfillmentPlants,
    categoriesFilter,
    stylesFilter,
    decoratingTechFilter,
    premiumFilter,
    setProducts,
    setPromos,
  ]);

  useEffect(() => {
    setPromos(
      locationState && locationState?.Promos ? [locationState.Promos] : []
    );

    setCategoriesFilter(
      locationState && locationState?.Categories
        ? [locationState.Categories]
        : []
    );

    setStylesFilter(
      locationState && locationState?.Styles ? [locationState.Styles] : []
    );
  }, [locationState]);

  if (!allProducts?.length) return <Loader open={true} />;

  return (
    <Container>
      <Header />
      <Wrapper>
        <Filters
          resetFilters={resetFilters}
          setDecoratingTechFilter={(v) => {
            const isExist = !!_.filter(decoratingTechFilter, (o) => o === v)
              ?.length;

            if (isExist) {
              setDecoratingTechFilter(_.without(decoratingTechFilter, v));
            } else {
              setDecoratingTechFilter([...decoratingTechFilter, v]);
            }
          }}
          decoratingTechFilter={decoratingTechFilter}
          fulfillmentPlants={fulfillmentPlants}
          updateFulfillmentPlants={(fulfillmentPlant) => {
            const isExist = !!_.filter(
              fulfillmentPlants,
              (o) => o === fulfillmentPlant
            )?.length;

            if (isExist) {
              setFulfillmentPlants(
                _.filter(fulfillmentPlants, (o) => o !== fulfillmentPlant)
              );
            } else {
              setFulfillmentPlants([...fulfillmentPlants, fulfillmentPlant]);
            }
          }}
          promos={promos}
          updatePromos={(promo) => {
            const isExist = !!_.filter(promos, (o) => o.Id === promo.Id)
              ?.length;

            if (isExist) {
              setPromos(_.filter(promos, (o) => o.Id !== promo.Id));
            } else {
              setPromos([...promos, promo]);
            }
          }}
          categories={categoriesFilter}
          updateCategories={(categoryId) => {
            const isExist = !!_.filter(
              categoriesFilter,
              (o) => o === categoryId
            )?.length;
            if (isExist) {
              setCategoriesFilter(_.without(categoriesFilter, categoryId));
            } else {
              setCategoriesFilter([...categoriesFilter, categoryId]);
            }
          }}
          stylesFilter={stylesFilter}
          updateStylesFilter={(style) => {
            const isExist = !!_.filter(stylesFilter, (o) => o.Id === style.Id)
              ?.length;
            if (isExist) {
              setStylesFilter(_.filter(stylesFilter, (o) => o.Id !== style.Id));
            } else {
              setStylesFilter([...stylesFilter, style]);
            }
          }}
          brands={brands}
          updateBrandsFilter={(brandName) => {
            const isExist = !!_.filter(brands, (o) => o === brandName)?.length;
            if (isExist) {
              setBrands(_.without(brands, brandName));
            } else {
              setBrands([...brands, brandName]);
            }
          }}
          colorsFilter={colorsFilter}
          updateColorsFilter={(colorId) => {
            const isExist = !!_.filter(colorsFilter, (o) => o === colorId)
              ?.length;
            if (isExist) {
              setColorsFilter(_.without(colorsFilter, colorId));
            } else {
              setColorsFilter([...colorsFilter, colorId]);
            }
          }}
          premiumFilter={premiumFilter}
          updatePremiumFilter={(d) => {
            const isExist = !!_.filter(premiumFilter, (o) => o === d)?.length;
            if (isExist) {
              setPremiumFilter(_.without(premiumFilter, d));
            } else {
              setPremiumFilter([...premiumFilter, d]);
            }
          }}
        />
        <Products resetFilters={resetFilters} products={products} />
      </Wrapper>
      {loaderVisibility && <Loader open={loaderVisibility} />}
    </Container>
  );
};

export default BrowseCatalog;
