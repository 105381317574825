import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  max-width: 100vw;
  overflow-x: auto;
`;
const ShippingVender = styled.h3``;
const ShippingCost = styled.div`
  min-width: 600px;
`;
const Heading = styled.div`
  background: rgba(0, 0, 0, 0.1);
  color: ${COLORS.DARK_BLUE};
  text-align: center;
  text-transform: uppercase;
  padding: 0.5em;
  font-weight: 600;
  font-size: 13px;
`;
const Heading1 = styled.div`
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5em;
  color: ${COLORS.BODY_TEXT};
  font-size: 13px;
`;
const Table = styled.div`
  display: flex;
  color: ${COLORS.BODY_TEXT};
  font-weight: 600;
  font-size: 13px;
`;
const P = styled.p`
  flex: 1;
  margin: 0;
  padding: 8px;
  font-weight: 600;
  font-size: 13px;
  &:first-child {
    flex: 2;
  }
`;

const State = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;

  .MuiButton-label {
    display: flex;
    margin: 0 10px;
  }

  .MuiButton-endIcon {
    flex: 1;
    justify-content: flex-end;
  }

  .MuiButton-startIcon {
    margin: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      width: 100%;
      margin-bottom: 20px !important;
      :last-child {
        margin-bottom: 0 !important;
      }
    }
  }
`;

const ButtonStyles = {
  fontSize: "13px",
  boxShadow: " 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff",
  borderRadius: "20px",
  padding: "5px",
  lineHeight: "1.5",
  textTransform: "inherit",
  color: "#86878b",
  margin: "0 3px",
  border: "1px solid transparent",
  flex: "1",
  textAlign: "left",
  fontWeight: 600,
  justifyContent: "start",
  height: "42px",
};

const FlagStyles = {
  borderRadius: "100%",
  boxShadow: "4px 4px 5px rgba(0, 0, 0, 0.1)",
  width: 30,
  height: 30,
  marginRight: 10,
};

const activeButtonStyles = {
  backgroundColor: COLORS.DARK_BLUE,
  color: COLORS.WHITE,
};

const ZipCodeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  gap: 20px;

  .MuiFormControl-root {
    width: 30%;
  }

  button {
    background-color: #5c92e2;
    color: white;
    :disabled {
      opacity: 0.5;
    }
    :hover {
      background-color: #5c92e2;
      color: white;
    }
  }
`;

const ShippingCarriersWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
`;

const ShippingCarrier = styled.div`
  border: 1px solid #283948;
  font-size: 13px;
  border-radius: 20px;
  justify-content: center;
  font-family: "Inter500";
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${(props) => (props?.selected ? "#283948" : "transparent")};
  color: ${(props) => (props?.selected ? "white" : "#283948")};
`;

const Error = styled.h4`
  color: red;
  margin: 0;
`;

const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  Container,
  ShippingVender,
  ShippingCost,
  Heading,
  Heading1,
  Table,
  P,
  State,
  ButtonStyles,
  FlagStyles,
  activeButtonStyles,
  ZipCodeWrapper,
  ShippingCarriersWrapper,
  ShippingCarrier,
  Error,
  Description,
};
