import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import OrderTableHead from 'pages/ip-program/table/Head';
import Body from 'pages/ip-program/table/Body';
import { TableWrapper } from 'styles/pages/orders';

const ArtFilesTable = ({ artFiles = [], onDelete, onApprove, onReject }) => (
  <TableWrapper>
    <TableContainer className='container' component={Paper}>
      <Table>
        <OrderTableHead />
        <TableBody>
          <Body
            artFiles={artFiles}
            onDelete={onDelete}
            onApprove={onApprove}
            onReject={onReject}
          />
        </TableBody>
      </Table>
    </TableContainer>
  </TableWrapper>
);

export default ArtFilesTable;
