import React from "react";
import SubHeader from "components/SubHeader";
import { ROUTES } from "shared/constants/routes";

const Header = () => (
  <SubHeader
    isDark
    title="Order Detail"
    links={[
      {
        title: ROUTES.DASHBOARD.TITLE,
        to: ROUTES.DASHBOARD.LINK,
      },
      {
        title: ROUTES.ORDERS.TITLE,
        to: ROUTES.ORDERS.LINK,
      },
    ]}
    currentPageTitle="Order Detail"
  />
);
export default Header;
