import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditProductInformation from 'pages/edit-products/EditProductInformation';
import EditColorInformation from 'pages/edit-products/EditColorInformation';
import EditProductSizes from 'pages/edit-products/EditProductSizes';
import EditPrintProviderInformation from 'pages/edit-products/EditPrintProvider';
import EditProductDesignerSettings from 'pages/edit-products/EditProductDesignerSettings';
import EditDtgPlus from 'pages/edit-products/EditDtgPlus';
import EditProductSKU from 'pages/edit-products/EditProductSKU';
import EditProductArtwork from 'pages/edit-products/EditProductArtwork';
import EditProductAdditionalImages from 'pages/edit-products/EditProductAdditionalImages';
import EditPrintAreas from 'pages/edit-products/EditPrintAreas';
import ProductSubstitute from 'pages/edit-products/ProductSubstitute';
import Select from 'components/Select';
import { Container } from 'styles/pages/edit-products/editProductDialog';
import ModalWrapper from 'components/ModalWrapper';

const getTabOptions = (isEu, isAu, isNY) => {
  const options = [
    'Product Information',
    'Print Areas',
    'Product Additional Images',
    'Decorating Techniques',
    'Product Sizes',
    'Product Colors',
    'Print Providers',
    'Product Artwork Settings',
    'Product Designer Settings',
    'Product Substitute',
  ];

  if (isEu || isAu || isNY) {
    options.push('Product SKU');
  }

  return options;
};

const EditProductDialog = ({
  open,
  setModal,
  product,
  categories,
  styleTags,
  brandsTags,
  promosTags,
  updateProductInformation,
  updateProductSizes,
  editColorsInformation,
  editDesignerSettings,
  updateProductPrintProviders,
  editDTGPlusInformation,
  editProductArtworkSettings,
  addAdditionalImages,
  editAdditionalTechniquesArtSettings,
  updatePrintArea,
  editProductSubstituteInformation,
  editSKUInformation,
}) => {
  const isEu = product?.PrintProviders?.find((p) => p?.ProviderName === 'EU');
  const isAu = product?.PrintProviders?.find((p) => p?.ProviderName === 'AU');
  const isNY = product?.PrintProviders?.find((p) => p?.ProviderName === 'NY');
  const tabOptions = getTabOptions(!!isEu, !!isAu, !!isNY);
  const [tab, setTab] = useState(tabOptions[0]);

  const handleClose = () => setModal(false);
  const productId = product?.Product?.ID;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
      <ModalWrapper className="edit-product-dialog">
        <Container>
          <DialogTitle>
            {productId ? 'Edit Product' : 'Add Product'}
            {product?.Product?.DisplayName &&
              ` (${product?.Product?.DisplayName})`}
            {/* {productId && (
            <Tooltip title='This step cannot be undone'>
              <DeleteIcon
                onClick={deleteProduct}
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  color: 'red',
                  marginTop: '5px',
                }}
              />
            </Tooltip>
          )} */}
            <Select
              options={tabOptions}
              onChange={(v) => setTab(v)}
              value={tab}
            />
          </DialogTitle>
          <DialogContent style={{ overflowX: 'hidden' }}>
            {tab === tabOptions[0] && (
              <EditProductInformation
                categories={categories}
                styleTags={styleTags}
                product={product}
                brandsTags={brandsTags}
                promosTags={promosTags}
                updateProductInformation={updateProductInformation}
              />
            )}

            {productId && (
              <React.Fragment>
                {tab === tabOptions[1] && (
                  <EditPrintAreas
                    product={product}
                    updatePrintArea={updatePrintArea}
                  />
                )}

                {tab === tabOptions[2] && (
                  <EditProductAdditionalImages
                    product={product}
                    addAdditionalImages={addAdditionalImages}
                  />
                )}

                {tab === tabOptions[3] && (
                  <EditDtgPlus
                    product={product}
                    editDTGPlusInformation={editDTGPlusInformation}
                  />
                )}

                {tab === tabOptions[4] && (
                  <EditProductSizes
                    product={product}
                    updateProductSizes={updateProductSizes}
                  />
                )}

                {tab === tabOptions[5] && (
                  <EditColorInformation
                    product={product}
                    editColorsInformation={editColorsInformation}
                  />
                )}

                {tab === tabOptions[6] && (
                  <EditPrintProviderInformation
                    product={product}
                    updateProductPrintProviders={updateProductPrintProviders}
                  />
                )}

                {tab === tabOptions[7] && (
                  <EditProductArtwork
                    product={product}
                    editProductArtworkSettings={editProductArtworkSettings}
                  />
                )}

                {tab === tabOptions[8] && (
                  <EditProductDesignerSettings
                    product={product}
                    editDesignerSettings={editDesignerSettings}
                    updatePrintArea={updatePrintArea}
                    editAdditionalTechniquesArtSettings={
                      editAdditionalTechniquesArtSettings
                    }
                  />
                )}

                {tab === tabOptions[9] && (
                  <ProductSubstitute
                    product={product}
                    editProductSubstituteInformation={
                      editProductSubstituteInformation
                    }
                  />
                )}

                {tab === tabOptions[10] && (
                  <EditProductSKU
                    product={product}
                    editSKUInformation={editSKUInformation}
                  />
                )}
              </React.Fragment>
            )}
          </DialogContent>
        </Container>
      </ModalWrapper>
    </Dialog>
  );
};

export default EditProductDialog;
