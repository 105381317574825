import * as React from 'react';

const OutlineTape = () => (
  <svg width={32} height={32} fill='none'>
    <path
      d='M10.158 4.154c-1.817 0-3.245 1.039-3.245 2.337 0 1.298 1.428 2.337 3.245 2.337 1.818 0 3.246-1.039 3.246-2.337 0-1.298-1.428-2.337-3.246-2.337zm0 3.375c-1.103 0-1.947-.584-1.947-1.038 0-.52.844-1.039 1.947-1.039 1.104 0 1.947.585 1.947 1.039 0 .52-.843 1.038-1.947 1.038z'
      fill='#2789C0'
    />
    <path
      d='M28.203 11.684h-8.438V6.49C19.765 2.92 15.48 0 10.158 0 4.835 0 .552 2.92.552 6.49v13.242c0 2.921 2.92 5.518 7.075 6.296v5.323c0 .39.26.649.649.649h19.927a3.214 3.214 0 003.245-3.245V14.929a3.214 3.214 0 00-3.245-3.245zM10.158 1.298c4.609 0 8.308 2.337 8.308 5.193 0 2.856-3.7 5.193-8.308 5.193-4.609 0-8.308-2.337-8.308-5.193 0-2.856 3.7-5.193 8.308-5.193zm8.308 8.438v1.948H15.87c1.039-.52 1.947-1.169 2.596-1.948zm-10.84 7.79v7.14c-3.375-.715-5.776-2.662-5.776-4.934V9.736c1.687 1.948 4.738 3.246 8.308 3.246h18.045c1.103 0 1.947.844 1.947 1.947a1.913 1.913 0 01-1.947 1.947H8.276c-.39 0-.65.26-.65.65zM30.28 28.69h-.13a1.912 1.912 0 01-1.947 1.947h-.78V27.65c0-.39-.259-.649-.648-.649-.39 0-.65.26-.65.65v2.985h-2.401V27.65c0-.39-.26-.649-.65-.649-.389 0-.648.26-.648.65v2.985h-2.402V27.65c0-.39-.26-.649-.649-.649-.39 0-.649.26-.649.65v2.985h-2.402V27.65c0-.39-.26-.649-.649-.649-.39 0-.649.26-.649.65v2.985h-2.402V27.65c0-.39-.26-.649-.649-.649s-.649.26-.649.65v2.985H9.055v-4.998c0-.065.064-.13.064-.13 0-.13 0-.195-.064-.324v-7.01h19.278c.713 0 1.427-.26 1.947-.65V28.69z'
      fill='#2789C0'
    />
  </svg>
);

export default OutlineTape;
