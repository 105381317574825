import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Image from 'components/Image';
import {
  Container,
  ImageWrapper,
  Title,
} from 'styles/components/ProductCategoryCard';

const ProductCategoryCard = ({
  title,
  imagePath,
  scaleDown,
  titleBackgroundOnHover,
  titleStyles,
  containerStyles,
  onClick,
  aspectRatio = 16 / 9,
}) => (
  <Container
    onClick={() => _.isFunction(onClick) && onClick()}
    containerStyles={containerStyles}
    scaleDown={scaleDown}
    titleBackgroundOnHover={titleBackgroundOnHover}>
    <ImageWrapper>
      <Image
        imageStyle={{
          width: '100%',
          objectFit: 'cover',
        }}
        aspectRatio={aspectRatio}
        src={imagePath}
        alt={title}
      />
    </ImageWrapper>
    <Title titleStyles={titleStyles}>{title}</Title>
  </Container>
);

ProductCategoryCard.propTypes = {
  imagePath: PropTypes.string,
  title: PropTypes.string,
  scaleDown: PropTypes.bool,
  titleStyles: PropTypes.object,
  containerStyles: PropTypes.object,
  titleBackgroundOnHover: PropTypes.bool,
  onClick: PropTypes.func,
};

ProductCategoryCard.defaultProps = {
  scaleDown: false,
  titleBackgroundOnHover: false,
};

export default ProductCategoryCard;
