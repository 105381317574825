import styled from "styled-components";

export const Container = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #000;
`;

export const ErrorMsgContainer = styled.div`
  min-height: calc(100vh - 244px);
  display: flex;
  align-items: center;
  .warning {
    width: 100%;
    text-align: center;
    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const AddArtWork = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }

  .checkbox {
    margin-top: 20px;
  }

  .file-input {
    margin-top: 20px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
  }

  .file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }
  .file-input label {
    width: 100%;
    border-radius: 5px;
    background-color: #5c92e2;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const Fields = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 20px;
  margin-bottom: 20px;
  div:nth-child(1) {
    flex: 1;
  }
  div:nth-child(2) {
    flex: 1;
  }
`;

export const Filter = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  label,
  button,
  .MuiInput-input,
  .Mui-focused {
    color: #fff;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: #fff;
  }
`;
