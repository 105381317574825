import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'components/Select';

const CountriesSelect = ({ state, setState }) => {
  const countries = useSelector((state) => state?.countries?.data);

  return (
    <Select
      label='Copyright / Trademark Country'
      options={countries}
      variant='filled'
      labelKey='name'
      valueKey='name'
      value={{ name: state?.country || '' }}
      onChange={(country) => {
        setState((prevState) => ({
          ...prevState,
          country,
        }));
      }}
    />
  );
};

export default CountriesSelect;
