import _ from 'lodash';
import Button from 'components/Button';
import {
  Heading,
  ErrorMsg,
} from 'styles/pages/order-details/customerInformation';

const getErrorMessage = (orderDetail) => {
  const initialValue = 'Please enter ';
  let errorMessage = initialValue;
  if (!orderDetail?.BuyerName) {
    errorMessage += 'Buyer Name, ';
  }
  if (!orderDetail?.AddressLineOne) {
    errorMessage += 'Address Line One, ';
  }
  if (!orderDetail?.City) {
    errorMessage += 'City, ';
  }
  if (!orderDetail?.StateProvince) {
    errorMessage += 'State, ';
  }
  if (!orderDetail?.CountryString) {
    errorMessage += 'Country, ';
  }
  if (!orderDetail?.ZipPostal) {
    errorMessage += 'Zip Code, ';
  }
  if (!orderDetail?.PrintProvider) {
    errorMessage += 'Fulfillment Plant, ';
  }
  if (errorMessage.trim().length > 0 && errorMessage !== initialValue) {
    errorMessage = errorMessage.slice(0, errorMessage.length - 2);
    return `(${errorMessage})`;
  }
  return null;
};

const Header = ({ edit, onSave, onCancel, onEdit, showEdit, orderDetail }) => (
  <Heading>
    <h3>
      Customer Information&nbsp;
      <ErrorMsg>{edit ? null : getErrorMessage(orderDetail)}</ErrorMsg>
    </h3>
    {showEdit && (
      <>
        {!edit && (
          <div>
            <Button
              onClick={() => _.isFunction(onEdit) && onEdit()}
              variant='text'
              title='Edit'
            />
          </div>
        )}
        {edit && (
          <div>
            <Button
              variant='text'
              title='Save'
              onClick={() => _.isFunction(onSave) && onSave()}
            />

            <Button
              variant='text'
              title='Cancel'
              onClick={() => _.isFunction(onCancel) && onCancel()}
            />
          </div>
        )}
      </>
    )}
  </Heading>
);

export default Header;
