import styled from "styled-components";

const Container = styled.div`
  background-image: ${(props) => `url(${props?.image})`};
  /* max-height: 752px;
  height: 100vh; */
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  @media (max-width: 764px) {
    height: auto;
    background-size: auto;
  }
`;
const Wrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px 15px 30px;
  margin: 0 auto;
  position: relative;
  align-items: center;
  .textSection {
    margin: 65px 0;
  }
  p {
    font-size: 18px;
  }
  h1 {
    font-family: "Inter800";
  }
  .text {
    color: #18191f;
    p {
      color: #455880;
    }
  }
  .imgSection {
    img {
      width: 100%;
      height: auto;
    }
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 49px;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    .imgSection {
      max-width: 300px;
    }
    .text {
      text-align: center;
    }
    .textSection {
      margin-top: 20px;
    }
    padding-bottom: 30px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 60px;
  button {
    padding: 12px 32px;
    font-family: "Inter500";
    line-height: 24px;
    border-radius: 6px;
    text-transform: capitalize;

    :nth-child(1),
    :nth-child(1):hover {
      background-color: #fdd500;
      color: #3f3e3e;
      box-shadow: none;
      margin-right: 10px;
    }

    :nth-child(2),
    :nth-child(2):hover {
      box-shadow: none;
      background: #5c92e2;
      color: #ffffff;
    }
  }
  @media (max-width: 1100px) {
    margin-top: 26px;
    display: flex;
    button {
      padding: 6px 16px;
    }
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export { Container, Wrapper, ButtonWrapper };
