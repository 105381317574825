import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { getCountries } from "actions/countries";
import Button from "components/Button";
import TextField from "components/TextField";
import Select from "components/Select";
import AddShippingInformationImage from "assets/images/add-shipping-information.png";
import USAFlag from "assets/images/usa-flag.png";
import CADFlag from "assets/images/canada-flag.png";
import EUFlag from "assets/images/eu-flag.png";
import AUFlag from "assets/images/aus-flag.jpeg";

import {
  Container,
  Information,
  Wrapper,
  ImageSection,
  Flags,
  FlagsWrapper,
  FlagImage,
} from "styles/pages/orders/basicOrderShippingInformationForm";

const ShippingInformation = ({ save }) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state?.countries?.data);
  const [statesList, setStateList] = useState([]);

  const [state, setState] = useState({
    BuyerName: "",
    AddressLineOne: "",
    AddressLineTwo: "",
    City: "",
    StateProvince: "",
    CountryString: "",
    ZipPostal: "",
    PrintProvider: "US",
    Sample: false,
    ReOrderState: 0,
  });

  const disabled = !(
    state.BuyerName.length &&
    state.AddressLineOne.length &&
    state.City.length &&
    state.StateProvince.length &&
    state.CountryString.length &&
    state.ZipPostal.length &&
    state.PrintProvider.length
  );

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  return (
    <Container>
      <h2>Create a Basic Order </h2>
      <Wrapper>
        <Information>
          <TextField
            fullWidth
            size="small"
            label="Full Name"
            onChange={(BuyerName) => setState({ ...state, BuyerName })}
          />
          <TextField
            fullWidth
            size="small"
            label="Address Line 1"
            onChange={(AddressLineOne) =>
              setState({ ...state, AddressLineOne })
            }
          />
          <TextField
            fullWidth
            size="small"
            label="Address Line 2"
            onChange={(AddressLineTwo) =>
              setState({ ...state, AddressLineTwo })
            }
          />
          <Select
            label="Country"
            options={countries}
            labelKey="name"
            valueKey="code2"
            value={{ code2: state.CountryString }}
            onChange={(CountryString) => {
              const states = _.find(
                countries,
                (o) => o.code2 === CountryString
              )?.states;
              setStateList(states);
              setState({
                ...state,
                CountryString,
                StateProvince: "",
              });
            }}
          />
          <Select
            label="State/Province"
            labelKey="name"
            valueKey="code"
            options={statesList}
            value={{ code: state.StateProvince }}
            onChange={(StateProvince) =>
              setState({
                ...state,
                StateProvince,
              })
            }
          />
          <TextField
            fullWidth
            size="small"
            label="City"
            onChange={(City) => setState({ ...state, City })}
          />
          <TextField
            fullWidth
            size="small"
            label="Zip/Postal"
            onChange={(ZipPostal) => setState({ ...state, ZipPostal })}
          />

          <Flags>
            <h1>
              Selected Fulfillment Plant - (
              {state?.PrintProvider === "US"
                ? "USA"
                : state?.PrintProvider === "NY"
                ? "NY Phone Cases"
                : state?.PrintProvider}
              Plant)
            </h1>
            <FlagsWrapper>
              <div>
                <FlagImage
                  src={USAFlag}
                  alt="us-flag"
                  selected={state?.PrintProvider === "US"}
                  onClick={() =>
                    setState({
                      ...state,
                      PrintProvider: "US",
                    })
                  }
                />
                <h5 style={{ margin: 0, textAlign: "center" }}>USA</h5>
              </div>

              <div>
                <FlagImage
                  src={USAFlag}
                  alt="us-flag"
                  selected={state?.PrintProvider === "NY"}
                  onClick={() =>
                    setState({
                      ...state,
                      PrintProvider: "NY",
                    })
                  }
                />
                <h5 style={{ margin: 0, textAlign: "center" }}>
                  NY Phone Cases
                </h5>
              </div>

              <div>
                <FlagImage
                  src={CADFlag}
                  alt="ca-flag"
                  selected={state?.PrintProvider === "CA"}
                  onClick={() =>
                    setState({
                      ...state,
                      PrintProvider: "CA",
                    })
                  }
                />
                <h5 style={{ margin: 0, textAlign: "center" }}>Canada</h5>
              </div>

              <div>
                <FlagImage
                  src={EUFlag}
                  alt="eu-flag"
                  selected={state?.PrintProvider === "EU"}
                  onClick={() =>
                    setState({
                      ...state,
                      PrintProvider: "EU",
                    })
                  }
                />
                <h5 style={{ margin: 0, textAlign: "center" }}>Europe</h5>
              </div>

              <div>
                <FlagImage
                  src={AUFlag}
                  alt="au-flag"
                  selected={state?.PrintProvider === "AU"}
                  onClick={() =>
                    setState({
                      ...state,
                      PrintProvider: "AU",
                    })
                  }
                />
                <h5 style={{ margin: 0, textAlign: "center" }}>Australia</h5>
              </div>
            </FlagsWrapper>
          </Flags>
          <Button
            title="Continue"
            onClick={() => _.isFunction(save) && save(state)}
            disabled={disabled}
          />
          {state?.PrintProvider?.length > 0 && (
            <h5>
              Only selected fulfillment plant products will be visible while
              placing the order
            </h5>
          )}
        </Information>

        <ImageSection>
          <img
            src={AddShippingInformationImage}
            alt="Add Shipping
Information"
          />
        </ImageSection>
      </Wrapper>
    </Container>
  );
};

export default ShippingInformation;
