import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from 'components/Button';
import COLORS from 'shared/constants/colors';
import { Container, Title } from 'styles/components/Banner';

const Banner = ({ title, links }) => (
  <Container>
    <Title>{title}</Title>
    {links.map((link, index) => (
      <Button
        key={`bannerLink${index}`}
        containerStyle={{
          backgroundColor: COLORS.WHITE,
          color: COLORS.BODY_TEXT,
          boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
          textTransform: 'none',
          borderRadius: '50px',
          marginTop: '12px',
          fontSize: '12px',
          lineHeight: '1.5',
          fontFamily: 'Inter500',
        }}
        onClick={() => _.isFunction(link.onClick) && link.onClick()}
        title={link.title}
      />
    ))}
  </Container>
);

Banner.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default Banner;
