import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container } from 'styles/pages/order-details/marketPlaceOrderDialog';

const AlertDialog = ({ open, setOpen }) => {
  const hide = () => setOpen();
  return (
    <Dialog open={!!open} onClose={hide} maxWidth='md'>
      <Container>
        <DialogTitle>{open?.plus ? 'TUFF PRINT TRANSFER' : 'DTG+'} Instruction</DialogTitle>
        <DialogContent>
          <iframe src='https://images.shirtly.com/image/upload/v1692635872/BOTApp/Tuff_Print/TUFF_Print_Transfers_Guideline_-_2023_sb5gfq.pdf' style={{
            width:'100%',height:'900px'
          }}>
          </iframe>
{/*
          {open?.dtgPlus && (
            <DialogContentText>
              <b>DTG+PLUS Specs</b>
              <br />
              <b>When creating a DTG+Plus job, you must include:</b>
              <br />
              1. TUFF PRINT TRANSFER file in EPS vector format
              <br />
              2. DTG print file in PNG format
              <br />
              3. MOCKUP image in PNG format that shows the placement of the TUFF PRINT TRANSFER
              design on the DTG print.
              <br />
              <br />
              <b>Note:</b>
              <br />
              TUFF PRINT TRANSFER file in EPS vector format must be the same size artboard as
              the DTG file for proper registration and application.  It is the
              art file used to create your TUFF PRINT TRANSFER media design.
              <br />
              The MOCKUP image can be uploaded as a PNG art file.  This file
              will be used to ensure application alignment is achieved.
              <br />
              <br />
              Please note, the TUFF PRINT TRANSFER file needs to be in an EPS vector format.
              Vector files created are created in vector-based programs such as
              Adobe Illustrator.  (We cannot accept PNG, JPG or other bitmap
              file types).
              <br />
              <br />
              When creating the MOCKUP image, please only use solid 100% black
              colour (C 0%, M 0%, Y 0%, K 100%) to indicate where you would like
              the TUFF PRINT TRANSFER media to be applied.
              <br />
              <br />
              <b>Additional Art Specifications & Disclaimer: </b>
              <hr />
              <b>
                * Do not use shadows, glows, gradients, or grayscale images.
                Only a solid 100% black colour (C 0%, M 0%, Y 0%, K 100%) be
                used.
              </b>
              <br />
              <br />
              <b>
                * Knockouts may shift (up to 1/4”) when the DTG+Plus media is
                applied to the garment.  Therefore, we cannot guarantee precise
                placement.
              </b>
              <br />
              <br />
              <b>
                * Only submit vector paths/shapes, all fonts and text must be
                converted to outline/paths. Fonts under 26pt size cannot be used
                with DTG+Plus media.
              </b>
              <br />
              <br />
              <b>
                * We reserve the right to refuse vector EPS TUFF PRINT TRANSFER files that are
                too detailed or complex to be made and applied as DTG+Plus
                media. (distressed designs, thin stroke lines, or detailed
                patterns are not recommended for DTG+Plus media).
              </b>
              <br />
              <br />
            </DialogContentText>
          )}

          {open?.plus && (
            <DialogContentText>
              <b>When creating a TUFF PRINT TRANSFER job, you must include:</b>
              <br />
              1. TUFF PRINT TRANSFER file in EPS vector format
              <br />
              2. MOCKUP image in PNG format that shows the placement of the TUFF PRINT TRANSFER
              design.
              <br />
              <br />
              <b>Note:</b>
              <br />
              TUFF PRINT TRANSFER file in EPS vector format must be the same size artboard.
              <br />
              The MOCKUP image can be uploaded as a PNG art file.  This file
              will be used to ensure application alignment is achieved.
              <br />
              <br />
              Please note, the TUFF PRINT TRANSFER file needs to be in an EPS vector format.
              Vector files created are created in vector-based programs such as
              Adobe Illustrator.  (We cannot accept PNG, JPG or other bitmap
              file types).
              <br />
               <br />
              When creating the MOCKUP image, please only use solid 100% black
              colour (C 0%, M 0%, Y 0%, K 100%) to indicate where you would like
              the TUFF PRINT TRANSFER media to be applied.
              <br />
              <br />
              <b>Additional Art Specifications & Disclaimer: </b>
              <hr />
              <b>
                * Do not use shadows, glows, gradients, or grayscale images.
                Only a solid 100% black colour (C 0%, M 0%, Y 0%, K 100%) be
                used.
              </b>
              <br />
              <br />
              <b>
                * Knockouts may shift (up to 1/4”) when the DTG+Plus media is
                applied to the garment.  Therefore, we cannot guarantee precise
                placement.
              </b>
              <br />
              <br />
              <b>
                * Only submit vector paths/shapes, all fonts and text must be
                converted to outline/paths. Fonts under 26pt size cannot be used
                with DTG+Plus media.
              </b>
              <br />
              <br />
              <b>
                * We reserve the right to refuse vector EPS TUFF PRINT TRANSFER files that are
                too detailed or complex to be made and applied as DTG+Plus
                media. (distressed designs, thin stroke lines, or detailed
                patterns are not recommended for DTG+Plus media).
              </b>
              <br />
              <br />
            </DialogContentText>
          )}
          */}
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default AlertDialog;
