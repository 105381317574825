import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  align-items: start;
  column-gap: 10px;
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;
const ShippingPricing = styled.div`
  border-radius: 5px;
  background-color: #fff;
  padding: 0 30px;
  @media (max-width: 650px) {
    padding: 0 20px;
  }
  > h1 {
    display: flex;
    align-items: center;
    margin: 0;
    color: #2789c0;
    font-size: 17px;
    font-family: 'Inter600';
    padding: 14px 0;
  }
  > div {
    display: grid;
    justify-items: center;
    border-top: 1px solid #efefef;
    padding: 15px 0;
    p {
      margin: 0;
      font-family: 'Inter400';
      font-size: 14px;
    }
    /* > div {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 0.3fr));
      column-gap: 10px;
      min-height: 240px;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 12.5px;
      flex-direction: column;
      width: 100%;
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
        transition: border ease-out 0.25s;
        border-radius: 4px;
        border: 2px solid #c7c8c9;
        box-sizing: border-box;
        border: 2px solid #3b3b3d;
        cursor: pointer;
      }
      h5 {
        font-size: 19px;
        margin: 19px auto;
        color: #3b3b3d;
      }
      @media (min-width: 992px) {
        flex-direction: row;
      }
    } */
  }
`;

const CheckboxWrapper = styled.div`
  width: 100%;

  .title {
    font-family: 'Inter600';
    font-size: 17px;
    padding-bottom: 14px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 14px;
    color: #2789c0;
  }
`;

export { Container, ShippingPricing, CheckboxWrapper };
