import styled, { css } from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  min-height: calc(100vh - 180px);
  padding: 0 15px;
  /* background-color: ${COLORS.LIGHT_GREY}; */
  background-color: #000;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: grid;
  transition: all ease-out 0.5s;
  column-gap: 20px;
  align-items: start;
  margin-bottom: 1em;
  font-size: 1.15em;
  @media (min-width: 1200px) {
    grid-template-columns: 0.25fr 0.75fr;
  }
`;

const Details = styled.div`
  padding: 1em 0.25em;
`;

const Brands = styled.div`
  display: flex;
  flex-direction: column;
`;

const Category = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-height: 80px;
  margin-top: 0;
  column-gap: 10px;
  row-gap: 10px;
  gap: 10px;
  margin: 1em 0;
  width: 100%;
`;

const Img = styled.img`
  min-height: 0;
  width: 100%;
  height: 100px;
  object-fit: cover;
`;
const Title = styled.p`
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;
  font-size: 11px;
  padding: 0.5em;
`;
const Item = styled.div`
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: all ease-out 0.25s;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  color: #6c6c6c;
  padding: 6px 0 2px;
  .category-icon {
    margin: 0 auto;
    color: red;
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: #cfeeff;
      color: #2789c0;
      /* ${Img} {
        filter: grayscale(1) brightness(1) contrast(100%);
        mix-blend-mode: multiply;
      } */
    `};

  &:hover {
    background-color: #cfeeff;
    color: #2789c0;
    /* ${Img} {
      filter: grayscale(1) brightness(1) contrast(100%);
      mix-blend-mode: multiply;
    } */
  }
`;

const Colors = styled.div`
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(20px, 22px));
  margin-bottom: 1em;
  width: 100%;
`;

export {
  Container,
  Wrapper,
  Details,
  Brands,
  Category,
  Item,
  Title,
  Img,
  Colors,
};
