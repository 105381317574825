import React from 'react';
const row = (
  artFile,
  patentNumber,
  createdDate,
  reviewing,
  country,
  approved,
  patentProofLink,
  emailId
) => {
  return {
    artFile,
    patentNumber,
    reviewing,
    createdDate,
    reviewing,
    country,
    approved,
    patentSearch:
      country === 'Canada' ? (
        <a
          href={`https://www.ic.gc.ca/opic-cipo/cpd/eng/patent/1234657/summary.html?query=${patentNumber}&type=basic_search`}
          target='_blank'>
          {patentNumber}
        </a>
      ) : country === 'United States' ? (
        <a
          href={`https://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO2&Sect2=HITOFF&p=1&u=%2Fnetahtml%2FPTO%2Fsearch-bool.html&r=0&f=S&l=50&TERM1=${patentNumber}&FIELD1=&co1=AND&TERM2=&FIELD2=&d=PTXT`}
          target='_blank'>
          {patentNumber}
        </a>
      ) : (
        ''
      ),
    patentProofLink: patentProofLink ? (
      <a href={patentProofLink} target='_blank'>
        Proof Link
      </a>
    ) : null,
    emailId,
  };
};

export { row };
