import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={28}
      height={28}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M18.636 19.96c-.687-.001-1.31-.352-1.667-.938a.467.467 0 00-.797.485c.248.407.584.737.975.973l-.485 2.194-1.164-1.343c.216-.249.155-.533-.022-.686a.466.466 0 00-.658.045l-.82.943-2.066-2.298a2.85 2.85 0 00.314-1.092c3.995 1.171 7.993-1.815 8.004-5.97l.002-3.798a3.264 3.264 0 00-3.263-3.268l-5.949-.004a3.264 3.264 0 00-3.268 3.263l-.002 3.026a.961.961 0 01-.524-.855l.004-5.684c0-.61.497-1.107 1.108-1.107.197 0 .365-.12.434-.293a4.171 4.171 0 013.875-2.62l6.274.004-.579 1.526a.467.467 0 00.306.614l.808.235a1.882 1.882 0 011.362 1.799l-.004 5.535a.467.467 0 00.933.001l.004-5.557c-.005-1.228-.84-2.328-2.034-2.674l-.323-.094.638-1.685a.467.467 0 00-.436-.632L12.668 0a5.104 5.104 0 00-4.626 2.937 2.044 2.044 0 00-1.725 2.016l-.004 5.683a1.9 1.9 0 001.46 1.846 6.241 6.241 0 003.55 5.409v.119a1.945 1.945 0 01-1.95 1.943c-2.518-.045-4.818 2.053-4.82 4.812l-.001 2.756a.467.467 0 00.933 0l.002-2.756a3.88 3.88 0 013.882-3.88c.162.004.393-.017.612-.064l.65 2.955a.467.467 0 00.807.206l1.68-1.931.418.465-.002 2.795a.467.467 0 10.933 0l.002-2.8.41-.47 1.686 1.945c.251.29.726.168.808-.205l.653-2.954c.198.042.402.065.61.065a3.88 3.88 0 013.88 3.885l-.002 2.756a.466.466 0 10.933 0l.002-2.755a4.813 4.813 0 00-4.813-4.819zm-9.933-7.712l.002-3.78a2.335 2.335 0 012.333-2.332l5.95.004a2.331 2.331 0 012.331 2.334l-.002 3.804a5.304 5.304 0 01-5.311 5.284 5.304 5.304 0 01-5.303-5.314zm2.64 10.422l-.483-2.195c.178-.107.345-.235.5-.381l1.13 1.257-1.148 1.32z'
        fill='#6C6C6C'
      />
    </svg>
  );
}

export default SvgComponent;
