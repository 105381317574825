import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';

const AboutDialog = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalWrapper className='about-us'>
        <DialogTitle>About Us</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Shirtly is a print-on-demand app that connects directly with your
            Shopify, Etsy, WooCommerce, BigCommerce, BigCartel and other
            platform stores. This allows you to create an entire catalog of
            products without spending tons of money on inventory. Shirtly
            partners with fulfillment companies who customize all its offered
            products for your online stores and ships them finished directly to
            your customers anywhere in the world.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </ModalWrapper>
    </Dialog>
  );
};

export default AboutDialog;
