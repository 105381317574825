import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Select from 'components/Select';

const SizesSelect = ({ variant, onChange, product }) => {
  const products = useSelector((state) => state?.products?.data);
  let sizes = _.find(
    products,
    (p) => p?.Product?.ID === variant?.syncData?.ProductID
  )?.Sizes;
  sizes = sizes?.filter((s) => s?.visible !== false);

  return (
    <Select
      disabled={!variant?.syncData?.ProductID}
      label='Size'
      options={sizes}
      value={{ ID: variant?.syncData?.sizeId || '' }}
      valueKey='ID'
      labelKey='Name'
      onChange={(sizeId, e) => {
        const c = _.find(sizes, (o) => o.ID === sizeId);

        const variants = product?.variants?.map((v) =>
          v?.id === variant?.id
            ? {
                ...v,
                syncData: {
                  ...v?.syncData,
                  sizeId,
                  sizeName: c?.Name,
                  ColorID: '',
                  colorName: '',
                },
              }
            : v
        );
        onChange(variants, e);
      }}
    />
  );
};

export default SizesSelect;
