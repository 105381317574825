import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { styled } from "@material-ui/core/styles";

const colors = {
  light: {
    border: "rgba(0, 0, 0, 0.23)",
    hoverBorder: "#1976D2",
    focusBorder: "#1976D2",
    labelColor: "#666666",
    textColor: "#333333",
    placeholderColor: "#999999",
    backgroundColor: "transparent",
  },
  dark: {
    border: "#FFFFFF",
    hoverBorder: "#FFFFFF",
    focusBorder: "#FFFFFF",
    labelColor: "#FFFFFF",
    textColor: "#FFFFFF",
    placeholderColor: "#888888",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
};

const StyledSelect = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "isDark",
})(({ theme, isDark }) => {
  const modeColors = isDark ? colors.dark : colors.light;

  return {
    width: "100%",
    marginTop: 0,
    marginBottom: 0,
    fontSize: "13px",

    "& .MuiOutlinedInput-root": {
      borderColor: modeColors.border,
      backgroundColor: modeColors.backgroundColor,

      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "& fieldset": {
        borderColor: modeColors.border,
        transition: theme.transitions.create(["border-color"]),
      },
      "&:hover fieldset": {
        borderColor: modeColors.hoverBorder,
      },
      "&.Mui-focused fieldset": {
        borderColor: modeColors.focusBorder,
      },
      "&.Mui-error fieldset": {
        borderColor: theme.palette.error.main,
      },
    },
    "& .MuiInputLabel-root": {
      color: modeColors.labelColor,

      "&.Mui-focused": {
        color: modeColors.focusBorder,
      },
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    "& .MuiInputBase-input": {
      color: modeColors.textColor,

      "&::placeholder": {
        color: modeColors.placeholderColor,
        opacity: 1,
      },
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "3px",
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    "& .Mui-disabled": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
      },
      "& .MuiInputBase-input": {
        color: isDark ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)",
      },
    },
    "& .MuiSelect-icon": {
      color: modeColors.labelColor,
    },
  };
});

const StyledInputLabel = styled(InputLabel)(({ isDark }) => {
  return {
    color: isDark ? "#fff" : "inherit",
    background: isDark ? "#000" : "inherit",
    paddingRight: 3,
  };
});

const CustomSelect = ({
  value,
  onChange,
  label,
  options,
  valueKey,
  labelKey,
  disabled,
  error,
  isDark,
}) => {
  return (
    <StyledSelect variant="outlined" margin="dense" isDark={isDark}>
      <StyledInputLabel isDark={isDark}>{label}</StyledInputLabel>
      <Select
        error={error}
        disabled={disabled}
        value={valueKey ? value[valueKey] : value ? value : ""}
        onChange={(e) => _.isFunction(onChange) && onChange(e.target.value, e)}
        label={label}
      >
        {options?.map((option, index) =>
          option.isNone ? (
            <MenuItem value="" key={`select${index}`}>
              <em>{option[valueKey] || ""}</em>
            </MenuItem>
          ) : (
            <MenuItem
              value={valueKey ? option[valueKey] : option}
              key={`select${index}`}
            >
              {option[labelKey] || option[valueKey] || option}
            </MenuItem>
          )
        )}
      </Select>
    </StyledSelect>
  );
};

CustomSelect.propTypes = {
  // value: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomSelect.defaultProps = {
  value: "",
  disabled: false,
};

export default CustomSelect;
