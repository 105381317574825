import React, { useState } from "react";
import PropTypes from "prop-types";
import BrowseLibrary from "components/ArtWork/BrowseLibrary";
import BrowseTextDrawer from "components/ArtWork/BrowseTextDrawer";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import LayersIcon from "@material-ui/icons/Layers";
import Tooltip from "@material-ui/core/Tooltip";
import { AdvanceToolBrowseLibraryButton } from "styles/components/ArtWork";

const FileUpload = ({
  onDrop,
  orderLine,
  canvas,
  setLayersDrawer,
  plus,
  printType,
}) => {
  const [browseLibrary, setBrowseLibrary] = useState(false);
  const [browseText, setBrowseText] = useState(false);
  const canvasJSON = canvas?.toJSON();

  const isImageExist = canvasJSON?.objects?.find((o) => o?.type === "image");
  const isTextExist = canvasJSON?.objects?.find((o) => o?.type === "i-text");

  return (
    <React.Fragment>
      <AdvanceToolBrowseLibraryButton>
        {printType === "EMB" && !!isImageExist ? null : (
          <Tooltip title="Add Image">
            <CloudUploadIcon onClick={() => setBrowseLibrary(true)} />
          </Tooltip>
        )}
        {printType === "EMB" && !!isTextExist ? null : (
          <Tooltip title="Add Text">
            <TextFormatIcon onClick={() => setBrowseText(true)} />
          </Tooltip>
        )}
        {!!canvasJSON?.objects?.length && (
          <Tooltip title="Layers">
            <LayersIcon onClick={() => setLayersDrawer(true)} />
          </Tooltip>
        )}
      </AdvanceToolBrowseLibraryButton>

      {browseLibrary && (
        <BrowseLibrary
          orderLine={orderLine}
          printType={printType}
          onDrop={(artwork) => onDrop(artwork)}
          open={browseLibrary}
          toggle={() => setBrowseLibrary(false)}
          showAdvanceToolTab={true}
        />
      )}
      {browseText && (
        <BrowseTextDrawer
          orderLine={orderLine}
          plus={plus}
          onDrop={(artwork) => onDrop(artwork)}
          open={browseText}
          toggle={() => setBrowseText(false)}
        />
      )}
    </React.Fragment>
  );
};

FileUpload.propTypes = {
  onDrop: PropTypes.func,
};

export default FileUpload;
