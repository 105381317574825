import React from "react";
import { useSelector } from "react-redux";
import Tabs from "components/Tabs";
import FileUpload from "components/ArtWork/FileUpload";
import EditArtWorkImage from "components/ArtWork/EditArtWorkImage";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import CenterHorizontalIcon from "@material-ui/icons/FormatAlignCenter";
import TextField from "components/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/Button";
import AdvanceTool from "components/ArtWork/AdvanceTool";
import CutomGraphicsOption from "components/ArtWork/CutomGraphicsOption";
import {
  isSub,
  getMarketingImagePathFromProductId,
  isAOP,
  getProductAdditionalImages,
} from "selectors/products";
import Tooltip from "@material-ui/core/Tooltip";
import Image from "components/Image";
import {
  Container,
  Wrapper,
  OuterEnvelope,
  InnerEnvelope,
  StyledCloseIcon,
  StyledVisibilityOffIcon,
  PrintableAreaAlign,
  AlignIcon,
  ButtonWrapper,
  PlatenDimensionInches,
} from "styles/components/ArtWork";
import ErrorMessage from "components/ErrorMessage";

const selectedDesignerSettingsStyle = {
  backgroundColor: "#5c92e2",
  color: "#FFFFFF",
};

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 500,
  },
}));

const ArtWork = ({
  order,
  orderLine = {},
  onDrop,
  setCoordinates,
  onRemove,
  onPreview,
  setOrderLine,
  onFabricDrop,
  onFabricRemove,
  product,
  plus,
  dtgPlusProcess,
  printType,
}) => {
  const classes = useStyles();
  const products = useSelector((state) => state?.products?.data);
  const printAreas = orderLine?.printAreas;
  const selectedPrintArea = printAreas?.find((p) => p?.selected);
  const designerSettings = selectedPrintArea?.designerSettings || {};
  const designerSettingsLength = Object.keys(designerSettings)?.length;
  const isProductAop = isAOP(products, orderLine.ProductID);
  const isProductSub = isSub(products, orderLine?.ProductID);
  const additionalImages = getProductAdditionalImages(
    products,
    orderLine?.ProductID
  );

  const isPlusSelected = orderLine?.plus;

  const isAdvanceToolSelected = printAreas?.find((p) => p?.canvasJSON);

  const isBasicToolSelected = printAreas?.find((p) => p?.artworkId);

  const advanceTool = () => (
    <Container>
      <AdvanceTool
        printType={printType}
        orderLine={orderLine}
        onFabricDrop={onFabricDrop}
        setCoordinates={setCoordinates}
        onFabricRemove={onFabricRemove}
        onPreview={onPreview}
        setOrderLine={setOrderLine}
        product={product}
        plus={plus}
        dtgPlusProcess={dtgPlusProcess}
        onDrop={onDrop}
      />
    </Container>
  );

  const basicTool = () => (
    <Container>
      <ButtonWrapper>
        {printAreas?.map((printArea, index) => {
          if (!printArea?.printAreaMarketingImage) {
            return null;
          }

          // if (printArea?.printAreaName !== 'Front' && isPlusSelected) {
          //   return null;
          // }

          const dSettings = printArea?.designerSettings;
          if (!dSettings?.width && !dSettings?.height) return null;

          return (
            <div key={`${printArea?.printAreaName}_${index}`}>
              <Button
                title={printArea?.printAreaName}
                onClick={() => {
                  setOrderLine({
                    ...orderLine,
                    printAreas: orderLine?.printAreas?.map((p, j) => ({
                      ...p,
                      selected: index === j,
                    })),
                  });

                  // if (isPlusSelected) {
                  //   setOrderLine({
                  //     ...orderLine,
                  //     printAreas: orderLine?.printAreas?.map((p, j) => ({
                  //       designerSettings: p?.designerSettings,
                  //       printAreaMarketingImage: p?.printAreaMarketingImage,
                  //       printAreaName: p?.printAreaName,
                  //       selected: index === j,
                  //     })),
                  //   });
                  // } else {
                  //   setOrderLine({
                  //     ...orderLine,
                  //     printAreas: orderLine?.printAreas?.map((p, j) => ({
                  //       ...p,
                  //       selected: index === j,
                  //     })),
                  //   });
                  // }
                }}
                containerStyle={
                  printArea?.selected ? selectedDesignerSettingsStyle : {}
                }
              />
              <PlatenDimensionInches selected={printArea?.selected}>
                {dSettings?.platenWidthInch}'' X {dSettings?.platenHeightInch}''
              </PlatenDimensionInches>
            </div>
          );
        })}

        {orderLine.PrintType === "DURASOFT" && (
          <div style={{ display: "flex", gridGap: 5 }}>
            {selectedPrintArea?.artworkId &&
              selectedPrintArea?.printAreaName === "Front" && (
                <>
                  <TextField
                    label="Front Width (inches)"
                    type="number"
                    style={{ display: "flex", flex: 1 }}
                    onChange={(width) => {
                      const widthInPixel =
                        (Number(width) * 255) / designerSettings?.multiplier;
                      const v = designerSettings?.width;
                      const widthInInches =
                        width < 1
                          ? 255 / designerSettings?.multiplier
                          : widthInPixel > v
                          ? v
                          : widthInPixel;

                      setCoordinates({
                        ...selectedPrintArea?.coordinates,
                        width: widthInInches,
                      });
                    }}
                  />
                  <TextField
                    label="Front Height (inches)"
                    type="number"
                    style={{ display: "flex", flex: 1 }}
                    onChange={(height) => {
                      const heightInPixel =
                        (Number(height) * 255) / designerSettings?.multiplier;
                      const v = designerSettings?.height;
                      const heightInInches =
                        height < 1
                          ? 255 / designerSettings?.multiplier
                          : heightInPixel > v
                          ? v
                          : heightInPixel;

                      setCoordinates({
                        ...selectedPrintArea?.coordinates,
                        height: heightInInches,
                      });
                    }}
                  />
                </>
              )}

            {selectedPrintArea?.artworkId &&
              selectedPrintArea?.printAreaName === "Back" && (
                <>
                  <TextField
                    label="Back Width (inches)"
                    type="number"
                    style={{ display: "flex", flex: 1 }}
                    onChange={(width) => {
                      const widthInPixel =
                        (Number(width) * 255) / designerSettings?.multiplier;
                      const v = designerSettings?.width;
                      const widthInInches =
                        width < 1
                          ? 255 / designerSettings?.multiplier
                          : widthInPixel > v
                          ? v
                          : widthInPixel;

                      setCoordinates({
                        ...selectedPrintArea?.coordinates,
                        width: widthInInches,
                      });
                    }}
                  />
                  <TextField
                    label="Back Height (inches)"
                    type="number"
                    style={{ display: "flex", flex: 1 }}
                    onChange={(height) => {
                      const heightInPixel =
                        (Number(height) * 255) / designerSettings?.multiplier;
                      const v = designerSettings?.height;
                      const heightInInches =
                        height < 1
                          ? 255 / designerSettings?.multiplier
                          : heightInPixel > v
                          ? v
                          : heightInPixel;

                      setCoordinates({
                        ...selectedPrintArea?.coordinates,
                        height: heightInInches,
                      });
                    }}
                  />
                </>
              )}
          </div>
        )}
      </ButtonWrapper>
      <Wrapper isSub={isProductSub}>
        <Image
          src={selectedPrintArea?.printAreaMarketingImage}
          alt="ArtWorkImage"
        />

        {!!designerSettingsLength && (
          <OuterEnvelope
            isSub={isProductSub}
            style={{
              top: `${designerSettings?.top}px`,
              left: `${designerSettings?.left}px`,
              right: `${designerSettings?.right}px`,
              bottom: `${designerSettings?.bottom}px`,
              width: `${designerSettings?.width}px`,
              height: `${designerSettings?.height}px`,
            }}
          >
            <CutomGraphicsOption
              onDrop={onDrop}
              orderLine={orderLine}
              printType={printType}
            />
            <InnerEnvelope
              previewImage={selectedPrintArea?.previewImage}
              onClick={() => onPreview(false)}
            >
              {selectedPrintArea?.artwork ? (
                <EditArtWorkImage
                  previewImage={selectedPrintArea?.previewImage}
                  coordinates={selectedPrintArea?.coordinates}
                  designerSettings={designerSettings}
                  url={selectedPrintArea?.artwork}
                  setCoordinates={setCoordinates}
                />
              ) : isProductAop ? null : (
                <FileUpload
                  printType={printType}
                  orderLine={orderLine}
                  onDrop={(artwork) => {
                    onDrop(artwork);
                  }}
                />
              )}
            </InnerEnvelope>

            {selectedPrintArea?.artwork && !selectedPrintArea?.previewImage && (
              <>
                <StyledCloseIcon onClick={() => onRemove()} />
                <StyledVisibilityOffIcon onClick={() => onPreview(true)} />
                <PrintableAreaAlign>
                  <div>
                    {designerSettings?.platenWidthInch} X
                    {designerSettings?.platenHeightInch}
                    &nbsp;Printable Area
                  </div>

                  <AlignIcon>
                    <Tooltip title="Horizontal Center">
                      <CenterHorizontalIcon
                        onClick={() =>
                          setCoordinates({
                            ...selectedPrintArea?.coordinates,
                            x:
                              (designerSettings?.width -
                                selectedPrintArea?.coordinates?.width) /
                              2,
                          })
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Align Top">
                      <VerticalAlignTopIcon
                        onClick={() =>
                          setCoordinates({
                            ...selectedPrintArea?.coordinates,
                            y: 0,
                          })
                        }
                      />
                    </Tooltip>
                  </AlignIcon>
                </PrintableAreaAlign>
              </>
            )}
          </OuterEnvelope>
        )}
      </Wrapper>
      {isProductSub && orderLine?.ColorID && orderLine?.ProductID && (
        <img
          src={getMarketingImagePathFromProductId(
            products,
            orderLine?.ProductID,
            orderLine?.ColorID
          )}
          alt="ArtWorkImage"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 100,
          }}
        />
      )}
      {additionalImages?.length > 0 && (
        <div>
          {additionalImages?.map((a, i) => (
            <Tooltip
              classes={{ tooltip: classes.customWidth }}
              key={`additionalImage${i}`}
              placement="top"
              arrow
              title={
                <img
                  src={a?.secure_url}
                  alt={a?.secure_url}
                  style={{ width: 500, height: 500 }}
                />
              }
            >
              <img
                alt={a?.secure_url}
                src={a?.secure_url}
                style={{
                  width: 100,
                  height: 100,
                  border: "1px solid #ccc",
                  marginRight: 10,
                  padding: 5,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ))}
        </div>
      )}
      {orderLine.PrintType === "DTG" && (
        <ErrorMessage>
          Note: Always centre your artwork design in the design box and not to
          the design box placement in the mockup image. The top of the design
          box will always be set up 1-2'' down form the neckline of the garment
          selected when actually produced. The design box will always be
          centered to the garment when being produced.
        </ErrorMessage>
      )}
      {orderLine.PrintType === "DURASOFT" && (
        <ErrorMessage>
          Note: Some mock up images may not appear centered on the design board.
          You can size the image down to have it centered for creating your
          store mock-ups. When you place an order ALWAYS center and size (to
          your desired size) to the design board, even if it looks off center to
          the mock-up background image. Production will ensure it is centered
          when actually printed.
        </ErrorMessage>
      )}
    </Container>
  );

  if (isAdvanceToolSelected || (order && order?.PrintProvider === "NY"))
    return advanceTool();

  if (isBasicToolSelected) return basicTool();

  return (
    <Container>
      <Tabs
        onTabChange={() => {
          setOrderLine({
            ...orderLine,
            printAreas: orderLine?.printAreas?.map((p) => ({
              ...p,
              canvasJSON: null,
              artwork: null,
              artworkId: null,
              coordinates: {},
            })),
          });
        }}
        tabs={[
          {
            title: "Basic Tool",
            children: basicTool(),
          },
          {
            title: "Advanced Tool",
            showNewReleaseIcon: true,
            children: advanceTool(),
          },
        ]}
      />
    </Container>
  );
};

export default ArtWork;
