import React from 'react';
import { Provider } from 'pages/admin-screen-printing/context';
import Header from 'pages/admin-screen-printing/Header';
import Body from 'pages/admin-screen-printing/Body';
import { Container } from 'styles/pages/admin-screen-printing';

const Wrapper = () => (
  <Container>
    <Header />
    <Body />
  </Container>
);

const ScreenPrinting = () => (
  <Provider>
    <Wrapper />
  </Provider>
);

export default ScreenPrinting;
