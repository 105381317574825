const columns = [
  {
    id: 'orderSubmitToProductionDate',
    label: 'Production Submit Date',
    minWidth: 170,
  },
  {
    id: 'orderShipDate',
    label: 'Order Ship Date',
    minWidth: 170,
  },
  {
    id: 'masterUserEmail',
    label: 'User Email',
    minWidth: 170,
  },
  {
    id: 'userCountry',
    label: 'User Country',
    minWidth: 170,
  },
  {
    id: 'printProvider',
    label: 'Print Provider',
    minWidth: 170,
  },
  {
    id: 'shipCountry',
    label: 'Ship Country',
    minWidth: 170,
  },
  {
    id: 'ID',
    label: 'Order#',
    minWidth: 170,
  },
  {
    id: 'lineItemsCost',
    label: 'Line Items Cost (Without Tax)',
    minWidth: 170,
  },
  {
    id: 'shippingCostWithoutTax',
    label: 'Shipping Cost (Without Tax)',
    minWidth: 170,
  },
  {
    id: 'totalTax',
    label: 'Total Tax',
    minWidth: 170,
  },
  {
    id: 'totalAmountPaidUsd',
    label: 'Total Amount Paid in USD',
    minWidth: 170,
  },
  {
    id: 'totalAmountPaidCad',
    label: 'Total Amount Paid in CAD',
    minWidth: 170,
  },
];

export { columns };
