import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import USAFlag from 'assets/images/usa-flag.png';
import CADFlag from 'assets/images/canada-flag.png';
import { SET_CURRENCY } from 'actions/currency';
import {
  FlagImage,
  FlagSection,
  BoldText,
  Text,
  CountryModalContainer,
  Flags,
  Flag,
} from 'styles/components/Header/CountryModal';

const styles = (theme) => ({
  root: {
    padding: '19px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '21px',
    color: theme.palette.grey[500],
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

const CountryModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state?.currency?.value);

  return (
    <Dialog onClose={onClose} open={open}>
      <CountryModalContainer>
        <DialogTitle onClose={onClose}>Select Currency</DialogTitle>
        <DialogContent>
          <BoldText gutterBottom>
            Select the currency you want to pay with.
          </BoldText>
          <Text gutterBottom>
            Product prices on the website will automatically be converted and
            you pay Shirtly in the currency you choose.
          </Text>
          <FlagSection>
            <Flags>
              <Flag
                selected={currency === 'USD'}
                onClick={() => {
                  dispatch({ type: SET_CURRENCY, currency: 'USD' });
                  onClose();
                }}
              >
                <FlagImage src={USAFlag} alt="USAFlag" />
                <p>USD</p>
              </Flag>

              <Flag
                selected={currency === 'CAD'}
                onClick={() => {
                  dispatch({ type: SET_CURRENCY, currency: 'CAD' });
                  onClose();
                }}
              >
                <FlagImage src={CADFlag} alt="USAFlag" />
                <p>CAD</p>
              </Flag>
            </Flags>

            <h3>
              If you wish to pay in Canadian dollars there is an option when you
              check out. We convert at current exchange rates.
            </h3>
          </FlagSection>
        </DialogContent>
      </CountryModalContainer>
    </Dialog>
  );
};

export default CountryModal;
