import styled from "styled-components";
const CardWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 14px;
  width: 100%;
  p {
    line-height: 32px;
    margin: 0;
    font-size: 14px;
    font-family: "Inter400";
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
  h6 {
    line-height: 32px;
    margin: 0;
    font-size: 16px;
    font-family: "Inter600";
  }
`;
const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  border-block: 1px solid #f0f0f0;
  padding-bottom: 18px;
  padding-top: 8px;
`;
const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StoreName = styled.div``;
const Activation = styled.div`
  h6 {
    font-size: 12px;
    line-height: 24px;
    width: 83px;
    background: #d9f49e;
    color: #709f0a;
    text-align: center;
    border-radius: 4px;
  }
`;
const MarketPlace = styled.div`
  img {
    max-width: 126px;
    width: 100%;
    height: auto;
  }
`;
const RemoveStore = styled.div``;
const Link = styled.div`
  line-height: 32px;
  font: 14px;
  margin-top: 7px;
  span {
    color: #5c92e2;
    cursor: pointer;
  }
`;
export {
  CardWrapper,
  CardHeader,
  CardBody,
  CardFooter,
  StoreName,
  Activation,
  MarketPlace,
  RemoveStore,
  Link,
};
