import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/Button";
import TextField from "components/TextField";
import Context from "pages/admin-screen-printing/context";
import {
  updateScreenPrintingInfo,
  getAllScreenPrinting,
  deleteScreenPrintingInfo,
} from "actions/products";
import {
  StyledBody,
  StyledRow,
  IconWrapper,
  StyledRowWrapper,
  Footer,
} from "styles/pages/admin-screen-printing";

const Body = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state?.products?.data);
  const { value, setValue } = Context();
  const rows = value?.rows;

  const fetchAllScreenPrinting = async () => {
    const data = await dispatch(getAllScreenPrinting());
    setValue({
      ...value,
      rows: data,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!rows) {
      fetchAllScreenPrinting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    if (products?.length && !value?.colors?.length) {
      let allUniqueColors = [];
      for (let i = 0; i < products.length; i++) {
        allUniqueColors = [...allUniqueColors, ...products[i]?.Colors];
      }

      if (!!allUniqueColors?.length) {
        setValue({
          ...value,
          colors: [{ Name: "Any Color" }, ..._.uniqBy(allUniqueColors, "ID")],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.colors, products]);

  const handleRowChange = (key, fieldValue, index) =>
    setValue({
      ...value,
      rows: rows?.map((p, j) => {
        if (index !== j) return p;

        return {
          ...p,
          [key]: fieldValue,
        };
      }),
    });

  return !!rows?.length ? (
    <StyledBody>
      {rows?.map((r, i) => {
        const disabled =
          !r?.colorName ||
          !r?.filmPrice ||
          !r?.decorationPrice ||
          !r?.minQuantity ||
          !r?.fulfillmentMinDays ||
          !r?.fulfillmentMaxDays;

        const options = value?.colors ? value?.colors?.map((c) => c?.Name) : [];

        return (
          <StyledRowWrapper key={`row_${i}`}>
            <StyledRow>
              <IconWrapper>
                <img src={r?.icon} alt="icon" />
                <Autocomplete
                  size="small"
                  options={options}
                  value={r?.colorName}
                  inputValue={r?.colorName}
                  disableClearable
                  onInputChange={(event, newInputValue) =>
                    handleRowChange("colorName", newInputValue, i)
                  }
                  renderOption={(option) => option}
                  onChange={(event, newValue) =>
                    handleRowChange("colorName", newValue, i)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select Color" />
                  )}
                />
              </IconWrapper>

              <TextField
                isDark
                type="number"
                size="small"
                label="Film Price"
                value={r?.filmPrice}
                onChange={(v) =>
                  handleRowChange("filmPrice", Number(v > 0 ? v : 0), i)
                }
              />
              <TextField
                isDark
                type="number"
                size="small"
                label="Decoration Price"
                value={r?.decorationPrice}
                onChange={(v) =>
                  handleRowChange("decorationPrice", Number(v > 0 ? v : 0), i)
                }
              />
              <TextField
                isDark
                type="number"
                size="small"
                label="Minimum Quantity"
                value={r?.minQuantity}
                onChange={(v) =>
                  handleRowChange("minQuantity", Number(v > 0 ? v : 0), i)
                }
              />
              <TextField
                isDark
                type="number"
                size="small"
                label="Fulfillment Min Days"
                value={r?.fulfillmentMinDays}
                onChange={(v) =>
                  handleRowChange(
                    "fulfillmentMinDays",
                    Number(v > 0 ? v : 0),
                    i
                  )
                }
              />
              <TextField
                isDark
                type="number"
                size="small"
                label="Fulfillment Max Days"
                value={r?.fulfillmentMaxDays}
                onChange={(v) =>
                  handleRowChange(
                    "fulfillmentMaxDays",
                    Number(v > 0 ? v : 0),
                    i
                  )
                }
              />
            </StyledRow>
            <Footer>
              <Button
                title={r?._id ? "Update" : "Add"}
                disabled={disabled}
                onClick={async () => {
                  await dispatch(updateScreenPrintingInfo(r));
                  fetchAllScreenPrinting();
                }}
              />
              {r?._id && r?.colorName !== "Any Color" && (
                <Button
                  title="Delete"
                  onClick={async () => {
                    await dispatch(deleteScreenPrintingInfo({ _id: r?._id }));
                    fetchAllScreenPrinting();
                  }}
                />
              )}
            </Footer>
          </StyledRowWrapper>
        );
      })}
    </StyledBody>
  ) : null;
};

export default Body;
