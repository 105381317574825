import styled from "styled-components";
import Radio from "@material-ui/core/Radio";

const StyledRadio = styled(Radio)`
  &.MuiRadio-colorPrimary.Mui-checked {
    color: #5c92e2;
  }
`;

export { StyledRadio };
