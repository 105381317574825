import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProductCategoryCard from 'components/ProductCategoryCard';
import { ROUTES } from 'shared/constants/routes';
import {
  CategoryWrapper,
  CategoryCard,
  CategoryTitle,
} from 'styles/pages/catalog';

const Categories = () => {
  const categories = useSelector((state) => state?.categories?.data) || [];
  const history = useHistory();
  return (
    <CategoryWrapper>
      <CategoryTitle>BROWSE BY CATEGORY</CategoryTitle>
      <CategoryCard>
        {categories.map((category, index) => (
          <ProductCategoryCard
            onClick={() =>
              history.push({
                pathname: ROUTES.BROWSE_CATALOG.LINK,
                state: { Categories: category.CatId },
              })
            }
            key={`${category.DisplayName}_${index}`}
            title={category.DisplayName}
            imagePath={category.MarketingImagePath}
            aspectRatio={13.5 / 16}
            titleStyles={{
              textAlign: 'center',
              fontSize: '14px',
              padding: '8px',
            }}
          />
        ))}
      </CategoryCard>
    </CategoryWrapper>
  );
};

export default Categories;
