import React from 'react';
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import { ModalContainer, Footer } from 'styles/pages/my-stores/registration';

const OrderSubmittedModal = ({ open, setModal }) => {
  return (
    <Dialog maxWidth='md' open={open} onClose={() => setModal(false)}>
      <ModalContainer>
        <h1>Success</h1>
        <p>Your order(s) has been successfully submitted</p>
        <Footer>
          <Button variant='text' title='Ok' onClick={() => setModal(false)} />
        </Footer>
      </ModalContainer>
    </Dialog>
  );
};

export default OrderSubmittedModal;
