import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { TableContainer } from "styles/pages/order-stock";
const useRowStyles = makeStyles({
  root: {
    background: `linear-gradient(60deg, #fff, #eee)`,
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useChipStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "20px 0",
    flexWrap: "wrap",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    "& .MuiChip-root": {
      fontFamily: "Inter600",
    },
    "& .MuiChip-colorPrimary": {
      backgroundColor: "#5c92e2",
    },
  },
}));

function createData(
  orderNumber,
  orderStatus,
  orderProductionDate,
  orderLines,
  PrintProvider
) {
  return {
    orderNumber,
    orderStatus,
    orderProductionDate,
    orderLines,
    PrintProvider,
  };
}

function Row(props) {
  const { row } = props;
  const products = useSelector((state) => state?.products?.data);
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.orderNumber}</TableCell>
        <TableCell align="center">{row.orderStatus}</TableCell>
        <TableCell align="center">{row.orderProductionDate}</TableCell>
        <TableCell align="center">
          {row.PrintProvider === "CA" ? "Canada" : "United States"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={6}
          style={{
            padding: "0 20px",
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Order Lines
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ProductID</TableCell>
                    <TableCell align="center">Product</TableCell>
                    <TableCell align="center">Size</TableCell>
                    <TableCell align="center">Color</TableCell>
                    <TableCell align="center">Supplier</TableCell>
                    <TableCell align="center">Supplier Code</TableCell>
                    <TableCell align="center">Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orderLines.map((historyRow) => {
                    const printProviders = products?.find(
                      (p) => p?.Product?.ID === historyRow.ProductID
                    )?.PrintProviders;
                    const supplier = printProviders?.find(
                      (p) => p?.ProviderName === row.PrintProvider
                    );
                    return (
                      <TableRow key={`orderLineId${moment().unix()}`}>
                        <TableCell align="center">
                          {historyRow.ProductID}
                        </TableCell>
                        <TableCell align="center">
                          {historyRow.Product}
                        </TableCell>
                        <TableCell align="center">{historyRow.Size}</TableCell>
                        <TableCell align="center">
                          {historyRow?.Color}
                        </TableCell>
                        <TableCell align="center">
                          {supplier?.supplier}
                        </TableCell>
                        <TableCell align="center">
                          {supplier?.productCode}
                        </TableCell>
                        <TableCell align="center">
                          {historyRow?.OliQuantity}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({
  orders,
  setCountrySupplier,
  selectedCountrySupplier,
  options,
}) {
  const chipStyle = useChipStyles();

  if (!orders) {
    return null;
  }

  let rows = [];
  for (let i = 0; i < orders?.length; i++) {
    const { ID, Status, OrderLines, PrintProvider } =
      orders[i]?.ShirtlyOrderData;
    rows.push(
      createData(
        ID,
        Status,
        moment(orders[i]?.OrderSubmitToProductionDate).format("MMM DD, YYYY"),
        OrderLines[0]?.OrderLineItems,
        PrintProvider
      )
    );
  }

  return (
    <>
      <div className={chipStyle?.root}>
        <Chip
          label={options[0]}
          clickable
          color={selectedCountrySupplier === options[0] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[0])}
        />
        <Chip
          label={options[1]}
          clickable
          color={selectedCountrySupplier === options[1] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[1])}
        />
        <Chip
          label={options[2]}
          clickable
          color={selectedCountrySupplier === options[2] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[2])}
        />
      </div>
      <div className={chipStyle?.root}>
        <Chip
          label={options[3]}
          clickable
          color={selectedCountrySupplier === options[3] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[3])}
        />
        <Chip
          label={options[4]}
          clickable
          color={selectedCountrySupplier === options[4] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[4])}
        />
        <Chip
          label={options[5]}
          clickable
          color={selectedCountrySupplier === options[5] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[5])}
        />
      </div>
      <TableContainer>
        <Table aria-label="collapsible table" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Production Date</TableCell>
              <TableCell align="center">Print Provider</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <Row key={`table_${moment().unix() + i}`} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
