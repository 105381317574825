import React, { useState } from 'react';
import Checkbox from 'components/CheckBox';
import {
  FieldsContainer,
  Title,
  ProductCheckBoxWrapper,
} from 'styles/pages/fulfillment-days';

const Products = ({ products, setProducts }) => {
  const [selectAll, setAll] = useState(false);
  return (
    <FieldsContainer>
      <Title>
        <Checkbox
          checked={!!selectAll}
          onChange={() => {
            setAll(!selectAll);
            setProducts(products?.map((c) => ({ ...c, selected: !selectAll })));
          }}
          label='Products'
        />
      </Title>
      <ProductCheckBoxWrapper>
        {products?.map((p) => (
          <Checkbox
            checked={!!p?.selected}
            onChange={() =>
              setProducts(
                products?.map((c) =>
                  p?.Product?.ID === c?.Product?.ID
                    ? { ...p, selected: !p?.selected }
                    : c
                )
              )
            }
            label={p?.Product?.Name}
            key={p?.Product?.ID}
          />
        ))}
      </ProductCheckBoxWrapper>
    </FieldsContainer>
  );
};

export default Products;
