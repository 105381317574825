import React from 'react';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { VariantDes } from 'styles/pages/sync-product';

const renderShopifyVariantDescription = (variant) => (
  <VariantDes>
    <span className='key'>Title:</span>
    <span className='value'>{variant?.title}</span>
    <span className='key'>SKU:</span>
    <span className='value'>{variant?.sku}</span>
    <span className='key'>Option1:</span>
    <span className='value'>{variant?.option1}</span>
    <span className='key'>Option2:</span>
    <span className='value'>{variant?.option2}</span>
    <span className='key'>Price:</span>
    <span className='value'>{variant?.price}</span>
  </VariantDes>
);

const renderWooCommVariantDescription = (variant) => (
  <VariantDes>
    <span className='key'>Title:</span>
    <span className='value'>{variant?.sku}</span>
    <span className='key'>SKU:</span>
    <span className='value'>{variant?.sku}</span>
    {variant?.attributes?.map((v, i) => (
      <React.Fragment key={variant?.attributes[i]?.option}>
        <span className='key'>Option{i + 1}:</span>
        <span className='value'>{variant?.attributes[i]?.option}</span>
      </React.Fragment>
    ))}
    {variant?.price && (
      <>
        <span className='key'>Price:</span>
        <span className='value'>{variant?.price}</span>
      </>
    )}
  </VariantDes>
);

const renderShopifyVariantImage = ({ variant, product }) => {
  const imageId = variant?.image_id;

  const image = product?.images.find((i) => i?.id === imageId);
  return !!image?.src ? (
    <img src={image?.src} alt='variant' width='50' />
  ) : (
    <WallpaperIcon />
  );
};

const renderWooCommVariantImage = ({ variant }) =>
  !!variant?.image[0]?.src ? (
    <img src={variant?.image[0]?.src} alt='variant' width='50' />
  ) : (
    <WallpaperIcon />
  );

export {
  renderShopifyVariantDescription,
  renderWooCommVariantDescription,
  renderShopifyVariantImage,
  renderWooCommVariantImage,
};
