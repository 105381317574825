import _ from 'lodash';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { Container, Wrapper } from 'styles/pages/home/product-catalog';

const ProductCatalog = ({
  toggleLoginDialog,
  toggleSignUpDialog,
  data = {},
}) => {
  const categories = useSelector((state) => state?.categories?.data) || [];
  const { components } = data;

  const {
    BackgroundColor,
    Description: description,
    Title: title,
    SubTitle,
    Buttons,
    Cards = [],
    SideImage,
  } = components || {};

  if (!categories) {
    return null;
  }

  return (
    <Container
      style={{ backgroundColor: BackgroundColor }}
      id="products-pricing"
    >
      <Wrapper>
        <div className="textSection">
          <div className="webHeaderText">
            <h2>{title}</h2>
            <p>{SubTitle}</p>

            {Buttons?.map((b, i) =>
              b?.Show ? (
                <Button
                  key={`button${i}`}
                  title={b?.ButtonName}
                  onClick={() => {
                    if (b?.Modal === 'SignIn') {
                      _.isFunction(toggleLoginDialog) && toggleLoginDialog();
                    } else if (b?.Modal === 'SignUp') {
                      _.isFunction(toggleSignUpDialog) && toggleSignUpDialog();
                    }
                  }}
                />
              ) : null
            )}
          </div>

          <div className="webBoxWrapper">
            {Cards?.map((c, i) => (
              <div className="box" key={c?.CardTitle}>
                <div className="image-wrapper">
                  <img src={c?.CardImage} alt="card" />
                </div>
                <div>
                  <h4>{c?.CardTitle}</h4>
                  <p>{c?.CardSubTitle}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="imgSection">
            {SideImage && <img src={SideImage} alt="ProductCatalog" />}
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default ProductCatalog;
