import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postEtsyCred } from 'actions/etsy';
import TextField from 'components/TextField';
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import { ModalContainer, Footer } from 'styles/pages/my-stores/registration';

const VerifierModal = ({ modal, setModal, token }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState(undefined);
  const [codeError, setCodeError] = useState(undefined);
  const emailId = useSelector((state) => state?.auth?.profile?.emailid);
  return (
    <Dialog maxWidth='md' open={modal} onClose={() => setModal(false)}>
      <ModalContainer>
        <h1>Authorize Your Etsy Account</h1>
        <p>Copy and paste verifier code from ETSY</p>
        <TextField
          fullWidth
          error={!!codeError}
          helperText={codeError}
          placeholder='Verifier Code'
          value={code || ''}
          onChange={(v) => setCode(v)}
        />
        <Footer>
          <Button
            variant='text'
            title='Verify'
            onClick={async () => {
              const request = await dispatch(
                postEtsyCred({
                  verifier: code,
                  etsyOauthToken: token?.oauthToken,
                  etsyOauthTokenSecret: token?.accessTokenSecret,
                  user: emailId,
                })
              );

              if (!request?.success) {
                setCodeError(request?.error);
                return;
              }

              if (request?.error) {
                return;
              }
              setModal(false);
              history.goBack();
            }}
          />
        </Footer>
      </ModalContainer>
    </Dialog>
  );
};

export default VerifierModal;
