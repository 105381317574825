import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/product-detail/accordion';
import { Description as StyledDescription } from 'styles/pages/product-detail/description';

const Description = ({ data }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Description</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <StyledDescription
          dangerouslySetInnerHTML={{ __html: data?.Product?.Description }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default Description;
