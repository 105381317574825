import { get, post } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { requests } from 'shared/requests';
import { SHOW_ERROR_MODAL } from 'actions/errorModal';

const getEtsyLoginUrl = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({ url: requests.etsyLoginUrl });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const postEtsyCred = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.getFinalEtsyCred, data });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

export { getEtsyLoginUrl, postEtsyCred };
