import React from 'react';
import { Rnd } from 'react-rnd';
import Dots from 'components/ArtWork/Dots';
import { ResizeDrag, ArtImage } from 'styles/components/ArtWork';

const EditArtWorkImage = ({
  url,
  designerSettings,
  setCoordinates,
  coordinates,
  previewImage,
}) =>
  url ? (
    <Rnd
      lockAspectRatio={true}
      disableDragging={previewImage ? true : false}
      enableResizing={!previewImage ? true : false}
      minWidth={40}
      minHeight={40}
      maxWidth={designerSettings?.width}
      maxHeight={designerSettings?.height}
      bounds='parent'
      size={{
        width: coordinates?.width,
        height: coordinates?.height,
      }}
      position={{
        x: coordinates?.x || 0,
        y: coordinates?.y || 0,
      }}
      onDragStop={(e, d) => {
        setCoordinates({
          ...coordinates,
          x: d.x < 0 ? 0 : d.x,
          y: d.y < 0 ? 0 : d.y,
        });
      }}
      onResize={(e, direction, ref, delta, position) =>
        setCoordinates({
          ...coordinates,
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          ...position,
        })
      }>
      <ResizeDrag previewImage={previewImage}>
        {!previewImage && (
          <Dots designerSettings={designerSettings} coordinates={coordinates} />
        )}
        <ArtImage
          src={url}
          alt='ArtWorkImage'
          // style={{ width: coordinates?.width, height: coordinates?.height }}
        />
      </ResizeDrag>
    </Rnd>
  ) : (
    <div>hi</div>
  );

export default EditArtWorkImage;
