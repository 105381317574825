import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
`;
const Container = styled.div`
  background: ${COLORS.WHITE};
  padding: 0.5em;
  position: relative;
  top: 0;
  transition: all ease-out 0.25s;
  transform-origin: top left;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  @media (min-width: 1200px) {
    position: sticky;
    height: 90vh;
  }
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0 1em;
  button {
    :hover {
      background: #ffffff !important;
      color: #5c92e2 !important;
      border: 1px solid #5c92e2;
    }
  }
`;

const PopularFilters = styled.div`
  display: flex;
  flex-direction: column;
  .MuiTypography-root {
    font-size: 12px;
    font-family: "Inter500";
    color: #6c6c6c;
  }
`;
export { Container, Filters, PopularFilters, Wrapper };
