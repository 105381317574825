import styled from "styled-components";

const Container = styled.div`
  padding: 15px;
  background-color: #000;
`;

const SubHeaderWrapper = styled.div`
  h2,
  p {
    color: #fff;
  }
`;

export { Container, SubHeaderWrapper };
