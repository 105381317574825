import React from 'react';
import Button from 'components/Button';
import { State, ButtonStyles } from 'styles/components/ShippingDetails';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import COLORS from 'shared/constants/colors';

const baseButtonStyle = {
  ...ButtonStyles,
  justifyContent: 'center',
  flex: 1,
};

const CourierVendors = ({ vendors, selectedVendor, setVendor }) => (
  <State>
    {vendors.map((vendor, index) => (
      <Button
        key={`vendorsButton${index}`}
        endIcon={<KeyboardArrowDownIcon />}
        containerStyle={
          selectedVendor?.ShippingDestination === vendor?.ShippingDestination
            ? {
                ...baseButtonStyle,
                backgroundColor: COLORS.BLACK,
                color: COLORS.WHITE,
              }
            : baseButtonStyle
        }
        variant="text"
        title={
          vendor.ShippingDestination === 'CA'
            ? 'Canada'
            : vendor.ShippingDestination === 'US'
            ? 'United States'
            : vendor.ShippingDestination === 'EU'
            ? 'EU'
            : vendor.ShippingDestination === 'AU'
            ? 'Australia'
            : vendor.ShippingDestination === 'NZ'
            ? 'New Zealand'
            : 'International'
        }
        onClick={() => setVendor(vendor)}
      />
    ))}
  </State>
);

export default CourierVendors;
