import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Select from 'components/Select';

const OrderDetails = ({ variant, onChange, product }) => {
  const products = useSelector((state) => state?.products?.data);
  let colors = _.find(
    products,
    (p) => p?.Product?.ID === variant?.syncData?.ProductID
  )?.Colors;
  colors = colors?.filter((s) => s?.visible !== false);

  return (
    <Select
      disabled={!variant?.syncData?.sizeId}
      label='Color'
      options={colors}
      value={{ ID: variant?.syncData?.ColorID || '' }}
      valueKey='ID'
      labelKey='Name'
      onChange={(ColorID, e) => {
        const c = _.find(colors, (o) => o.ID === ColorID);
        const image = _.find(colors, (c) => c.ID === ColorID);

        const variants = product?.variants?.map((v) =>
          v?.id === variant?.id
            ? {
                ...v,
                syncData: {
                  ...v?.syncData,
                  ColorID,
                  colorName: c?.Name,
                  printAreas: variant?.syncData?.printAreas?.map((p) => ({
                    ...p,
                    printAreaMarketingImage:
                      p?.printAreaName === 'Front'
                        ? image?.Image
                        : image[`${p?.printAreaName}Image`],
                  })),
                },
              }
            : v
        );
        onChange(variants, e);
      }}
    />
  );
};

export default OrderDetails;
