import React from 'react';
import Button from 'components/Button';
import ShopifyLogo from 'assets/images/shopify.png';
import Etsy from 'assets/images/etsy.png';
import BigCommerceLogo from 'assets/images/big-commerce-lg-logo.png';
import WooCommerceLogo from 'assets/images/woo-commerce.png';
import BigCartelLogo from 'assets/images/big-cartel-logo.png';
import {
  CardWrapper,
  CardHeader,
  CardBody,
  CardFooter,
  StoreName,
  Activation,
  MarketPlace,
  RemoveStore,
  Link,
} from 'styles/pages/my-stores/stores/storeCard';

const StoreCard = ({ data, onRemove }) => (
  <CardWrapper>
    <CardHeader>
      <StoreName>
        <p>Store Name</p> <h6>{data?.ShopName}</h6>
      </StoreName>
      <Activation>
        <p>My Store</p> <h6>Active</h6>
      </Activation>
    </CardHeader>
    <CardBody>
      <MarketPlace>
        <p>Marketplace</p>

        <img
          src={
            data?.MarketplaceType === 'Shopify'
              ? ShopifyLogo
              : data?.MarketplaceType === 'Etsy'
              ? Etsy
              : data?.MarketplaceType === 'WooCommerce'
              ? WooCommerceLogo
              : data?.MarketplaceType === 'BigCommerce'
              ? BigCommerceLogo
              : BigCartelLogo
          }
          alt='brand-logo'
        />
      </MarketPlace>
      <RemoveStore>
        <p>Remove Store</p>
        <Button
          containerStyle={{
            fontSize: '14px',
            minWidth: '120px',
            borderRadius: '6px',
            padding: '7px 5px',
            lineHeight: '1.5',
            textTransform: 'inherit',
            color: '#ffffff',
            backgroundColor: '#EE2B4E',
            fontFamily: 'Inter400',
          }}
          variant='text'
          title='Remove'
          onClick={onRemove}
        />
      </RemoveStore>
    </CardBody>
    {data?.ShopURL && (
      <CardFooter>
        <Link>
          Link :<span>{data?.ShopURL}</span>
        </Link>
      </CardFooter>
    )}
  </CardWrapper>
);

export default StoreCard;
