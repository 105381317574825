import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Banner from 'components/Banner';
import ProductCategoryCard from 'components/ProductCategoryCard';
import { getPromos } from 'selectors/tags';
import { imagesBaseURL } from 'shared/requests';
import { ROUTES } from 'shared/constants/routes';
import {
  CatalogTopBanner,
  PromosWrapper,
  CardSection,
} from 'styles/pages/catalog';

const Promos = () => {
  const tags = useSelector((state) => state?.tags?.data);
  const promosTags = getPromos(tags) || [];

  const history = useHistory();

  return (
    <CatalogTopBanner>
      <Banner
        title='Order custom design products for yourself or sell them online under your
    own brand'
        links={[
          {
            title: 'Browse All Products',
            onClick: () => history.push(ROUTES.BROWSE_CATALOG.LINK),
          },
        ]}
      />
      <PromosWrapper>
        <CardSection>
          {promosTags.map((tag, index) => (
            <ProductCategoryCard
              onClick={() =>
                history.push({
                  pathname: ROUTES.BROWSE_CATALOG.LINK,
                  state: { Promos: tag },
                })
              }
              key={`ProductCategoryCard${index}`}
              title={tag.DisplayName}
              imagePath={`${imagesBaseURL}/Images/promos/${tag.DisplayName.replace(
                ' ',
                '_'
              )}.jpg`}
              scaleDown={true}
              titleBackgroundOnHover={true}
              containerStyles={{
                borderRadius: '4px',
                backgroundColor: '#ffffff',
              }}
              titleStyles={{
                padding: '10px',
              }}
            />
          ))}
        </CardSection>
      </PromosWrapper>
    </CatalogTopBanner>
  );
};

export default Promos;
