import * as React from 'react';

const OutlineSpecs = () => (
  <svg width={34} height={34} fill='none'>
    <path
      d='M10.027 27.957a3.973 3.973 0 00-1.267-2.91v-.006c.07-.976.128-2.004.171-3.064 2.04.384 3.485 1.157 4.303 2.306 1.055 1.48.774 3.22.663 3.718-.371 1.66.612 3.34 2.277 3.66a15.23 15.23 0 002.876.278c3.344 0 6.698-1.143 9.466-3.43C32 25.63 34 21.437 34 17c.002-8.408-7.31-15.268-14.948-14.994-4.172.15-7.922 2.205-10.508 4.468a53.883 53.883 0 00-.104-.9c-.19-1.526-.414-2.736-.666-3.595C7.601 1.386 7.194 0 6.043 0 4.892 0 4.485 1.387 4.312 1.979c-.253.86-.477 2.07-.666 3.595C3.265 8.641 3.055 12.7 3.055 17c0 2.81.093 5.59.27 8.04l.001.006a3.973 3.973 0 00-1.267 2.911v2.059c0 1.08-.943 1.992-2.059 1.992V34h4.05c3.291 0 5.977-2.727 5.977-6.043zm9.097-23.96C25.542 3.767 32.01 9.627 32.008 17c-.001 3.84-1.736 7.475-4.76 9.973a12.92 12.92 0 01-10.699 2.732c-.554-.106-.833-.71-.708-1.27.2-.898.507-3.215-.984-5.308-1.17-1.643-3.14-2.704-5.86-3.162.022-.976.034-3.04.034-3.076a2.992 2.992 0 005.15-1.178 2.992 2.992 0 00-2.113-3.66 2.986 2.986 0 00-3.097 1.007 102.338 102.338 0 00-.203-4.041c2.254-2.381 6.065-4.866 10.356-5.02zM10.333 14.68a.996.996 0 111.923.514.996.996 0 01-1.923-.514zM5.525 6.67c.172-1.603.356-2.69.518-3.422.162.732.346 1.819.518 3.422.308 2.87.478 6.54.478 10.33 0 .333-.001.665-.004.996H5.051c-.003-.331-.004-.663-.004-.996 0-3.79.17-7.46.478-10.33zm-.442 13.318h1.92a106.986 106.986 0 01-.173 4.063 3.987 3.987 0 00-1.574 0c-.08-1.289-.139-2.657-.173-4.063zM4.051 30.016v-2.059c0-1.099.893-1.992 1.992-1.992 1.098 0 1.992.893 1.992 1.992 0 2.234-1.787 4.05-3.984 4.05h-.548c.357-.6.548-1.282.548-1.991z'
      fill='#2789C0'
    />
    <path
      d='M26.74 13.478a2.987 2.987 0 000-4.226 2.992 2.992 0 00-4.226 0 2.987 2.987 0 000 4.226 2.992 2.992 0 004.226 0zm-2.817-2.817a.997.997 0 011.408 1.409.997.997 0 01-1.408-1.41zM17.703 12.19a2.992 2.992 0 002.113-3.66 2.992 2.992 0 00-3.66-2.114 2.992 2.992 0 00-2.113 3.66 2.987 2.987 0 003.66 2.113zm-1.031-3.85a.997.997 0 11-.704 1.22.997.997 0 01.704-1.22zM18.168 25.47a2.992 2.992 0 003.66 2.114 2.992 2.992 0 002.113-3.66 2.987 2.987 0 00-3.66-2.113 2.992 2.992 0 00-2.113 3.66zm2.629-1.735a.995.995 0 11.516 1.923.995.995 0 01-.516-1.923zM23.803 18.29a2.992 2.992 0 002.113 3.659 2.987 2.987 0 003.66-2.113 2.987 2.987 0 00-2.113-3.66 2.987 2.987 0 00-3.66 2.113zm3.848 1.03a.995.995 0 11-1.923-.514.995.995 0 011.923.514z'
      fill='#2789C0'
    />
  </svg>
);

export default OutlineSpecs;
