import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import TextField from 'components/TextField';
import CloudinaryWidget from 'components/CloudinaryWidget';
import { DashboardAddSubSection } from 'styles/pages/web-pages';

const CustomDialog = ({ open, setOpen, addSubSection, data }) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    if (data && !state) {
      setState(data);
    }
  }, [data, state]);

  return (
    <Dialog open={open} onClose={setOpen}>
      <ModalWrapper>
        <DialogTitle>Add Sub Section</DialogTitle>
        <DialogContent>
          <DashboardAddSubSection>
            <TextField
              label="Sub Section Name"
              value={state?.subSectionName || ''}
              onChange={(subSectionName) =>
                setState({ ...state, subSectionName })
              }
            />

            <CloudinaryWidget
              label="Sub Section Image"
              disabled={false}
              value={state?.subSectionImage || ''}
              setValue={(subSectionImage) =>
                setState({
                  ...state,
                  subSectionImage,
                })
              }
              folderPath="Shirtly/Web_Pages/Dashboard"
            />

            <CloudinaryWidget
              disabled={false}
              label="Sub Section Link"
              value={state?.subSectionLink || ''}
              setValue={(subSectionLink) =>
                setState({
                  ...state,
                  subSectionLink,
                })
              }
              folderPath="Shirtly/Web_Pages/Dashboard"
            />
          </DashboardAddSubSection>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => addSubSection(state)}
            disabled={
              !state?.subSectionLink ||
              !state?.subSectionName ||
              !state?.subSectionImage
            }
          >
            Save
          </Button>
        </DialogActions>
      </ModalWrapper>
    </Dialog>
  );
};

export default CustomDialog;
