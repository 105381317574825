import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { columns } from 'pages/sales-report/table/columns';
import { rows } from 'pages/sales-report/table/rows';

const useStyles = makeStyles({
  td: {
    fontSize: 14,
    padding: '13px 16px',
    fontFamily: 'Inter400',
    '& img': {
      width: 50,
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'none',
    },
  },
  tr: {
    transition: 'all ease-out 0.25s',
    '&:nth-of-type(odd)': {
      background: 'linear-gradient(60deg, #fff, #eee)',
    },
    '&:nth-of-type(even)': {
      background: 'linear-gradient(-45deg, #fff, #eee)',
    },
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      background: 'linear-gradient(60deg, #fff, #eee)',
    },
    '&:nth-of-type(even)': {
      background: 'linear-gradient(-45deg, #fff, #eee)',
    },
    '&:hover': {
      cursor: 'pointer',
      background: 'white',
      boxShadow: '10px 10px 30px #d9d9d9, -10px -10px 30px #d9d9d9',
      position: 'relative',
    },
  },
}))(TableRow);

const Orders = ({ data, taxInfo }) => {
  const classes = useStyles();
  const dataTemp = rows(data, false, taxInfo);

  return dataTemp?.length > 0 ? (
    dataTemp.map((row, index) => (
      <StyledTableRow key={`OrdersTable${index}`} hover tabIndex={-1}>
        {columns.map((column, columnIndex) => (
          <TableCell
            className={classes.td}
            key={`${column.id}tbody${columnIndex}${index}`}
            align={column.align}>
            {row[column.id]}
          </TableCell>
        ))}
      </StyledTableRow>
    ))
  ) : (
    <StyledTableRow key={`NodataFound`} hover tabIndex={-1}>
      <TableCell
        className={classes.td}
        key='tbodynodatafound'
        colSpan={columns.length}>
        No Data Found
      </TableCell>
    </StyledTableRow>
  );
};

export default Orders;
