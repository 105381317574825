import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROUTES } from "shared/constants/routes";
import Button from "components/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ModalWrapper from "components/ModalWrapper";
import EtsyModal from "pages/my-stores/registration/EtsyModal";
import VerifierModal from "pages/my-stores/registration/VerifierModal";
import CancelIcon from "@material-ui/icons/Cancel";
import EtsyLogo from "assets/images/etsy-logo.png";
import ShopifyLogo from "assets/images/shopify-logo.png";
import BigCommerceLogo from "assets/images/big-commerce-logo.png";
import WooCommerceLogo from "assets/images/woo-logo.png";
import BigCartelLogo from "assets/images/big-cartel-icon.png";
import AddIcon from "assets/svg/AddIcon";
import Tooltip from "@material-ui/core/Tooltip";
import SettingsIcon from "@material-ui/icons/Settings";

const AddStoreDialog = ({ open, onClose }) => {
  const history = useHistory();
  const [etsyModal, setEtsyModal] = useState(false);
  const [verifierModal, setVerifierModal] = useState(false);
  const [etsyToken, setEtsyToken] = useState(undefined);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <ModalWrapper className="add-store">
        <CancelIcon className="close-icon" onClick={onClose} />
        <DialogTitle>Add a Store</DialogTitle>
        <DialogContent>
          <h6>Choose your Platform</h6>
          <DialogContentText>
            <p>
              Which marketplace are you hosting your store with? Pick one option
              below.
            </p>
            <p>
              After you connect your Store, orders made through your Store will
              automatically appear in Shirtly.
            </p>
            <p>
              You can add as many stores as you want and manage them all from
              one convenient location!
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <img src={EtsyLogo} alt="etsy-logo" />
            <Button
              title="Connect to Etsy"
              onClick={() => setEtsyModal(true)}
              endIcon={<AddIcon color="#ff5a00" />}
            />
          </div>
          <div style={{ position: "relative" }}>
            <img src={ShopifyLogo} alt="shopify-logo" />

            <Button
              title="Connect to Shopify"
              onClick={() =>
                window.open(
                  "https://apps.shopify.com/shirtly-fulfillment",
                  "_blank"
                )
              }
              endIcon={<AddIcon color="#5e8d3d" />}
            />

            <div
              style={{
                position: "absolute",
                right: "-3px",
                bottom: "-4px",
              }}
            >
              <Tooltip title="Manual Connection">
                <SettingsIcon
                  onClick={() => history.push(ROUTES.CONNECT_TO_SHOPIFY.LINK)}
                  style={{
                    color: "#5e8d3d",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          </div>

          <div>
            <img src={BigCommerceLogo} alt="big-commerce-logo" />
            <Button
              title="Connect to BigCommerce"
              onClick={() => {
                window.open("https://www.bigcommerce.com/apps/shirtly/");
                onClose();
              }}
              endIcon={<AddIcon color="#121118" />}
            />
          </div>

          <div>
            <img src={WooCommerceLogo} alt="woo-commerce-logo" />
            <Button
              title="Connect to WooCommerce"
              onClick={() => history.push(ROUTES.CONNECT_TO_WOO_COMMERCE.LINK)}
              endIcon={<AddIcon color="#7f54b3" />}
            />
          </div>

          <div>
            <img src={BigCartelLogo} alt="big-cartel-logo" />
            <Button
              title="Connect to BigCartel"
              onClick={() => {
                const url = `https://my.bigcartel.com/oauth/authorize?client_id=7JHOWyyFTLGGlSApqk6OSZJxtaH570&response_type=code&state=${emailid}&redirect_uri=https://shirtly-app.herokuapp.com/bigCartelRedirect`;
                window.open(url);
                onClose();
              }}
              endIcon={<AddIcon color="#222222" />}
            />
          </div>
        </DialogActions>
      </ModalWrapper>

      {etsyModal && (
        <EtsyModal
          modal={etsyModal}
          setModal={(v) => setEtsyModal(v)}
          showVerifierModal={(data) => {
            setEtsyModal(false);
            setEtsyToken(data);
            setVerifierModal(true);
          }}
        />
      )}
      {verifierModal && (
        <VerifierModal
          token={etsyToken}
          modal={verifierModal}
          setModal={(v) => setVerifierModal(v)}
        />
      )}
    </Dialog>
  );
};

export default AddStoreDialog;
