import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const ProductSizes = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  margin-bottom: 2em;
`;
const SizeBox = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  border-radius: 2px;
  overflow: hidden;
  align-items: center;
`;
const SizeName = styled.div`
  text-align: center;
  background: #2280c1;
  border-bottom: 1px solid #fff;
  color: #fff;
  min-height: 30px;
  display: grid;
  align-items: center;
  font-weight: bold;
  font-size: 0.825em;
  letter-spacing: 1px;
  cursor: pointer;
`;
const Price = styled.div`
  text-align: center;
  font-weight: 600;

  button {
    padding: 8px 10px;
    font-size: 12px;
    background: #2280c1;
    color: white;
    font-weight: bold;
    line-height: 10px;

    :hover {
      background: #2280c1;
      color: white;
    }
  }
`;
const PricesUnit = styled.p`
  font-weight: bold;
  transition: all ease-out 0.25s;
  margin: 0 0 10px;
  width: 100%;
  color: ${COLORS.BODY_TEXT};
`;
export { ProductSizes, SizeBox, SizeName, Price, PricesUnit };
