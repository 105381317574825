import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Tabs from "components/Tabs";
import Loader from "components/Loader";
import ArtworksLibraryItem from "components/ArtWork/ArtworksLibraryItem";
import AdvanceArtFiles from "components/ArtWork/AdvanceArtFiles";
import CustomGraphics from "components/ArtWork/CustomGraphics";
import AIGeneratedImages from "components/ArtWork/AIGeneratedImages";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import {
  getDesigns,
  clearArtWork,
  getBackgroundBurnDesigns,
  getPlusArtFiles,
} from "actions/designer";
import {
  ArtworksLibrary,
  CustomGraphicWrapper,
} from "styles/components/ArtWork/browseLibrary";

const buttonRow = {
  justifyContent: "space-between",
  display: "flex",
  gridGap: 10,
  flexDirection: "row",
};

const buttonStyles = {
  color: "#fff",
  textAlign: "right",
  fontSize: 18,
  textDecoration: "underline",
  cursor: "pointer",
  marginBottom: 10,
};

const BrowseLibraryTabs = ({
  toggle,
  onDrop,
  email,
  viewOnly = false,
  scroll = true,
  verify = false,
  libraryFiles,
  setLibraryFiles,
  setActiveTab,
  bbLibraryFiles,
  setBBLibraryFiles,
  plusLibraryFiles,
  setPlusLibraryFiles,
  advancedDesigns,
  setAdvancedDesigns,
  showAdvanceToolTab,
  advancedLibraryFiles,
  printType,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const loaderVisibility = useSelector((state) => state?.loader?.visibility);
  // const [libraryFiles, setLibraryFiles] = useState(undefined);
  // const [bbLibraryFiles, setBBLibraryFiles] = useState(undefined);
  // const [plusLibraryFiles, setPlusLibraryFiles] = useState(undefined);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  const isShirtlyAccount = emailid === "support@shirtly.com";

  const populateLibraryData = (library) => {
    for (let i = 0; i < library?.resources?.length; i++) {
      const l = library?.resources[i];
      const secureUrl = l.secure_url;
      const splitSecureUrl = secureUrl.split("/");
      if (!!splitSecureUrl?.length) {
        l.name = splitSecureUrl[splitSecureUrl?.length - 1];
      }
    }
  };

  // useEffect(() => {
  //   const loadLibrary = async () => {
  //     if (!libraryFiles) {
  //       const library = await dispatch(
  //         getDesigns({
  //           user: email || emailid,
  //         })
  //       );
  //       if (library.error) {
  //         return;
  //       }
  //       populateLibraryData(library);
  //       setLibraryFiles({
  //         ...library,
  //         cursors: library?.next_cursor
  //           ? [undefined, library?.next_cursor]
  //           : [],
  //       });
  //     }
  //   };
  //   loadLibrary();
  // }, [dispatch, libraryFiles, setLibraryFiles, emailid, email]);

  return (
    <div style={{ background: "#000", padding: 10 }}>
      <Tabs
        dark={true}
        activeTab={activeTab}
        onTabChange={async (index) => {
          setActiveTab(index);
          if (index === 1) {
            const library = await dispatch(
              getBackgroundBurnDesigns({
                user: email || emailid,
              })
            );
            if (library.error) {
              return;
            }
            populateLibraryData(library);
            setBBLibraryFiles({
              ...library,
              cursors: library?.next_cursor
                ? [undefined, library?.next_cursor]
                : [],
            });
          } else if (index === 2) {
            const library = await dispatch(
              getPlusArtFiles({
                user: email || emailid,
              })
            );
            if (library.error) {
              return;
            }
            populateLibraryData(library);
            setPlusLibraryFiles({
              ...library,
              cursors: library?.next_cursor
                ? [undefined, library?.next_cursor]
                : [],
            });
          }
          // else if (index === 3) {
          //   const library = await dispatch(
          //     getAdvancedDesigns({
          //       user: email || emailid,
          //     })
          //   );
          //   if (library.error) {
          //     return;
          //   }
          //   populateLibraryData(library);
          //   setAdvancedDesigns({
          //     ...library,
          //     cursors: advancedDesigns?.next_cursor
          //       ? [undefined, advancedDesigns?.next_cursor]
          //       : [],
          //   });
          // }
        }}
        tabs={[
          {
            title: "User Library",
            children: (
              <ArtworksLibrary scroll={scroll}>
                <div style={{ ...buttonRow, margin: "10px 0" }}>
                  <div
                    style={buttonStyles}
                    onClick={async () => {
                      const data = await dispatch(
                        getDesigns({
                          user: email || emailid,
                          next_cursor:
                            libraryFiles?.cursors[
                              libraryFiles?.cursors?.length - 3
                            ],
                        })
                      );

                      setLibraryFiles({
                        ...data,
                        cursors: libraryFiles?.cursors.slice(
                          0,
                          libraryFiles?.cursors?.length - 1
                        ),
                      });
                    }}
                  >
                    {libraryFiles?.cursors?.length > 2 ||
                    (libraryFiles?.cursors?.length === 2 &&
                      !libraryFiles?.next_cursor)
                      ? "Prev Page"
                      : ""}
                  </div>

                  <div
                    style={buttonStyles}
                    onClick={async () => {
                      const data = await dispatch(
                        getDesigns({
                          user: email || emailid,
                          next_cursor:
                            libraryFiles?.cursors[
                              libraryFiles?.cursors?.length - 1
                            ],
                        })
                      );
                      setLibraryFiles({
                        ...data,
                        cursors: data?.next_cursor
                          ? [...libraryFiles?.cursors, data?.next_cursor]
                          : [...libraryFiles?.cursors, undefined],
                      });
                    }}
                  >
                    {libraryFiles?.next_cursor ? "Next Page" : ""}
                  </div>
                </div>

                <div>
                  {libraryFiles && (
                    <ArtworksLibraryItem
                      isDark={true}
                      verify={verify}
                      libraryFiles={libraryFiles?.resources}
                      onSelect={(artwork) => {
                        onDrop({
                          ...artwork,
                          secure_url: _.endsWith(artwork?.secure_url, "jpg")
                            ? _.replace(artwork?.secure_url, ".jpg", ".png")
                            : _.replace(artwork?.secure_url, ".jpeg", ".png"),
                        });

                        if (!viewOnly) {
                          toggle();
                        }
                      }}
                      viewOnly={viewOnly}
                      onDeleteImage={async (publicId) => {
                        const artwork = await dispatch(
                          clearArtWork({
                            user: emailid,
                            publicId,
                          })
                        );
                        if (artwork.error) {
                          return;
                        }

                        setLibraryFiles(undefined);
                      }}
                    />
                  )}
                </div>
                {loaderVisibility && (
                  <div>
                    <Loader open={loaderVisibility} />
                  </div>
                )}
              </ArtworksLibrary>
            ),
          },
          {
            title: "Background Burn Library",
            children: (
              <ArtworksLibrary scroll={scroll}>
                <div style={{ ...buttonRow, margin: "10px 0" }}>
                  <div
                    style={buttonStyles}
                    onClick={async () => {
                      const data = await dispatch(
                        getBackgroundBurnDesigns({
                          user: email || emailid,
                          next_cursor:
                            bbLibraryFiles?.cursors[
                              bbLibraryFiles?.cursors?.length - 3
                            ],
                        })
                      );

                      setBBLibraryFiles({
                        ...data,
                        cursors: bbLibraryFiles?.cursors.slice(
                          0,
                          bbLibraryFiles?.cursors?.length - 1
                        ),
                      });
                    }}
                  >
                    {bbLibraryFiles?.cursors?.length > 2 ||
                    (bbLibraryFiles?.cursors?.length === 2 &&
                      !bbLibraryFiles?.next_cursor)
                      ? "Prev Page"
                      : ""}
                  </div>

                  <div
                    style={buttonStyles}
                    onClick={async () => {
                      const data = await dispatch(
                        getBackgroundBurnDesigns({
                          user: email || emailid,
                          next_cursor:
                            bbLibraryFiles?.cursors[
                              bbLibraryFiles?.cursors?.length - 1
                            ],
                        })
                      );
                      setBBLibraryFiles({
                        ...data,
                        cursors: data?.next_cursor
                          ? [...bbLibraryFiles?.cursors, data?.next_cursor]
                          : [...bbLibraryFiles?.cursors, undefined],
                      });
                    }}
                  >
                    {bbLibraryFiles?.next_cursor ? "Next Page" : ""}
                  </div>
                </div>

                <div>
                  {bbLibraryFiles && (
                    <ArtworksLibraryItem
                      verify={verify}
                      libraryFiles={bbLibraryFiles?.resources}
                      onSelect={(artwork) => {
                        onDrop({
                          ...artwork,
                          secure_url: _.endsWith(artwork?.secure_url, "jpg")
                            ? _.replace(artwork?.secure_url, ".jpg", ".png")
                            : _.replace(artwork?.secure_url, ".jpeg", ".png"),
                        });
                        if (!viewOnly) {
                          toggle();
                        }
                      }}
                      viewOnly={viewOnly}
                    />
                  )}
                </div>
              </ArtworksLibrary>
            ),
          },
          {
            title: "TUFF PRINT TRANSFER Art Files",
            children: (
              <ArtworksLibrary scroll={scroll}>
                <div style={{ ...buttonRow, margin: "10px 0" }}>
                  <div
                    style={buttonStyles}
                    onClick={async () => {
                      const data = await dispatch(
                        getPlusArtFiles({
                          user: email || emailid,
                          next_cursor:
                            plusLibraryFiles?.cursors[
                              plusLibraryFiles?.cursors?.length - 3
                            ],
                        })
                      );

                      setPlusLibraryFiles({
                        ...data,
                        cursors: plusLibraryFiles?.cursors.slice(
                          0,
                          plusLibraryFiles?.cursors?.length - 1
                        ),
                      });
                    }}
                  >
                    {plusLibraryFiles?.cursors?.length > 2 ||
                    (plusLibraryFiles?.cursors?.length === 2 &&
                      !plusLibraryFiles?.next_cursor)
                      ? "Prev Page"
                      : ""}
                  </div>

                  <div
                    style={buttonStyles}
                    onClick={async () => {
                      const data = await dispatch(
                        getPlusArtFiles({
                          user: email || emailid,
                          next_cursor:
                            plusLibraryFiles?.cursors[
                              plusLibraryFiles?.cursors?.length - 1
                            ],
                        })
                      );
                      setPlusLibraryFiles({
                        ...data,
                        cursors: data?.next_cursor
                          ? [...plusLibraryFiles?.cursors, data?.next_cursor]
                          : [...plusLibraryFiles?.cursors, undefined],
                      });
                    }}
                  >
                    {plusLibraryFiles?.next_cursor ? "Next Page" : ""}
                  </div>
                </div>

                <div>
                  {plusLibraryFiles && (
                    <ArtworksLibraryItem
                      verify={verify}
                      libraryFiles={plusLibraryFiles?.resources}
                      onSelect={(artwork) => {
                        onDrop({
                          ...artwork,
                          secure_url: _.endsWith(artwork?.secure_url, "jpg")
                            ? _.replace(artwork?.secure_url, ".jpg", ".png")
                            : _.replace(artwork?.secure_url, ".jpeg", ".png"),
                        });
                        if (!viewOnly) {
                          toggle();
                        }
                      }}
                      viewOnly={viewOnly}
                      onDeleteImage={async (publicId) => {
                        const artwork = await dispatch(
                          clearArtWork({
                            user: emailid,
                            publicId,
                          })
                        );
                        if (artwork.error) {
                          return;
                        }

                        const library = await dispatch(
                          getPlusArtFiles({
                            user: email || emailid,
                          })
                        );
                        if (library.error) {
                          return;
                        }
                        populateLibraryData(library);
                        setPlusLibraryFiles({
                          ...library,
                          cursors: library?.next_cursor
                            ? [undefined, library?.next_cursor]
                            : [],
                        });
                      }}
                    />
                  )}
                </div>
              </ArtworksLibrary>
            ),
          },
          {
            title: (
              <CustomGraphicWrapper>
                Stock Designs (HQ)
                <NewReleasesIcon style={{ color: "red" }} />
              </CustomGraphicWrapper>
            ),
            children: (
              <CustomGraphics
                onDrop={onDrop}
                toggle={toggle}
                printType={printType}
              />
            ),
          },
          isShirtlyAccount
            ? {
                title: "AI Generated Images",
                children: <AIGeneratedImages onDrop={onDrop} toggle={toggle} />,
              }
            : null,
          showAdvanceToolTab && {
            title: "Advanced Art Files",
            children: (
              <AdvanceArtFiles
                toggle={toggle}
                onDrop={onDrop}
                email={email}
                viewOnly={viewOnly}
                scroll={scroll}
                verify={verify}
                advancedDesigns={advancedDesigns}
                setAdvancedDesigns={setAdvancedDesigns}
                advancedLibraryFiles={advancedLibraryFiles}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default BrowseLibraryTabs;
