import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import DetailAccordion from 'pages/product-detail/DetailAccordion';
import { ROUTES } from 'shared/constants/routes';
import {
  Container,
  Heading,
  StartDesigning,
} from 'styles/pages/product-detail/detail';

const Section = ({ data, onColorSelect, onImageSelect }) => {
  const history = useHistory();
  return (
    <Container>
      <Heading>{data?.Product?.Name}</Heading>
      <StartDesigning>
        <Button
          title="Start Designing"
          onClick={() => {
            history.push(
              `${ROUTES.MY_STORES_PRODUCTS.LINK}?productId=${data?.Product?.ID}&catId=${data?.Product?.CategoryIds[0]?.CatId}`
            );
          }}
        />
      </StartDesigning>

      <DetailAccordion
        data={data}
        onColorSelect={onColorSelect}
        onImageSelect={onImageSelect}
      />
    </Container>
  );
};

export default Section;
