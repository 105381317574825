import React from 'react';
import {
  TopLeftCorner,
  TopMiddleCorner,
  TopRightCorner,
  BottomLeftCorner,
  BottomMiddleCorner,
  BottomRightCorner,
  CenterRightCorner,
  CenterLeftCorner,
  ImageInch,
} from 'styles/components/ArtWork';

const Dots = ({ designerSettings, coordinates }) => {
  const width =
    coordinates?.width >= designerSettings?.width
      ? designerSettings?.platenWidthInch
      : ((coordinates.width * designerSettings?.multiplier) / 255).toFixed(1);

  const height =
    coordinates?.height >= designerSettings?.height
      ? designerSettings?.platenHeightInch
      : ((coordinates.height * designerSettings?.multiplier) / 255).toFixed(1);

  return (
    <React.Fragment>
      <TopLeftCorner />
      <TopMiddleCorner />
      <TopRightCorner />
      <BottomLeftCorner />
      <BottomMiddleCorner>
        <ImageInch>
          {width} X {height} inch
        </ImageInch>
      </BottomMiddleCorner>
      <BottomRightCorner />
      <CenterRightCorner />
      <CenterLeftCorner />
    </React.Fragment>
  );
};
export default Dots;
