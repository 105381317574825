import React from 'react';
import { useSelector } from 'react-redux';
import { getTagByTypeAndId } from 'selectors/tags';
import { Category, Popular, New } from 'styles/pages/browse-catalog/details.js';

const ProductTags = ({ tags = [] }) => {
  const data = useSelector((state) => state?.tags?.data) || [];

  return (
    <Category>
      {tags.map((tag, index) => {
        const selectedTag = getTagByTypeAndId(data, tag);
        if (selectedTag?.Name === 'Popular') {
          return <Popular key={`selectedTag${index}`}>Popular</Popular>;
        } else if (selectedTag?.Name === 'New') {
          return <New key={`selectedTag${index}`}>New</New>;
        }
        return null;
      })}
    </Category>
  );
};

export default ProductTags;
