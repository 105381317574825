import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMocks, clearMocks } from "actions/designer";
import FileDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  Container,
  Filter,
  FilterButton,
  CardWrapper,
  Card,
  CardFooter,
  CheckboxWrapper,
} from "styles/pages/generated-mocks";

function bytesToSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

const GeneratedMocks = ({ userEmail }) => {
  const dispatch = useDispatch();
  const [mocks, setMocks] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  const getMocksData = async () => {
    const response = await dispatch(getMocks({ user: userEmail || emailid }));
    setMocks({
      ...response,
      cursors: response?.next_cursor ? [undefined, response?.next_cursor] : [],
    });
  };

  useEffect(() => {
    if (!mocks) {
      getMocksData();
    }
  }, [dispatch, mocks, setMocks, emailid, userEmail]);

  const deleteDisabled = !mocks?.resources?.filter((r) => r?.selected)?.length;

  return (
    <Container>
      <Filter>
        <div>
          <FilterButton
            style={{ marginRight: 20 }}
            onClick={() => {
              setMocks(() => ({
                ...mocks,
                resources: mocks?.resources?.map((m) => ({
                  ...m,
                  selected: !selectAll,
                })),
              }));

              setSelectAll(!selectAll);
            }}
          >
            Select All
          </FilterButton>

          <FilterButton
            disabled={deleteDisabled}
            style={{ backgroundColor: "#f50057" }}
            onClick={async () => {
              const data = mocks?.resources?.filter((r) => r?.selected);

              for (let index = 0; index < data?.length; index++) {
                await dispatch(clearMocks({ publicId: data[index].public_id }));
              }

              await getMocksData();
            }}
          >
            Delete Mocks
          </FilterButton>
        </div>
        <div>
          <FilterButton
            style={{ marginRight: 20 }}
            disabled={
              mocks?.cursors?.length > 2 ||
              (mocks?.cursors?.length === 2 && !mocks?.next_cursor)
                ? false
                : true
            }
            onClick={async () => {
              const data = await dispatch(
                getMocks({
                  user: userEmail || emailid,
                  next_cursor: mocks?.cursors[mocks?.cursors?.length - 3],
                })
              );

              setMocks({
                ...data,
                cursors: mocks?.cursors.slice(0, mocks?.cursors?.length - 1),
              });
            }}
          >
            Prev Page
          </FilterButton>

          <FilterButton
            disabled={mocks?.next_cursor ? false : true}
            onClick={async () => {
              const data = await dispatch(
                getMocks({
                  user: userEmail || emailid,
                  next_cursor: mocks?.cursors[mocks?.cursors?.length - 1],
                })
              );
              setMocks({
                ...data,
                cursors: data?.next_cursor
                  ? [...mocks?.cursors, data?.next_cursor]
                  : [...mocks?.cursors, undefined],
              });
            }}
          >
            Next Page
          </FilterButton>
        </div>
      </Filter>

      <CardWrapper>
        {mocks?.resources?.map((c) => (
          <Card
            key={c?.asset_id}
            onClick={() => {
              setMocks(() => ({
                ...mocks,
                resources: mocks?.resources?.map((m) => ({
                  ...m,
                  selected:
                    m?.secure_url === c?.secure_url ? !m?.selected : false,
                })),
              }));
            }}
          >
            <CheckboxWrapper>
              {c?.selected ? (
                <CheckCircleIcon className="check-icon" />
              ) : (
                <RadioButtonUncheckedIcon className="uncheck-icon" />
              )}
            </CheckboxWrapper>

            <img alt="resource" src={c?.secure_url} width="100" height="100" />
            <CardFooter>
              <div>
                <FileDownloadIcon
                  style={{ cursor: "pointer", color: "#5c92e2" }}
                  onClick={() => {
                    window.open(c?.secure_url);
                  }}
                />
              </div>
              <div>{bytesToSize(c?.bytes)}</div>
            </CardFooter>
          </Card>
        ))}
      </CardWrapper>
    </Container>
  );
};

export default GeneratedMocks;
