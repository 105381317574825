import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Colors } from 'styles/pages/product-detail';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/product-detail/accordion';

const AdditionalImages = ({ data = {}, onImageSelect }) => {
  const classes = useStyles();
  if (!data?.AdditionalImages?.length) {
    return null;
  }

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Additional Images</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Colors
          style={{
            gridTemplateColumns: 'repeat(auto-fit,minmax(100px,100px))',
          }}>
          {data?.AdditionalImages?.map((image, index) => (
            <img
              alt='additional'
              onClick={() => onImageSelect(image?.secure_url)}
              style={{ width: '100%', cursor: 'pointer' }}
              src={image?.secure_url}
              key={`additionalImage${index}`}
            />
          ))}
        </Colors>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdditionalImages;
