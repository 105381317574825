import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  width: 88vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  position: relative;
  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 160px;
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  h4 {
    font-size: clamp(1.5rem, 2.5vw, 2.25rem);
    margin: 0;
    font-family: "Inter800";
    margin-bottom: 30px;
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 0;
  }
  button {
    background-color: #5c92e2;
    font-size: 16px;
    border-radius: 6px;
    padding: 0.5em 1em;
    line-height: 1.5;
    color: ${COLORS.WHITE};
    font-family: "Inter500";
    box-shadow: none;
    cursor: pointer;
    text-transform: capitalize;
    :hover {
      background-color: #5c92e2;
    }
  }
`;

const ForgetPassword = styled.a`
  color: #2789c0;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;

  font-size: 15px;
  &:hover {
    color: #2789c0;
    text-decoration: underline;
  }
`;

const LeftSection = styled.div`
  background-color: #000;
  color: #fff;
  padding: 35px;
  h2 {
    font-size: clamp(1.25rem, 2.5vw, 2.5rem);
    margin: 10px 0;
    font-family: "Inter800";
  }
  p {
    font-family: "Inter400";
  }
  @media (max-width: 700px) {
    display: none;
  }
`;
const RightSection = styled.div`
  padding: 60px 30px;

  @media (max-width: 850px) {
    padding: 40px 20px;
  }
  @media (max-width: 650px) {
    width: calc(100vw - 64px);
    max-width: 338px;
    h4 {
      display: none;
    }
  }
`;
const ImageWrapper = styled.div`
  max-width: 350px;
  margin: 40px auto 0;

  img {
    width: 100%;
  }
`;
const CreateAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter400";
  font-size: 14px;
  margin-top: 30px;
  flex-wrap: wrap;
  p {
    margin: 0;
  }
  .sign-up {
    color: #2889c0;
    margin-left: 5px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 650px) {
    margin: 15px 0 !important;
    p {
      :first-child {
        display: none;
      }
    }
  }
`;

const IntellectualAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter400";
  font-size: 14px;
  flex-wrap: wrap;
  p {
    margin: 0;
  }
  .sign-up {
    color: #2889c0;
    margin-left: 5px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 650px) {
    margin: 15px 0 !important;
    p {
      :first-child {
        display: none;
      }
    }
  }
`;

const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  button:nth-child(2) {
    background-color: #ffd300;
  }
`;

export {
  Container,
  Header,
  Body,
  ForgetPassword,
  LeftSection,
  RightSection,
  ImageWrapper,
  CreateAccountWrapper,
  IntellectualAccount,
  ActionButtons,
};
