import React from "react";
import _ from "lodash";
import Stepper from "components/Stepper";
import { stepperData } from "pages/order-details/data";
import { Container } from "styles/pages/order-details/orderSteps";

const OrdersStep = ({ activeStepIndex, updateActiveIndex, order = {} }) => {
  let isArtWorkPending = false;
  let isFulfillmentPending = false;

  const { OrderLines } = order;
  if (OrderLines?.length) {
    const { OrderLineItems } = OrderLines[0];

    isArtWorkPending = !!_.find(OrderLineItems, (c) => {
      if (c.aopVectorFile) {
        return false;
      }
      return c?.printAreas?.length > 0
        ? !c?.printAreas?.find((p) => p?.artwork || p?.canvasJSON)
        : !c.ArtworkId && !c.BackArtworkId && !c?.canvasJSON;
    });
    isFulfillmentPending = !!_.find(OrderLineItems, (c) => !c.FulfillmentCost);
  }

  const steps =
    order?.Status === "PendingDetails" ||
    isArtWorkPending ||
    isFulfillmentPending
      ? ["Order Info", "Order Details"]
      : stepperData;

  return (
    <Container>
      <Stepper
        activeStep={activeStepIndex}
        steps={steps}
        onChange={(index) =>
          _.isFunction(updateActiveIndex) && updateActiveIndex(index)
        }
      />
    </Container>
  );
};

export default OrdersStep;
