const DECORATING_TECHNIQUES = [
  {
    DisplayName: 'DTG',
    minFulfillmentDays: 0,
    maxFulfillmentDays: 0,
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/dtg_aobgz3.png',
  },
  {
    DisplayName: 'DTGPlus',
    minFulfillmentDays: 0,
    maxFulfillmentDays: 0,
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/dtg_aobgz3.png',
  },
  {
    DisplayName: 'PLUS',
    minFulfillmentDays: 0,
    maxFulfillmentDays: 0,
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/dtg_aobgz3.png',
  },
  {
    DisplayName: 'EMB',
    minFulfillmentDays: 0,
    maxFulfillmentDays: 0,
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/embroidery_ixztqg.png',
  },
  {
    DisplayName: 'SUB',
    minFulfillmentDays: 0,
    maxFulfillmentDays: 0,
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456603/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/sublimation_zrg3kk.png',
  },
  {
    DisplayName: 'AOP',
    minFulfillmentDays: 0,
    maxFulfillmentDays: 0,
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/alloverprint_osqy5g.png',
  },
  {
    DisplayName: 'DURASOFT',
    minFulfillmentDays: 0,
    maxFulfillmentDays: 0,
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/durasoft_okeuhn.png',
  },
];

export { DECORATING_TECHNIQUES };
