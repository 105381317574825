import { row } from 'pages/ip-program/table/row';

const rows = (data) => {
  let items = [];

  for (let i = 0; i < data?.length; i++) {
    if (data[i]) {
      const {
        secure_url,
        patentNumber,
        created_at,
        reviewing,
        country,
        approved,
        patentProofLink,
        emailId,
      } = data[i];
      items.push(
        row(
          secure_url,
          patentNumber,
          created_at,
          reviewing,
          country,
          approved,
          patentProofLink,
          emailId
        )
      );
    }
  }
  return items;
};

export { rows };
