import * as React from 'react';

const Icon = ({ color }) => (
  <svg width={25} height={25} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.399 20.616L21.283 6.548a1.758 1.758 0 00-1.745-1.616h-2.206v3.267a.732.732 0 11-1.464 0V4.932H9.132v3.267a.732.732 0 11-1.464 0V4.932H5.463c-.91 0-1.676.71-1.745 1.614L2.6 20.618a4.083 4.083 0 001.072 3.077A4.084 4.084 0 006.66 25h11.682c1.13 0 2.218-.476 2.986-1.305a4.082 4.082 0 001.072-3.08zm-6.6-7.164l-3.787 3.787a.73.73 0 01-1.036 0l-1.774-1.775a.732.732 0 111.035-1.036l1.257 1.257 3.27-3.269a.733.733 0 011.035 1.036z'
      fill={color}
    />
    <path
      d='M12.5 0a4.838 4.838 0 00-4.832 4.833v.099h1.464v-.1A3.372 3.372 0 0112.5 1.465a3.372 3.372 0 013.368 3.369v.099h1.464v-.1A4.838 4.838 0 0012.5 0z'
      fill={color}
    />
  </svg>
);

export default Icon;
