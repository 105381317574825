import React, { useState } from 'react';
import {
  Step,
  Heading,
  CardWrapper,
  Card,
} from 'styles/pages/order-details/orderDetailsDrawer';

const Products = ({ selectedProduct, setProduct, products = [] }) => {
  const [cardVisibility, setCardVisibility] = useState(true);
  return (
    <Step>
      <Heading
        selected={cardVisibility}
        onClick={() => setCardVisibility(!cardVisibility)}>
        Select Product&nbsp;
        {selectedProduct?.Product?.DisplayName && (
          <span>({selectedProduct?.Product?.DisplayName})</span>
        )}
      </Heading>
      {cardVisibility && (
        <CardWrapper>
          {products?.map((p, index) => {
            const product = p.Product;
            return (
              <Card
                key={`products${index}`}
                active={selectedProduct?.ID === product.ID}
                onClick={() => {
                  setCardVisibility(false);
                  setProduct(p);
                }}>
                <img
                  src={product.MarketingImagePath}
                  alt={product.DisplayName}
                />
                <p>{product.DisplayName}</p>
              </Card>
            );
          })}
        </CardWrapper>
      )}
    </Step>
  );
};

export default Products;
