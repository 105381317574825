import React, { useState } from 'react';
import _ from 'lodash';
import Color from 'components/Color';
import {
  Step,
  Heading,
  ColorWrapper,
} from 'styles/pages/order-details/orderDetailsDrawer';

const Colors = ({ selectedColor, setColor, colors }) => {
  const [cardVisibility, setCardVisibility] = useState(true);
  return (
    <>
      <Step>
        <Heading
          selected={cardVisibility}
          onClick={() => setCardVisibility(!cardVisibility)}>
          Select Color&nbsp;
          {selectedColor?.Name && <span>({selectedColor?.Name})</span>}
        </Heading>
        {cardVisibility && (
          <ColorWrapper>
            {colors?.map((color, index) => (
              <Color
                selected={selectedColor?.ID === color?.ID}
                title={color.Name}
                code={color.HexCode}
                key={`colorFilter${index}`}
                onClick={() => {
                  setCardVisibility(false);
                  _.isFunction(setColor) && setColor(color);
                }}
              />
            ))}
          </ColorWrapper>
        )}
      </Step>
    </>
  );
};

export default Colors;
