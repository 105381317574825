import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalWrapper from "components/ModalWrapper";
import DialogActions from "@material-ui/core/DialogActions";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "components/Button";
import Radio from "components/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getStores, getRandomUserStores } from "actions/stores";
import COLORS from "shared/constants/colors";
import { imagesBaseURL } from "shared/requests";
import BigCommerceLogo from "assets/images/big-commerce-lg-logo.png";
import WooCommerceLogo from "assets/svg/WooCommerceLogo";
import BigCartelLogo from "assets/images/big-cartel-coloured-logo.jpeg";
import {
  StoreWrapper,
  ButtonWrapper,
} from "styles/pages/orders/retrieveLatestOrder";

const RetrieveLatestOrder = ({
  retrieveShopifyOrders,
  emailId,
  loggedInUser = false,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(undefined);
  const [modal, setModal] = useState(false);
  const [randomUserStores, setRandomUserStores] = useState(null);
  const stores = useSelector((state) => state?.stores?.data);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  useEffect(() => {
    const fetchStores = async () => {
      if (loggedInUser && !stores?.length) {
        return dispatch(getStores(emailid));
      } else if (!loggedInUser && !randomUserStores) {
        const data = await dispatch(getRandomUserStores(emailId));
        setRandomUserStores(data);
      }
    };

    fetchStores();
  }, [dispatch, loggedInUser, emailid, emailId]);

  const storesData = loggedInUser ? stores : randomUserStores;

  return (
    <React.Fragment>
      <ButtonWrapper>
        <Button
          onClick={() => setModal(true)}
          title="Retrieve Latest Order"
          containerStyle={{
            background: "#fff",
            boxShadow: "none",
            height: "48px",
            textTransform: "capitalize",
            color: "#5c92e2",
            fontFamily: "Inter500",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        />
      </ButtonWrapper>

      <Dialog
        maxWidth="md"
        open={modal}
        onClose={() => {
          setValue(null);
          setModal(false);
        }}
      >
        <ModalWrapper className="retrieve-orders">
          <CancelIcon
            className="close-icon"
            onClick={() => {
              setValue(null);
              setModal(false);
            }}
          />
          <DialogTitle>Retrieve Orders</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ textAlign: "center" }}>
              {!storesData?.length ? (
                <h3>No stores connected.</h3>
              ) : (
                <RadioGroup
                  name="retrieve-latest-orders"
                  onChange={(e) => setValue(e.target.value)}
                >
                  {storesData?.map((store, index) => (
                    <StoreWrapper key={`StoreWrapper${index}`}>
                      <FormControlLabel
                        key={`ShopID${index}`}
                        value={store.ShopID}
                        control={
                          <Radio
                            value={store.ShopID}
                            checked={value === store.ShopID}
                          />
                        }
                        label={store.ShopName}
                        style={{ textTransform: "capitalize" }}
                      />
                      {store.MarketplaceType === "Shopify" && (
                        <img
                          src={`${imagesBaseURL}/Images/store-logo-shopify_shdure.png`}
                          alt="Shopify"
                          width="100"
                        />
                      )}
                      {store.MarketplaceType === "Etsy" && (
                        <img
                          src={`${imagesBaseURL}/Images/store-logo-etsy_pucy8l.png`}
                          alt="Shopify"
                          width="40"
                        />
                      )}

                      {store.MarketplaceType === "BigCommerce" && (
                        <img src={BigCommerceLogo} alt="Shopify" width="100" />
                      )}

                      {store.MarketplaceType === "BigCartel" && (
                        <img src={BigCartelLogo} alt="Shopify" width="100" />
                      )}

                      {store.MarketplaceType === "WooCommerce" && (
                        <WooCommerceLogo />
                      )}
                    </StoreWrapper>
                  ))}
                </RadioGroup>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              title="Retrieve"
              disabled={!value}
              onClick={() => {
                const selectedStore = _.find(
                  storesData,
                  (s) => s.ShopID === value
                );
                retrieveShopifyOrders(selectedStore);
                setValue(null);
                setModal(false);
              }}
            />
          </DialogActions>
        </ModalWrapper>
        {/* <Container>
          <Heading>Retrieve Orders</Heading>
          {!stores?.length ? (
            <h3>No stores connected.</h3>
          ) : (
            <RadioGroup
              name='retrieve-latest-orders'
              onChange={(e) => setValue(e.target.value)}>
              {stores?.map((store, index) => (
                <StoreWrapper key={`StoreWrapper${index}`}>
                  <FormControlLabel
                    key={`ShopID${index}`}
                    value={store.ShopID}
                    control={<Radio color='primary' />}
                    label={store.ShopName}
                    style={{ textTransform: 'capitalize' }}
                  />
                  {store.MarketplaceType === 'Shopify' && (
                    <img
                      src={`${imagesBaseURL}/Images/store-logo-shopify_shdure.png`}
                      alt='Shopify'
                      width='60'
                    />
                  )}
                  {store.MarketplaceType === 'Etsy' && (
                    <img
                      src={`${imagesBaseURL}/Images/store-logo-etsy_pucy8l.png`}
                      alt='Shopify'
                      width='40'
                    />
                  )}
                </StoreWrapper>
              ))}
            </RadioGroup>
          )}
          <Footer>
            <Button
              title='Retrieve'
              disabled={!value}
              onClick={() => {
                const selectedStore = _.find(stores, (s) => s.ShopID === value);
                retrieveShopifyOrders(selectedStore);
                setValue(null);
                setModal(false);
              }}
            />
          </Footer>
        </Container> */}
      </Dialog>
    </React.Fragment>
  );
};

export default RetrieveLatestOrder;
