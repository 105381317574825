import React from "react";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { TableContainer } from "styles/pages/submitted-stock-orders";
const useRowStyles = makeStyles({
  root: {
    background: `linear-gradient(60deg, #fff, #eee)`,
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useChipStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "20px 0",
    flexWrap: "wrap",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    "& .MuiChip-root": {
      fontFamily: "Inter600",
    },
    "& .MuiChip-colorPrimary": {
      backgroundColor: "#5c92e2",
    },
  },
}));

function createData(
  orderNumber,
  expectedDeliveryDate,
  orderDate,
  orderLines,
  amount,
  shippingMethod,
  poNumber
) {
  return {
    orderNumber,
    expectedDeliveryDate,
    orderDate,
    orderLines,
    amount,
    shippingMethod,
    poNumber,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.poNumber}</TableCell>
        {/* <TableCell align='center'>{row.poNumber}</TableCell> */}
        <TableCell align="center">{row.orderDate}</TableCell>
        <TableCell align="center">{row.expectedDeliveryDate}</TableCell>
        <TableCell align="center">{row.shippingMethod}</TableCell>
        <TableCell align="center">${row.amount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={6}
          style={{
            padding: "0 20px",
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Order Lines
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Title</TableCell>
                    <TableCell align="center">Product</TableCell>
                    <TableCell align="center">Color</TableCell>
                    <TableCell align="center">Size</TableCell>
                    <TableCell align="center">Qty</TableCell>
                    <TableCell align="center">Unit Price</TableCell>
                    <TableCell align="center">Total Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orderLines.map((historyRow) => {
                    return (
                      <TableRow key={`orderLineId${moment().unix()}`}>
                        <TableCell align="center">{historyRow.title}</TableCell>
                        <TableCell align="center">
                          {historyRow.brandName}&nbsp;{historyRow?.styleName}
                        </TableCell>
                        <TableCell align="center">
                          {historyRow.colorName}
                        </TableCell>
                        <TableCell align="center">
                          {historyRow.sizeName}
                        </TableCell>
                        <TableCell align="center">
                          {historyRow.qtyOrdered}
                        </TableCell>
                        <TableCell align="center">
                          ${historyRow.price}
                        </TableCell>
                        <TableCell align="center">
                          ${historyRow.price * historyRow.qtyOrdered}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable({
  orders,
  setCountrySupplier,
  selectedCountrySupplier,
  options,
}) {
  const chipStyle = useChipStyles();

  let rows = [];
  for (let i = 0; i < orders?.length; i++) {
    const {
      orderNumber,
      expectedDeliveryDate,
      orderDate,
      lines,
      total,
      shippingMethod,
      poNumber,
    } = orders[i];
    rows.push(
      createData(
        orderNumber,
        moment(expectedDeliveryDate).format("MMM DD, YYYY"),
        moment(orderDate).format("MMM DD, YYYY"),
        lines,
        total,
        shippingMethod,
        poNumber
      )
    );
  }

  return (
    <>
      <div className={chipStyle?.root}>
        <Chip
          label={options[0]}
          clickable
          color={selectedCountrySupplier === options[0] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[0])}
        />
        <Chip
          label={options[1]}
          clickable
          color={selectedCountrySupplier === options[1] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[1])}
        />
        <Chip
          label={options[2]}
          clickable
          color={selectedCountrySupplier === options[2] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[2])}
        />
      </div>
      <div className={chipStyle?.root}>
        <Chip
          label={options[3]}
          clickable
          color={selectedCountrySupplier === options[3] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[3])}
        />
        <Chip
          label={options[4]}
          clickable
          color={selectedCountrySupplier === options[4] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[4])}
        />
        <Chip
          label={options[5]}
          clickable
          color={selectedCountrySupplier === options[5] ? "primary" : ""}
          onClick={() => setCountrySupplier(options[5])}
        />
      </div>
      <TableContainer>
        <Table aria-label="collapsible table" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell />
              {/* <TableCell align='center'>Order Number</TableCell> */}
              <TableCell align="center">PO Number</TableCell>
              <TableCell align="center">Order Date</TableCell>
              <TableCell align="center">Expected Delivery Date</TableCell>
              <TableCell align="center">Shipping Method</TableCell>
              <TableCell align="center">Total Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <Row key={`table_${moment().unix() + i}`} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
