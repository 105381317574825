import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import TextField from 'components/TextField';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import COLORS from 'shared/constants/colors';
import { HexColorPicker } from 'react-colorful';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { default as MUIButton } from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import {
  Container,
  Header,
  CloseIconStyle,
  FontPickerWrapper,
  HexColorPickerWrapper,
  PreviewText,
} from 'styles/components/ArtWork/browseLibrary';

const EditTextDrawer = ({ open, toggle, onDrop, data, plus }) => {
  const googleFonts = useSelector((state) => state?.googleFonts?.data);
  const [value, setValue] = useState(data);
  const fonts = googleFonts?.items?.filter(
    (d) => d?.version === 'v15' || d?.version === 'v14'
  );
  const [inputValue, setInputValue] = React.useState(value?.fontFamily || '');

  return (
    <Drawer anchor="left" open={open} onClose={toggle}>
      <Container style={{ width: '30vw' }}>
        <Header style={{ borderBottom: '1px solid #ccc' }}>
          <h2>Edit Text</h2>
          <CloseIconStyle onClick={toggle}></CloseIconStyle>
        </Header>
        <div style={{ textAlign: 'center', margin: '30px 0' }}>
          <PreviewText style={{ display: 'inline-block' }}>
            <h1
              style={{
                textAlign: 'center',
                margin: 0,
                fontFamily: value?.fontFamily,
                padding: 0,
                color: value?.fill || '#000',
                display: 'inline-block',
                fontWeight: value?.fontWeight === 'bold' ? 'bold' : 'normal',
                textDecoration: `${value?.underline ? 'underline' : ''} ${
                  value?.linethrough ? 'line-through' : ''
                } ${value?.overline ? 'overline' : ''}`,
                fontStyle: value?.fontStyle === 'italic' ? 'italic' : 'unset',
              }}
            >
              {value?.text?.length ? value?.text : 'Enter text to preview'}
            </h1>
          </PreviewText>
        </div>

        <TextField
          label={`Enter Text (${
            value?.text?.length ? value?.text?.length : 0
          }/20)`}
          style={{ width: '100%' }}
          value={value?.text || ''}
          onChange={(v) => {
            if (v?.length <= 20) {
              setValue({
                ...value,
                text: v,
              });
            }
          }}
        />

        {value?.text && (
          <div style={{ margin: 20, textAlign: 'center' }}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <MUIButton
                onClick={() =>
                  setValue({
                    ...value,
                    fontWeight: value?.fontWeight === 'bold' ? '' : 'bold',
                  })
                }
                variant={
                  value?.fontWeight === 'bold' ? 'contained' : 'outlined'
                }
              >
                <FormatBoldIcon />
              </MUIButton>
              <MUIButton
                onClick={() =>
                  setValue({
                    ...value,
                    fontStyle: value?.fontStyle === 'italic' ? '' : 'italic',
                  })
                }
                variant={
                  value?.fontStyle === 'italic' ? 'contained' : 'outlined'
                }
              >
                <FormatItalicIcon />
              </MUIButton>
              {!plus && (
                <MUIButton
                  onClick={() =>
                    setValue({
                      ...value,
                      underline: !value?.underline,
                    })
                  }
                  variant={value?.underline ? 'contained' : 'outlined'}
                >
                  <FormatUnderlinedIcon />
                </MUIButton>
              )}
              {!plus && (
                <MUIButton
                  onClick={() =>
                    setValue({
                      ...value,
                      linethrough: !value?.linethrough,
                    })
                  }
                  variant={value?.linethrough ? 'contained' : 'outlined'}
                >
                  <FormatStrikethroughIcon />
                </MUIButton>
              )}
              {!plus && (
                <MUIButton
                  onClick={() =>
                    setValue({
                      ...value,
                      overline: !value?.overline,
                    })
                  }
                  variant={value?.overline ? 'contained' : 'outlined'}
                >
                  <VerticalAlignTopIcon />
                </MUIButton>
              )}
            </ButtonGroup>
          </div>
        )}

        {value?.text && (
          <div style={{ margin: '30px 0' }}>
            <h3 style={{ borderBottom: '1px solid #ccc', paddingBottom: 10 }}>
              Select Font Family
            </h3>
            <FontPickerWrapper>
              {/* <FontPicker
                sort="alphabet"
                apiKey="AIzaSyCFeviktD35r76PXTlfjR17S3-9HkTjclw"
                activeFontFamily={value?.fontFamily || "Open Sans"}
                onChange={(nextFont) =>
                  setValue({ ...value, fontFamily: nextFont?.family })
                }
              /> */}
              <Autocomplete
                options={fonts.map((f) => f?.family)}
                value={value?.fontFamily}
                inputValue={inputValue}
                disableClearable
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderOption={(option) => (
                  <div style={{ fontFamily: option }}>{option}</div>
                )}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    fontFamily: newValue || fonts[0]?.family,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Font" />
                )}
              />
            </FontPickerWrapper>
          </div>
        )}

        {value?.text && !value?.fill?.source && (
          <div style={{ margin: '30px 0' }}>
            <h3 style={{ borderBottom: '1px solid #ccc', paddingBottom: 10 }}>
              Select Text Color ({value?.fill})
            </h3>
            <HexColorPickerWrapper>
              <HexColorPicker
                color={value?.fill || '#000000'}
                onChange={(color) => {
                  setValue({
                    ...value,
                    fill: color,
                  });
                }}
              />

              <div style={{ marginTop: 30 }}>
                <TextField
                  label={`Enter Color Hex Code`}
                  style={{ width: '100%' }}
                  value={value?.fill || ''}
                  onChange={(color) => {
                    if (color?.length <= 7)
                      setValue({
                        ...value,
                        fill: `#${color?.replace(/#/g, '')}`,
                      });
                  }}
                />
              </div>
            </HexColorPickerWrapper>
          </div>
        )}

        {value?.text && (
          <div style={{ textAlign: 'center' }}>
            <Button
              containerStyle={{
                backgroundColor: COLORS.DARK_BLUE,
                color: COLORS.WHITE,
                fontWeight: 'normal',
                boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
                textTransform: 'none',
                borderRadius: '3px',
                lineHeight: '1.5',
                marginBottom: 30,
                width: '100%',
              }}
              title="Edit Text"
              onClick={() => onDrop(value)}
            />
          </div>
        )}
      </Container>
    </Drawer>
  );
};

export default EditTextDrawer;
