import React, { useState } from "react";
import _ from "lodash";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import { TabsWrapper, Tab } from "styles/components/Tabs";

const Tabs = ({
  tabs,
  onTabChange,
  parentActiveIndex = 0,
  parentState = false,
  activeTab,
  dark = false,
}) => {
  const [index, setIndex] = useState(activeTab || 0);

  return (
    <div>
      <TabsWrapper>
        {tabs?.map((tab, i) => {
          const active = !parentState ? index === i : parentActiveIndex === i;
          return (
            <Tab
              dark={dark}
              key={`${tab?.title}_${i}`}
              className={active ? "active" : ""}
              onClick={() => {
                setIndex(i);
                if (_.isFunction(onTabChange)) {
                  onTabChange(i);
                }
              }}
            >
              {tab?.title}
              {tab?.showNewReleaseIcon && (
                <NewReleasesIcon style={{ color: "red" }} />
              )}
            </Tab>
          );
        })}
      </TabsWrapper>
      <div>{tabs[index]?.children}</div>
    </div>
  );
};

export default Tabs;
