import React, { useState, useEffect } from "react";
import Password from "components/PasswordField";
import TextField from "components/TextField";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import CryptoJS from "crypto-js";
import ImageSection from "pages/account-settings/ImageSection";

import {
  Container,
  Form,
  Submit,
  TextSection,
} from "styles/pages/account-setting/passwordForm";

const PasswordForm = ({ deactivate, user, updatePassword }) => {
  const [state, setState] = useState(undefined);

  useEffect(() => {
    if (!state && user) {
      const { PasswordHash } = user?.User || {};

      setState({
        oldPasswordValue: PasswordHash,
        oldPassword: undefined,
        newPassword: undefined,
        confirmNewPassword: undefined,
      });
    }
  }, [user, setState, state]);

  const decryptPassword = CryptoJS.AES.decrypt(
    state?.oldPasswordValue || "",
    "shirtly"
  ).toString(CryptoJS.enc.Utf8);

  const disabled =
    state?.oldPassword !== decryptPassword ||
    !state?.newPassword ||
    !state?.confirmNewPassword ||
    state?.newPassword !== state?.confirmNewPassword;

  return (
    <Container>
      <TextSection>
        <Form>
          <TextField
            isDark
            label="Old Password"
            size="small"
            value={state?.oldPassword || ""}
            type="password"
            error={state?.oldPassword && state?.oldPassword !== decryptPassword}
            helperText={
              state?.oldPassword && state?.oldPassword !== decryptPassword
                ? "Please enter correct old password"
                : null
            }
            onChange={(oldPassword) =>
              setState({
                ...state,
                oldPassword,
              })
            }
          />
          <TextField
            isDark
            label="New Password"
            size="small"
            type="password"
            value={state?.newPassword || ""}
            error={!!(state?.newPassword && disabled)}
            helperText={
              state?.newPassword &&
              state?.newPassword !== state?.confirmNewPassword
                ? "Password not matched"
                : null
            }
            onChange={(newPassword) =>
              setState({
                ...state,
                newPassword,
              })
            }
          />
          <TextField
            isDark
            label="Confirm New Password"
            size="small"
            type="password"
            value={state?.confirmNewPassword || ""}
            error={!!(state?.confirmNewPassword && disabled)}
            helperText={
              state?.confirmNewPassword &&
              state?.newPassword !== state?.confirmNewPassword
                ? "Password not matched"
                : null
            }
            onChange={(confirmNewPassword) =>
              setState({
                ...state,
                confirmNewPassword,
              })
            }
          />
        </Form>
        <Submit>
          <Button
            containerStyle={{
              backgroundColor: "#5c92e2",
              fontSize: "16px",
              borderRadius: "6px",
              padding: "12px",
              lineHeight: "1.5",
              color: COLORS.WHITE,
              fontFamily: "Inter500",
              width: "100%",
              textTransform: "capitalize",
            }}
            variant="text"
            title="Change Password"
            disabled={disabled}
            onClick={() => {
              const payload = {
                userId: user?.User?.Email,
                oldPassword: state?.oldPasswordValue,
                newPassword: CryptoJS.AES.encrypt(
                  state?.newPassword,
                  "shirtly"
                ).toString(),
              };
              updatePassword(payload);
            }}
          />
          <Button
            containerStyle={{
              backgroundColor: COLORS.LIGHT_RED,
              fontSize: "16px",
              borderRadius: "6px",
              padding: "12px",
              lineHeight: "1.5",
              color: COLORS.WHITE,
              fontFamily: "Inter500",
              width: "100%",
              textTransform: "capitalize",
            }}
            variant="text"
            title="Remove Account"
            onClick={deactivate}
            disabled={
              !state?.oldPassword || state?.oldPassword !== decryptPassword
            }
          />
        </Submit>
      </TextSection>
      <ImageSection />
    </Container>
  );
};

export default PasswordForm;
