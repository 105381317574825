import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Storefront1 from 'assets/images/WooStoreFront1.jpeg';
import Storefront2 from 'assets/images/WooStoreFront2.jpeg';
import Button from 'components/Button';
import SubHeader from 'components/SubHeader';
import TextField from 'components/TextField';
import WooCommerceLogo from 'assets/images/woo-commerce.png';
import { wooAuth } from 'actions/stores';
import {
  Container,
  Detail,
  Row1,
  Row2,
  Img,
  TitleSection,
  TextSection,
  TextHeader,
} from 'styles/pages/connect-to-shopify';

const ConnectToShopify = () => {
  const dispatch = useDispatch();
  const [storeName, setStoreName] = useState(undefined);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  return (
    <Container>
      <SubHeader title='Register a Store' />
      <TitleSection>
        <p>Connect To WooCommerce</p>
      </TitleSection>
      <Detail>
        <Row1>
          <div>
            <TextField
              label='WooCommerce Store Url'
              onChange={(value) => setStoreName(value)}
            />
            <Button
              disabled={!storeName?.trim().length}
              title='Connect Store'
              onClick={async () => {
                const storeUrl = storeName.replace(/(^\w+:|^)\/\//, '');
                const response = await dispatch(
                  wooAuth({
                    storeUrl: storeUrl.endsWith('/')
                      ? storeUrl.substr(0, storeUrl.length - 1)
                      : storeUrl,
                    userId: emailid,
                  })
                );

                if (response?.WooAuthUrl) {
                  window.open(`http://${response?.WooAuthUrl}`);
                }
              }}
            />
          </div>
          <TextSection>
            <TextHeader>
              <p>How can I find my WooCommerce Store URL?</p>
              <img src={WooCommerceLogo} alt='woo-commerce' />
            </TextHeader>

            <p>
              Your WooCommerce storefront address is the default address that
              you put when you are Registering/Sign-Up with WooCommerce.
            </p>
            <p>
              If you can't remember your WooCommerce Store URL, you can find it
              by logging into your WooCommerce admin.There are two ways you
              should be able to find your store URL:
            </p>
          </TextSection>
        </Row1>
        <Row2>
          <Img src={Storefront2} alt='Storefront2' />
          <Img src={Storefront1} alt='Storefront1' />
        </Row2>
      </Detail>
    </Container>
  );
};

export default ConnectToShopify;
