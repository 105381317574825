import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StylesFilter from 'pages/browse-catalog/StylesFilter';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/browse-catalog/accordion';

const CategoriesFilter = ({ stylesFilter, updateStylesFilter }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Browse by Style</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <StylesFilter
          stylesFilter={stylesFilter}
          updateStylesFilter={updateStylesFilter}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CategoriesFilter;
