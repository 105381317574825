import Context, { Steps } from "pages/ai-mock-up-generator/context";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  Container,
  Option,
  Wrapper,
} from "styles/pages/ai-mock-up-generator/sidebar";

const Sidebar = () => {
  const { state, setState } = Context();

  return (
    <Container>
      <Wrapper>
        <Option
          selected={state.step === Steps.Products}
          onClick={() =>
            setState({
              ...state,
              step: Steps.Products,
              artwork: {
                ...state.artwork,
                printAreas: state.artwork?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        aiGenerateImages: false,
                        aiTempArtwork: null,
                        canvasJSON: null,
                      }
                    : p
                ),
              },
            })
          }
          id="products-tab"
        >
          <ArrowRightIcon />
          Products
        </Option>
        <Option
          id="ai-design-studio"
          selected={state.step === Steps.AIDesginStudio}
          onClick={() => {
            if (!state?.colors?.length) {
              alert("Please select product!");
              return;
            }
            setState({
              ...state,
              step: Steps.AIDesginStudio,
              artwork: {
                ...state.artwork,
                printAreas: state.artwork?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        aiGenerateImages: false,
                        aiTempArtwork: null,
                        canvasJSON: null,
                      }
                    : p
                ),
              },
            });
          }}
        >
          <ArrowRightIcon />
          AI Design Studio
        </Option>
        <Option
          selected={state.step === Steps.MockUp}
          id="mock-up"
          onClick={() => {
            if (!state?.colors?.length) {
              alert("Please select product!");
              return;
            }
            setState({
              ...state,
              step: Steps.MockUp,
              artwork: {
                ...state.artwork,
                printAreas: state.artwork?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        aiGenerateImages: false,
                        aiTempArtwork: null,
                        canvasJSON: null,
                      }
                    : p
                ),
              },
            });
          }}
        >
          <ArrowRightIcon />
          Mock-up
        </Option>
        <Option
          selected={state.step === Steps.Order}
          onClick={() =>
            setState({
              ...state,
              step: Steps.Order,
              artwork: {
                ...state.artwork,
                printAreas: state.artwork?.printAreas?.map((p) =>
                  p?.selected
                    ? {
                        ...p,
                        aiGenerateImages: false,
                        aiTempArtwork: null,
                        canvasJSON: null,
                      }
                    : p
                ),
              },
            })
          }
        >
          <ArrowRightIcon />
          Order
        </Option>

        <Option
          selected={state.step === Steps.MockLibrary}
          onClick={() =>
            setState({
              ...state,
              step: Steps.MockLibrary,
            })
          }
        >
          <ArrowRightIcon />
          Mocks Library
        </Option>
      </Wrapper>
    </Container>
  );
};

export default Sidebar;
