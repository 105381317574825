import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #000;
`;

const Wrapper = styled.div`
  text-align: center;
  margin-top: 20px;

  p {
    font-family: "Inter600";
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;

    &.usage {
      font-size: 18px;
      span {
        background-color: #f9682a;
        padding: 0 10px;
        color: #ffffff;
        border-radius: 6px;
      }
    }

    &.title-message {
      font-size: 22px;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  button {
    background-color: #f9682a;
    color: #ffffff;
    border-radius: 6px;
    text-transform: none;
    font-weight: bold;
    font-size: 20px;

    :hover {
      background-color: #ef510d;
      color: #ffffff;
    }
  }
`;

const Progress = styled.div`
  .MuiLinearProgress-colorSecondary {
    background-color: #efc2af;
    margin-bottom: 20px;
  }
  .MuiLinearProgress-barColorSecondary {
    background-color: #f9682a;
  }

  p {
    font-family: "Inter600";
    font-weight: bold;
  }

  h1 {
    margin-top: 0;
    font-size: 40px;
    span {
      font-size: 20px;
    }
  }
`;

const ObjectTitle = styled.p`
  margin-top: 40px;
`;

const ObjectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  grid-gap: 0 40px;

  @media (max-width: 650px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 0 20px;
  }
`;

const Upload = styled.div`
  width: 50%;
  margin: 0 auto;
  border: 2px dashed #ccc;
  padding: 20px 0;
  border-radius: 4px;

  p {
    margin-top: 0;
    color: #ffffff;
  }

  @media (max-width: 650px) {
    width: 90%;
  }
`;

const Message = styled.div`
  text-align: initial;
  border-top: 1px solid #ccc;
  margin-top: 20px;
  color: #ffffff;
`;

export {
  Container,
  Wrapper,
  ButtonWrapper,
  Progress,
  ObjectsWrapper,
  ObjectTitle,
  Upload,
  Message,
};
