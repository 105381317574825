import { Container, Wrapper } from 'styles/pages/home/key-features';

const KeyFeatures = ({ data = {} }) => {
  const { components = {} } = data;

  const { BackgroundColor = '', Cards = [], Title = '' } = components;

  return (
    <Container style={{ backgroundColor: BackgroundColor }} id='key-features'>
      <Wrapper>
        <div className='textSection'>
          <h2>{Title}</h2>
        </div>
        <div className='storeSection'>
          {Cards?.map((c) => (
            <p key={c?.CardTitle}>{c?.CardTitle}</p>
          ))}
        </div>
      </Wrapper>
    </Container>
  );
};

export default KeyFeatures;
