import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShippingDetails from 'components/ShippingDetails';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/product-detail/accordion';

const ShippingCost = ({ data }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>
          Shipping costs (Expand for Total Price)
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ShippingDetails data={data} />
      </AccordionDetails>
    </Accordion>
  );
};

export default ShippingCost;
