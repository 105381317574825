import React from 'react';
import { useSelector } from 'react-redux';
import SizesSelect from 'pages/sync-product/SizesSelect';
import CategoriesSelect from 'pages/sync-product/CategoriesSelect';
import ProductsSelect from 'pages/sync-product/ProductsSelect';
import ColorsSelect from 'pages/sync-product/ColorsSelect';
import DtgPlus from 'pages/order-details/DtgPlus';
import { getDTGPlusFromProductId } from 'selectors/products';
import {
  renderShopifyVariantDescription,
  renderWooCommVariantDescription,
  renderShopifyVariantImage,
  renderWooCommVariantImage,
} from 'pages/sync-product/helper';
import {
  Card,
  CardFields,
  SuccessIcon,
  ErrorIcon,
} from 'styles/pages/sync-product';

const ProductCard = ({ setProduct, variant, product, marketPlaceType }) => {
  const products = useSelector((state) => state?.products?.data);
  const printAreas = variant?.syncData?.printAreas;
  const selectedPrintArea = printAreas?.find((p) => p?.selected);

  const updateProduct = (key, value, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setProduct((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const success = !(
    !variant?.syncData?.ProductID ||
    !variant?.syncData?.sizeId ||
    !variant?.syncData?.categoryId ||
    !variant?.syncData?.ColorID ||
    !selectedPrintArea?.artwork ||
    (variant?.syncData?.dtgPlus &&
      (!variant?.syncData?.dtgVectorFile ||
        !variant?.syncData?.dtgProcessName ||
        !variant?.syncData?.dtgColorName ||
        !variant?.syncData?.dtgPlusMockFile)) ||
    (variant?.syncData?.plus &&
      (!variant?.syncData?.dtgProcessName || !variant?.syncData?.dtgColorName))
  );

  const dtgPlusOptions = getDTGPlusFromProductId(
    products,
    variant?.syncData?.ProductID
  );
  let title;

  if (marketPlaceType === 'Shopify') {
    title = renderShopifyVariantDescription(variant);
  } else if (marketPlaceType === 'WooCommerce') {
    title = renderWooCommVariantDescription(variant);
  }

  return (
    <Card
      opacity={variant?.selected ? 1 : 0.5}
      onClick={(e) => {
        updateProduct(
          'variants',
          product?.variants?.map((v) => ({
            ...v,
            selected: v?.id === variant?.id,
          })),
          e
        );
      }}>
      {success ? <SuccessIcon /> : <ErrorIcon />}
      <div>{title}</div>
      <CardFields>
        {marketPlaceType === 'Shopify'
          ? renderShopifyVariantImage({ variant, product })
          : renderWooCommVariantImage({ variant })}

        <CategoriesSelect
          variant={variant}
          onChange={(variants, e) => updateProduct('variants', variants, e)}
          product={product}
        />
        <ProductsSelect
          variant={variant}
          onChange={(variants, e) => updateProduct('variants', variants, e)}
          product={product}
        />

        <SizesSelect
          variant={variant}
          onChange={(variants, e) => updateProduct('variants', variants, e)}
          product={product}
        />
        <ColorsSelect
          variant={variant}
          onChange={(variants, e) => updateProduct('variants', variants, e)}
          product={product}
        />
      </CardFields>

      {variant?.syncData?.ProductID && variant?.syncData?.categoryId && (
        <DtgPlus
          dtgPlusOptions={dtgPlusOptions}
          dtgPlusMockFile={variant?.syncData?.dtgPlusMockFile}
          dtgPlus={variant?.syncData?.dtgPlus}
          plus={variant?.syncData?.plus}
          dtgProcessName={variant?.syncData?.dtgProcessName}
          setOrderLine={(data, e) => {
            updateProduct(
              'variants',
              product?.variants?.map((v) => {
                return v?.id === variant?.id ? { ...v, syncData: data } : v;
              }),
              e
            );
          }}
          dtgColorName={variant?.syncData?.dtgColorName}
          dtgVectorFile={variant?.syncData?.dtgVectorFile}
          selectedOrderLine={variant?.syncData}
          orderLine={variant?.syncData}
        />
      )}
    </Card>
  );
};

export default ProductCard;
