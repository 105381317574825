import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 180px);
  padding: 15px;
  background-color: #000;
  display: flex;
  flex-direction: column;
`;
const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e6e6e6;
  color: #fff;

  p {
    line-height: 32px;
    font-size: 16px;
    margin: 0;
    padding-bottom: 10px;
    padding-right: 60px;
    position: relative;
    font-family: "Inter600";

    ::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 5px;
      background: #ffd363;
      position: absolute;
      bottom: -3px;
      right: 0;
    }
  }
`;
const Detail = styled.div`
  color: inherit;
  margin-top: 35px;
  h2 {
    margin-top: 5px;
    font-size: 24px;
    font-weight: 100;
  }
`;
const Row1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @media (min-width: 960px) {
    flex-direction: row;
  }
  > div {
    /* .MuiOutlinedInput-notchedOutline {
      border-radius: 6px;
      border: 1px solid #dbdbdb;
    }
    .MuiFormLabel-root {
      font-family: "Inter400";
    }
    .MuiOutlinedInput-input {
      background-color: #fff;
    } */
    button {
      font-size: 16px;
      min-width: 120px;
      border-radius: 6px;
      padding: 14px 5px;
      line-height: 1.5;
      text-transform: inherit;
      color: #fff;
      font-family: "Inter500";
      background-color: #fff;
      margin-bottom: "15px";
      box-shadow: none;
      :hover {
        background-color: #fff;
      }

      &.MuiButton-contained.Mui-disabled {
        background-color: #fff;
      }
    }
    &:first-child {
      flex: 0.34;
      display: flex;
      flex-direction: column;
      > div {
        margin-bottom: 15px;
      }
    }
    &:last-child {
      flex: 0.66;
      background-color: #fff;
      border-radius: 10px;
      margin-top: 20px;
      label {
        margin-bottom: 5px;
        font-weight: 700;
        display: inline-block;
      }
      p {
        margin-top: 0;
      }
      @media (min-width: 960px) {
        margin-left: 24px;
        margin-top: 0;
      }
    }
  }
`;
const Row2 = styled.div`
  display: grid;
  padding: 0 15px;
  grid-gap: 30px;
  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Img = styled.img`
  width: 100%;
`;
const TextSection = styled.div`
  padding: 14px 24px;
  p {
    font-size: 14px;
    font-family: "Inter400";
  }
`;

const TextHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  img {
    width: 20%;
  }
  p {
    color: #5c92e2;
    font-size: 16px;
    line-height: 32px;
    margin: 0;
    font-family: "Inter600";
  }
`;
export {
  Container,
  TitleSection,
  Detail,
  Row1,
  Row2,
  Img,
  TextSection,
  TextHeader,
};
