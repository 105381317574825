import styled from "styled-components";

const Container = styled.div`
  .MuiAccordionSummary-expandIcon {
    margin: 0;
    padding: 5px;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
`;
const Heading = styled.h3`
  color: #fff;
  font-size: 36px;
  font-family: "Inter800";
  margin: 0 0 20px;
  line-height: 54px;
`;

const StartDesigning = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  button {
    background-color: #2280c1;
    color: white;
    :hover {
      background-color: #2280c1;
      color: white;
    }
  }
`;

export { Container, Heading, StartDesigning };
