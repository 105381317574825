import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  margin-bottom: 20px;
`;
const Wrapper = styled.div`
  border-radius: 10px;
  border: none !important;
  background-color: ${COLORS.WHITE};
  z-index: ${({ edit }) => (!edit ? 'unset' : '999')};
  position: ${({ edit }) => (!edit ? 'unset' : 'relative')};
  pointer-events: ${({ edit }) => (!edit ? 'unset' : 'relative')};
  padding: 0 30px;

  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;

const ErrorMsg = styled.span`
  color: red;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: bold;
  animation: glow 1s ease-in-out infinite alternate;

  @keyframes glow {
    from {
      text-shadow: 0 0 8px #fff, 0 0 16px #fff, 0 0 24px #ca060c,
        0 0 32px #ca060c, 0 0 40px #ca060c, 0 0 48px #ca060c, 0 0 56px #ca060c;
    }

    to {
      text-shadow: 0 0 4px #fff, 0 0 8px #ff0000, 0 0 12px #ff0000,
        0 0 16px #ff0000, 0 0 20px #ff0000, 0 0 24px #ff0000, 0 0 28px #ff0000;
    }
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  color: #2789c0;
  font-size: 17px;
  font-family: 'Inter600';
  padding: 14px 0;
  justify-content: space-between;
  h3 {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 17px;
  }

  > div {
    display: flex;
    grid-gap: 15px;
    button {
      font-size: 11px;
      padding: 2px 2px;
      text-transform: capitalize;
      :first-child {
        background-color: ${COLORS.BLUE};
        color: ${COLORS.WHITE};
        :hover {
          background-color: ${COLORS.BLUE};
          opacity: 0.8;
        }
      }
      :nth-child(2) {
        background-color: #ff0000;
        color: ${COLORS.WHITE};
        :hover {
          background-color: #ff0000;
        }
        opacity: 0.8;
      }
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: flex-end;
      margin-top: 5px;
    }
    h3 {
      width: 100%;
    }
  }
`;

const OrderInfo = styled.ul`
  padding: 0;
  margin: 0;
  li {
    display: flex;
    p {
      margin: 0;
    }
    > span {
      font-family: 'Inter400';
      font-size: 14px;
      line-height: 31px;
      :first-child {
        width: auto;
        font-family: 'Inter600';
        margin-right: 8px;
        line-height: 32px;
      }
    }
  }
`;
const Panel = styled.div`
  &.edit {
    ${OrderInfo} {
      padding: 0 10px;
      li {
        margin-bottom: 15px;
        flex-direction: column;

        > span {
          :first-child {
            min-width: 124px;
            margin-bottom: 5px;
          }
          :nth-child(2) {
            flex: 1;
          }
        }
      }
    }
  }
`;
const Status = styled.div`
  flex-direction: column;
  position: relative;
  justify-content: center;
  border-radius: 4px;
  padding: 3px 12.5px;
  border: 1px solid var(--status-color);
  width: auto;
  min-width: 175px;
  text-align: center;
  display: inline-flex;
  flex: 0;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #fff;
  h5 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0;
    font-size: 11px;
    color: inherit;
  }
  span {
    font-size: 12px;
  }
`;

const OrderAction = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #f3f3f3;
    padding-top: 15px;
    Button {
      margin-left: 14px;
      text-transform: capitalize;
      font-size: 14px;
      line-height: 1.42857;
      padding: 6px 12px;
      text-align: center;
      white-space: nowrap;
      font-weight: normal;
      :first-child {
        margin-left: 0;
      }
      :hover {
        opacity: 0.8;
      }
    }
  }
`;

const FulfillmentDisclaimer = styled.h1`
  text-align: right;
  color: #d43f3a;
  font-size: 14px;
`;

const BodySection = styled.div`
  border-top: 1px solid #efefef;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 15px 0;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
export {
  Container,
  BodySection,
  Wrapper,
  Heading,
  Panel,
  OrderInfo,
  Status,
  OrderAction,
  FulfillmentDisclaimer,
  ErrorMsg,
};
