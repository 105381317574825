import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ProductCatalog from "pages/home/ProductCatalog";
import CloudinaryWidget from "components/CloudinaryWidget";
import TextField from "components/TextField";
import Switch from "components/Switch";
import Select from "components/Select";
import { updateSection } from "actions/webPages";
import RichTextEditor, {
  getHtml,
  initiateEditorState,
} from "components/RichTextEditor";
import {
  Card,
  CardTitle,
  Row,
  AddSectionButton,
  AddSectionHeader,
  SectionNavbar,
  TextAreaWrapper,
  TextFieldWrapperIcon,
  ThreeBoxWrapper,
  TwoBoxWrapper,
  OneBoxWrapper,
} from "styles/pages/web-pages";

const ProductPricing = ({ data }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    if (data && data?.find((d) => d?.name === "Product & Pricing")) {
      const d = data?.find((d) => d?.name === "Product & Pricing");

      setBannerData(() => ({
        ...d,
        components: {
          ...d?.components,
          Description: initiateEditorState(d?.components?.Description),
        },
      }));
    }
  }, [data]);

  const { components, navVisibility, name } = bannerData || {};
  const {
    Title = "",
    BackgroundColor = "",
    Description,
    SubTitle = "",
    SideImage = "",
    Buttons = [],
    Cards = [],
  } = components || {};

  return (
    <Card>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999999,
          }}
        ></div>
        <ProductCatalog
          data={{
            ...bannerData,
            components: {
              ...bannerData?.components,
              Description: bannerData?.components?.Description
                ? getHtml(bannerData?.components?.Description)
                : "",
            },
          }}
        />
      </div>
      <SectionNavbar>
        <CardTitle style={{ border: "none" }}>{name}</CardTitle>
        <Switch
          handleChange={(e) =>
            setBannerData({
              ...bannerData,
              navVisibility: e.target.checked,
            })
          }
          checked={navVisibility || false}
        />
      </SectionNavbar>

      <TwoBoxWrapper>
        <TextField
          label="Background Color"
          size="small"
          value={BackgroundColor}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                BackgroundColor: v,
              },
            })
          }
        />
        <TextField
          label="Title"
          size="small"
          value={Title}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Title: v,
              },
            })
          }
        />
      </TwoBoxWrapper>

      <OneBoxWrapper>
        <TextField
          label="Sub Title"
          size="small"
          value={SubTitle}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                SubTitle: v,
              },
            })
          }
        />

        <CloudinaryWidget
          label="Side Image"
          value={SideImage}
          setValue={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                SideImage: v,
              },
            })
          }
          folderPath="Shirtly/Web_Pages/LandingPage/ProductPricing"
        />
      </OneBoxWrapper>

      <CardTitle style={{ marginTop: 10 }}>Button Settings</CardTitle>
      {Buttons?.map((r, i) => (
        <Row key={`button${i}`}>
          <Switch
            handleChange={(e) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Buttons: bannerData.components.Buttons?.map((b, j) =>
                    i === j ? { ...b, Show: e.target.checked } : b
                  ),
                },
              })
            }
            checked={r?.Show}
          />

          <TextField
            label="Button Name"
            size="small"
            value={r?.ButtonName || ""}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Buttons: bannerData.components.Buttons?.map((b, j) =>
                    i === j ? { ...b, ButtonName: v } : b
                  ),
                },
              })
            }
          />
          <Select
            label="Link To"
            value={{ value: r?.Modal }}
            labelKey="label"
            valueKey="value"
            options={[
              { label: "SignIn", value: "SignIn" },
              { label: "SignUp", value: "SignUp" },
            ]}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Buttons: bannerData.components.Buttons?.map((b, j) =>
                    i === j ? { ...b, Modal: v } : b
                  ),
                },
              })
            }
          />
        </Row>
      ))}

      <CardTitle
        style={{
          marginTop: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Cards Settings
        <AddIcon
          style={{ cursor: "pointer" }}
          onClick={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Cards: [
                  ...bannerData.components.Cards,
                  {
                    CardTitle: "",
                    CardImage: "",
                    CardSubTitle: "",
                    CardDescription: "",
                  },
                ],
              },
            })
          }
        />
      </CardTitle>
      {Cards?.map((r, i) => (
        <ThreeBoxWrapper key={`card${i}`}>
          <CloudinaryWidget
            label="Icon"
            value={r?.CardImage}
            setValue={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardImage: v } : b
                  ),
                },
              })
            }
            folderPath="Shirtly/Web_Pages/LandingPage/ProductPricing"
          />

          <TextField
            label="Title"
            size="small"
            value={r?.CardTitle}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardTitle: v } : b
                  ),
                },
              })
            }
          />
          <TextFieldWrapperIcon>
            <TextField
              label="Sub Title"
              size="small"
              value={r?.CardSubTitle}
              onChange={(v) =>
                setBannerData({
                  ...bannerData,
                  components: {
                    ...bannerData.components,
                    Cards: bannerData.components.Cards?.map((b, j) =>
                      i === j ? { ...b, CardSubTitle: v } : b
                    ),
                  },
                })
              }
            />

            <DeleteIcon
              className="delete-icon"
              onClick={() =>
                setBannerData({
                  ...bannerData,
                  components: {
                    ...bannerData.components,
                    Cards: bannerData.components.Cards?.filter(
                      (b, j) => i !== j
                    ),
                  },
                })
              }
            />
          </TextFieldWrapperIcon>
        </ThreeBoxWrapper>
      ))}
      <TextAreaWrapper>
        <RichTextEditor
          value={Description}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Description: v,
              },
            })
          }
          title="Description"
        />
      </TextAreaWrapper>

      <AddSectionHeader>
        <AddSectionButton
          title="Update"
          containerStyle={{
            backgroundColor: "#5c92e2",
            color: "#fff",
            fontWeight: "bold",
          }}
          onClick={async () => {
            const payload = {
              ...{
                ...bannerData,
                components: {
                  ...bannerData?.components,
                  Description: getHtml(bannerData?.components?.Description),
                },
              },
              page: "LandingPage",
            };

            await dispatch(updateSection({ data: payload, token }));
          }}
        />
      </AddSectionHeader>
    </Card>
  );
};

export default ProductPricing;
