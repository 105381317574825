import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Title = styled.p`
  padding: ${(props) => props?.titleStyles?.padding || "16px"};
  margin: 0;
  transition: all ease-out 0.25s;
  text-transform: uppercase;
  color: #000;
  font-family: "Inter700";
  font-size: ${(props) => props?.titleStyles?.fontSize || "16px"};
  background-color: ${(props) =>
    props?.titleStyles?.backgroundColor || "inherit"};
  text-align: ${(props) => props?.titleStyles?.textAlign || "left"};
`;

const Container = styled.div`
  position: relative;
  border-radius: ${(props) => props?.containerStyles?.borderRadius || "10px"};
  /* box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px ${COLORS.WHITE}; */
  overflow: hidden;
  transition: all ease-out 0.25s;
  text-transform: uppercase;
  cursor: pointer;
  /* background-color: ${(props) =>
    props?.containerStyles?.backgroundColor || "inherit"}; */
  background-color: #fff;
  ${(props) => `&:hover {
      transform: scale(${props.scaleDown ? "0.91" : "1.1"});
      color: ${props.titleBackgroundOnHover ? `${COLORS.WHITE}` : "inherit"};
      ${Title} {
        background-color: ${
          props.titleBackgroundOnHover ? "#5c92e2" : "inherit"
        };
      }
  }`}
`;
const ImageWrapper = styled.div``;

export { Container, ImageWrapper, Title };
