import React, { useState, useEffect } from 'react';
import { calculateTax } from 'actions/stripe';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Body from 'pages/sales-report/table/Body';
import OrderTableHead from 'pages/sales-report/table/Head';
import { TableWrapper } from 'styles/pages/sales-report';

const OrderTable = ({ data = [] }) => {
  const dispatch = useDispatch();
  const [taxInfo, setTaxInfo] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const data = await dispatch(calculateTax());
      setTaxInfo(data);
    };

    if (!taxInfo) {
      getData();
    }
  }, [dispatch, taxInfo]);

  return (
    <TableWrapper>
      <TableContainer className='container' component={Paper}>
        <Table stickyHeader>
          <OrderTableHead />
          <TableBody>
            {!!taxInfo && <Body data={data} taxInfo={taxInfo} />}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default OrderTable;
