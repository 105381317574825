import React, { useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/Button";
import Category from "pages/order-details/Category";
import Products from "pages/order-details/Products";
import Colors from "pages/order-details/Colors";
import Sizes from "pages/order-details/Sizes";
import { getProductsByCategoryId } from "selectors/inventory";
import { getProductsByPrintProvider, isSub } from "selectors/products";
import COLORS from "shared/constants/colors";
import {
  Container,
  Header,
  Selection,
} from "styles/pages/order-details/orderDetailsDrawer";

const OrderDetailsDrawer = ({
  open,
  onClose,
  onUpdateOrderLineItem,
  order,
}) => {
  let allProducts = useSelector((state) => state?.products?.data);
  allProducts = getProductsByPrintProvider(allProducts, order?.PrintProvider);

  const [productsByCategory, setProductsByCategory] = useState(undefined);
  const [productSizes, setProductSizes] = useState(undefined);
  const [productColors, setProductColors] = useState(undefined);
  const [selectedCategory, setCategory] = useState(undefined);
  const [selectedProduct, setProduct] = useState(undefined);
  const [selectedSize, setSize] = useState(undefined);
  const [selectedColor, setColor] = useState(undefined);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => _.isFunction(onClose) && onClose()}
    >
      <Container>
        <Header>
          <h1>ITEM DETAILS</h1>
          <CloseIcon onClick={() => onClose()} />
        </Header>
        <Category
          selectedCategory={selectedCategory}
          allProducts={allProducts}
          setCategory={async (c) => {
            setCategory(c);
            setProduct(undefined);
            setSize(undefined);
            setColor(undefined);

            let products = getProductsByCategoryId(allProducts, c.CatId);

            products = products?.filter(
              (p) =>
                p?.Product?.visible === undefined ||
                p?.Product?.visible === true
            );

            setProductsByCategory(products);
          }}
        />
        <Products
          selectedProduct={selectedProduct}
          setProduct={(p) => {
            setProduct(p);
            setProductSizes(p?.Sizes?.filter((s) => s?.visible !== false));
            setSize(undefined);
            setColor(undefined);
          }}
          products={productsByCategory}
        />
        <Sizes
          sizes={productSizes}
          selectedSize={selectedSize}
          setSize={(s) => {
            setSize(s);
            setColor(undefined);
            setProductColors(
              selectedProduct?.Colors?.filter((s) => s?.visible !== false)
            );
          }}
        />
        <Colors
          selectedColor={selectedColor}
          setColor={async (c) => {
            setColor(c);
          }}
          colors={productColors}
        />

        {selectedCategory &&
          selectedProduct &&
          selectedSize &&
          selectedColor && (
            <Selection>
              <Button
                containerStyle={{
                  fontSize: "12px",
                  borderRadius: "3px",
                  padding: "4px 10px",
                  fontWeight: "normal",
                  textTransform: "initial",
                  boxShadow: "none",
                  marginBottom: "14px",
                  backgroundColor: COLORS.DARK_BLUE,
                  color: COLORS.WHITE,
                  width: "200px",
                }}
                title="Select"
                onClick={() => {
                  const image = _.find(
                    selectedProduct?.Colors,
                    (c) => c.ID === selectedColor.ID
                  );

                  const printAreas = _.find(
                    allProducts,
                    (p) => p?.Product?.ID === selectedProduct?.Product?.ID
                  )?.PrintAreas;

                  const payload = {
                    CategoryID: selectedCategory.CatId,
                    Category: selectedCategory.Name,
                    ColorID: selectedColor.ID,
                    Color: selectedColor.Name,
                    SizeID: selectedSize.ID,
                    Size: selectedSize.Name,
                    ProductID: selectedProduct?.Product?.ID,
                    Product: selectedProduct?.Product?.Name,
                    MarketingImagePath: image?.Image,
                    BackImage: image?.BackImage || null,
                    PrintType: selectedProduct?.Product?.PrintType,
                    MarketingImageBase64:
                      selectedProduct?.Product?.MarketingImageBase64,
                    printAreas: printAreas?.map((p) => {
                      const name = p?.printAreaName;
                      return {
                        ...p,
                        printAreaMarketingImage:
                          name === "Front"
                            ? selectedColor?.Image
                            : selectedColor[`${name}Image`],
                      };
                    }),
                  };

                  onUpdateOrderLineItem(payload);
                  setProductsByCategory(null);
                  setProductSizes(null);
                  setProductColors(null);
                  setCategory(null);
                  setProduct(null);
                  setColor(null);
                  setSize(null);
                }}
              />
            </Selection>
          )}
      </Container>
    </Drawer>
  );
};

export default OrderDetailsDrawer;
