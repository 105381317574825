import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BrowseLibrary from 'components/ArtWork/BrowseLibrary';
import BrowseTextDrawer from 'components/ArtWork/BrowseTextDrawer';
import { BrowseLibraryButton, LibraryText } from 'styles/components/ArtWork';

const FileUpload = ({ onDrop, orderLine, printType }) => {
  const [browseLibrary, setBrowseLibrary] = useState(false);
  const [browseText, setBrowseText] = useState(false);
  const selectedPrintArea = orderLine?.printAreas?.find((p) => p?.selected);
  const { width, height } = selectedPrintArea?.designerSettings || {};

  return (
    <React.Fragment>
      {width > 0 && height > 0 && (
        <BrowseLibraryButton>
          <LibraryText onClick={() => setBrowseLibrary(true)}>
            Browse Library
          </LibraryText>
          {/* <div>or</div>
          <LibraryText onClick={() => setBrowseText(true)}>
            Add Text
          </LibraryText> */}
        </BrowseLibraryButton>
      )}
      {browseLibrary && (
        <BrowseLibrary
          printType={printType}
          orderLine={orderLine}
          onDrop={(artwork) => onDrop(artwork)}
          open={browseLibrary}
          toggle={() => setBrowseLibrary(false)}
        />
      )}
      {browseText && (
        <BrowseTextDrawer
          orderLine={orderLine}
          onDrop={(artwork) => onDrop(artwork)}
          open={browseText}
          toggle={() => setBrowseText(false)}
        />
      )}
    </React.Fragment>
  );
};

FileUpload.propTypes = {
  onDrop: PropTypes.func,
};

export default FileUpload;
