import React from "react";
import { ROUTES } from "shared/constants/routes";
import SubHeader from "components/SubHeader";
import ProductSection from "pages/my-stores/products/ProductSection";
import { Container } from "styles/pages/my-stores/products";

const Products = () => (
  <Container>
    <SubHeader
      isDark
      title="Browse"
      links={[
        {
          title: ROUTES.HOME.TITLE,
          to: ROUTES.HOME.LINK,
        },
      ]}
      currentPageTitle="Products"
    />

    <ProductSection />
  </Container>
);

export default Products;
