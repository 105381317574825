import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";

const colors = {
  light: {
    border: "rgba(0, 0, 0, 0.23)",
    hoverBorder: "#1976D2",
    focusBorder: "#1976D2",
    labelColor: "#666666",
    textColor: "#333333",
    placeholderColor: "#999999",
    backgroundColor: "transparent",
  },
  dark: {
    border: "#FFFFFF",
    hoverBorder: "#FFFFFF",
    focusBorder: "#FFFFFF",
    labelColor: "#FFFFFF",
    textColor: "#FFFFFF",
    placeholderColor: "#888888",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
};

// Create styled TextField component
const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isDark",
})(({ theme, isDark }) => {
  const modeColors = isDark ? colors.dark : colors.light;

  return {
    "& .MuiOutlinedInput-root": {
      backgroundColor: modeColors.backgroundColor,
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "& fieldset": {
        borderColor: modeColors.border,
        transition: theme.transitions.create(["border-color"]),
      },
      "&:hover fieldset": {
        borderColor: modeColors.hoverBorder,
      },
      "&.Mui-focused fieldset": {
        borderColor: modeColors.focusBorder,
      },
      "&.Mui-error fieldset": {
        borderColor: theme.palette.error.main,
      },
    },
    "& .MuiInputLabel-root": {
      color: modeColors.labelColor,
      "&.Mui-focused": {
        color: modeColors.focusBorder,
      },
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    "& .MuiInputBase-input": {
      color: modeColors.textColor,
      "&::placeholder": {
        color: modeColors.placeholderColor,
        opacity: 1,
      },
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "3px",
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    "& .Mui-disabled": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
      },
      "& .MuiInputBase-input": {
        color: isDark ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)",
      },
    },
  };
});

const CustomTextField = ({
  label,
  value,
  onChange,
  isDark = false,
  ...rest
}) => (
  <StyledTextField
    label={label}
    variant="outlined"
    value={value}
    onChange={(e) => _.isFunction(onChange) && onChange(e.target.value)}
    isDark={isDark}
    {...rest}
  />
);

CustomTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  isDark: PropTypes.bool,
};

CustomTextField.defaultProps = {
  isDark: false,
};

export default CustomTextField;
