import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f3f4f6;
`;

export const LeftPanel = styled.div`
  width: 50%;
  background-color: #121212;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RightPanel = styled.div`
  width: 50%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledLogo = styled.img`
  width: 300px;
`;

export const TagLine = styled.p`
  color: #fff;
  margin: 4px 0 0;
  font-size: 14px;
`;

export const StyledBanner = styled.img`
  width: 65vh;
  margin: 0 auto;
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: white;
  justify-content: center;
  font-weight: bold;
  color: #000;
  font-size: 18px;
  cursor: pointer;
`;

export const MoreSection = styled.div`
  background-color: #000;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
`;

export const StoreTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #fff;
`;

export const Products = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

export const Product = styled.div`
  background-color: white;
  border: 1px solid #e5e7eb;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  padding: 10px;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

export const ComingSoonProduct = styled.div`
  position: relative;
`;

export const ComingSoon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5c92e4;
  opacity: 0.9;
  color: #fff;
  font-weight: bold;
`;

export const ProductName = styled.div`
  color: #fff;
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
`;
