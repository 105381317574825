import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { verifyArtFile, adminVerifyArtFile } from "actions/orders";
import SubHeader from "components/SubHeader";
import BrowseLibraryTabs from "components/ArtWork/BrowseLibraryTabs";
import VerifyArtFileStatus from "pages/verify-art-files/VerifyArtFileStatus";
import { ROUTES } from "shared/constants/routes";
import { getSubmittedUsers } from "actions/user";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getDesignsByPublicId, getDesigns } from "actions/designer";
import VerifyArtFileByPublicId from "pages/verify-art-files/VerifyArtFileByPublicId";
import { Container, UserDropDown } from "styles/pages/verify-art-files";
import HistoryIcon from "@material-ui/icons/History";

const VerifyArtFiles = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state?.auth?.profile?.emailid);
  const [showArtFiles, setArtFilesVisibility] = useState(true);
  const [artFileByPublicId, setArtFileByPublicId] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);
  const [publicId, setPublicId] = useState(null);
  const isAdmin = email === "support@shirtly.com";
  const [libraryFiles, setLibraryFiles] = useState(undefined);
  const [bbLibraryFiles, setBBLibraryFiles] = useState(undefined);
  const [plusLibraryFiles, setPlusLibraryFiles] = useState(undefined);
  const [advancedLibraryFiles, setAdvancedLibraryFiles] = useState(undefined);
  const [advancedDesigns, setAdvancedDesigns] = useState(undefined);
  const [activeTab, setActiveTab] = useState(0);

  // return (
  //   <div style={{ textAlign: 'center', margin: 20 }}>
  //     <HistoryIcon style={{ fontSize: 60 }} />
  //     <h1>Page Under Construction</h1>
  //   </div>
  // );

  useEffect(() => {
    const getUsers = async () => {
      const data = await dispatch(getSubmittedUsers());
      setUsers(data?.users);
    };

    if (!users) getUsers();
  }, [dispatch, users]);

  const populateLibraryData = (library) => {
    for (let i = 0; i < library?.resources?.length; i++) {
      const l = library?.resources[i];
      const secureUrl = l.secure_url;
      const splitSecureUrl = secureUrl.split("/");
      if (!!splitSecureUrl?.length) {
        l.name = splitSecureUrl[splitSecureUrl?.length - 1];
      }
    }
  };

  useEffect(() => {
    const loadLibrary = async () => {
      if (!libraryFiles) {
        const library = await dispatch(
          getDesigns({
            user: email || email,
          })
        );
        if (library.error) {
          return;
        }
        populateLibraryData(library);
        setLibraryFiles({
          ...library,
          cursors: library?.next_cursor
            ? [undefined, library?.next_cursor]
            : [],
        });
      }
    };
    loadLibrary();
  }, [dispatch, libraryFiles, setLibraryFiles, email, email]);

  return (
    <Container>
      <SubHeader
        isDark={true}
        title={ROUTES.VERIFY_ART_FILES.TITLE}
        links={[
          {
            title: ROUTES.HOME.TITLE,
            to: ROUTES.HOME.LINK,
          },
        ]}
        currentPageTitle={ROUTES.VERIFY_ART_FILES.TITLE}
      />

      {isAdmin && (
        <UserDropDown>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.User}
            value={user}
            onChange={(event, newValue) => {
              setUser(newValue);
              setArtFilesVisibility(false);
              setTimeout(() => setArtFilesVisibility(true), 100);
            }}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="User" />}
          />
          {user?.User && (
            <TextField
              isDark={true}
              label="Public ID"
              style={{ width: 300 }}
              value={publicId}
              onKeyDown={async (e) => {
                if (e.keyCode === 13 && publicId) {
                  const response = await dispatch(
                    getDesignsByPublicId({
                      public_id: `Shirtly/${user?.User}/ArtFiles/${publicId}`,
                      user: user?.User,
                    })
                  );

                  setArtFileByPublicId(response);
                }
              }}
              onChange={(e) => setPublicId(e.target.value)}
            />
          )}
        </UserDropDown>
      )}

      {showArtFiles && (
        <BrowseLibraryTabs
          verify={true}
          setAdvancedDesigns={setAdvancedDesigns}
          advancedDesigns={advancedDesigns}
          advancedLibraryFiles={advancedLibraryFiles}
          setAdvancedLibraryFiles={setAdvancedLibraryFiles}
          scroll={false}
          activeTab={activeTab}
          toggle={(data) => {}}
          libraryFiles={libraryFiles}
          bbLibraryFiles={bbLibraryFiles}
          onDrop={async (data) => {
            if (user?.User) {
              setArtFilesVisibility(false);
              await dispatch(
                adminVerifyArtFile({
                  asset_id: data?.asset_id,
                  public_id: data?.public_id,
                  secure_url: data?.secure_url,
                  email: user?.User,
                  verified: !data?.verified,
                })
              );
              setArtFilesVisibility(true);
              return;
            }

            setStatus({
              artFileSecureUrl: data?.secure_url,
              asset_id: data?.asset_id,
              verifying: true,
            });
            setArtFilesVisibility(false);

            const response = await dispatch(
              verifyArtFile({
                asset_id: data?.asset_id,
                secure_url: data?.secure_url,
                public_id: data?.public_id,
                email,
                artWidth: data?.width,
                artHeight: data?.height,
              })
            );

            setStatus({
              ...response,
              artFileSecureUrl: data?.secure_url,
              asset_id: data?.asset_id,
              public_id: data?.public_id,
              verifying: false,
            });
            setArtFilesVisibility(true);
            setActiveTab(0);
            setLibraryFiles(undefined);
          }}
          email={user?.User || email}
          viewOnly={true}
          plusLibraryFiles={plusLibraryFiles}
          setActiveTab={(v) => setActiveTab(v)}
          setBBLibraryFiles={(v) => {
            setLibraryFiles(undefined);
            setPlusLibraryFiles(undefined);
            setBBLibraryFiles(v);
          }}
          setLibraryFiles={(v) => {
            setPlusLibraryFiles(undefined);
            setBBLibraryFiles(undefined);
            setLibraryFiles(v);
          }}
          setPlusLibraryFiles={(v) => {
            setLibraryFiles(undefined);
            setPlusLibraryFiles(v);
            setBBLibraryFiles(undefined);
          }}
        />
      )}

      {!!status && (
        <VerifyArtFileStatus
          open={!!status}
          setOpen={() => setStatus(undefined)}
          status={status}
        />
      )}

      {!!artFileByPublicId && (
        <VerifyArtFileByPublicId
          open={!!artFileByPublicId}
          setOpen={() => setArtFileByPublicId(undefined)}
          data={artFileByPublicId}
          triggerArtFileAction={async () => {
            setArtFilesVisibility(false);
            await dispatch(
              adminVerifyArtFile({
                asset_id: artFileByPublicId?.asset_id,
                public_id: artFileByPublicId?.public_id,
                secure_url: artFileByPublicId?.secure_url,
                email: user?.User,
                verified: !artFileByPublicId?.verified,
              })
            );
            setArtFilesVisibility(true);
            setArtFileByPublicId(undefined);
          }}
        />
      )}
    </Container>
  );
};

export default VerifyArtFiles;
