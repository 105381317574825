import styled, { css } from 'styled-components';

const ColorBox = styled.div`
  background-color: ${(props) => props?.code};
  list-style: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all ease-out 0.25s;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
  transition: all ease-out 0.25s;
  border-radius: 4px;
  ${(props) =>
    props.selected &&
    css`
      border: 2px solid black;
    `};
`;

export { ColorBox };
