import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStyles } from 'selectors/tags';
import { imagesBaseURL } from 'shared/requests';
import ProductCategoryCard from 'components/ProductCategoryCard';
import { ROUTES } from 'shared/constants/routes';
import { StyleWrapper, StyleCard, StyleTitle } from 'styles/pages/catalog';

const Styles = () => {
  const tags = useSelector((state) => state?.tags?.data);
  const stylesTags = getStyles(tags) || [];
  const history = useHistory();

  return (
    <StyleWrapper>
      <StyleTitle>BROWSE BY STYLE</StyleTitle>
      <StyleCard>
        {stylesTags.map((tag, index) => (
          <ProductCategoryCard
            onClick={() =>
              history.push({
                pathname: ROUTES.BROWSE_CATALOG.LINK,
                state: { Styles: tag },
              })
            }
            key={`ProductCategoryCard${index}`}
            title={tag.DisplayName}
            imagePath={`${imagesBaseURL}/Images/styles/${tag.DisplayName.replace(
              ' ',
              '_'
            )}.jpg`}
            titleStyles={{
              fontSize: '18px',
            }}
          />
        ))}
      </StyleCard>
    </StyleWrapper>
  );
};

export default Styles;
