import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import Image from 'components/Image';
import CheckIcon from '@material-ui/icons/Check';
import {
  ColorContainer,
  ColorBox,
  IconWrapper,
  Premium,
} from 'styles/pages/my-stores/products/productCreator';
const Colors = ({ product, onChange, colors = [] }) => {
  const premiumProduct = product?.Product?.premiumProduct;

  return (
    <ColorContainer>
      {product?.Colors?.map((color, i) => {
        const isExist = !!_.find(colors, (c) => c?.ID === color?.ID);
        return color?.visible === false ? null : (
          <ColorBox key={`${color?.ID}_${i}_color`}>
            <IconWrapper
              style={{ background: isExist ? '#2789C0' : '#F4F4F4' }}
            >
              {isExist ? <CheckIcon /> : null}
            </IconWrapper>
            <Image
              src={color?.Image}
              key={`Colors${color.ID}`}
              alt={color?.Name}
              onClick={() => {
                if (!isExist) {
                  const value = [...colors, color];
                  onChange(value);
                } else {
                  const filterData = _.filter(colors, (c) => c.ID !== color.ID);
                  onChange(filterData);
                }
              }}
            />
            <p>{color?.Name}</p>

            {premiumProduct && (
              <Premium
                style={{
                  top: 0,
                  left: 0,
                  bottom: 'unset',
                  right: 'unset',
                  padding: '2px 8px',
                }}
              >
                Premium
              </Premium>
            )}
          </ColorBox>
        );
      })}
    </ColorContainer>
  );
};

export default Colors;
