import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector } from "react-redux";
import _ from "lodash";
import Button from "components/Button";
import Categories from "pages/my-stores/products/product-creator/Categories";
import Products from "pages/my-stores/products/product-creator/Products";
import Colors from "pages/my-stores/products/product-creator/Colors";
import Context, { Steps } from "pages/ai-mock-up-generator/context";
// import USAFlag from "assets/images/usa-flag.png";
// import CADFlag from "assets/images/canada-flag.png";
// import EUFlag from "assets/images/eu-flag.png";
// import AUSFlag from "assets/images/aus-flag.jpeg";
// import CheckIcon from "@material-ui/icons/Check";
import {
  Container,
  CategoriesWrapper,
  ProductColorWrapper,
  FooterButton,
  Heading,
  BoxWrapper,
  AiActivated,
  // CategoriesContainer,
  // CategoryBox,
  // IconWrapper,
} from "styles/pages/my-stores/products/productCreator";

const style = { width: "calc(100vw - 400px)" };

const fulfillmentPlants = ["CA", "US", "EU", "AU", "NY"];

const ProductCreator = () => {
  const { state, setState } = Context();
  const isAiActivated = state?.aiActivated;
  const products = useSelector((state) => state?.products?.data);

  const renderAiMessage = (message) => {
    return `<div style='display:flex;gap:10px;align-items:center;justify-content:center;width:500px;'>
        <div>
          <img src=${state.selectedAiCharaterImage} style='width:100px;' />
        </div>
        <div style='font-size:22px;'>
          ${message}
        </div>
      </div>`;
  };

  const handleNext = async () => {
    if (isAiActivated) {
      const driverObj = driver();
      driverObj.destroy();
    }

    const remainingDecorativeTechniques = products?.find(
      (p) => p?.Product?.ID === state?.product?.Product?.ID
    )?.RemainingDecorativeTechniques;

    setState({
      ...state,
      step: Steps.AIDesginStudio,
      artwork: {
        ...state?.artwork,
        ...state?.product?.Product,
        remainingDecorativeTechniques: remainingDecorativeTechniques,
        printAreas: state?.product?.PrintAreas?.map((p, j) => ({
          ...p,
          selected: j === 0,
          printAreaMarketingImage:
            p?.printAreaName === "Front"
              ? state?.colors[0]?.Image
              : state?.colors[0][`${p?.printAreaName}Image`],
        })),
        ProductID: state?.product?.Product?.ID,
        plus: undefined,
      },
    });
  };

  const getStepContent = () => {
    return (
      <>
        <h2 style={{ margin: "0 0 20px", textAlign: "center" }}>
          SELECT YOUR SHIRT AND COLOR
        </h2>
        {/* <div>
          <Heading>Select Fulfillment Plant</Heading>

          <BoxWrapper style={{ ...style }}>
            <CategoriesContainer>
              {fulfillmentPlants?.map((f) => (
                <CategoryBox
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      selectedFulfillmentPant: f,
                      category: undefined,
                      product: undefined,
                      colors: [],
                      plus: undefined,
                      printTypeTechniqueSelected: true,
                    }))
                  }
                >
                  <IconWrapper
                    style={{
                      background:
                        state?.selectedFulfillmentPant === f
                          ? "#2789C0"
                          : "#F4F4F4",
                    }}
                  >
                    {state?.selectedFulfillmentPant === f ? (
                      <CheckIcon />
                    ) : null}
                  </IconWrapper>

                  <img
                    src={
                      f === "CA"
                        ? CADFlag
                        : f === "US" || f === "NY"
                        ? USAFlag
                        : f === "AU"
                        ? AUSFlag
                        : EUFlag
                    }
                    alt="CAFlag"
                    width="100"
                    height="100"
                  />
                  <h3 className="country-name">{f} Plant</h3>
                </CategoryBox>
              ))}
            </CategoriesContainer>
          </BoxWrapper>
        </div> */}
        <CategoriesWrapper>
          <div id="select-category">
            <Heading>Select Category</Heading>
            <BoxWrapper style={{ ...style }}>
              <Categories
                categoryId={state?.category?.CatId}
                onChange={(state) => {
                  setState((prevState) => ({
                    ...prevState,
                    ...state,
                  }));

                  if (isAiActivated) {
                    setTimeout(() => {
                      const driverObj = driver({
                        allowKeyboardControl: false,
                        showButtons: ["close"],
                      });
                      driverObj.highlight({
                        element: "#select-product",
                        popover: {
                          description: renderAiMessage(
                            "Select the Product<br /><br />Pick the desired product from the provided list."
                          ),
                          side: "top",
                          align: "start",
                        },
                      });
                    }, 10);
                  }
                }}
              />
            </BoxWrapper>
          </div>

          {state?.category?.CatId && (
            <div id="select-product">
              <Heading>Select Product</Heading>
              <BoxWrapper style={{ ...style }}>
                <Products
                  value={state?.product?.Product?.ID}
                  CatId={state?.category?.CatId}
                  selectedFulfillmentPant={state?.selectedFulfillmentPant}
                  onChange={(product) => {
                    setState({
                      ...state,
                      product: {
                        ...product,
                        RemainingDecorativeTechniques:
                          product?.RemainingDecorativeTechniques?.map((x) => ({
                            ...x,
                            selected: false,
                          })),
                      },
                      colors: [],
                    });

                    if (isAiActivated) {
                      setTimeout(() => {
                        const driverObj = driver({
                          allowKeyboardControl: false,
                          showButtons: ["close"],
                        });
                        driverObj.highlight({
                          element: "#select-product-colors",
                          popover: {
                            description: renderAiMessage(
                              "Select the Product Colors<br/><br/>You can select multiple colors to start designing at the same time."
                            ),
                            side: "top",
                            align: "start",
                          },
                        });
                      }, 10);
                    }
                  }}
                />
              </BoxWrapper>
            </div>
          )}
        </CategoriesWrapper>
        {state?.product && (
          <ProductColorWrapper
            premium={state?.product?.Product?.premiumProduct}
            id="select-product-colors"
          >
            <Heading>
              Select Product Colors &nbsp;-&nbsp;
              <span
                onClick={() =>
                  setState({ ...state, colors: state?.product?.Colors })
                }
              >
                Select All Product Colors
              </span>
            </Heading>

            <BoxWrapper style={{ ...style }}>
              <Colors
                colors={state?.colors}
                product={state?.product}
                onChange={(colors) => {
                  setState({ ...state, colors });

                  if (isAiActivated) {
                    setTimeout(() => {
                      const driverObj = driver({
                        allowKeyboardControl: false,
                        showButtons: ["close"],
                      });
                      driverObj.highlight({
                        element: "#choose-product-next",
                        popover: {
                          description: renderAiMessage(
                            "Click Next to start Generating AI Images with AI Design Studio"
                          ),
                          side: "top",
                          align: "start",
                        },
                      });
                    }, 10);
                  }
                }}
              />
            </BoxWrapper>
          </ProductColorWrapper>
        )}
      </>
    );
  };

  useEffect(() => {
    if (isAiActivated) {
      const driverObj = driver({
        animate: false,
        showProgress: false,
        allowKeyboardControl: false,

        steps: [
          {
            element: "#products-tab",
            popover: {
              description: renderAiMessage(
                "Let's start by selecting products<br/><br/>This is where you select category, products style and color.<br/><br/>You can select multiple colors."
              ),
              side: "left",
              align: "start",
              showButtons: ["next"],
              nextBtnText: "Get Started &#8594;",
            },
          },
          {
            element: "#select-category",
            popover: {
              description: renderAiMessage(
                "Select the category<br /><br />AI Design Studio provides various category of products based on requirements and target audience."
              ),
              side: "top",
              align: "start",
              showButtons: [],
            },
          },
        ],
      });

      driverObj.drive();
    }
  }, []);

  return (
    <Container style={{ margin: "15px 0 60px" }}>
      {isAiActivated && (
        <AiActivated>
          <img src={state.selectedAiCharaterImage} />
          <h1>AI Activated</h1>
        </AiActivated>
      )}

      {getStepContent()}

      {state?.colors?.length > 0 && (
        <FooterButton id="choose-product-next">
          <Button
            title="Next"
            style={{ background: "#000", fontWeight: "bold" }}
            onClick={handleNext}
          />
        </FooterButton>
      )}
    </Container>
  );
};

export default ProductCreator;
