import styled from 'styled-components';

const Container = styled.div``;
const Wrapper = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  transition: all ease-out 0.25s;
  @media (min-width: 1200px) {
    grid-template-columns: auto auto;
  }
`;

export { Container, Wrapper };
