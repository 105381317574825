import React from 'react';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';
import Header from 'pages/catalog/Header';
import Categories from 'pages/catalog/Categories';
import Promos from 'pages/catalog/Promos';
import Styles from 'pages/catalog/Styles';
import { PageWrapper, CatalogMainWrapper } from 'styles/pages/catalog';

const Catalog = () => {
  const products = useSelector((state) => state?.products?.data);

  if (!products?.length) return <Loader open={true} />;

  return (
    <PageWrapper>
      <Header />
      <CatalogMainWrapper>
        <Promos />
        <Categories />
        <Styles />
      </CatalogMainWrapper>
    </PageWrapper>
  );
};

export default Catalog;
