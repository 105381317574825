import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Container } from 'styles/pages/product-detail/navAction';

const Header = () => {
  const history = useHistory();
  return (
    <Container>
      <Button
        containerStyle={{
          fontSize: '12px',
          border: '1px solid #F9682A',
          borderRadius: '16px',
          padding: '1px 22px',
          lineHeight: '22px',
          textTransform: 'inherit',
          color: '#ffffff',
          background: '#F9682A',
          fontFamily: 'Inter600',
        }}
        // startIcon={<NavigateBeforeIcon />}
        variant='text'
        title='Back'
        onClick={() => history.goBack()}
      />
    </Container>
  );
};

export default Header;
