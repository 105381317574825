import { makeStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

const AccordionSummary = withStyles({
  root: {
    minHeight: 0,
    padding: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: '0',
  },
})(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '14px',
    color: '#2789c0',
    fontFamily: 'Inter600',
  },

  accordion: {
    boxShadow: 'none',
    borderTop: '1px solid #d9d9d9;',
    borderRadius: '0 !important',
    margin: '0 !important',
    '&::before': {
      content: 'none',
    },
  },
}));

export { AccordionSummary, AccordionDetails, useStyles };
