import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { useSelector, useDispatch } from 'react-redux';
import TermsAndConditionDialog from 'components/TermsAndCondition';
import { getCountries } from 'actions/countries';
import { updateField } from 'actions/common';
import {
  SET_USER_EMAIL,
  SET_USER_PASSWORD,
  USER_INITIATE,
  UPDATE_USER_FIELD,
  SET_USER_CONFIRM_PASSWORD,
  TOGGLE_TERMS_AND_CONDITION,
} from 'actions/user';
import { signUp, ipSignUp } from 'actions/auth';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/Button';
import TextField from 'components/TextField';
import Select from 'components/Select';
import CustomCheckbox from 'components/CheckBox';
import signUpBanner from 'assets/images/sign-up-banner.png';
import CloseIcon from 'assets/svg/CloseIcon';
import {
  Container,
  Header,
  Body,
  Column,
  Fields,
  Description,
  Terms,
  FieldsGroup,
  ImageWrapper,
  IpDescription,
} from 'styles/pages/home/signUpDialog';

const SignUpDialog = ({ open, onClose, ipProgram }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const countries = useSelector((state) => state?.countries?.data);
  const homeWebPage = useSelector((state) => state?.homeWebPage);
  const homeData = homeWebPage?.find((p) => p?.name === 'Header') || {};
  const { components } = homeData;
  const { Image } = components || {};

  const name = user?.name;
  const email = user?.email;
  const company = user?.company;
  const address1 = user?.address1;
  const address2 = user?.address2;
  const city = user?.city;
  const zip = user?.zip;
  const country = user?.country;
  const state = user?.state;
  const password = user?.password;
  const confirmPassword = user?.confirmPassword;
  const agreeTermsAndCondition = user?.agreeTermsAndCondition;
  const contactNumber = user?.contactNumber;

  const [statesList, setStateList] = useState(undefined);
  const [termsDialog, setTermsDialog] = useState(false);

  const disabled =
    !name?.valid ||
    !email?.valid ||
    !company?.valid ||
    !address1?.valid ||
    !country?.valid ||
    !state?.valid ||
    !city?.valid ||
    !zip?.valid ||
    !password?.valid ||
    !confirmPassword?.value ||
    password?.value !== confirmPassword?.value ||
    (!contactNumber?.valid && ipProgram) ||
    !agreeTermsAndCondition;

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  return (
    <Dialog
      open={open}
      className='sign-up-dialog'
      maxWidth='lg'
      onClose={() => {
        dispatch(updateField(USER_INITIATE));
        _.isFunction(onClose) && onClose();
      }}>
      <Container>
        <CloseIcon className='close-icon' onClick={onClose} />
        <Column>
          {!ipProgram ? (
            <Description>
              <h4>Connect your ecommerce store and save on fulfillment</h4>
              <p>
                Simple, intuitive integrations with Shopify, WooCommerce,
                BigCommerce, BigCartel & Etsy
              </p>
              <h4>Easily. Efficiently. Shirtly</h4>
              <p>
                We print, package and ship your orders straight to your
                customers - duty-free, across all of North America
              </p>
              <h4>Your apparel ally</h4>
              <p>
                We help you shift your business from simply stitched together to
                bursting at the seams, one shirt at a time
              </p>
              <ImageWrapper>
                <img src={signUpBanner} alt='loginBanner' />
              </ImageWrapper>
            </Description>
          ) : (
            <IpDescription>
              <h4 className='title'>Intellectual Property Infringement</h4>
              <h4>
                Update of Service - Intellectual Property Infringement
                procedures.
              </h4>
              <p>
                In light of our industry’s growth, more users are seeing the
                opportunities to sell customized products through online retail
                business models. As a result, more awareness and attention are
                being put towards protecting people’s hard-earned Intellectual
                Property (IP).
              </p>
              <p>
                Shirtly is committed to operating and providing its service with
                its best efforts to prevent counterfeiting and the unauthorized
                use of trademarked and copyrighted IP.
              </p>
              <p>
                There is an ever-increasing effort by those who own these IPs to
                protect their livelihood.
              </p>
              <p>
                As a result, we are seeing more legal action and online stores
                being shut down for breaching these rights.
              </p>
              <h4>
                In order to protect your stores and business models as well as
                support those who have worked hard to create their own IP,
                Shirtly is proud to announce the following:
              </h4>
              <p>
                Shirtly will be introducing a groundbreaking AI art file
                scrubbing algorithm. Users and owners that have registered IPs
                will be able to upload their art files in our new IP Support
                Portal. Our AI art file scrubber will analyze all art files used
                to either create mock-ups or generate orders through our
                platform. If an art file has elements that would breach a
                registered IP art file that has been uploaded it will be blocked
                for use by the user. The user will be notified and shown why the
                art file has been blocked. Users will have the opportunity to
                dispute this finding as we recognize that there may be
                exceptions.
              </p>
              <p>
                Although the success rate cannot be 100% guaranteed, we are
                confident that our AI art scrubber will do its best to support
                this initiative. Shirtly will continuously work to improve and
                perfect this system in order to support the prevention of
                counterfeiting and Intellectual Property Infringement
              </p>
              <p>
                Please be advised that this policy will go live no later than
                Friday, December 3rd, 2021. Shirtly welcomes all users and those
                concerned with their IP and counterfeiting to use this portal
                going forward. As always, we are here to support everyone and if
                you have any questions, please feel free to reach out:
                support@shirtly.com.
              </p>
            </IpDescription>
          )}
          <Fields>
            <Header>
              <img src={Image} alt='logo' />
              {ipProgram ? (
                <h4 className='ipProgram'> Sign Up to Shirtly IP Program</h4>
              ) : (
                <h4> Sign Up to Shirtly</h4>
              )}
            </Header>
            <Body>
              <TextField
                fullWidth
                size='small'
                label='Name'
                error={!!(name?.value && !name?.valid)}
                helperText={name?.value && !name?.valid ? 'Enter name' : null}
                value={name?.value || ''}
                onChange={(value) =>
                  dispatch(
                    updateField(UPDATE_USER_FIELD, { value, key: 'name' })
                  )
                }
              />
              <TextField
                fullWidth
                size='small'
                label='Email'
                error={!!(email?.value && !email?.valid)}
                helperText={
                  email?.value && !email?.valid ? 'Enter valid email' : null
                }
                value={email?.value || ''}
                onChange={(v) =>
                  dispatch(updateField(SET_USER_EMAIL, { email: v }))
                }
              />
              <TextField
                fullWidth
                size='small'
                label='Company'
                error={!!(company?.value && !company?.valid)}
                helperText={
                  company?.value && !company?.valid ? 'Enter company' : null
                }
                value={company?.value || ''}
                onChange={(value) =>
                  dispatch(
                    updateField(UPDATE_USER_FIELD, { value, key: 'company' })
                  )
                }
              />

              {ipProgram && (
                <TextField
                  fullWidth
                  size='small'
                  label='Contact Number'
                  error={!!(contactNumber?.value && !contactNumber?.valid)}
                  helperText={
                    contactNumber?.value && !contactNumber?.valid
                      ? 'Enter Contact Number'
                      : null
                  }
                  value={contactNumber?.value || ''}
                  onChange={(value) =>
                    dispatch(
                      updateField(UPDATE_USER_FIELD, {
                        value,
                        key: 'contactNumber',
                      })
                    )
                  }
                />
              )}
              <FieldsGroup>
                <TextField
                  fullWidth
                  type='password'
                  size='small'
                  label='Password'
                  error={!!(password?.value && !password?.valid)}
                  helperText={
                    password?.value && !password?.valid
                      ? `Password should be of minimum ${password.MINIMUM_PASSWORD_LENGTH} characters `
                      : null
                  }
                  value={password?.value || ''}
                  onChange={(v) =>
                    dispatch(updateField(SET_USER_PASSWORD, { password: v }))
                  }
                />
                <TextField
                  fullWidth
                  size='small'
                  type='password'
                  label='Confirm Password'
                  error={!!(confirmPassword?.value && !confirmPassword?.valid)}
                  helperText={
                    confirmPassword?.value && !confirmPassword?.valid
                      ? `Password should be of minimum ${password.MINIMUM_PASSWORD_LENGTH} characters `
                      : null
                  }
                  value={confirmPassword?.value || ''}
                  onChange={(v) =>
                    dispatch(
                      updateField(SET_USER_CONFIRM_PASSWORD, {
                        confirmPassword: v,
                      })
                    )
                  }
                />
              </FieldsGroup>
              <FieldsGroup>
                <TextField
                  fullWidth
                  size='small'
                  label='Address1'
                  error={!!(address1?.value && !address1?.valid)}
                  helperText={
                    address1?.value && !address1?.valid
                      ? 'Enter address1'
                      : null
                  }
                  value={address1?.value || ''}
                  onChange={(value) =>
                    dispatch(
                      updateField(UPDATE_USER_FIELD, { value, key: 'address1' })
                    )
                  }
                />
                <TextField
                  fullWidth
                  size='small'
                  label='Address2'
                  error={!!(address2?.value && !address2?.valid)}
                  helperText={
                    address2?.value && !address2?.valid
                      ? 'Enter address2'
                      : null
                  }
                  value={address2?.value || ''}
                  onChange={(value) =>
                    dispatch(
                      updateField(UPDATE_USER_FIELD, { value, key: 'address2' })
                    )
                  }
                />
              </FieldsGroup>

              <FieldsGroup>
                <Select
                  label='Country'
                  options={countries}
                  labelKey='name'
                  valueKey='code2'
                  value={{ code2: country?.value || '' }}
                  onChange={(CountryString) => {
                    const states = _.find(
                      countries,
                      (o) => o.code2 === CountryString
                    )?.states;
                    setStateList(states);

                    dispatch(
                      updateField(UPDATE_USER_FIELD, {
                        value: CountryString,
                        key: 'country',
                      })
                    );

                    dispatch(
                      updateField(UPDATE_USER_FIELD, {
                        value: null,
                        key: 'state',
                      })
                    );
                  }}
                />
                <Select
                  label='State/Province'
                  labelKey='name'
                  valueKey='code'
                  options={statesList}
                  value={{ code: state?.value || '' }}
                  onChange={(StateProvince) =>
                    dispatch(
                      updateField(UPDATE_USER_FIELD, {
                        value: StateProvince,
                        key: 'state',
                      })
                    )
                  }
                />
              </FieldsGroup>
              <FieldsGroup>
                <TextField
                  fullWidth
                  size='small'
                  label='City'
                  error={!!(city?.value && !city?.valid)}
                  helperText={city?.value && !city?.valid ? 'Enter city' : null}
                  value={city?.value || ''}
                  onChange={(value) =>
                    dispatch(
                      updateField(UPDATE_USER_FIELD, { value, key: 'city' })
                    )
                  }
                />
                <TextField
                  fullWidth
                  size='small'
                  label='Zip'
                  error={!!(zip?.value && !zip?.valid)}
                  helperText={zip?.value && !zip?.valid ? 'Enter zip' : null}
                  value={zip?.value || ''}
                  onChange={(value) =>
                    dispatch(
                      updateField(UPDATE_USER_FIELD, { value, key: 'zip' })
                    )
                  }
                />
              </FieldsGroup>
              <Terms>
                <CustomCheckbox
                  label=''
                  checked={agreeTermsAndCondition}
                  onChange={() =>
                    dispatch(updateField(TOGGLE_TERMS_AND_CONDITION))
                  }
                />
                <p>
                  Agree to Shirtly{' '}
                  <span onClick={() => setTermsDialog(true)}>
                    Terms and Conditions
                  </span>
                </p>
              </Terms>

              <Button
                disabled={disabled}
                variant='contained'
                title='Sign Up'
                onClick={async () => {
                  const payload = {
                    name: name?.value,
                    email: email?.value,
                    company: company?.value,
                    address1: address1?.value,
                    address2: address2?.value,
                    city: city?.value,
                    state: state?.value,
                    country: country?.value,
                    zip: zip?.value,
                    password: CryptoJS.AES.encrypt(
                      password?.value,
                      'shirtly'
                    ).toString(),
                  };

                  let request;
                  if (ipProgram) {
                    request = await dispatch(
                      ipSignUp({
                        ...payload,
                        contactNumber: contactNumber?.value,
                      })
                    );
                  } else {
                    request = await dispatch(signUp(payload));
                  }

                  if (request?.error) {
                    return;
                  }
                  _.isFunction(onClose) && onClose();
                }}
              />
            </Body>
          </Fields>
        </Column>
      </Container>
      {termsDialog && (
        <TermsAndConditionDialog
          open={termsDialog}
          setOpen={(v) => setTermsDialog(v)}
        />
      )}
    </Dialog>
  );
};

SignUpDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SignUpDialog;
