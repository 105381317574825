import TextField from "components/TextField";
import Select from "components/Select";
import Button from "components/Button";
import SubHeader from "components/SubHeader";

import {
  Container,
  Wrapper,
  Settings,
  Setting,
  Title,
  TermsConditions,
} from "styles/pages/admin-settings";

const AdminSettings = () => (
  <Container>
    <Wrapper>
      <SubHeader isDark title="Settings" />
      <Settings>
        <Setting>
          <Title>System Status</Title>
          <div className="flex-wrapper">
            <TextField fullWidth size="small" placeholder="System Status" />
            <Select label="" labelKey="name" valueKey="code" />
          </div>
          <Button variant="text" title="Submit" />
        </Setting>
        <Setting>
          <Title>Under Construction</Title>
          <Select label="" labelKey="name" valueKey="code" />
          <Button variant="text" title="Submit" />
        </Setting>
        <Setting>
          <Title>Production Cutoff:</Title>
          <TextField fullWidth size="small" placeholder="System Status" />
          <Button variant="text" title="Submit" />
        </Setting>
        <Setting>
          <Title>Canadian Exchange Rate</Title>
          <TextField fullWidth size="small" placeholder="System Status" />
          <Button variant="text" title="Submit" />
        </Setting>
        <TermsConditions>
          <Title>Terms and Conditions</Title>
          <Button variant="text" title="Edit" />
        </TermsConditions>
      </Settings>
    </Wrapper>
  </Container>
);

export default AdminSettings;
