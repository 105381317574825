import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import { getProductByProductId } from 'selectors/products';
import {
  ProductSubstitute,
  ProductSubstituteWrapper,
  ProductImage,
  ProductName,
} from 'styles/pages/order-details';

const ProductSubstitueModal = ({ open, setOpen, data, onProductSelect }) => {
  const products = useSelector((state) => state?.products?.data);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
      <ModalWrapper className="about-us">
        <DialogTitle id="alert-dialog-title">
          Available Products for Substitute
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ProductSubstitute>
              {data?.map((d) => {
                const product = getProductByProductId(products, d);

                return (
                  <ProductSubstituteWrapper
                    key={d}
                    onClick={() => onProductSelect(product)}
                  >
                    <ProductImage
                      alt="marketing"
                      src={product?.Product?.MarketingImagePath}
                    />
                    <ProductName>{product?.Product?.DisplayName}</ProductName>
                  </ProductSubstituteWrapper>
                );
              })}
            </ProductSubstitute>
          </DialogContentText>
        </DialogContent>
      </ModalWrapper>
    </Dialog>
  );
};

export default ProductSubstitueModal;
