import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CADFlag from 'assets/images/canada-flag.png';
import USAFlag from 'assets/images/usa-flag.png';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  StockContainer,
  StockTitle,
  NoDataFound,
  Quantity,
  ColorName,
  AutocompleteWrapper,
} from 'styles/pages/inventory';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 20,
  },
}));

const Stocks = ({ title, data, isCad, search, onSearch }) => {
  const classes = useStyles();

  const newData = search
    ? data?.filter((d) => `${d.colorName} / ${d.sizeName}` === search)
    : data;

  return (
    <StockContainer>
      <StockTitle>
        <img
          src={isCad ? CADFlag : USAFlag}
          alt="Flag"
          style={{ width: 40, marginRight: 5 }}
        />
        &nbsp;{title}
      </StockTitle>

      {newData?.length && (
        <AutocompleteWrapper>
          <Autocomplete
            options={data}
            onChange={onSearch}
            getOptionLabel={(option) =>
              `${option.colorName}${
                option.sizeName ? ` / ${option?.sizeName}` : ``
              }`
            }
            renderInput={(params) => (
              <TextField {...params} label="Search" variant="outlined" />
            )}
          />
        </AutocompleteWrapper>
      )}
      {newData?.length ? (
        newData?.map((row, i) => (
          <List key={`Stock${i}`} className={classes.root}>
            <ListItem>
              <ListItemAvatar>
                <img
                  src={row?.colorFrontImage}
                  style={{ width: 50, marginRight: 20 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={<ColorName>{row?.colorName}</ColorName>}
                secondary={<ColorName>{row?.sizeName}</ColorName>}
              />
              <ListItemSecondaryAction>
                <Quantity>{row?.qty}</Quantity>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))
      ) : (
        <NoDataFound>Stock data is not available.</NoDataFound>
      )}
    </StockContainer>
  );
};

export default Stocks;
