const objectsData = [
  {
    title: 'Default',
    key: 'default',
  },
  {
    title: 'Airplane',
    key: 'airplane',
  },
  {
    title: 'Bus',
    key: 'bus',
  },
  {
    title: 'Dining Table',
    key: 'dining_table',
  },
  {
    title: 'Sheep',
    key: 'sheep',
  },
  {
    title: 'Bicycle',
    key: 'bicycle',
  },
  {
    title: 'Car',
    key: 'car',
  },
  {
    title: 'Dog',
    key: 'dog',
  },
  {
    title: 'Sofa',
    key: 'sofa',
  },
  {
    title: 'Bird',
    key: 'bird',
  },
  {
    title: 'Cat',
    key: 'cat',
  },
  {
    title: 'Horse',
    key: 'horse',
  },
  {
    title: 'Train',
    key: 'train',
  },
  {
    title: 'Boat',
    key: 'boat',
  },
  {
    title: 'Chair',
    key: 'chair',
  },
  {
    title: 'Person',
    key: 'person',
  },
  {
    title: 'TV',
    key: 'tv',
  },
  {
    title: 'Bottle',
    key: 'bottle',
  },
  {
    title: 'Cow',
    key: 'cow',
  },
  {
    title: 'Potted Plant',
    key: 'potted_plant',
  },
  {
    title: 'Motorbike',
    key: 'motorbike',
  },
];

export { objectsData };
