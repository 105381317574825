import React from 'react';
import CategoriesFilter from 'pages/browse-catalog/CategoriesFilter';
import StylesFilterAccordion from 'pages/browse-catalog/StylesFilterAccordion';
import TagsFilter from 'pages/browse-catalog/TagsFilter';
import FulfillmentPlantFilter from 'pages/browse-catalog/FulfillmentPlantFilter';
import DecorationTechniqueFilter from 'pages/browse-catalog/DecorationTechniqueFilter';
import PremiumFilter from 'pages/browse-catalog/PremiumFilter';
// import BrandsFilter from 'pages/browse-catalog/BrandsFilter';
// import ColorsFilter from 'pages/browse-catalog/ColorsFilter';

const FiltersAccordion = ({
  categories,
  updateCategories,
  stylesFilter,
  updateStylesFilter,
  updatePromos,
  promos,
  brands,
  updateBrandsFilter,
  colorsFilter,
  updateColorsFilter,
  fulfillmentPlants,
  updateFulfillmentPlants,
  setDecoratingTechFilter,
  decoratingTechFilter,
  updatePremiumFilter,
  premiumFilter,
}) => (
  <React.Fragment>
    <CategoriesFilter
      categories={categories}
      updateCategories={updateCategories}
    />
    <FulfillmentPlantFilter
      fulfillmentPlants={fulfillmentPlants}
      updateFulfillmentPlants={updateFulfillmentPlants}
    />
    <PremiumFilter
      premiumFilter={premiumFilter}
      updatePremiumFilter={updatePremiumFilter}
    />

    <TagsFilter promos={promos} updatePromos={updatePromos} />

    <DecorationTechniqueFilter
      decoratingTechFilter={decoratingTechFilter}
      setDecoratingTechFilter={setDecoratingTechFilter}
    />

    <StylesFilterAccordion
      stylesFilter={stylesFilter}
      updateStylesFilter={updateStylesFilter}
    />
    {/* <BrandsFilter brands={brands} updateBrandsFilter={updateBrandsFilter} />
    <ColorsFilter
      colorsFilter={colorsFilter}
      updateColorsFilter={updateColorsFilter}
    /> */}
  </React.Fragment>
);

export default FiltersAccordion;
