import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllDTGPlus } from 'actions/products';
import _, { isDate } from 'lodash';
import Select from 'components/Select';
import CheckBox from 'components/CheckBox';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from 'components/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LogoImage from 'assets/images/shirtly-logo-small.png';
import AlertDialog from 'pages/order-details/DtgPlusInstructions';
import { showLoader, hideLoader } from 'actions/loader';
import {
  DtgWrapper,
  ColorWrapper,
  DtgTitle,
} from 'styles/pages/order-details/itemsSummary';

import { getPrintTypeFromProductId } from 'selectors/products';

const isImageValid = (url) =>
  url?.includes('.png') ||
  url?.includes('.jpeg') ||
  url?.includes('.jpg') ||
  url?.includes('.eps');

const openCloudinaryWidget = (folder, clientAllowedFormats, cb) => {
  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: 'big-oven-tees-inc',
      show_powered_by: false,
      uploadPreset: 'tnedst8q',
      sources: ['local', 'camera', 'url'],
      folder,
      clientAllowedFormats,
    },
    (error, result) => {
      if (!error && result && result.event === 'success') {
        cb(result?.info?.secure_url);
        myWidget.close();
      }
    }
  );
  myWidget.open();
};

const DtgPlus = ({
  dtgPlusOptions,
  dtgPlus,
  plus,
  dtgProcessName,
  setOrderLine,
  selectedOrderLine,
  dtgColorName,
  dtgVectorFile,
  dtgPlusMockFile,
  orderLine,
}) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(undefined);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const products = useSelector((state) => state?.products?.data);

  const isDerek = emailid === 'derek@estherthewonderpig.com';

  const rowSelected =
    selectedOrderLine?.ID === orderLine?.ID ||
    (selectedOrderLine?.tempId &&
      orderLine?.tempId &&
      selectedOrderLine?.tempId === orderLine?.tempId);

  const productId = rowSelected
    ? selectedOrderLine?.ProductID
    : orderLine?.ProductID;

  let printType = getPrintTypeFromProductId(products, productId);

  if (
    selectedOrderLine &&
    selectedOrderLine?.tempId &&
    orderLine?.tempId &&
    orderLine?.tempId === selectedOrderLine?.tempId
  ) {
    printType = getPrintTypeFromProductId(
      products,
      selectedOrderLine.ProductID
    );
  }

  const productRemainingTechs =
    _.find(products, (p) => p?.Product?.ID === productId)
      ?.RemainingDecorativeTechniques || [];

  const rowRemainingTech = rowSelected
    ? selectedOrderLine?.RemainingDecorativeTechniques
    : orderLine?.RemainingDecorativeTechniques;

  const isScreenPrinting = rowRemainingTech?.find(
    (x) => x?.DisplayName === 'ScreenPrint' && x?.selected
  );

  const isRemainingTechSel = productRemainingTechs?.filter(
    (v) =>
      !!rowRemainingTech?.filter(
        (k) => k?.DisplayName === v?.DisplayName && k?.selected
      )?.length
  )?.length;

  let days = {
    minDays: 0,
    maxDays: 0,
  };

  const product = _.find(products, (p) => p?.Product?.ID === productId);
  if (!dtgPlus && !plus && !isRemainingTechSel) {
    days = {
      minDays: product?.Product?.defaultMinimumFulfillDays || 0,
      maxDays: product?.Product?.defaultMaximumFulfillDays || 0,
    };
  } else if (dtgPlus || plus) {
    const { minFulfillmentDays, maxFulfillmentDays } =
      product?.AdditionalTechniquesArtSettings?.find(
        (p) => p?.name === 'DTGPlus'
      ) || {};

    days = {
      minDays: minFulfillmentDays || 0,
      maxDays: maxFulfillmentDays || 0,
    };
  } else if (isRemainingTechSel) {
    const selectedValue = productRemainingTechs?.find(
      (v) =>
        !!rowRemainingTech?.filter(
          (k) => k?.DisplayName === v?.DisplayName && k?.selected
        )?.length
    );

    days = {
      minDays: selectedValue?.minFulfillmentDays || 0,
      maxDays: selectedValue?.maxFulfillmentDays || 0,
    };
  }

  return (
    <React.Fragment>
      {printType && (
        <h3>
          Decoration Techniques
          {!!days?.minDays && !!days?.maxDays && (
            <span className="glow">
              &nbsp;-&nbsp;Decoration Fulfillment times: {days?.minDays} –
              {days?.maxDays} business days
            </span>
          )}
        </h3>
      )}

      <DtgTitle>
        {printType && (
          <CheckBox
            label={printType}
            checked={!dtgPlus && !plus && !isRemainingTechSel ? true : false}
            onChange={(v) => {
              const p = _.find(
                products,
                (p) => p?.Product?.ID === selectedOrderLine.ProductID
              );

              setOrderLine({
                ...selectedOrderLine,
                Artwork: null,
                ArtworkId: null,
                BackArtwork: null,
                BackArtworkId: null,
                dtgPlus: null,
                plus: null,
                ColorID: null,
                PrintType: printType,
                printAreas: p?.PrintAreas?.map((p) => ({
                  ...p,
                  selected: p?.printAreaName == 'Front',
                })),
                RemainingDecorativeTechniques: productRemainingTechs?.map(
                  (x) => ({
                    ...x,
                    selected: false,
                  })
                ),
              });
            }}
          />
        )}

        {dtgPlusOptions?.length ? (
          <CheckBox
            label={`TUFF PRINT TRANSFER`}
            checked={!!plus}
            onChange={(v) => {
              const p = _.find(
                products,
                (p) => p?.Product?.ID === selectedOrderLine.ProductID
              );
              const plusArtSettings = p?.AdditionalTechniquesArtSettings?.find(
                (s) => s?.name === 'Plus'
              )?.artSettings;

              setOrderLine({
                ...selectedOrderLine,
                plus: v,
                PrintType: 'Plus',
                Artwork: null,
                ColorID: null,
                ArtworkId: null,
                BackArtwork: null,
                BackArtworkId: null,
                dtgPlus: v ? false : selectedOrderLine?.dtgPlus,
                printAreas: plusArtSettings?.map((p) => ({
                  printAreaName: p?.areaName,
                  printAreaMarketingImage: p?.image,
                  designerSettings: p?.areaSettings,
                  selected: p?.areaName == 'Front',
                })),
                RemainingDecorativeTechniques: productRemainingTechs?.map(
                  (x) => ({
                    ...x,
                    selected: false,
                  })
                ),
              });
            }}
          />
        ) : null}

        {productRemainingTechs?.map((v) => {
          const selected =
            _.find(rowRemainingTech, (c) => c.DisplayName === v?.DisplayName)
              ?.selected || false;
          return (
            <CheckBox
              key={v?.DisplayName}
              label={v?.DisplayName}
              checked={selected}
              onChange={(value) => {
                const p = _.find(
                  products,
                  (p) => p?.Product?.ID === selectedOrderLine.ProductID
                );

                const artSettings = p?.AdditionalTechniquesArtSettings?.find(
                  (s) => s?.name === v?.DisplayName
                )?.artSettings;

                setOrderLine({
                  ...selectedOrderLine,
                  Artwork: null,
                  ArtworkId: null,
                  ColorID: isDerek ? selectedOrderLine?.ColorID : null,
                  BackArtwork: null,
                  BackArtworkId: null,
                  PrintType: v?.DisplayName,
                  dtgPlus: null,
                  plus: null,
                  printAreas: isDerek
                    ? selectedOrderLine?.printAreas
                    : artSettings?.map((p) => ({
                        printAreaName: p?.areaName,
                        printAreaMarketingImage: p?.image,
                        designerSettings: p?.areaSettings,
                        selected: p?.areaName == 'Front',
                      })),
                  RemainingDecorativeTechniques: productRemainingTechs?.map(
                    (x) => ({
                      ...x,
                      selected:
                        x?.DisplayName !== v?.DisplayName ? false : value,
                    })
                  ),
                });
              }}
            />
          );
        })}
      </DtgTitle>

      {dtgPlus && (
        <h3
          onClick={() =>
            setModal({
              dtgPlus: true,
            })
          }
          style={{
            padding: 0,
            textDecoration: 'underline',
            color: '#2280c1',
          }}
        >
          Please read the instructions for DTG+ order
        </h3>
      )}

      {plus && (
        <h3
          onClick={() =>
            setModal({
              plus: true,
            })
          }
          style={{
            padding: 0,
            textDecoration: 'underline',
            color: '#2280c1',
          }}
        >
          Please read the instructions for TUFF PRINT TRANSFER order
        </h3>
      )}

      {(dtgPlus || plus) && (
        <DtgWrapper>
          <Select
            options={dtgPlusOptions}
            label="TUFF PRINT TRANSFER Process"
            value={dtgProcessName || { DisplayName: '' }}
            valueKey="DisplayName"
            labelKey="DisplayName"
            onChange={(dtgProcessName, e) => {
              setOrderLine(
                {
                  ...selectedOrderLine,
                  dtgProcessName: dtgPlusOptions.find(
                    (c) => c?.DisplayName === dtgProcessName
                  ),
                  dtgColorName: null,
                  printAreas: selectedOrderLine?.printAreas?.map((p) =>
                    p?.selected
                      ? {
                          ...p,
                          artwork: null,
                          artworkId: null,
                        }
                      : p
                  ),
                },
                e
              );
            }}
          />

          {dtgProcessName?.Colors && (
            <ColorWrapper>
              {dtgColorName?.ColorImagePath && (
                <Tooltip
                  placement="top"
                  arrow
                  title={<img alt="color" src={dtgColorName?.ColorImagePath} />}
                >
                  <img alt="color" src={dtgColorName?.ColorImagePath} />
                </Tooltip>
              )}
              <Select
                options={dtgProcessName?.Colors}
                label="TUFF PRINT TRANFER Color"
                value={dtgColorName || { ColorName: '' }}
                valueKey="ColorName"
                labelKey="ColorName"
                onChange={async (dtgColorName, e) => {
                  const request = await dispatch(getAllDTGPlus());
                  const dtgProcessDisplayName = dtgProcessName?.DisplayName;
                  const colors = request?.data?.find(
                    (c) => c?.DisplayName === dtgProcessDisplayName
                  )?.Colors;

                  const color = colors?.find(
                    (c) => c?.ColorName === dtgColorName
                  );

                  setOrderLine(
                    {
                      ...selectedOrderLine,
                      dtgColorName: color,
                      printAreas: selectedOrderLine?.printAreas?.map((p) =>
                        p?.selected
                          ? {
                              ...p,
                              artwork: null,
                              artworkId: null,
                            }
                          : p
                      ),
                    },
                    e
                  );
                }}
              />
            </ColorWrapper>
          )}
        </DtgWrapper>
      )}

      {dtgColorName && dtgPlus && (
        <DtgWrapper>
          <ColorWrapper>
            {dtgPlusMockFile && (
              <Tooltip
                placement="right"
                arrow
                title={<img alt="color" width="150" src={dtgPlusMockFile} />}
              >
                <img
                  src={
                    isImageValid(dtgPlusMockFile) ? dtgPlusMockFile : LogoImage
                  }
                />
              </Tooltip>
            )}
            <TextField
              fullWidth={true}
              label="Actual Mockup File With Effect"
              disabled={true}
              size="small"
              value={dtgPlusMockFile || ''}
              onChange={(dtgPlusMockFile) =>
                setOrderLine({
                  ...selectedOrderLine,
                  dtgPlusMockFile,
                })
              }
            />
            <CloudUploadIcon
              onClick={() => {
                openCloudinaryWidget(
                  `Shirtly/${emailid}/DTGPlusFiles`,
                  ['png', 'jpg'],
                  (secureUrl) => {
                    setOrderLine({
                      ...selectedOrderLine,
                      dtgPlusMockFile: secureUrl,
                    });
                  }
                );
              }}
            />
          </ColorWrapper>
        </DtgWrapper>
      )}

      {!!isScreenPrinting && (
        <h4 style={{ margin: 0, color: 'red' }}>
          Note: Artwork will be printed only in selected Pantone Color.
        </h4>
      )}

      {modal && <AlertDialog open={modal} setOpen={() => setModal(false)} />}
    </React.Fragment>
  );
};

export default DtgPlus;
