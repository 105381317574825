import styled from "styled-components";

export const Container = styled.div`
  min-height: calc(100vh - 180px);
  padding: 0 15px;
  background-color: #000;
  display: flex;
  flex-direction: column;
`;

export const ProductStockContainer = styled.div``;

export const ProductStockHeader = styled.div`
  padding: 20px 0 10px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
  }

  button {
    font-size: 8px;
    color: #5c92e2;
    font-size: 12px;
    border-radius: 10px;
    font-weight: bold;
    animation: glowing 2000ms infinite;
  }

  @keyframes glowing {
    0% {
      box-shadow: 0 0 -10px #5c92e2;
    }
    40% {
      box-shadow: 0 0 20px #5c92e2;
    }
    60% {
      box-shadow: 0 0 20px #5c92e2;
    }
    100% {
      box-shadow: 0 0 -10px #5c92e2;
    }
  }
`;

export const ProductSubstitute = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
`;

export const ProductSubstituteWrapper = styled.div`
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  height: 200px;
  cursor: pointer;
`;

export const ProductImage = styled.img`
  width: 100%;
  max-height: 220px;
`;

export const ProductName = styled.h3`
  margin: 0;
  padding: 0 10px;
  text-align: center;
  font-size: 12px;
`;
