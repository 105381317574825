import React from 'react';
import _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Color from 'components/Color';
import { Colors } from 'styles/pages/product-detail';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/product-detail/accordion';

const ColorAvailable = ({ data = {}, onColorSelect }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Color Available</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Colors>
          {data?.Colors?.map((color, index) =>
            color?.visible === false ? null : (
              <Color
                selected={data?.Product?.MarketingImagePath === color?.Image}
                onClick={() =>
                  _.isFunction(onColorSelect) && onColorSelect(color)
                }
                title={color.Name}
                code={color.HexCode}
                key={`colorFilter${index}`}
              />
            )
          )}
        </Colors>
      </AccordionDetails>
    </Accordion>
  );
};

export default ColorAvailable;
