import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import SubHeader from "components/SubHeader";
import StockTable from "pages/submitted-stock-orders/Table";
import { useDispatch } from "react-redux";
import { getStockSubmittedOrders } from "actions/inventory";

const options = [
  "S&S Active Wear (Canada)",
  "Alpha Broder (Canada)",
  "SANMAR (Canada)",
  "S&S Active Wear (United States)",
  "Alpha Broder (United States)",
  "SANMAR (United States)",
];

const OrderStock = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedCountrySupplier, setCountrySupplier] = useState(undefined);

  return (
    <Container
      maxWidth="xl"
      style={{ background: "#000", display: "flex", flexDirection: "column" }}
    >
      <SubHeader title="Submitted Stock Orders" isDark />
      <StockTable
        orders={data}
        options={options}
        selectedCountrySupplier={selectedCountrySupplier}
        setCountrySupplier={async (v) => {
          setCountrySupplier(v);

          if (v === options[0]) {
            const newData = await dispatch(
              getStockSubmittedOrders("SSActiveWearCA")
            );
            setData(newData);
          } else if (v === options[1]) {
          } else if (v === options[2]) {
          } else if (v === options[3]) {
            const newData = await dispatch(
              getStockSubmittedOrders("SSActiveWearUSA")
            );
            setData(newData);
          } else if (v === options[4]) {
          } else if (v === options[5]) {
          }
        }}
      />
    </Container>
  );
};

export default OrderStock;
