import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddDashboardSectionDialog from "pages/web-pages/AddDashboardSectionDialog";
import DashboardSection from "pages/web-pages/DashboardSection";
import { addDashboardSection } from "actions/webPages";
import {
  DashboardContainer,
  DashboardNoDataFound,
  DashboardHeader,
} from "styles/pages/web-pages";

const Dashboard = ({ data, refresh, isAdmin = true }) => {
  const dispatch = useDispatch();
  const [addSectionDialog, setAddSectionDialog] = useState(false);

  const Header = () => {
    if (!isAdmin) return null;
    return (
      <DashboardHeader>
        <AddCircleIcon onClick={() => setAddSectionDialog(true)} />
        {addSectionDialog && (
          <AddDashboardSectionDialog
            open={addSectionDialog}
            setOpen={() => setAddSectionDialog(false)}
            addSection={async (sectionName) => {
              setAddSectionDialog(false);
              await dispatch(addDashboardSection({ sectionName }));
              refresh();
            }}
          />
        )}
      </DashboardHeader>
    );
  };

  if (!data?.length)
    return (
      <DashboardContainer>
        <Header />
        <Paper elevation={3}>
          <DashboardNoDataFound>No Data Found !</DashboardNoDataFound>
        </Paper>
      </DashboardContainer>
    );

  return (
    <DashboardContainer>
      <Header />
      <DashboardSection sections={data} refresh={refresh} isAdmin={isAdmin} />
    </DashboardContainer>
  );
};

export default Dashboard;
