import React from 'react';
import _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Category, Item, Title } from 'styles/pages/browse-catalog';
import { DECORATING_TECHNIQUES } from 'shared/constants/decorating-techniques';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/browse-catalog/accordion';

const options = [
  ...DECORATING_TECHNIQUES?.filter(d => d?.DisplayName !== 'AOP' && d?.DisplayName !== 'SUB' && d?.DisplayName !== 'ScreenPrint'),
  ...[
    {
      DisplayName: 'DTGPlus',
      ImageUrl:
        'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620541564/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/clean-clothes_uqthco.png',
    },
    {
      DisplayName: 'Plus',
      ImageUrl:
        'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620541564/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/clean-clothes_uqthco.png',
    },
  ],
];

const DecoratingTechFilter = ({
  decoratingTechFilter,
  setDecoratingTechFilter,
}) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>
          Browse by Decorating Techniques
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Category>
          {options?.map((category, index) => {
            const selected = !!_.filter(
              decoratingTechFilter,
              (o) => o === category.DisplayName
            )?.length;

            return (
              <Item
                selected={selected}
                style={{ alignItems: 'center' }}
                key={`categoryImage${index}`}
                onClick={() => setDecoratingTechFilter(category.DisplayName)}>
                <img
                  src={category?.ImageUrl}
                  alt='decorating'
                  width='30'
                  height='30'
                />
                <Title>{category.DisplayName === 'Plus' ? 'TUFF PRINT TRANSFER' : category?.DisplayName}</Title>
              </Item>
            );
          })}
        </Category>
      </AccordionDetails>
    </Accordion>
  );
};

export default DecoratingTechFilter;
