import React from 'react';
import Button from 'components/Button';
import { FooterWrapper } from 'styles/pages/sync-product';

const Footer = ({ disabled, onSave }) => (
  <FooterWrapper>
    <Button title='Save' disabled={disabled} onClick={onSave} />
  </FooterWrapper>
);

export default Footer;
