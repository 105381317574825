import React, { useState } from 'react';
import {
  Step,
  Heading,
  SizeWrapper,
  Size,
} from 'styles/pages/order-details/orderDetailsDrawer';

const Sizes = ({ selectedSize, sizes, setSize }) => {
  const [cardVisibility, setCardVisibility] = useState(true);
  return (
    <Step>
      <Heading
        selected={cardVisibility}
        onClick={() => setCardVisibility(!cardVisibility)}>
        Select Size&nbsp;
        {selectedSize?.Name && <span>({selectedSize?.Name})</span>}
      </Heading>
      {cardVisibility && (
        <SizeWrapper>
          {sizes?.map((size, index) => (
            <Size
              active={selectedSize?.ID === size.ID}
              key={`size${index}`}
              onClick={() => {
                setCardVisibility(!cardVisibility);
                setSize(size);
              }}>
              {size.Name}
            </Size>
          ))}
        </SizeWrapper>
      )}
    </Step>
  );
};

export default Sizes;
