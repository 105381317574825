import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDTGPlusInfo, deleteDTGPlusInfo } from "actions/products";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "components/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import LogoImage from "assets/images/shirtly-logo-small.png";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "components/Button";
import moment from "moment";
import ModalWrapper from "components/ModalWrapper";
import {
  Heading,
  CloudWrapper,
  FieldWrapper,
  Update,
  FourBoxWrapper,
  ThreeBoxWrapper,
  TextFieldWrapperIcon,
} from "styles/pages/dtg-plus";

const isImageValid = (url) =>
  url?.includes(".png") || url?.includes(".jpeg") || url?.includes(".jpg");

const openCloudinaryWidget = (folder, cb) => {
  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "big-oven-tees-inc",
      show_powered_by: false,
      uploadPreset: "tnedst8q",
      sources: ["local", "camera", "url"],
      folder,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        cb(result?.info?.secure_url);
        myWidget.close();
      }
    }
  );
  myWidget.open();
};

const EditDtgPlusDialog = ({ open, setModal, dtgPlus }) => {
  const dispatch = useDispatch();
  const handleClose = () => setModal(false);
  const [state, setState] = useState(dtgPlus);
  const disabled =
    !state?.Name ||
    !state?.DisplayName ||
    !state?.MarketingImage ||
    !state?.Colors?.length ||
    !state?.Price ||
    !state?.Colors[0]?.ColorName ||
    !state?.Colors[0]?.ColorImagePath;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      scroll="paper"
      fullWidth={true}
    >
      <ModalWrapper className="edit-dtg-plus">
        <DialogTitle>Edit Dtg Plus</DialogTitle>
        <DialogContent>
          <FourBoxWrapper>
            <TextField
              label="Name"
              size="small"
              value={state?.Name}
              onChange={(Name) =>
                setState({
                  ...state,
                  Name,
                })
              }
            />
            <TextField
              label="DisplayName"
              size="small"
              value={state?.DisplayName}
              onChange={(DisplayName) =>
                setState({
                  ...state,
                  DisplayName,
                })
              }
            />
            <TextField
              label="Price"
              type="number"
              size="small"
              value={Number(state?.Price) || 0}
              onChange={(Price) =>
                setState({
                  ...state,
                  Price: Number(Price),
                })
              }
            />
            <CloudWrapper>
              <img
                alt="MarketingImage"
                className="logo"
                src={
                  isImageValid(state?.MarketingImage)
                    ? state?.MarketingImage
                    : LogoImage
                }
              />
              <TextField
                label="Image"
                size="small"
                value={state?.MarketingImage || ""}
                onChange={(MarketingImage) =>
                  setState({
                    ...state,
                    MarketingImage,
                  })
                }
              />
              <CloudUploadIcon
                className="download-icon"
                onClick={() => {
                  openCloudinaryWidget(
                    `Shirtly/Shirtly_Products_Mock/Images/dtg+/${state?.Name}`,
                    (MarketingImage) =>
                      setState({
                        ...state,
                        MarketingImage,
                      })
                  );
                }}
              />
            </CloudWrapper>
          </FourBoxWrapper>
          <Update>
            {state?._id && (
              <Button
                containerStyle={{
                  backgroundColor: "#FF0000",
                }}
                title="Delete"
                onClick={async () => {
                  const request = await dispatch(
                    deleteDTGPlusInfo({ ID: state?.ID })
                  );

                  if (request?.error) {
                    return;
                  }
                  setModal(false, true);
                }}
              />
            )}
            <Button
              title="Update"
              disabled={disabled}
              onClick={async () => {
                const request = await dispatch(updateDTGPlusInfo(state));

                if (request?.error) {
                  return;
                }
                setModal(false, true);
              }}
            />
          </Update>
          <Heading>
            <h3>Dtg Plus Colors</h3>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() =>
                setState({
                  ...state,
                  Colors: [
                    ...(state?.Colors || []),
                    {
                      ColorName: "",
                      ColorImagePath: "",
                      ID: moment().unix() + state?.Colors?.length,
                    },
                  ],
                })
              }
            >
              <AddCircleIcon style={{ fontSize: 30 }} />
            </IconButton>
          </Heading>
          {state?.Colors?.map((color, index) => (
            <ThreeBoxWrapper
              key={`color${index}`}
              style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr auto" }}
            >
              <FieldWrapper>
                <TextField
                  label="Color Name"
                  style={{ width: "100%" }}
                  size="small"
                  value={color?.ColorName}
                  onChange={(ColorName) =>
                    setState({
                      ...state,
                      Colors: state?.Colors?.map((color, j) => {
                        if (index !== j) {
                          return color;
                        }
                        return {
                          ...color,
                          ColorName,
                        };
                      }),
                    })
                  }
                />
              </FieldWrapper>
              <FieldWrapper>
                <CloudWrapper>
                  <img
                    alt="edit-dtg-plus"
                    className="logo"
                    src={
                      isImageValid(color?.ColorImagePath)
                        ? color?.ColorImagePath
                        : LogoImage
                    }
                  />
                  <TextField
                    label="Image"
                    size="small"
                    value={color?.ColorImagePath || ""}
                    onChange={(ColorImagePath) => {
                      setState({
                        ...state,
                        Colors: state?.Colors?.map((color, j) => {
                          if (index !== j) {
                            return color;
                          }
                          return {
                            ...color,
                            ColorImagePath,
                          };
                        }),
                      });
                    }}
                  />
                  <CloudUploadIcon
                    className="download-icon"
                    onClick={() => {
                      openCloudinaryWidget(
                        `Shirtly/Shirtly_Products_Mock/Images/dtg+/${state?.Name}`,
                        (ColorImagePath) => {
                          setState({
                            ...state,
                            Colors: state?.Colors?.map((color, j) => {
                              if (index !== j) {
                                return color;
                              }
                              return {
                                ...color,
                                ColorImagePath,
                              };
                            }),
                          });
                        }
                      );
                    }}
                  />
                </CloudWrapper>
              </FieldWrapper>
              <FieldWrapper>
                <TextField
                  label="Color Code"
                  size="small"
                  value={color?.ColorCode || ""}
                  onChange={(ColorCode) =>
                    setState({
                      ...state,
                      Colors: state?.Colors?.map((color, j) => {
                        if (index !== j) {
                          return color;
                        }
                        return {
                          ...color,
                          ColorCode,
                        };
                      }),
                    })
                  }
                />
              </FieldWrapper>

              <FieldWrapper>
                <TextField
                  label="Swatch Code"
                  size="small"
                  value={color?.SwatchCode || ""}
                  onChange={(SwatchCode) =>
                    setState({
                      ...state,
                      Colors: state?.Colors?.map((color, j) => {
                        if (index !== j) {
                          return color;
                        }
                        return {
                          ...color,
                          SwatchCode,
                        };
                      }),
                    })
                  }
                />
              </FieldWrapper>

              <TextFieldWrapperIcon style={{ marginLeft: "10px" }}>
                <DeleteIcon
                  className="delete-icon"
                  onClick={() =>
                    setState({
                      ...state,
                      Colors: state?.Colors?.filter((s, i) => i !== index),
                    })
                  }
                />
              </TextFieldWrapperIcon>
            </ThreeBoxWrapper>
          ))}
        </DialogContent>
        <DialogActions>
          <Update>
            <Button
              title="Update"
              disabled={disabled}
              onClick={async () => {
                const request = await dispatch(updateDTGPlusInfo(state));

                if (request?.error) {
                  return;
                }
                setModal(false, true);
              }}
            />
          </Update>
        </DialogActions>
      </ModalWrapper>
    </Dialog>
  );
};

export default EditDtgPlusDialog;
