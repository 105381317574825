import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UnisexIcon from 'assets/svg/UnisexIcon';
import KidsIcon from 'assets/svg/KidsIcon';
import AccessoriesIcon from 'assets/svg/AccessoriesIcon';
import InfantIcon from 'assets/svg/InfantIcon';
import MenIcon from 'assets/svg/MenIcon';
import WomenIcon from 'assets/svg/WomenIcon';
import { Category, Item, Title } from 'styles/pages/browse-catalog';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/browse-catalog/accordion';

const CategoriesFilter = ({
  categories: categoriesFilter,
  updateCategories: updateCategoriesFilter,
}) => {
  const classes = useStyles();
  const categories = useSelector((state) => state?.categories?.data);

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.heading}>Browse by Category</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Category>
          {categories?.map((category, index) => {
            const selected = !!_.filter(
              categoriesFilter,
              (o) => o === category.CatId
            )?.length;

            return (
              <Item
                selected={selected}
                key={`categoryImage${index}`}
                onClick={() => updateCategoriesFilter(category.CatId)}
              >
                {/* <Img
                  src={category.MarketingImagePath}
                  alt={category.DisplayName}
                /> */}

                {category?.DisplayName === 'Unisex' && (
                  <UnisexIcon className="category-icon" color="#6c6c6c" />
                )}
                {category?.DisplayName === 'Kids' && (
                  <KidsIcon className="category-icon" color="#6c6c6c" />
                )}
                {category?.DisplayName === 'Accessories' && (
                  <AccessoriesIcon className="category-icon" color="#6c6c6c" />
                )}
                {category?.DisplayName === 'Infant' && (
                  <InfantIcon className="category-icon" color="#6c6c6c" />
                )}
                {category?.DisplayName === 'Mens' && (
                  <MenIcon className="category-icon" color="#6c6c6c" />
                )}
                {category?.DisplayName === 'Ladies' && (
                  <WomenIcon className="category-icon" color="#6c6c6c" />
                )}
                <Title>{category.DisplayName}</Title>
              </Item>
            );
          })}
        </Category>
      </AccordionDetails>
    </Accordion>
  );
};

export default CategoriesFilter;
