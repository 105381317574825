import React from "react";
import Button from "components/Button";

const selectedDesignerSettingsStyle = {
  backgroundColor: "#5c92e2",
  color: "#FFFFFF",
};

const ToolPrintAreas = ({ orderLine = {}, setOrderLine, canvas }) => {
  const printAreas = orderLine?.printAreas;
  const isPlusSelected = orderLine?.plus;

  return printAreas?.map((printArea, index) => {
    if (!printArea?.printAreaMarketingImage) {
      return null;
    }

    if (printArea?.printAreaName !== "Front" && isPlusSelected) {
      return null;
    }

    const designerSettings = printArea?.designerSettings;

    if (!designerSettings?.width && !designerSettings?.height) return null;

    return (
      <Button
        key={`${printArea?.printAreaName}_${index}`}
        title={printArea?.printAreaName}
        onClick={() => {
          const printAreas = orderLine?.printAreas?.map((p, j) => ({
            ...p,
            selected: index === j,
          }));

          const selectedPrintArea = printAreas?.find((p) => p?.selected);

          canvas?.clear();

          if (selectedPrintArea?.canvasJSON) {
            canvas?.loadFromJSON(selectedPrintArea?.canvasJSON);
          }

          setOrderLine({
            ...orderLine,
            printAreas,
          });
        }}
        containerStyle={
          printArea?.selected ? selectedDesignerSettingsStyle : {}
        }
      />
    );
  });
};

export default ToolPrintAreas;
