import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ModalWrapper from 'components/ModalWrapper';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Slider from 'react-slick';
import {
  VerifyImage,
  VerifiedImages,
  Title,
  ImageWrapper,
  Note,
  AssetId,
  Verified,
} from 'styles/pages/verify-art-files';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const CustomDialog = ({ open, setOpen, status }) => {
  return (
    <Dialog open={open} onClose={setOpen}>
      <ModalWrapper className='about-us'>
        <DialogTitle>
          Art File&nbsp;
          {status?.verifying
            ? 'Verifying'
            : status?.matchCount === 0
            ? 'Verified'
            : status?.matchCount === -1
            ? 'Verification Error'
            : 'Matched'}
        </DialogTitle>

        {status?.verifying && (
          <DialogContentText style={{ textAlign: 'center' }}>
            Verifying your image, please wait !
          </DialogContentText>
        )}

        {status?.matchCount === 0 && (
          <Verified>
            <CheckCircleIcon style={{ color: 'green', fontSize: 60 }} />
            <DialogContentText>
              <b>Art File Successfully Verified !</b>
            </DialogContentText>
          </Verified>
        )}

        {status?.matchCount === -1 && (
          <Verified>
            <ErrorOutlineIcon style={{ color: 'orange', fontSize: 60 }} />
            <DialogContentText>
              <b>Unable to verify the image</b>
            </DialogContentText>
          </Verified>
        )}

        {status?.matchCount > 0 && (
          <DialogContentText>
            <VerifyImage>
              <ImageWrapper>
                <img
                  src={status?.artFileSecureUrl}
                  alt='art-file'
                  width='200'
                />
              </ImageWrapper>
            </VerifyImage>
            <AssetId>
              Public ID : <b>{status?.public_id?.split('/ArtFiles/')[1]}</b>
            </AssetId>
            <Title>Trademark Images</Title>
            <VerifiedImages>
              <Slider {...settings}>
                {status?.matchingImages?.map((m) => (
                  <div key={m}>
                    <div
                      style={{
                        backgroundImage: `url(${m})`,
                        backgroundSize: 'contain',
                        backgroundPosition: '50% 50%',
                        height: 150,
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </VerifiedImages>
          </DialogContentText>
        )}

        <Note>
          Image comparison is in Testing Phase. Results are not 100% accurate.
        </Note>

        <Note>
          Please contact&nbsp;
          <b>
            <a href='mailto:support@shirtly.com'>support@shirtly.com</a>
          </b>
          &nbsp; and provide <b>Public ID</b>, if there is any verifying
          concern.
        </Note>
        <DialogActions>
          <Button onClick={setOpen} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </ModalWrapper>
    </Dialog>
  );
};

export default CustomDialog;
