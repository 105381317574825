import React, { useState, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from "components/Loader";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import TextField from "components/TextField";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import { HexColorPicker } from "react-colorful";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import FontPicker from 'font-picker-react';
import { default as MUIButton } from "@material-ui/core/Button";
import ErrorMessage from "components/ErrorMessage";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import { checkAIDescriptionForBrand } from "actions/aiDesignStudio";
import {
  Container,
  Header,
  CloseIconStyle,
  FontPickerWrapper,
  HexColorPickerWrapper,
  PreviewText,
} from "styles/components/ArtWork/browseLibrary";

const BrowseTextDrawer = ({ open, toggle, onDrop, plus }) => {
  const dispatch = useDispatch();
  const loaderVisibility = useSelector((state) => state?.loader?.visibility);
  const [trademarkError, setTrademarkError] = useState(false);
  const [text, setText] = useState(null);
  const [color, setColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Open Sans");
  const [fontSize, setFontSize] = useState(20);
  const [fontBold, setFontBold] = useState(undefined);
  const [fontItalic, setFontItalic] = useState(undefined);
  const [fontUnderline, setFontUnderline] = useState(undefined);
  const [fontStrikeThrough, setFontStrikeThrough] = useState(undefined);
  const [fontOverline, setFontOverline] = useState(undefined);
  const previewTextRef = createRef(null);
  const googleFonts = useSelector((state) => state?.googleFonts?.data);
  const token = useSelector((state) => state?.auth?.profile?.token);

  const fonts = googleFonts?.items?.filter(
    (d) => d?.version === "v15" || d?.version === "v14"
  );
  const [inputValue, setInputValue] = React.useState(fontFamily || "");

  // useEffect(() => {
  //   const fetchGoogleFonts = async () => {
  //     dispatch(getGoogleFonts());
  //   };
  //   if (!googleFonts?.items?.length) fetchGoogleFonts();
  // }, [googleFonts]);

  return (
    <Drawer anchor="left" open={open} onClose={toggle}>
      <Container style={{ width: "30vw" }}>
        <Header style={{ borderBottom: "1px solid #ccc" }}>
          <h2>Add Text</h2>
          <CloseIconStyle onClick={toggle}></CloseIconStyle>
        </Header>
        {/* <div style={{ textAlign: "center", margin: "30px 0" }}>
          <PreviewText ref={previewTextRef} style={{ display: "inline-block" }}>
            <h1
              className="apply-font"
              style={{
                textAlign: "center",
                margin: 0,
                fontFamily,
                padding: 0,
                color: color || "#000",
                fontSize,
                display: "inline-block",
                fontWeight: fontBold ? "bold" : "normal",
                textDecoration: `${fontUnderline ? "underline" : ""} ${
                  fontStrikeThrough ? "line-through" : ""
                } ${fontOverline ? "overline" : ""}`,
                fontStyle: fontItalic ? "italic" : "unset",
              }}
            >
              {text?.length ? text : "Enter text to preview"}
            </h1>
          </PreviewText>
        </div> */}

        <textarea
          placeholder="Enter Text"
          style={{
            width: "100%",
            minHeight: 100,
            borderColor: "rgba(0, 0, 0, 0.23)",
          }}
          value={text || ""}
          onChange={(e) => {
            if (trademarkError) {
              setTrademarkError(false);
            }

            setText(e.target.value);
          }}
        />
        {trademarkError && (
          <ErrorMessage>
            Entered text contains some trademark or copy infringement value.
            Please change the text.
          </ErrorMessage>
        )}

        {text && (
          <div style={{ margin: 20, textAlign: "center" }}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <MUIButton
                onClick={() => setFontBold(!fontBold)}
                variant={fontBold ? "contained" : "outlined"}
              >
                <FormatBoldIcon />
              </MUIButton>
              <MUIButton
                onClick={() => setFontItalic(!fontItalic)}
                variant={fontItalic ? "contained" : "outlined"}
              >
                <FormatItalicIcon />
              </MUIButton>
              {!plus && (
                <MUIButton
                  onClick={() => setFontUnderline(!fontUnderline)}
                  variant={fontUnderline ? "contained" : "outlined"}
                >
                  <FormatUnderlinedIcon />
                </MUIButton>
              )}
              {!plus && (
                <MUIButton
                  onClick={() => setFontStrikeThrough(!fontStrikeThrough)}
                  variant={fontStrikeThrough ? "contained" : "outlined"}
                >
                  <FormatStrikethroughIcon />
                </MUIButton>
              )}
              {!plus && (
                <MUIButton
                  onClick={() => setFontOverline(!fontOverline)}
                  variant={fontOverline ? "contained" : "outlined"}
                >
                  <VerticalAlignTopIcon />
                </MUIButton>
              )}
            </ButtonGroup>
          </div>
        )}

        {text && (
          <div style={{ margin: "30px 0" }}>
            <h3 style={{ borderBottom: "1px solid #ccc", paddingBottom: 10 }}>
              Select Font Family
            </h3>
            <FontPickerWrapper>
              {/* <FontPicker
                sort="alphabet"
                apiKey="AIzaSyCFeviktD35r76PXTlfjR17S3-9HkTjclw"
                activeFontFamily={fontFamily}
                onChange={(nextFont) => setFontFamily(nextFont.family)}
              /> */}
              <Autocomplete
                options={fonts.map((f) => f?.family)}
                value={fontFamily}
                inputValue={inputValue}
                disableClearable
                onInputChange={(event, newInputValue) =>
                  setInputValue(newInputValue)
                }
                renderOption={(option) => (
                  <div style={{ fontFamily: option }}>{option}</div>
                )}
                onChange={(event, newValue) =>
                  setFontFamily(newValue || fonts[0]?.family)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select Font" />
                )}
              />
            </FontPickerWrapper>
          </div>
        )}

        {/* {text && (
          <div style={{ margin: '30px 0' }}>
            <h3 style={{ borderBottom: '1px solid #ccc', paddingBottom: 10 }}>
              Select Font Size ({`${fontSize}px`})
            </h3>
            <div style={{ width: '100%', padding: '30px 10px 0 10px' }}>
              <Slider
                defaultValue={10}
                getAriaValueText={valueText}
                step={2}
                valueLabelDisplay='on'
                marks
                min={20}
                max={70}
                value={fontSize}
                onChange={(e, v) => setFontSize(v)}
              />
            </div>
          </div>
        )} */}

        {text && !plus && (
          <div style={{ margin: "30px 0" }}>
            <h3 style={{ borderBottom: "1px solid #ccc", paddingBottom: 10 }}>
              Select Text Color ({color})
            </h3>
            <HexColorPickerWrapper>
              <HexColorPicker
                color={color}
                onChange={(color) => {
                  setColor(color);
                }}
              />

              <div style={{ marginTop: 30 }}>
                <TextField
                  label={`Enter Color Hex Code`}
                  style={{ width: "100%" }}
                  value={color}
                  onChange={(v) => {
                    if (v?.length <= 7) setColor(`#${v.replace(/#/g, "")}`);
                  }}
                />
              </div>
            </HexColorPickerWrapper>
          </div>
        )}

        {text && (
          <div style={{ textAlign: "center" }}>
            <Button
              containerStyle={{
                backgroundColor: COLORS.DARK_BLUE,
                color: COLORS.WHITE,
                fontWeight: "normal",
                boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
                textTransform: "none",
                borderRadius: "3px",
                fontSize: "16px",
                lineHeight: "1.5",
                marginBottom: 20,
                width: "100%",
              }}
              title="Add Text"
              onClick={async () => {
                const tradeMarkInfo = await dispatch(
                  checkAIDescriptionForBrand({ message: text }, token)
                );

                if (tradeMarkInfo?.tradeMarkWordsCount > 0) {
                  setTrademarkError(true);
                  return;
                }

                toggle();
                onDrop({
                  text,
                  fontBold,
                  fontFamily,
                  fontItalic,
                  fontSize,
                  fontStrikeThrough,
                  fontUnderline,
                  color,
                  fontOverline,
                  public_id: `${moment().millisecond()}${text}`,
                  secure_url: text,
                });

                // html2canvas(previewTextRef?.current, {
                //   scrollX: 0,
                //   scrollY: 0,
                //   width,
                //   height,
                //   backgroundColor: 'transparent',
                // }).then(function (canvas) {
                //   onDrop({
                //     secure_url: canvas.toDataURL(),
                //     width,
                //     height,
                //     public_id: `${moment().millisecond()}${text}`,
                //   });
                // });
              }}
            />
          </div>
        )}
      </Container>
      <Loader open={loaderVisibility} />
    </Drawer>
  );
};

BrowseTextDrawer.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

export default BrowseTextDrawer;
