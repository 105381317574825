import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import Select from "components/Select";
import { getProductsByCategoryId } from "selectors/inventory";

const ProductsSelect = ({ variant, onChange, product }) => {
  const allProducts = useSelector((state) => state?.products?.data);

  let productsByCategory = getProductsByCategoryId(
    allProducts,
    variant?.syncData?.categoryId
  );

  if (productsByCategory?.length > 0) {
    let productOptions = [];
    for (let i = 0; i < productsByCategory.length; i++) {
      productOptions.push({
        ...productsByCategory[i],
        ...productsByCategory[i].Product,
      });
    }
    productsByCategory = productOptions;
  }

  productsByCategory = productsByCategory?.filter(
    (p) => p?.visible === undefined || p?.visible === true
  );

  return (
    <Select
      label="Product"
      options={productsByCategory}
      disabled={!variant?.syncData?.categoryId}
      value={{ ID: variant?.syncData?.ProductID || "" }}
      valueKey="ID"
      labelKey="DisplayName"
      onChange={(ProductID, e) => {
        const c = _.find(productsByCategory, (o) => o?.ID === ProductID);

        const variants = product?.variants?.map((v) =>
          v?.id === variant?.id || !v?.syncData?.ProductID
            ? {
                ...v,
                syncData: {
                  ...v?.syncData,
                  ProductID: c?.ID,
                  productName: c?.Name,
                  sizeId: "",
                  sizeName: "",
                  ColorID: "",
                  colorName: "",
                  printAreas: c?.PrintAreas?.map((p, i) => ({
                    ...p,
                    selected: i === 0,
                  })),
                },
              }
            : v
        );
        onChange(variants, e);
      }}
    />
  );
};

export default ProductsSelect;
