import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SubHeader from "components/SubHeader";
import CreateNotification from "pages/notifications/CreateNotification";
import NotificationsTable from "pages/notifications/NotificationsTable";
import { ROUTES } from "shared/constants/routes";
import {
  createNotification,
  getAllNotifications,
  deleteNotification,
  editNotification,
} from "actions/notifications";
import { Container } from "styles/pages/notifications";

const Notifications = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [notifications, setNotifications] = useState(null);

  const getNotificationsData = async () => {
    const data = await dispatch(getAllNotifications());
    setNotifications(() => data);
  };

  useEffect(() => {
    if (!notifications) {
      getNotificationsData();
    }
  }, [dispatch, notifications]);

  return (
    <Container>
      <SubHeader
        isDark
        title={ROUTES.NOTIFICATIONS.TITLE}
        links={[
          {
            title: ROUTES.HOME.TITLE,
            to: ROUTES.HOME.LINK,
          },
        ]}
        currentPageTitle={ROUTES.NOTIFICATIONS.TITLE}
        rightCornerButtons={[
          { title: "Create Notification", onClick: () => setModal(true) },
        ]}
      />

      <NotificationsTable
        data={notifications}
        onDelete={async (id) => {
          await dispatch(deleteNotification(id));
          setNotifications(() => null);
        }}
        onEdit={async (data) => {
          setNotifications(
            notifications?.map((n) => ({ ...n, selected: n?.id === data?.id }))
          );
          setModal(true);
        }}
      />

      {modal && (
        <CreateNotification
          open={modal}
          data={notifications?.find((n) => n?.selected)}
          toggle={() => {
            setNotifications(
              notifications?.map((n) => ({ ...n, selected: false }))
            );
            setModal(false);
          }}
          onCreate={async (data, edit) => {
            setModal(false);
            if (edit) {
              await dispatch(editNotification(data));
            } else {
              await dispatch(createNotification(data));
            }
            setNotifications(() => null);
          }}
        />
      )}
    </Container>
  );
};

export default Notifications;
