import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={28}
      height={28}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.565 17.689H3.23a.41.41 0 110-.82h13.335a.41.41 0 110 .82zM10.026 19.946H3.23a.41.41 0 110-.82h6.796a.41.41 0 110 .82zM15.642 6.715H6.89a.41.41 0 100 .82h8.752c.283 0 .512.23.512.513v5.312l-1.789-2.155a1.32 1.32 0 00-1.018-.479 1.32 1.32 0 00-1.019.479l-1.173 1.413-2.423-2.92a1.32 1.32 0 00-1.018-.478 1.32 1.32 0 00-1.019.478l-3.054 3.68v-5.33c0-.283.23-.513.512-.513h.82a.41.41 0 100-.82h-.82c-.735 0-1.333.598-1.333 1.333v6.17c0 .736.598 1.334 1.333 1.334h11.489c.735 0 1.333-.598 1.333-1.333V8.048c0-.735-.598-1.333-1.333-1.333zM3.749 14.533l3.577-4.31a.502.502 0 01.775 0l2.521 3.037-1.22 1.471H4.152a.512.512 0 01-.404-.198zm11.893.198h-5.175l2.492-3.002a.502.502 0 01.775 0l2.32 2.794a.511.511 0 01-.412.208z'
        fill='#6C6C6C'
      />
      <path
        d='M22.87 4.082h-2.81V2.3c0-.717-.583-1.3-1.3-1.3H1.3c-.717 0-1.3.583-1.3 1.3v19.615a5.094 5.094 0 005.088 5.088h9.884a5.094 5.094 0 005.081-4.82C24.454 22.07 28 18.455 28 14.027V9.212a5.135 5.135 0 00-5.13-5.13zm-3.63 17.832a4.272 4.272 0 01-4.268 4.267H5.088A4.272 4.272 0 01.82 21.914V2.299a.48.48 0 01.48-.48h17.46a.48.48 0 01.48.48v12.764a.41.41 0 10.82 0V8.265a1.36 1.36 0 011.047-.82l1.453-.224c.788-.121 1.575.211 2.005.848.229.338.35.733.35 1.143v4.814a5.09 5.09 0 01-3.695 4.884 1.023 1.023 0 01-1.16-.475v-1.454a.41.41 0 10-.82 0v4.933zm7.94-7.888c0 3.974-3.174 7.22-7.12 7.336V19.54a1.835 1.835 0 001.382.16 5.914 5.914 0 004.292-5.674V9.212a2.85 2.85 0 00-.489-1.602c-.607-.899-1.71-1.37-2.81-1.2l-1.453.225a2.178 2.178 0 00-.922.372V4.903h2.81a4.314 4.314 0 014.31 4.309v4.814z'
        fill='#6C6C6C'
      />
    </svg>
  );
}

export default SvgComponent;
