import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStores, removeStore } from 'actions/stores';
import StoreCard from 'pages/my-stores/stores/StoreCard';
import { GridView } from 'styles/pages/my-stores/stores';

const StoresGrid = () => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const stores = useSelector((state) => state?.stores?.data);

  useEffect(() => {
    return dispatch(getStores(emailid));
  }, [dispatch, emailid]);

  return (
    <GridView>
      {stores?.map((s) => (
        <StoreCard
          key={s?.ID}
          data={s}
          onRemove={async () => {
            await dispatch(
              removeStore({
                user: emailid,
                storeId: s.ID,
              })
            );

            await dispatch(getStores(emailid));
          }}
        />
      ))}
    </GridView>
  );
};

export default StoresGrid;
