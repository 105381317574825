import React, { useState } from "react";
import { useSelector } from "react-redux";
import Storefront1 from "assets/images/Shopify-Storefront---1.jpg";
import Storefront2 from "assets/images/Shopify-Storefront---2.jpg";
import Button from "components/Button";
import SubHeader from "components/SubHeader";
import TextField from "components/TextField";
import ShopifyLogo from "assets/images/shopify.png";
import {
  Container,
  Detail,
  Row1,
  Row2,
  Img,
  TitleSection,
  TextSection,
  TextHeader,
} from "styles/pages/connect-to-shopify";

const ConnectToShopify = () => {
  const [storeName, setStoreName] = useState(undefined);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  return (
    <Container>
      <SubHeader isDark title="Register a Store" />
      <TitleSection>
        <p>Connect To Shopify</p>
      </TitleSection>
      <Detail>
        <Row1>
          <div>
            <TextField
              isDark
              label="Shopify storefront address"
              onChange={(value) => setStoreName(value)}
            />
            <Button
              disabled={!storeName?.trim().length || storeName?.includes(".")}
              title="Connect Store"
              onClick={() =>
                window.open(
                  `https://${storeName}.myshopify.com/admin/oauth/authorize?client_id=5ab62acb1f5582657ffeae380e6631c1&scope=read_products,write_products,read_product_listings,read_orders,write_orders&redirect_uri=https://shirtly-app.herokuapp.com/shopifyRedirectedUrl&state=${storeName}.myshopify.com/${emailid}&grant_options[]=`
                )
              }
            />
          </div>
          <TextSection>
            <TextHeader>
              <p>How can I find my Shopify Storefront Address?</p>
              <img src={ShopifyLogo} alt="ShopifyLogo" />
            </TextHeader>

            <p>
              Your Shopify storefront address will be in the format
              your_shop_name.myshopify.com and is the default address provided
              to you by Shopify when you first opened your store.
            </p>
            <p>
              If you can't remember your shop name you can find it by logging
              into your Shopify admin. There are two ways you should be able to
              find your shop name:
            </p>
          </TextSection>
        </Row1>
        <Row2>
          <Img src={Storefront1} alt="Storefront1" />
          <Img src={Storefront2} alt="Storefront2" />
        </Row2>
      </Detail>
    </Container>
  );
};

export default ConnectToShopify;
