import React, { useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getProductsByCategoryId } from "selectors/inventory";

import {
  Step,
  Heading,
  CardWrapper,
  Card,
} from "styles/pages/order-details/orderDetailsDrawer";

const OrderDetailsDrawer = ({ selectedCategory, setCategory, allProducts }) => {
  const categories = useSelector((state) => state?.categories?.data) || [];
  const [cardVisibility, setCardVisibility] = useState(true);

  return (
    <Step>
      <Heading
        selected={cardVisibility}
        onClick={() => setCardVisibility(!cardVisibility)}
      >
        Select Category&nbsp;
        {selectedCategory?.DisplayName && (
          <span>({selectedCategory?.DisplayName})</span>
        )}
      </Heading>
      {cardVisibility && (
        <CardWrapper>
          {categories.map((category, index) => (
            <Card
              key={`categories${index}`}
              active={selectedCategory?.CatId === category.CatId}
              onClick={() => {
                setCardVisibility(false);
                _.isFunction(setCategory) && setCategory(category);
              }}
            >
              <img
                src={category.MarketingImagePath}
                alt={category.DisplayName}
              />
              <p>
                {category.DisplayName} (
                {getProductsByCategoryId(allProducts, category.CatId)?.length})
              </p>
            </Card>
          ))}
        </CardWrapper>
      )}
    </Step>
  );
};

export default OrderDetailsDrawer;
