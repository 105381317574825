import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Progress } from 'styles/pages/background-burner';

const Modal = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  const [count, setCount] = useState(15);

  setTimeout(() => setCount(() => count - 1), 1000);

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick>
      <ModalWrapper>
        <DialogTitle>Background Burning Initiated</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Progress>
              {count > 0 && (
                <>
                  <h1>
                    {count}
                    <span>s.</span>
                  </h1>
                  <LinearProgress color='secondary' />
                  <p>
                    Shirtly AI is burning your pictures background and update
                    the image in your Design Library under Background Burn
                    Files.
                  </p>
                </>
              )}

              {count <= 0 && (
                <p>
                  New Image has been added to your Design Library under
                  Background Burn Files.
                </p>
              )}
            </Progress>
          </DialogContentText>
        </DialogContent>
        {count <= 0 && (
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Ok
            </Button>
          </DialogActions>
        )}
      </ModalWrapper>
    </Dialog>
  );
};

export default Modal;
