import React from 'react';
import { useSelector } from 'react-redux';
import { USD_TO_CAD_CONVERSION_RATE } from 'constants/currency';
import {
  Container,
  ShippingVender,
  ShippingCost,
  Heading,
  Heading1,
  Table,
  P,
} from 'styles/components/ShippingDetails';

const ShippingCostBody = ({ cost, selectedVendor }) => {
  const currency = useSelector((state) => state?.currency?.value);
  return (
    <Container>
      <ShippingVender>Price Chart - {selectedVendor?.Title}</ShippingVender>
      <ShippingCost>
        <Heading>
          <b>Standard Prices : </b>
          {cost?.ShippingTimeMinInDays} - {cost?.ShippingTimeMaxInDays} Business
          Days
        </Heading>
        <Heading1>FIRST ITEM</Heading1>
        <Table>
          <P>Shipping Cost ({currency})</P>
          <P>
            $
            {currency === 'USD'
              ? cost.PrimaryCost.toFixed(2)
              : (Number(cost.PrimaryCost) * USD_TO_CAD_CONVERSION_RATE).toFixed(
                  2
                )}
          </P>
        </Table>

        <Heading1>ADDITIONAL ITEMS</Heading1>
        <Table>
          <P>Shipping Cost ({currency})</P>
          <P>
            $
            {currency === 'USD'
              ? cost.SecondaryCost.toFixed(2)
              : (
                  Number(cost.SecondaryCost) * USD_TO_CAD_CONVERSION_RATE
                ).toFixed(2)}
          </P>
        </Table>
      </ShippingCost>
    </Container>
  );
};

export default ShippingCostBody;
