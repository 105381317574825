import React, { createContext, useState, useContext, useMemo } from 'react';

const Context = createContext();

export const Provider = ({ children }) => {
  const [value, setValue] = useState(undefined);

  const a = useMemo(
    () => ({
      value,
      setValue,
    }),
    [value]
  );

  return <Context.Provider value={a}>{children}</Context.Provider>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => useContext(Context);
