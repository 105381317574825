import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import CancelIcon from '@material-ui/icons/Cancel';

const Modal = ({ open, setOpen, error }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalWrapper>
        <CancelIcon className="close-icon" onClick={handleClose} />
        <DialogTitle>Notification</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            Payment declined due to the following reasons:
            <ul style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
              <li>{error?.decline_code?.replace(/_/g, ' ')}</li>
              <li>{error?.code?.replace(/_/g, ' ')}</li>
            </ul>
            <p>
              Please try with different card. If there is still error please
              contact&nbsp;
              <a href="mailto:support@shirtly.com">
                Shirtly INC (support@shirtly.com)
              </a>
              .
            </p>
          </DialogContentText>
        </DialogContent>
      </ModalWrapper>
    </Dialog>
  );
};

export default Modal;
