import styled, { css } from 'styled-components';
import COLORS from 'shared/constants/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const Container = styled.div``;
const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'buyernotes'
    'artwork'
    'oli';
  align-items: start;
  column-gap: 20px;
  @media (min-width: 1290px) {
    grid-template-columns: 1fr minmax(auto, 600px);
    grid-template-rows: repeat(auto-fit, minmax(0px, auto));
    grid-template-areas: 'oli artwork';
    margin-bottom: 15px;
  }
`;
const Summary = styled.div`
  grid-area: oli;
`;
const ItemList = styled.div`
  border-radius: 5px;
  border: none !important;
  padding: 0 30px;
  background-color: ${COLORS.WHITE};
  margin-bottom: 20px;
  Button {
    border: 1px solid ${COLORS.DARK_BLUE};
    background-color: ${COLORS.WHITE};
    color: ${COLORS.DARK_BLUE};
    &:hover {
      background-color: ${COLORS.DARK_BLUE};
      color: ${COLORS.WHITE};
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: none;
  border: none !important;
  background: transparent;
  color: #2789c0;
  font-size: 17px;
  font-family: 'Inter600';
  padding: 14px 0;
`;
const ItemText = styled.div`
  border: 3px solid ${COLORS.BLUE};
  border-radius: 0 2px 2px 2px;
  padding: 8px 9px;
  transition: all ease-out 0.25s;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: rgb(128 128 128 / 0.1);
      border: 3px solid #dedede;
      cursor: pointer;
    `};
`;
const CircleIcon = styled(CheckCircleIcon)`
  position: absolute;
  right: -12px;
  top: -14px;
  color: ${COLORS.GREEN};
  background-color: ${COLORS.WHITE};
  border-radius: 50%;
`;
const ProblemIcon = styled(ReportProblemIcon)`
  position: absolute;
  right: -12px;
  top: -14px;
  color: #ea5050;
  background-color: ${COLORS.WHITE};
  border-radius: 50%;
`;
const LineItem = styled.div`
  padding-bottom: 15px;
  font-size: 14px;
  color: #939497;

  .more-details {
    margin-left: 10px;
    color: #2280c1;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
`;
const Cost = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  margin: 5px 0;
  float: right;
  flex-direction: column;
  text-align: right;
`;

const CostError = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  margin: 5px 0;
  float: right;
  color: red;
`;

const Form = styled.div`
  font-size: 13px;
  width: 100%;
  display: flex;
  grid-column-gap: 10px;
  grid-row-gap: 15px;
  flex-wrap: wrap;
  > div {
    min-width: 110px;
    flex: 1;
    max-width: 125px;
    :nth-child(2) {
      max-width: none;
    }
  }
  @media (max-width: 568px) {
    display: grid;
    > div {
      max-width: none;
    }
  }
`;
const FieldsGroup = styled.div``;
const ActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
  min-height: 28px;
  flex-wrap: wrap;
  @media (max-width: 475px) {
    justify-content: space-between;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  grid-gap: 10px;
  position: absolute;
  left: 9px;
  @media (max-width: 475px) {
    position: unset;
    margin-bottom: 4px;
  }
  button {
    font-size: 11px;
    padding: 2px 2px;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.BLUE};
    border: none;
    text-transform: capitalize;
    :last-child {
      background-color: #ff0000;
    }
  }
`;

const BlankArea = styled.div`
  border: none !important;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-area: artwork;
  padding: 15px;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  > div {
    display: flex;
    align-items: center;
    background: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    padding: 5px 10px;
    animation: zoom 1s infinite;
    background: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    border-radius: 15px;
    @keyframes zoom {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  @media (min-width: 1290px) {
    position: sticky;
    top: 0;
    min-height: 600px;
  }
`;

const DtgWrapper = styled.div`
  display: flex;
  margin: 10px 0px;
  grid-gap: 20px;
  align-items: center;
  > div {
    flex: 1;
  }
`;

const ColorWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  img {
    width: 30px;
    height: 30px;
  }
`;

const DtgTitle = styled.div`
  display: flex;
  margin-top: 10px;
  border-top: 1px solid #ccc;
  flex-wrap: wrap;
`;

export const CopyArtworkTitle = styled.h3`
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
`;

export {
  Container,
  Wrapper,
  Summary,
  Title,
  ItemList,
  ItemText,
  LineItem,
  Form,
  Cost,
  FieldsGroup,
  ActionSection,
  ButtonSection,
  BlankArea,
  CircleIcon,
  ProblemIcon,
  CostError,
  DtgWrapper,
  ColorWrapper,
  DtgTitle,
};
