import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';

const YotiVerificationStatusModal = ({ open, setOpen, data }) => {
  const handleClose = () => setOpen(false);

  const isPending = data?.find((o) => o?.state === 'PENDING');

  const isVerified = !!(
    data?.find((o) => o?.state === 'DONE') &&
    data?.find((o) => o?.report?.recommendation?.value === 'APPROVE')
  );

  const isRejected = !!(
    data?.find((o) => o?.state === 'DONE') &&
    data?.find((o) => o?.report?.recommendation?.value === 'REJECT')
  );

  const isNotAvailable = !!(
    data?.find((o) => o?.state === 'DONE') &&
    data?.find((o) => o?.report?.recommendation?.value === 'NOT_AVAILABLE')
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalWrapper className="about-us">
        <DialogTitle>Yoti Verification Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isVerified && 'Your submitted document is Approved'}
            {isPending && 'Your submitted document is still under review.'}
            {isRejected &&
              'Your submitted document has been Rejected. Please submit again.'}

            {isNotAvailable &&
              'Yoti couldn’t perform the check, usually due to the quality of submitted images or user error.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </ModalWrapper>
    </Dialog>
  );
};

export default YotiVerificationStatusModal;
