import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCustomGraphics } from "actions/designer";
import { fabric } from "fabric";
import { showLoader, hideLoader } from "actions/loader";
import FileUpload from "components/ArtWork/AdvanceToolFileUpload";
import { isAOP } from "selectors/products";

const AdvanceToolArtworkBoard = ({
  orderLine = {},
  canvas,
  setLayersDrawer,
  onFabricDrop,
  plus,
  printType,
}) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const products = useSelector((state) => state?.products?.data);
  const printAreas = orderLine?.printAreas;
  const selectedPrintArea = printAreas?.find((p) => p?.selected);
  const isProductAop = isAOP(products, orderLine.ProductID);

  useEffect(() => {
    if (orderLine?.aiGenerateImages && canvas) {
      const selctedPrintArea = orderLine?.printAreas?.find((a) => a?.selected);
      const artwork = selctedPrintArea?.aiTempArtwork;

      if (artwork) {
        artwork.type = "image";

        new fabric.Image.fromURL(
          artwork?.secure_url,
          function (img) {
            img.set({
              top: 10,
              left: 10,
              lockAspectRatio: true,
              ...artwork,
            });
            const { width: artworkWidth, height: artworkHeight } = artwork;
            const canvasWidth = canvas?.width;
            const canvasHeight = canvas?.height;
            const largeDimension =
              artworkWidth > artworkHeight
                ? "width"
                : artworkWidth < artworkHeight
                ? "height"
                : "width";
            if (artworkHeight > canvasHeight && largeDimension === "height") {
              img.scaleToHeight(canvasHeight - 45);
            }
            if (artworkWidth > canvasWidth && largeDimension === "width") {
              img.scaleToWidth(canvasWidth - 30);
            }
            canvas.add(img);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      }
    }
  }, [orderLine, canvas]);

  return !selectedPrintArea?.previewImage && !isProductAop ? (
    <FileUpload
      plus={plus}
      printType={printType}
      orderLine={orderLine}
      onDrop={async (artwork) => {
        if (artwork?.text) {
          const text = new fabric.IText(artwork?.text, {
            fontWeight: artwork?.fontBold ? "bold" : "normal",
            fontFamily: artwork?.fontFamily,
            linethrough: artwork?.fontStrikeThrough,
            underline: artwork?.fontUnderline,
            overline: artwork?.fontOverline,
            fontStyle: artwork?.fontItalic ? "italic" : "normal",
            fontSize: 30,
          });
          text.set({
            fill: artwork?.color,
          });
          canvas.add(text);
        } else if (artwork?.objects) {
          const json = canvas?.toJSON();
          const customGraphics = artwork?.objects?.filter((j) =>
            j?.src?.includes("/CustomGraphics/")
          );

          if (!!customGraphics?.length) {
            const fileNames = customGraphics?.map(
              (c) => c?.src?.split("/CustomGraphics/")[1]
            );
            const customGraphicsdData = await dispatch(
              getCustomGraphics({ fileNames }, token)
            );

            onFabricDrop(
              {
                ...json,
                objects: [...json?.objects, ...artwork?.objects],
              },
              customGraphicsdData?.map((q) => ({
                productionFile: q?.Art_Url,
                thumbnailUrl: q?.Thumbnail_Url,
                chargeAmountInUSD: q["Charge_Amount(USD)"],
                shirtlyChargeAmountInUsd: q["Shirtly_Amount(USD)"],
                sku: q["Art_SKU"],
                user: q["user"],
                fileName: q?.Filename,
              }))
            );

            canvas?.loadFromJSON({
              ...json,
              objects: [...json?.objects, ...artwork?.objects],
            });
          } else {
            onFabricDrop({
              ...json,
              objects: [...json?.objects, ...artwork?.objects],
            });
            canvas?.loadFromJSON({
              ...json,
              objects: [...json?.objects, ...artwork?.objects],
            });
          }
        } else {
          let secureUrl = artwork?.secure_url;
          let secureUrlSplit = secureUrl.split("/upload/");
          const API_ENDPOINT =
            "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

          if (secureUrlSplit?.length === 1) {
            dispatch(showLoader());
            const fileData = new FormData();
            fileData.append("file", secureUrl);
            fileData.append("upload_preset", "tnedst8q");
            fileData.append(
              "folder",
              `Shirtly/${emailid}/CustomGraphics/${artwork?.user}`
            );
            const data = await fetch(API_ENDPOINT, {
              method: "post",
              body: fileData,
            });
            const tempArtwork = await data?.json();

            artwork = { ...artwork, ...tempArtwork };
            dispatch(hideLoader());
          }

          artwork.type = "image";

          new fabric.Image.fromURL(
            artwork?.secure_url,
            function (img) {
              img.set({
                top: 10,
                left: 10,
                lockAspectRatio: true,
                ...artwork,
              });
              const { width: artworkWidth, height: artworkHeight } = artwork;
              const canvasWidth = canvas?.width;
              const canvasHeight = canvas?.height;
              const largeDimension =
                artworkWidth > artworkHeight
                  ? "width"
                  : artworkWidth < artworkHeight
                  ? "height"
                  : "width";
              if (artworkHeight > canvasHeight && largeDimension === "height") {
                img.scaleToHeight(canvasHeight - 45);
              }
              if (artworkWidth > canvasWidth && largeDimension === "width") {
                img.scaleToWidth(canvasWidth - 30);
              }
              canvas.add(img);
            },
            {
              crossOrigin: "anonymous",
            }
          );
        }
      }}
      canvas={canvas}
      setLayersDrawer={setLayersDrawer}
    />
  ) : null;
};

export default AdvanceToolArtworkBoard;
