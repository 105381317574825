import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { getWebPage } from 'actions/webPages';
import Header from 'pages/home/Header';
import Footer from 'pages/home/Footer';
import { Container } from 'styles/pages/home';
import USAFlag from 'assets/images/usa-flag.png';
import CADFlag from 'assets/images/canada-flag.png';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import {
  SupportContainer,
  Wrapper,
  Address,
  Title,
  SubTitle,
} from 'styles/pages/support';

const Support = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getWebPageData = async () => {
      const data = await dispatch(getWebPage({ page: 'LandingPage' }));
      setData(() => data);
    };

    getWebPageData();
  }, []);

  return (
    <Container>
      <Header
        showNavMenu={false}
        data={data?.find((d) => d?.name === 'Header')}
      />
      <Paper elevation={3} style={{ margin: '20px 20%', padding: 20 }}>
        <Title>Support</Title>
        <SubTitle>Need any help ?</SubTitle>
        <SupportContainer>
          <Wrapper>
            <img className='flag' src={CADFlag} alt='canada-flag' />
            <iframe src='https://www.google.com/maps/embed/v1/place?key=AIzaSyAfgpNQd7dbRT7RPMDGxAHxHjXAtg9gKw4&q=122 Middleton Street, Brantford, Ontario, N3S 7V7, Canada&zoom=12&maptype=roadmap' />
            <Address>
              <div>
                <LocationOnIcon />
              </div>
              <div>
                <p>Shirtly CANADA</p>
                <p>122 Middleton Street</p>
                <p>Brantford ON N3S 7V7</p>
              </div>
            </Address>
            <Address>
              <div>
                <MailIcon />
              </div>
              <div>
                <p>
                  <a href='mailto:support@shirtly.com'>support@shirtly.com</a>
                </p>
              </div>
            </Address>
          </Wrapper>
          <Wrapper>
            <img className='flag' src={USAFlag} alt='usa-flag' />
            <iframe
              className='iframe'
              src='https://www.google.com/maps/embed/v1/place?key=AIzaSyAfgpNQd7dbRT7RPMDGxAHxHjXAtg9gKw4&q=8742 Buffalo Ave, Niagara Falls, , 14304, &zoom=12&maptype=roadmap'
            />
            <Address>
              <div>
                <LocationOnIcon />
              </div>
              <div>
                <p>Shirtly USA</p>
                <p>8742 Buffalo Avenue</p>
                <p>Niagara Falls NY 14304</p>
              </div>
            </Address>
            <Address>
              <div>
                <MailIcon />
              </div>
              <div>
                <p>
                  <a href='mailto:support@shirtly.com'>support@shirtly.com</a>
                </p>
              </div>
            </Address>
          </Wrapper>
        </SupportContainer>
      </Paper>
      <Footer links={[]} />
    </Container>
  );
};

export default Support;
