import _ from 'lodash';
import { useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import {
  CategoriesContainer,
  CategoryBox,
  IconWrapper,
} from 'styles/pages/my-stores/products/productCreator';

const Categories = ({ categoryId, onChange }) => {
  const categories = useSelector((state) => state?.categories?.data);
  return (
    <CategoriesContainer>
      {categories?.map((c) => (
        <CategoryBox
          key={c?.CatId}
          onClick={() => {
            const category = _.find(categories, (o) => o?.CatId === c?.CatId);
            onChange({
              category,
              product: undefined,
              colors: [],
            });
          }}>
          <IconWrapper
            style={{
              background: categoryId === c?.CatId ? '#2789C0' : '#F4F4F4',
            }}>
            {categoryId === c?.CatId ? <CheckIcon /> : null}
          </IconWrapper>

          <img
            src={c?.MarketingImagePath}
            alt={c?.DisplayName}
            width='100'
            height='100'
          />
          <p>{c?.DisplayName}</p>
        </CategoryBox>
      ))}
    </CategoriesContainer>
  );
};

export default Categories;
