import React from 'react';
import OrderInformation from 'pages/order-details/OrderInformation';
import CustomerInformation from 'pages/order-details/CustomerInformation';
// import BuyerNote from 'pages/order-details/BuyerNote';

import { Container, Wrapper } from 'styles/pages/order-details/orderInfo';

const OrderInfo = ({ orderDetail = {}, refresh }) => (
  <Container>
    <Wrapper>
      <OrderInformation orderDetail={orderDetail} />
      <CustomerInformation orderDetail={orderDetail} refresh={refresh} />
      {/* <BuyerNote /> */}
    </Wrapper>
  </Container>
);

export default OrderInfo;
