import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BrowseLibrary from 'components/ArtWork/BrowseLibrary';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { AdvanceToolBrowseLibraryButton } from 'styles/components/ArtWork';

const FileUpload = ({ onDrop, orderLine, printType }) => {
  const [browseLibrary, setBrowseLibrary] = useState(false);
  const selectedPrintArea = orderLine?.printAreas?.find((p) => p?.selected);

  return (
    <React.Fragment>
      {!selectedPrintArea?.previewImage && printType !== 'AOP' && (
        <AdvanceToolBrowseLibraryButton
          style={{
            position: 'absolute',
            top: '-40px',
            width: '100%',
            minWidth: '162px',
          }}
        >
          <h4
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
            onClick={() => setBrowseLibrary(true)}
          >
            <b>
              Stock Designs <sup>HQ</sup>
            </b>

            <NewReleasesIcon />
          </h4>
        </AdvanceToolBrowseLibraryButton>
      )}

      {browseLibrary && (
        <BrowseLibrary
          orderLine={orderLine}
          printType={printType}
          onDrop={(artwork) => onDrop(artwork)}
          open={browseLibrary}
          toggle={() => setBrowseLibrary(false)}
          showAdvanceToolTab={true}
          activeTabIndex={3}
        />
      )}
    </React.Fragment>
  );
};

FileUpload.propTypes = {
  onDrop: PropTypes.func,
};

export default FileUpload;
