import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
  flex-wrap: wrap-reverse;

  Button {
    margin: 4px 5px;
    span {
      margin-right: 0;
    }
    svg {
      font-size: 16px;
    }
  }
  @media (max-width: 834px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    Button {
      margin: 12px 5px;
    }
  }
`;
const StepWrapper = styled.div`
  display: flex;
  p {
    display: flex;
    align-items: center;
    margin: 0;
    min-height: 45px;
    background: linear-gradient(70deg, #e6e6e6, #fff);
    padding: 0 10px 0 26px;
    cursor: pointer;
    ::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 22.5px solid transparent;
      border-left: 18px solid #fff;
      border-bottom: 22.5px solid transparent;
      position: relative;
      right: -28px;
      display: block;
    }
  }
  @media (max-width: 834px) {
    width: 100%;
    > div {
      flex: 1;
    }
    p {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      ::after {
        display: none;
      }
    }
  }
  @media (max-width: 480px) {
    p {
      flex-direction: column;
      height: 100%;
      text-align: center;
    }
  }
`;
const Step = styled.div`
  svg {
    margin-right: 5px;
  }
  p {
    ${(props) =>
      props.completed &&
      css`
        background: linear-gradient(70deg, #e6e6e6, #fff);
        ::after {
          content: '';
          width: 0;
          height: 0;
          border-top: 22.5px solid transparent;
          border-left: 18px solid #fff;
          border-bottom: 22.5px solid transparent;
          position: relative;
          right: -28px;
          display: block;
        }
        @media (max-width: 834px) {
          ::after {
            display: none;
          }
        }
      `}

    ${(props) =>
      props.active &&
      css`
        background: #2280c1;
        color: #fff;
        cursor: pointer;
        ::after {
          content: '';
          width: 0;
          height: 0;
          border-top: 22.5px solid transparent;
          border-left: 18px solid #2280c1;
          border-bottom: 22.5px solid transparent;
          position: relative;
          right: -28px;
          display: block;
        }
        @media (max-width: 834px) {
          ::after {
            display: none;
          }
        }
      `}
  }
`;

export { Container, StepWrapper, Step };
