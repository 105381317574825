import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getColumns } from 'pages/ip-program/table/columns';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { rows } from 'pages/ip-program/table/rows';

const useStyles = makeStyles({
  td: {
    fontSize: 14,
    padding: '13px 16px',
    fontFamily: 'Inter400',
    '& img': {
      width: 50,
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'none',
    },
  },
  tr: {
    transition: 'all ease-out 0.25s',
    '&:nth-of-type(odd)': {
      background: 'linear-gradient(60deg, #fff, #eee)',
    },
    '&:nth-of-type(even)': {
      background: 'linear-gradient(-45deg, #fff, #eee)',
    },
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      background: 'linear-gradient(60deg, #fff, #eee)',
    },
    '&:nth-of-type(even)': {
      background: 'linear-gradient(-45deg, #fff, #eee)',
    },
    '&:hover': {
      cursor: 'pointer',
      background: 'white',
      boxShadow: '10px 10px 30px #d9d9d9, -10px -10px 30px #d9d9d9',
      position: 'relative',
    },
  },
}))(TableRow);

const Body = ({ artFiles, onDelete, onApprove, onReject }) => {
  const classes = useStyles();
  const data = rows(artFiles);
  const emailId = useSelector((state) => state?.auth?.profile?.emailid);
  const isAdmin = emailId === 'support@shirtly.com';
  const columns = getColumns(isAdmin);

  return data?.length > 0 ? (
    data.map((row, index) => (
      <StyledTableRow key={`OrdersTable${index}`} hover tabIndex={-1}>
        {columns.map((column, columnIndex) => (
          <TableCell
            className={classes.td}
            key={`${column.id}tbody${columnIndex}${index}`}
            align={column.align}>
            {column.id === 'artFile' ? (
              <img src={row[column.id]} alt='art-file' width='50' />
            ) : column.id === 'createdAt' ? (
              moment(row[column.id]).format('MM/DD/YYYY')
            ) : column.id === 'status' ? (
              <Chip
                style={{
                  backgroundColor: row?.reviewing
                    ? 'orange'
                    : row?.approved
                    ? 'green'
                    : 'red',
                  color: '#fff',
                }}
                label={
                  row?.reviewing
                    ? 'Reviewing'
                    : row?.approved
                    ? 'Approved'
                    : 'Rejected'
                }
              />
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
        <TableCell>
          {!isAdmin ? (
            <Tooltip title='This cannot be undone'>
              <DeleteIcon
                style={{ color: 'red' }}
                onClick={() => onDelete(artFiles[index])}
              />
            </Tooltip>
          ) : (
            <>
              <ThumbUpIcon
                style={{ color: 'green', marginRight: 10 }}
                onClick={() => onApprove(artFiles[index])}
              />
              <ThumbDownIcon
                style={{ color: 'red' }}
                onClick={() => onReject(artFiles[index])}
              />
            </>
          )}
        </TableCell>
      </StyledTableRow>
    ))
  ) : (
    <StyledTableRow key={`NodataFound`} hover tabIndex={-1}>
      <TableCell
        className={classes.td}
        key='tbodynodatafound'
        colSpan={columns.length + 1}>
        No Data Found
      </TableCell>
    </StyledTableRow>
  );
};

export default Body;
