import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import LoginDialog from "pages/home/Header/LoginDialog";
import SignUpDialog from "pages/home/Header/SignUpDialog";
import ForgotPasswordDialog from "pages/home/Header/ForgotPassword";
import PersonIcon from "@material-ui/icons/Person";
import ShirtlyLogo from "assets/images/shirtly-logo-new.png";
import HomeBanner from "assets/images/home-banner.jpeg";
import MyStoreImg from "assets/images/store-open.jpeg";
import VizuailLogo from "assets/images/vizuail.png";
import ConnectYourStore from "assets/images/connect-your-store.png";
import CustomLogo from "assets/images/custom-logo.png";
import HowUseVideo from "assets/images/how-to-use-video.png";
import Bizhub from "assets/images/bizhub.png";
import AiAssinstant from "assets/images/ai-assistant.png";
import { getWebPage } from "actions/webPages";
import {
  Container,
  LeftPanel,
  RightPanel,
  StyledLogo,
  TagLine,
  StyledBanner,
  Navigation,
  NavItem,
  MoreSection,
  StoreTitle,
  Products,
  Product,
  ProductName,
  ComingSoon,
  ComingSoonProduct,
} from "styles/pages/home-new";

const Home = () => {
  const dispatch = useDispatch();
  const [loginDialog, setLoginDialog] = useState(false);
  const [signUpDialog, setSignUpDialog] = useState(false);
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
  const [ipProgram, setIpProgram] = useState(false);
  const toggleLoginDialog = () => setLoginDialog(!loginDialog);
  const toggleSignUpDialog = () => setSignUpDialog(!signUpDialog);
  const toggleForgotPasswordDialog = () =>
    setForgotPasswordDialog(!forgotPasswordDialog);

  useEffect(() => {
    const getWebPageData = async () => {
      await dispatch(getWebPage({ page: "LandingPage" }));
    };

    getWebPageData();
  }, []);

  return (
    <Container>
      <LeftPanel>
        <div>
          <StyledLogo src={ShirtlyLogo} alt="shirtly-logo" />

          <TagLine>elevating your brand with stylish precision</TagLine>
        </div>

        <StyledBanner src={HomeBanner} alt="Gray T-shirt" />

        <Navigation>
          <NavItem>USD</NavItem>
          <NavItem onClick={toggleLoginDialog}>
            <PersonIcon
              style={{
                border: "1px solid #000",
                borderRadius: "50%",
                padding: 2,
              }}
            />

            <span style={{ marginLeft: 10 }}>LOG IN</span>
          </NavItem>
        </Navigation>
      </LeftPanel>

      <RightPanel>
        <MoreSection>
          <StoreTitle>My Store and More</StoreTitle>
          <div style={{ padding: 10, background: "#fff", marginTop: 10 }}>
            <div style={{ padding: 10, border: "4px solid #000" }}>
              <img
                src={MyStoreImg}
                alt="Store front"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </MoreSection>

        <Products>
          <Product
            onClick={() => window.open("https://vizuail.com/", "_blank")}
          >
            <ProductName>Vizual</ProductName>
            <img src={VizuailLogo} alt="Shopify logo" />
          </Product>

          <Product onClick={toggleLoginDialog}>
            <ProductName>How to connect your store</ProductName>
            <img src={ConnectYourStore} alt="Shopify logo" />
          </Product>

          <Product onClick={toggleLoginDialog}>
            <ProductName>Build your custom logo</ProductName>
            <ComingSoonProduct>
              <ComingSoon>Coming Soon</ComingSoon>
              <img src={CustomLogo} alt="Shopify logo" />
            </ComingSoonProduct>
          </Product>

          <Product onClick={toggleLoginDialog}>
            <ProductName>How to use videos</ProductName>
            <ComingSoonProduct>
              <ComingSoon>Coming Soon</ComingSoon>
              <img src={HowUseVideo} alt="Shopify logo" />
            </ComingSoonProduct>
          </Product>

          <Product onClick={toggleLoginDialog}>
            <ProductName>Small Business Hub</ProductName>
            <ComingSoonProduct>
              <ComingSoon>Coming Soon</ComingSoon>
              <img src={Bizhub} alt="Shopify logo" />
            </ComingSoonProduct>
          </Product>

          <Product onClick={toggleLoginDialog}>
            <ProductName>AI Assistant</ProductName>
            <ComingSoonProduct>
              <ComingSoon>Coming Soon</ComingSoon>
              <img src={AiAssinstant} alt="Shopify logo" />
            </ComingSoonProduct>
          </Product>
        </Products>
      </RightPanel>

      {loginDialog && (
        <LoginDialog
          open={loginDialog}
          onClose={() => toggleLoginDialog()}
          showSignUpModal={(ip) => {
            setIpProgram(ip);
            toggleLoginDialog();
            toggleSignUpDialog();
          }}
          forgotPasswordDialog={() => {
            toggleLoginDialog();
            toggleForgotPasswordDialog();
          }}
        />
      )}

      {signUpDialog && (
        <SignUpDialog
          open={signUpDialog}
          ipProgram={ipProgram}
          onClose={() => toggleSignUpDialog()}
          showLoginModal={() => {
            toggleSignUpDialog();
            toggleLoginDialog();
          }}
        />
      )}

      {forgotPasswordDialog && (
        <ForgotPasswordDialog
          open={forgotPasswordDialog}
          onClose={() => toggleForgotPasswordDialog()}
        />
      )}
    </Container>
  );
};

export default Home;
