import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import Button from "components/Button";
import Datepicker from "components/DatePicker";
import { allOrderReport, allUserOrderReport } from "actions/orders";
import { FilterWrapper } from "styles/pages/sales-report";
import { calculateTax } from "actions/stripe";
import FileDownloadIcon from "@material-ui/icons/CloudDownload";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { rows } from "pages/sales-report/table/rows";

const Filters = ({
  startDate,
  endDate,
  onChange,
  generateReport,
  data,
  users,
  user,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const [taxInfo, setTaxInfo] = useState(null);

  const csvEl = useRef(null);
  const [allOrderData, setAllOrderData] = useState(undefined);

  let disabled = !startDate || !endDate;
  if (startDate && endDate) {
    disabled = moment(moment(endDate).format("MM/DD/YYYY")).isBefore(
      moment(startDate).format("MM/DD/YYYY")
    );
  }

  useEffect(() => {
    const getData = async () => {
      const data = await dispatch(calculateTax());
      setTaxInfo(data);
    };

    if (!taxInfo) {
      getData();
    }
  }, [dispatch, taxInfo]);

  const csvData = [
    [
      "Production Submit Date",
      "Order Ship Date",
      "User Country",
      "Print Provider",
      "Ship Country",
      "Order#",
      "User Email",
      "Line Items Cost (Without Tax)",
      "Line Items Cost Currency",
      "Shipping Cost (Without Tax)",
      "Shipping Cost Currency",
      "Total Tax",
      "Total Tax Currency",
      "Total Amount Paid in USD",
      "Total Amount Paid in CAD",
    ],
  ];

  if (allOrderData) {
    const dataTemp = rows(allOrderData, true, taxInfo);
    if (!!dataTemp?.length) {
      for (let i = 0; i < dataTemp?.length; i++) {
        const {
          orderSubmitToProductionDate,
          orderShipDate,
          masterUserEmail,
          ID,
          lineItemsCost,
          shippingCostWithoutTax,
          totalTax,
          totalAmountPaidUsd,
          totalAmountPaidCad,
          userCountry,
          printProvider,
          shipCountry,
        } = dataTemp[i];

        csvData.push([
          orderSubmitToProductionDate,
          orderShipDate,
          userCountry,
          printProvider,
          shipCountry,
          `S-${ID}`,
          masterUserEmail,
          lineItemsCost,
          "USD",
          shippingCostWithoutTax,
          "USD",
          totalTax,
          "USD",
          totalAmountPaidUsd,
          totalAmountPaidCad,
        ]);
      }
    }
  }

  return (
    <FilterWrapper>
      <Datepicker
        label="Start Date"
        value={startDate}
        onChange={(v) => onChange("startDate", v)}
      />
      <Datepicker
        label="End Date"
        value={endDate}
        onChange={(v) => onChange("endDate", v)}
      />

      {isAdmin && (
        <Autocomplete
          options={users}
          getOptionLabel={(option) => option.User}
          value={user}
          onChange={(event, newValue) => onChange("user", newValue)}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="User" />}
        />
      )}

      <Button
        title="Generate Report"
        disabled={disabled}
        onClick={() => {
          generateReport();
          setAllOrderData(undefined);
        }}
        containerStyle={{
          height: 20,
          padding: 20,
          backgroundColor: disabled ? "#CCCCCC" : "#5c92e2",
          color: "#FFFFFF",
          boxShadow: "none",
          textTransform: "capitalize",
          borderRadius: "6px",
          height: "48px",
          fontSize: "16px",
        }}
      />

      {data?.orders && (
        <FileDownloadIcon
          onClick={async () => {
            let response;

            if (user) {
              response = await dispatch(
                allUserOrderReport({
                  startDate: moment(startDate).subtract(1, "days"),
                  endDate: moment(endDate),
                  user: user?.User,
                })
              );
            } else {
              response = await dispatch(
                allOrderReport({
                  startDate: moment(startDate).subtract(1, "days"),
                  endDate: moment(endDate),
                })
              );
            }

            if (response?.error) {
              return;
            }
            setAllOrderData(response?.allOrders);

            setTimeout(() => {
              csvEl.current.link.click();
            }, 10);
          }}
          style={{ color: "#5c92e2", fontSize: 36, cursor: "pointer" }}
        />
      )}

      {csvData && (
        <CSVLink
          style={{ display: "none" }}
          ref={csvEl}
          data={csvData}
          filename={`Shirtly_Sales_Report${
            user?.User ? `(${user?.User})` : ""
          }(${moment(startDate).format("MMM DD YYYY")} - ${moment(
            endDate
          ).format("MMM DD YYYY")}).csv`}
        />
      )}
    </FilterWrapper>
  );
};

export default Filters;
