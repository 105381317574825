import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { marketPlaceOrderDetails } from 'actions/orders';
import Image from 'components/Image';
import {
  Container,
  Shopify,
  Etsy,
} from 'styles/pages/order-details/marketPlaceOrderDialog';

const AlertDialog = ({ open, setOpen, payload }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(async () => {
    const response = await dispatch(marketPlaceOrderDetails(payload));
    setData(response);
  }, [payload]);

  const hide = () => {
    setData(null);
    setOpen();
  };

  if (!data) {
    return null;
  }

  let variation;

  if (payload?.marketPlace === 'WooCommerce') {
    variation = data?.variations?.find((v) => v?.id === payload?.lineItemArrId);
  }

  return (
    <Dialog open={open} onClose={hide} maxWidth='md'>
      <Container>
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {payload?.marketPlace === 'Shopify' && (
              <Shopify>
                <Image style={{ width: '100%' }} src={data?.imgSrc || null} />
              </Shopify>
            )}
            {payload?.marketPlace === 'Etsy' && (
              <Etsy>
                {data?.map((item, index) => (
                  <Image key={`item${index}`} src={item?.imgSrc || null} />
                ))}
              </Etsy>
            )}
            {payload?.marketPlace === 'BigCartel' && (
              <Image src={data?.data?.attributes?.primary_image_url || null} />
            )}
            {!!variation?.image?.length ? (
              <Etsy>
                {variation?.image?.map((item, index) => (
                  <Image key={`item${index}`} src={item?.src || null} />
                ))}
              </Etsy>
            ) : (
              <Etsy>
                {data?.images?.map((item, index) => (
                  <Image key={`item${index}`} src={item?.src || null} />
                ))}
              </Etsy>
            )}
            <h4>{payload?.details}</h4>
            {!!data?.description && (
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            )}
          </DialogContentText>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default AlertDialog;
