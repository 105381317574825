const row = (
  orderSubmitToProductionDate,
  orderShipDate,
  masterUserEmail,
  ID,
  lineItemsCost,
  shippingCostWithoutTax,
  totalTax,
  totalAmountPaidUsd,
  totalAmountPaidCad,
  printProvider,
  shipCountry,
  userCountry
) => {
  return {
    orderSubmitToProductionDate,
    orderShipDate,
    masterUserEmail,
    ID,
    lineItemsCost,
    shippingCostWithoutTax,
    totalTax,
    totalAmountPaidUsd,
    totalAmountPaidCad,
    printProvider,
    shipCountry,
    userCountry,
  };
};

export { row };
