import styled from "styled-components";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const PhasesContainer = styled.div`
  max-width: 1100px;
  margin-bottom: 20px;
`;

const PhaseContainer = styled.div`
  margin-bottom: 40px;
  padding: 0 30px;
`;

const PhaseHeader = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const PhaseTitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  margin: 6px 0;
`;

const PhaseWrapper = styled.div``;

const PhaseSubTitle = styled.div`
  color: #000;
  font-weight: 500;
  font-size: 16px;

  .more {
    padding: 0 5px;
    color: green;
    display: inline-block;
    position: relative;
    width: 34px;

    svg {
      position: absolute;
      top: -19px;
    }
  }
`;

const DesignInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
`;
const DesignInput = styled.input`
  flex: 1;
  height: 40px;
  border: 1px solid black;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 10px;
`;

const GoButton = styled.button`
  background: black;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 0px 10px;
  color: white;
  border: 1px solid black;
  height: 40px;
  font-size: 18px;
  width: 60px;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const ChipsWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  gap: 10px;
  align-items: center;

  .more {
    color: green;
    font-weight: bold;
    cursor: pointer;
    display: flex;

    svg {
      font-size: 26px;
    }
  }

  .MuiChip-label {
    font-size: 12px;
  }

  .MuiChip-colorPrimary {
    background-color: green;
    :hover,
    :active,
    :focus {
      background-color: green;
    }
  }
`;

const StyledAddCircleIcon = styled(AddCircleIcon)``;

const DesignInputWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const IpScanTitle = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: bold;

  svg {
    font-size: 32px;
  }
`;

const IpScans = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 20px;
`;

const IpScan = styled.div`
  flex: 1;
  background-color: #ffd78e;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  flex-direction: column;
  gap: 20px;
`;

export {
  PhasesContainer,
  PhaseContainer,
  PhaseHeader,
  PhaseTitle,
  PhaseWrapper,
  PhaseSubTitle,
  DesignInput,
  GoButton,
  DesignInputWrapper,
  ChipsWrapper,
  StyledAddCircleIcon,
  DesignInputWrapper1,
  IpScans,
  IpScan,
  IpScanTitle,
};
