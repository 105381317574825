import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import Slider from "react-slick";
import Image from "components/Image";
import {
  Card,
  CardWrapper,
  Title,
  Wrapper,
  StockWrapper,
} from "styles/pages/inventory";
import { settings } from "pages/inventory/data";
import { Blinking } from "styles/components/Drawer";
import Stocks from "pages/inventory/Stocks";
import { technoInventoryCA, technoInventoryUSA } from "actions/inventory";
import { alphaInventoryCA, alphaInventoryUS } from "actions/products";
const XMLParser = require("react-xml-parser");

const Inventory = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state?.products?.data);
  const [usaInventoryStock, setUSAInventoryStock] = useState(undefined);
  const [cadInventoryStock, setCADInventoryStock] = useState(undefined);
  const [selectedProduct, setProduct] = useState(undefined);

  const [cadSearch, setCadSearch] = useState(undefined);
  const [usaSearch, setUsaSearch] = useState(undefined);

  return (
    <Container
      maxWidth="xl"
      style={{ background: "#000", display: "flex", flexDirection: "column" }}
    >
      <Title>
        Inventory &nbsp;&nbsp;[&nbsp;
        <Blinking>
          <circle cx="5" cy="5" r="5" fill="red" />
        </Blinking>
        &nbsp;&nbsp;LIVE&nbsp;]
      </Title>
      <Wrapper style={{ width: "calc(100vw - 50px)", margin: "0 auto" }}>
        <Slider {...settings}>
          {products?.map((p, index) => {
            const product = p.Product;
            const isSelected = product?.ID === selectedProduct?.Product?.ID;
            return (
              <CardWrapper
                key={`product${index}`}
                onClick={async () => {
                  setProduct(p);
                  setCadSearch(undefined);
                  setUsaSearch(undefined);
                  setCADInventoryStock(undefined);
                  setUSAInventoryStock(undefined);

                  const isCAD = p?.PrintProviders.find(
                    (c) => c?.ProviderName == "CA"
                  );
                  const isUSA = p?.PrintProviders.find(
                    (c) => c?.ProviderName == "US"
                  );

                  if (isCAD) {
                    const isSSActiveWear =
                      isCAD?.supplier === "S&S ACTIVE WEAR";

                    const isAlphaBroader = isCAD?.supplier === "ALPHA BRODER";

                    if (isSSActiveWear) {
                      const cadData = await dispatch(
                        await technoInventoryCA({
                          styleId: isCAD?.productCode,
                        })
                      );

                      let data = [];
                      for (let i = 0; i < cadData?.length; i++) {
                        data.push({
                          ...cadData[i],
                          colorFrontImage: `http://cdn.ssactivewear.com/${cadData[i]?.colorFrontImage}`,
                        });
                      }
                      setCADInventoryStock(data);
                    } else if (isAlphaBroader) {
                      const cadData = await dispatch(
                        await alphaInventoryCA({
                          styleId: isCAD?.productCode,
                        })
                      );

                      const json = new XMLParser().parseFromString(cadData);
                      let data = [];
                      for (let i = 0; i < json?.children?.length; i++) {
                        const { attributes, children } = json?.children[i];
                        const styleCode =
                          attributes?.["style-code"]?.toLowerCase();
                        const colorCode = attributes?.["color-code"];

                        if (styleCode && colorCode) {
                          data.push({
                            colorName: attributes?.description,
                            qty: children[0]?.value,
                            colorFrontImage: `https://www.alphabroder.ca/images/can/prodDetail/${styleCode}_${colorCode}_p.jpg`,
                          });
                        }
                      }
                      setCADInventoryStock(data);
                    }
                  }

                  if (isUSA) {
                    const isSSActiveWear =
                      isUSA?.supplier === "S&S ACTIVE WEAR";

                    const isAlphaBroader = isUSA?.supplier === "ALPHA BRODER";

                    if (isSSActiveWear) {
                      const usaData = await dispatch(
                        await technoInventoryUSA({
                          styleId: isUSA?.productCode,
                        })
                      );

                      let data = [];
                      for (let i = 0; i < usaData?.length; i++) {
                        data.push({
                          ...usaData[i],
                          colorFrontImage: `http://cdn.ssactivewear.com/${usaData[i]?.colorFrontImage}`,
                        });
                      }
                      setUSAInventoryStock(data);
                    } else if (isAlphaBroader) {
                      const usaData = await dispatch(
                        await alphaInventoryUS({
                          styleId: isUSA?.productCode,
                        })
                      );

                      const json = new XMLParser().parseFromString(usaData);
                      let data = [];
                      for (let i = 0; i < json?.children?.length; i++) {
                        const { attributes, children } = json?.children[i];
                        const styleCode =
                          attributes?.["style-code"]?.toLowerCase();
                        const colorCode = attributes?.["color-code"];

                        if (styleCode && colorCode) {
                          data.push({
                            colorName: attributes?.description,
                            qty: children[0]?.value,
                            colorFrontImage: `https://www.alphabroder.ca/images/can/prodDetail/${styleCode}_${colorCode}_p.jpg`,
                          });
                        }
                      }
                      setUSAInventoryStock(data);
                    }
                  }
                }}
              >
                <Card
                  style={{
                    border: isSelected ? "1px solid #5c92e2" : "none",
                    height: "auto",
                  }}
                >
                  <div>
                    <Image
                      src={product.MarketingImagePath}
                      alt={product.DisplayName}
                    />
                    <div style={{ marginTop: 10 }}>{product?.DisplayName}</div>
                  </div>
                </Card>
              </CardWrapper>
            );
          })}
        </Slider>
      </Wrapper>
      <StockWrapper>
        <Stocks
          title="Canada Plant Stock"
          data={cadInventoryStock}
          isCad={true}
          search={cadSearch}
          onSearch={(e, v) => {
            if (v) {
              setCadSearch(`${v.colorName} / ${v.sizeName}`);
            } else {
              setCadSearch(undefined);
            }
          }}
        />
        <Stocks
          title="USA Plant Stock"
          data={usaInventoryStock}
          isCad={false}
          search={usaSearch}
          onSearch={(e, v) => {
            if (v) {
              setUsaSearch(`${v.colorName} / ${v.sizeName}`);
            } else {
              setUsaSearch(undefined);
            }
          }}
        />
      </StockWrapper>
    </Container>
  );
};

export default Inventory;
