import styled from 'styled-components';

const Container = styled.div`
  max-width: 700px;
  width: 82vw;
  h4 {
    text-align: center;
  }
  @media (max-width: 600px) {
    h4 {
      font-size: 15px;
    }
  }
`;
const Shopify = styled.div``;
const Etsy = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;
export { Container, Shopify, Etsy };
