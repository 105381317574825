import React from 'react';
import {
  Card,
  CardImage,
  CardTitle,
  CardSubTitle,
} from 'styles/pages/news-room';

const NewsCard = () => (
  <Card
    onClick={() =>
      window.open(
        'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1645114453/Press_Release/SHIRTLY_-_User_Verification_Counterfeit_Policing_and_IP_protection_Policy_-_Final_wkiam9.pdf'
      )
    }
  >
    <CardImage
      src="https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1645114453/Press_Release/SHIRTLY_-_User_Verification_Counterfeit_Policing_and_IP_protection_Policy_-_Final_wkiam9.png"
      alt="news-room"
    />
    <CardTitle>
      User Verification, Counterfeit Policing and IP Protecion Policy
    </CardTitle>
    <CardSubTitle>17/02/2022</CardSubTitle>
  </Card>
);

export default NewsCard;
