import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Context from 'pages/admin-screen-printing/context';
import { StyledHeader } from 'styles/pages/admin-screen-printing';

const Header = () => {
  const { value, setValue } = Context();

  return (
    <StyledHeader>
      <h2>Screen Printing</h2>
      <AddCircleIcon
        onClick={() => {
          const rows = value?.rows;
          const data = {
            decorationTechnique: 'ScreenPrint',
            displayName: 'ScreenPrint',
            colorName: '',
            filmPrice: 0,
            decorationPrice: 0,
            minQuantity: 0,
            fulfillmentMinDays: 0,
            fulfillmentMaxDays: 0,
            icon: 'https://images.shirtly.com/image/upload/v1647441505/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/screen-printer_qrdewd.png',
          };

          setValue({
            ...value,
            rows: !rows ? [data] : [...rows, data],
          });
        }}
      />
    </StyledHeader>
  );
};

export default Header;
