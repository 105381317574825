import React from "react";
import SubHeader from "components/SubHeader";
import { ROUTES } from "shared/constants/routes";

const Header = () => (
  <SubHeader
    title="Browse"
    isDark
    links={[
      {
        title: ROUTES.HOME.TITLE,
        to: ROUTES.HOME.LINK,
      },
      {
        title: ROUTES.CATALOG.TITLE,
        to: ROUTES.CATALOG.LINK,
      },
    ]}
    currentPageTitle="Browse"
  />
);

export default Header;
