import styled from "styled-components";

const Container = styled.div`
  padding: 0 20px 20px;
  background: #000;
  display: flex;
  flex-direction: column;
`;
const TableContainer = styled.div`
  border-radius: 6px;
  margin: 8px 0;
  /* width: calc(100vw - 55px); */
  overflow: auto;
  /* @media (max-width: 650px) {
    width: calc(100vw - 38px);
  } */
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  .MuiAvatar-colorDefault {
    margin: 0 7px;
    background: #f7f8fa;
    cursor: pointer;

    :hover {
      background: #e8e8e8;
    }
  }
`;
const TableWrapper = styled.div`
  .MuiTableCell-stickyHeader {
    background-color: #fff;
    color: #5c92e2;
    font-family: Inter600;
    font-size: 14px;
    min-width: 150px;
  }
  .MuiTable-stickyHeader {
    min-width: 1000px;
  }
  .MuiTableCell-body {
    font-size: 14px;
    font-family: Inter400;
    background-color: #fff;
    .delete-icon {
      color: red;
    }
    .edit-icon {
      color: #709f0a;
    }
  }
`;
export { Container, TableContainer, IconWrapper, TableWrapper };
