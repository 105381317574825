import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _, { find } from "lodash";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { useSelector, useDispatch } from "react-redux";
import Stepper from "components/Stepper";
import Select from "components/Select";
import Button from "components/Button";
import ArtWork from "components/ArtWork";
import FileDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckBox from "components/CheckBox";
import { showLoader, hideLoader } from "actions/loader";
import { clearMocks } from "actions/designer";
import Categories from "pages/my-stores/products/product-creator/Categories";
import Products from "pages/my-stores/products/product-creator/Products";
import Colors from "pages/my-stores/products/product-creator/Colors";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { generateMocks } from "actions/orders";
import { getSteps } from "pages/my-stores/products/product-creator/data";
import { default as ImageComponent } from "components/Image";
import SaveAdvanceToolArtFiles from "pages/my-stores/products/product-creator/SaveAdvanceToolArtFiles";
import USAFlag from "assets/images/usa-flag.png";
import CADFlag from "assets/images/canada-flag.png";
import EUFlag from "assets/images/eu-flag.png";
import AUSFlag from "assets/images/aus-flag.jpeg";
import CheckIcon from "@material-ui/icons/Check";
import {
  getPrintTypeFromProductId,
  getDTGPlusFromProductId,
} from "selectors/products";

import {
  Container,
  CategoriesWrapper,
  ProductColorWrapper,
  FooterButton,
  ProductColors,
  Heading,
  ProductColorsBox,
  GeneratedMockups,
  BoxWrapper,
  DecoratingWrapper,
  CategoriesContainer,
  CategoryBox,
  IconWrapper,
} from "styles/pages/my-stores/products/productCreator";

const fulfillmentPlants = ["CA", "US", "EU", "AU", "NY"];

const onloadImageProcess = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const addImageProcess = (artwork) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = artwork?.coordinates?.width;
    canvas.height = artwork?.coordinates?.height;
    const image = new Image();

    image.onload = function (e) {
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const reSampledImage = new Image();
      reSampledImage.src = canvas.toDataURL();
      resolve(canvas.toDataURL());
    };
    image.src = artwork?.artwork;
  });
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ProductCreator = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const products = useSelector((state) => state?.products?.data);
  const [advanceToolArtFilesDialog, setAdvanceToolArtFilesDialog] =
    useState(false);
  const [mocks, setMocks] = useState([]);
  const [artwork, setArtWork] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const [state, setState] = useState({
    category: undefined,
    product: undefined,
    colors: [],
    plus: undefined,
    printTypeTechniqueSelected: true,
    selectedFulfillmentPant: fulfillmentPlants[0],
  });

  const steps = getSteps();

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const printType = getPrintTypeFromProductId(
    products,
    state?.product?.Product?.ID
  );

  const dtgPlusOptions = getDTGPlusFromProductId(
    products,
    state?.product?.Product?.ID
  );

  const moveToNextStep = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      const remainingDecorativeTechniques = products?.find(
        (p) => p?.Product?.ID === state?.product?.Product?.ID
      )?.RemainingDecorativeTechniques;

      setArtWork((prevState) => ({
        ...prevState,
        ...state?.product?.Product,
        remainingDecorativeTechniques: remainingDecorativeTechniques,
        printAreas: state?.product?.PrintAreas?.map((p, j) => ({
          ...p,
          selected: j === 0,
          printAreaMarketingImage:
            p?.printAreaName === "Front"
              ? state?.colors[0]?.Image
              : state?.colors[0][`${p?.printAreaName}Image`],
        })),
        ProductID: state?.product?.Product?.ID,
        plus: undefined,
      }));
      moveToNextStep();
    } else if (activeStep === 1) {
      let printAreasCanvasJSON = artwork?.printAreas?.filter(
        (p) => p?.canvasJSON
      );

      if (!!printAreasCanvasJSON?.length) {
        setAdvanceToolArtFilesDialog(true);
      } else {
        const printAreasArtwork = artwork?.printAreas?.filter(
          (p) => p?.artworkId
        );

        let mockImagesResponse = [];
        for (let i = 0; i < state?.colors?.length; i++) {
          const color = state?.colors[i];
          for (let j = 0; j < printAreasArtwork?.length; j++) {
            const row = printAreasArtwork[j];
            const {
              coordinates,
              artwork,
              designerSettings,
              printAreaName,
              artworkId,
            } = row;

            const { width, height, x = 0, y = 0 } = coordinates;
            const {
              left,
              top,
              width: designerSettingsWidth,
            } = designerSettings;

            const artworkImageSplit = artwork?.split("/upload/");
            const artImageDataUrl =
              artwork?.indexOf("base64") > 0
                ? await addImageProcess(row)
                : null;

            const isSub = state?.product?.Product?.PrintType === "SUB";

            const mainImageSplit =
              printAreaName === "Front"
                ? color?.Image?.split("/upload/")
                : color?.[`${printAreaName}Image`]?.split("/upload/");

            const payload = {
              mainImages: [
                `${mainImageSplit[0]}/upload/w_565,h_565/${mainImageSplit[1]}`,
              ],
              user: emailid,
              productName: state?.product?.Product?.DisplayName,
              artImage: artImageDataUrl
                ? artImageDataUrl
                : artworkImageSplit?.length === 1
                ? artworkImageSplit[0]
                : isSub
                ? `${artworkImageSplit[0]}/upload/w_${Math.round(
                    parseInt(width)
                  )},h_${Math.round(parseInt(height))}/${artworkImageSplit[1]}`
                : `${artworkImageSplit[0]}/upload/w_${Math.round(
                    width
                  )},h_${Math.round(height)}/${artworkImageSplit[1]}`,

              x: artwork
                ? isSub
                  ? Math.round(left / 2 + x + (565 - designerSettingsWidth) / 2)
                  : Math.round((565 - designerSettingsWidth) / 2 + x + left / 2)
                : 0,

              y: artwork ? (isSub ? parseInt(top + y) : top + y) : 0,
              artWidth: Math.round(parseInt(width)),
              artHeight: Math.round(parseInt(height)),
            };

            const response = await dispatch(generateMocks(payload));

            if (response?.error) {
              return;
            }

            mockImagesResponse = [...mockImagesResponse, ...response];

            if (artwork?.includes("/CustomGraphics/")) {
              await dispatch(clearMocks({ publicId: artworkId }));
            }
          }
        }

        if (mockImagesResponse?.length > 0) {
          for (let i = 0; i < mockImagesResponse?.length; i++) {
            const splitValue = mockImagesResponse[i].split("/upload/");
            mockImagesResponse[
              i
            ] = `${splitValue[0]}/upload/b_white,fl_attachment/${splitValue[1]}`;
          }

          setMocks(mockImagesResponse);
        }

        moveToNextStep();
      }
    }
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  useEffect(() => {
    const catId = query.get("catId");
    const productId = query.get("productId");

    if (
      !state?.category &&
      !state?.product &&
      catId &&
      products?.length &&
      productId
    ) {
      const product = products?.find(
        (p) => p?.Product?.ID === Number(productId)
      );

      if (catId && product) {
        setState({
          ...state,
          product,
          category: { CatId: Number(catId) },
        });
      }
    }
  }, [state, products]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <div>
              <Heading>Select Fulfillment Plant</Heading>

              <BoxWrapper>
                <CategoriesContainer>
                  {fulfillmentPlants?.map((f) => (
                    <CategoryBox
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedFulfillmentPant: f,
                          category: undefined,
                          product: undefined,
                          colors: [],
                          plus: undefined,
                          printTypeTechniqueSelected: true,
                        }))
                      }
                    >
                      <IconWrapper
                        style={{
                          background:
                            state?.selectedFulfillmentPant === f
                              ? "#2789C0"
                              : "#F4F4F4",
                        }}
                      >
                        {state?.selectedFulfillmentPant === f ? (
                          <CheckIcon />
                        ) : null}
                      </IconWrapper>

                      <img
                        src={
                          f === "CA"
                            ? CADFlag
                            : f === "US" || f === "NY"
                            ? USAFlag
                            : f === "AU"
                            ? AUSFlag
                            : EUFlag
                        }
                        alt="CAFlag"
                        width="100"
                        height="100"
                      />
                      <h3 className="country-name">{f} Plant</h3>
                    </CategoryBox>
                  ))}
                </CategoriesContainer>
              </BoxWrapper>
            </div>
            <CategoriesWrapper>
              <div>
                <Heading>Select Category</Heading>
                <BoxWrapper>
                  <Categories
                    categoryId={state?.category?.CatId}
                    onChange={(state) =>
                      setState((prevState) => ({
                        ...prevState,
                        ...state,
                      }))
                    }
                  />
                </BoxWrapper>
              </div>

              {state?.category?.CatId && (
                <div>
                  <Heading>Select Product</Heading>
                  <BoxWrapper>
                    <Products
                      value={state?.product?.Product?.ID}
                      CatId={state?.category?.CatId}
                      selectedFulfillmentPant={state?.selectedFulfillmentPant}
                      onChange={(product) => {
                        setState({
                          ...state,
                          product: {
                            ...product,
                            RemainingDecorativeTechniques:
                              product?.RemainingDecorativeTechniques?.map(
                                (x) => ({
                                  ...x,
                                  selected: false,
                                })
                              ),
                          },
                          colors: [],
                        });
                      }}
                    />
                  </BoxWrapper>
                </div>
              )}
            </CategoriesWrapper>
            {state?.product && (
              <ProductColorWrapper
                premium={state?.product?.Product?.premiumProduct}
              >
                <Heading>
                  Select Product Colors &nbsp;-&nbsp;
                  <span
                    onClick={() =>
                      setState({ ...state, colors: state?.product?.Colors })
                    }
                  >
                    Select All Product Colors
                  </span>
                </Heading>

                <BoxWrapper>
                  <Colors
                    colors={state?.colors}
                    product={state?.product}
                    onChange={(colors) => setState({ ...state, colors })}
                  />
                </BoxWrapper>
              </ProductColorWrapper>
            )}
          </>
        );
      case 1:
        return (
          <>
            <div>
              <DecoratingWrapper>
                <h3 className="title">
                  Select Decorating Technique for Mock-Up
                </h3>

                <div className="decoration-techniques">
                  {printType && (
                    <CheckBox
                      label={printType}
                      checked={
                        !!state?.product?.RemainingDecorativeTechniques?.length
                          ? state?.printTypeTechniqueSelected
                          : state?.printTypeTechniqueSelected || !state?.plus
                      }
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          printTypeTechniqueSelected: true,
                          plus: undefined,
                          product: {
                            ...state?.product,
                            RemainingDecorativeTechniques:
                              state?.product?.RemainingDecorativeTechniques?.map(
                                (x) => ({
                                  ...x,
                                  selected: false,
                                })
                              ),
                          },
                        }));

                        setArtWork((prevState) => ({
                          ...prevState,
                          printAreas: state?.product?.PrintAreas?.map(
                            (p, i) => ({
                              ...p,
                              artwork: null,
                              artworkId: null,
                              coordinates: {},
                              selected: i === 0,
                              printAreaMarketingImage:
                                p?.printAreaName === "Front"
                                  ? state?.colors[0]?.Image
                                  : state?.colors[0][
                                      `${p?.printAreaName}Image`
                                    ],
                            })
                          ),
                        }));
                      }}
                    />
                  )}

                  {dtgPlusOptions?.length ? (
                    <>
                      <CheckBox
                        label="TUFF PRINT TRANSFER"
                        checked={!!state.plus}
                        onChange={(v) => {
                          const p = _.find(
                            products,
                            (p) =>
                              p?.Product?.ID === state?.product?.Product?.ID
                          );

                          setState({
                            ...state,
                            // plus: state?.plus ? undefined : p?.DTGPlus,
                            plus: true,
                            printTypeTechniqueSelected: false,
                            product: {
                              ...state?.product,
                              RemainingDecorativeTechniques:
                                state?.product?.RemainingDecorativeTechniques?.map(
                                  (x) => ({
                                    ...x,
                                    selected: false,
                                  })
                                ),
                            },
                          });

                          const additionalTechniquesArtSettings =
                            state?.product?.AdditionalTechniquesArtSettings;

                          const plusData =
                            additionalTechniquesArtSettings?.find(
                              (p) => p?.name === "Plus"
                            );

                          if (!!plusData) {
                            setArtWork((prevState) => ({
                              ...prevState,
                              printAreas: plusData?.artSettings?.map(
                                (p, i) => ({
                                  ...p,
                                  artwork: null,
                                  artworkId: null,
                                  coordinates: {},
                                  designerSettings: p?.areaSettings,
                                  printAreaName: p?.areaName,
                                  selected: i === 0,
                                  printAreaMarketingImage:
                                    p?.areaName === "Front"
                                      ? state?.colors[0]?.Image
                                      : state?.colors[0][`${p?.areaName}Image`],
                                })
                              ),
                            }));
                          }
                        }}
                      />
                    </>
                  ) : null}
                </div>

                <div className="decoration-techniques">
                  {state?.product?.RemainingDecorativeTechniques?.map((v) => {
                    const selected =
                      _.find(
                        state?.product?.RemainingDecorativeTechniques,
                        (c) => c.DisplayName === v?.DisplayName
                      )?.selected || false;

                    return (
                      <CheckBox
                        key={v?.DisplayName}
                        label={v?.DisplayName}
                        checked={selected}
                        onChange={(value) => {
                          setState((prevState) => ({
                            ...prevState,
                            printTypeTechniqueSelected: undefined,
                            plus: undefined,
                            product: {
                              ...state?.product,
                              RemainingDecorativeTechniques:
                                state?.product?.RemainingDecorativeTechniques?.map(
                                  (x) => ({
                                    ...x,
                                    selected:
                                      x?.DisplayName !== v?.DisplayName
                                        ? false
                                        : value,
                                  })
                                ),
                            },
                          }));

                          const additionalTechniquesArtSettings =
                            state?.product?.AdditionalTechniquesArtSettings;

                          const data = additionalTechniquesArtSettings?.find(
                            (p) => p?.name === v?.DisplayName
                          );

                          if (!!data) {
                            setArtWork((prevState) => ({
                              ...prevState,
                              printAreas: data?.artSettings?.map((p, i) => ({
                                ...p,
                                artwork: null,
                                artworkId: null,
                                coordinates: {},
                                designerSettings: p?.areaSettings,
                                printAreaName: p?.areaName,
                                selected: i === 0,
                                printAreaMarketingImage:
                                  p?.areaName === "Front"
                                    ? state?.colors[0]?.Image
                                    : state?.colors[0][`${p?.areaName}Image`],
                              })),
                            }));
                          }
                        }}
                      />
                    );
                  })}
                </div>

                {!!state?.plus && (
                  <div className="decoration-techniques">
                    <Select
                      options={dtgPlusOptions}
                      label="TUFF PRINT TRANSFER Process"
                      value={state?.dtgPlusProcess || { DisplayName: "" }}
                      valueKey="DisplayName"
                      labelKey="DisplayName"
                      onChange={(dtgProcessName) => {
                        setState((prevState) => ({
                          ...prevState,
                          dtgPlusProcess: dtgPlusOptions.find(
                            (c) => c?.DisplayName === dtgProcessName
                          ),
                        }));
                        setArtWork((prevState) => ({
                          ...prevState,
                          printAreas: prevState?.printAreas?.map((p) =>
                            p?.selected
                              ? {
                                  ...p,
                                  artwork: null,
                                  artworkId: null,
                                  coordinates: {},
                                  canvasJSON: null,
                                }
                              : p
                          ),
                        }));
                      }}
                    />

                    {state?.dtgPlusProcess?.Colors && (
                      <div className="color">
                        {state?.dtgPlusProcess?.Colors?.find(
                          (c) => c?.selected
                        ) && (
                          <img
                            alt="color"
                            src={
                              state?.dtgPlusProcess?.Colors?.find(
                                (c) => c?.selected
                              )?.ColorImagePath
                            }
                          />
                        )}
                        <Select
                          options={state?.dtgPlusProcess?.Colors}
                          label="TUFF PRINT TRANSFER Color"
                          value={
                            state?.dtgPlusProcess?.Colors?.find(
                              (c) => c?.selected
                            ) || { ColorName: "" }
                          }
                          valueKey="ColorName"
                          labelKey="ColorName"
                          onChange={async (dtgColorName) => {
                            setState((prevState) => ({
                              ...prevState,
                              dtgPlusProcess: {
                                ...prevState?.dtgPlusProcess,
                                Colors: prevState?.dtgPlusProcess?.Colors?.map(
                                  (c) => ({
                                    ...c,
                                    selected: c?.ColorName === dtgColorName,
                                  })
                                ),
                              },
                            }));
                            setArtWork((prevState) => ({
                              ...prevState,
                              printAreas: prevState?.printAreas?.map((p) =>
                                p?.selected
                                  ? {
                                      ...p,
                                      artwork: null,
                                      artworkId: null,
                                      coordinates: {},
                                      canvasJSON: null,
                                    }
                                  : p
                              ),
                            }));
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </DecoratingWrapper>
              <ArtWork
                printType={
                  state?.printTypeTechniqueSelected
                    ? printType
                    : state?.plus
                    ? "Plus"
                    : state?.product?.RemainingDecorativeTechniques?.find(
                        (p) => p?.selected
                      )?.DisplayName
                }
                setOrderLine={setArtWork}
                product={state?.product}
                plus={state?.plus}
                dtgPlusProcess={state?.dtgPlusProcess}
                onDrop={async (file) => {
                  const greyOut = state?.product?.ArtworkSettings?.greyOut;
                  let secureUrl = file.secure_url;
                  let secureUrlSplit = secureUrl.split("/upload/");
                  const API_ENDPOINT =
                    "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

                  if (secureUrlSplit?.length === 1) {
                    dispatch(showLoader());
                    const fileData = new FormData();
                    fileData.append("file", secureUrl);
                    fileData.append("upload_preset", "tnedst8q");
                    fileData.append(
                      "folder",
                      `Shirtly/${emailid}/CustomGraphics/${file?.user}`
                    );
                    const data = await fetch(API_ENDPOINT, {
                      method: "post",
                      body: fileData,
                    });
                    const json = await data?.json();
                    secureUrl = json.secure_url;
                    await onloadImageProcess(secureUrl);
                    secureUrlSplit = secureUrl.split("/upload/");
                    file.public_id = json?.public_id;
                    file.height = json?.height;
                    file.width = json?.width;
                    dispatch(hideLoader());
                  }

                  if (greyOut) {
                    if (!!secureUrlSplit?.length) {
                      secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:aaa9ad/${secureUrlSplit[1]}`;

                      dispatch(showLoader());
                      await fetch(secureUrl);
                      dispatch(hideLoader());
                    }
                  } else if (
                    !!state?.plus &&
                    !!state?.dtgPlusProcess &&
                    !!state?.dtgPlusProcess?.Colors?.find((c) => c?.selected)
                  ) {
                    const selectedColor = state?.dtgPlusProcess?.Colors?.find(
                      (c) => c?.selected
                    );

                    if (secureUrl) {
                      const canvas = document.createElement("canvas");
                      const ctx = canvas.getContext("2d");
                      const secureUrlSplit = secureUrl.split("/upload/");

                      if (!!secureUrlSplit?.length) {
                        secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;
                      }

                      dispatch(showLoader());

                      const img1 = await onloadImageProcess(secureUrl);
                      const img2 = await onloadImageProcess(
                        selectedColor?.ColorImagePath
                      );
                      canvas.width = img1.width * 0.5;
                      canvas.height = img1.height * 0.5;
                      ctx.fillStyle = ctx.createPattern(img2, "repeat");
                      // fill canvas with pattern
                      ctx.fillRect(0, 0, canvas.width, canvas.height);
                      // use blending mode multiply
                      ctx.globalCompositeOperation = "multiply";
                      // draw sofa on top
                      ctx.drawImage(
                        img1,
                        0,
                        0,
                        img1.width * 0.5,
                        img1.height * 0.5
                      );
                      // change composition mode
                      ctx.globalCompositeOperation = "destination-in";
                      // draw to cut-out sofa
                      ctx.drawImage(
                        img1,
                        0,
                        0,
                        img1.width * 0.5,
                        img1.height * 0.5
                      );
                      const dataURL = canvas.toDataURL("image/png");

                      const fileData = new FormData();
                      fileData.append("file", dataURL);
                      fileData.append("upload_preset", "tnedst8q");
                      fileData.append(
                        "folder",
                        `Shirtly/${emailid}/PlusArtFiles`
                      );
                      const data = await fetch(API_ENDPOINT, {
                        method: "post",
                        body: fileData,
                      });
                      dispatch(hideLoader());
                      const json = await data?.json();
                      secureUrl = json?.secure_url;
                      file.public_id = json?.public_id;
                    }
                  }

                  const selectedPrintArea = artwork?.printAreas?.find(
                    (p) => p?.selected
                  );

                  const designerSettings = selectedPrintArea?.designerSettings;

                  const ArtWorkCoordinates =
                    selectedPrintArea?.coordinates || {};

                  const { width: fileWidth, height: fileHeight } = file;

                  const { width: designedWidth, height: designedHeight } =
                    designerSettings;

                  if (designedWidth > designedHeight) {
                    const aspectRatio = fileWidth / fileHeight;
                    if (fileWidth > fileHeight) {
                      if (fileWidth > designedWidth) {
                        ArtWorkCoordinates.height = Math.round(
                          designedWidth / aspectRatio
                        );
                        ArtWorkCoordinates.width = designedWidth;

                        if (ArtWorkCoordinates.height > designedHeight) {
                          ArtWorkCoordinates.height = designedHeight;
                        }
                      } else {
                        ArtWorkCoordinates.height = designedHeight;
                        ArtWorkCoordinates.width = Math.round(
                          designedHeight * aspectRatio
                        );
                      }
                    } else {
                      ArtWorkCoordinates.height = designedHeight;
                      ArtWorkCoordinates.width = Math.round(
                        designedHeight * aspectRatio
                      );
                    }
                  } else if (
                    fileWidth <= designedWidth &&
                    fileHeight <= designedHeight
                  ) {
                    ArtWorkCoordinates.width = fileWidth;
                    ArtWorkCoordinates.height = fileHeight;
                  } else if (fileWidth > fileHeight) {
                    const aspectRatio = fileWidth / fileHeight;
                    ArtWorkCoordinates.width = designedWidth;
                    ArtWorkCoordinates.height = Math.round(
                      designedWidth / aspectRatio
                    );
                  } else if (fileWidth < fileHeight) {
                    const aspectRatio = fileWidth / fileHeight;

                    if (designedHeight * aspectRatio > designedWidth) {
                      ArtWorkCoordinates.width = designedWidth;
                      ArtWorkCoordinates.height = Math.round(
                        designedWidth / aspectRatio
                      );
                    } else {
                      ArtWorkCoordinates.height = designedHeight;
                      ArtWorkCoordinates.width = Math.round(
                        designedHeight * aspectRatio
                      );
                    }
                  } else if (fileWidth === fileHeight) {
                    if (designedWidth > designedHeight) {
                      ArtWorkCoordinates.height = designedHeight;
                      ArtWorkCoordinates.width = designedHeight;
                    } else {
                      ArtWorkCoordinates.height = designedWidth;
                      ArtWorkCoordinates.width = designedWidth;
                    }
                  }

                  setArtWork((prevState) => ({
                    ...prevState,
                    printAreas: prevState?.printAreas?.map((p) =>
                      p?.selected
                        ? {
                            ...p,
                            artwork: secureUrl,
                            artworkId: file.public_id,
                            coordinates: ArtWorkCoordinates,
                          }
                        : p
                    ),
                  }));
                }}
                onRemove={async () => {
                  const selectedPrintArea = artwork?.printAreas?.find(
                    (p) => p?.selected
                  );

                  if (
                    selectedPrintArea?.artworkId?.includes("/CustomGraphics/")
                  ) {
                    await dispatch(
                      clearMocks({
                        publicId: selectedPrintArea?.artworkId,
                      })
                    );
                  }

                  setArtWork((prevState) => ({
                    ...prevState,
                    printAreas: prevState?.printAreas?.map((p) =>
                      p?.selected
                        ? {
                            ...p,
                            artwork: null,
                            artworkId: null,
                            coordinates: {},
                          }
                        : p
                    ),
                  }));
                }}
                setCoordinates={(coordinates) =>
                  setArtWork((prevState) => ({
                    ...prevState,
                    printAreas: prevState?.printAreas?.map((p) =>
                      p?.selected
                        ? {
                            ...p,
                            coordinates,
                          }
                        : p
                    ),
                  }))
                }
                orderLine={artwork}
                onPreview={(previewImage) =>
                  setArtWork((prevState) => ({
                    ...prevState,
                    printAreas: prevState?.printAreas?.map((p) =>
                      p?.selected
                        ? {
                            ...p,
                            previewImage,
                          }
                        : p
                    ),
                  }))
                }
                onFabricDrop={(canvasJSON) => {
                  setArtWork((prevState) => ({
                    ...prevState,
                    printAreas: prevState?.printAreas?.map((p) =>
                      p?.selected
                        ? {
                            ...p,
                            canvasJSON,
                          }
                        : p
                    ),
                  }));
                }}
                onFabricRemove={async () => {
                  const selectedPrintArea = artwork?.printAreas?.find(
                    (p) => p?.selected
                  );

                  if (selectedPrintArea?.canvasJSON) {
                    const objects = selectedPrintArea?.canvasJSON?.objects;
                    for (let i = 0; i < objects?.length; i++) {
                      const element = objects[i];
                      if (element?.type !== "image") {
                        continue;
                      }

                      if (element?.src?.includes("/CustomGraphics/")) {
                        const publicId = decodeURIComponent(
                          `Shirtly/${element?.src?.split("/Shirtly/")[1]}`
                        );
                        await dispatch(
                          clearMocks({
                            publicId: publicId?.split(".png")[0],
                          })
                        );
                      }
                    }
                  }

                  setArtWork((prevState) => ({
                    ...prevState,
                    printAreas: prevState?.printAreas?.map((p) =>
                      p?.selected
                        ? {
                            ...p,
                            canvasJSON: null,
                          }
                        : p
                    ),
                  }));
                }}
              />
              {advanceToolArtFilesDialog && (
                <SaveAdvanceToolArtFiles
                  open={advanceToolArtFilesDialog}
                  setMocks={setMocks}
                  artwork={artwork}
                  colors={state?.colors}
                  product={state?.product}
                  moveToNextStep={moveToNextStep}
                  setOpen={() => setAdvanceToolArtFilesDialog(false)}
                />
              )}
            </div>

            <div>
              <ProductColors>
                {state?.colors?.map((color) => (
                  <ProductColorsBox key={`Colors${color.ID}`}>
                    <img
                      alt="color"
                      src={color?.Image}
                      width="100%"
                      onClick={() => {
                        setArtWork({
                          ...artwork,
                          // MarketingImagePath: color?.Image,
                          // BackImage: color?.BackImage,
                          printAreas: artwork?.printAreas?.map((p) => ({
                            ...p,
                            printAreaMarketingImage:
                              p?.printAreaName === "Front"
                                ? color?.Image
                                : color[`${p?.printAreaName}Image`],
                          })),
                        });
                      }}
                    />
                    {state?.colors?.length > 1 && (
                      <DeleteOutlineIcon
                        color="error"
                        onClick={() => {
                          const filterData = _.filter(
                            state?.colors,
                            (c) => c.ID !== color.ID
                          );

                          setState({
                            ...state,
                            colors: filterData,
                          });

                          setArtWork({
                            ...artwork,
                            // MarketingImagePath: filterData[0]?.Image,
                            // BackImage: filterData[0]?.BackImage,
                            printAreas: artwork?.printAreas?.map((p) => ({
                              ...p,
                              printAreaMarketingImage:
                                p?.printAreaName === "Front"
                                  ? filterData[0]?.Image
                                  : filterData[0]?.[`${p?.printAreaName}Image`],
                            })),
                          });
                        }}
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "5px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </ProductColorsBox>
                ))}
              </ProductColors>
            </div>
          </>
        );

      default:
        return (
          <ProductColorWrapper>
            <GeneratedMockups>
              {mocks?.map((mock, index) => (
                <a key={`Mocks${index}`} href={mock} download>
                  <ImageComponent src={mock} imageStyle={{ width: "200px" }} />
                  <FileDownloadIcon className="download-icon" />
                </a>
              ))}
            </GeneratedMockups>
          </ProductColorWrapper>
        );
    }
  };

  return (
    <Container>
      <Stepper activeStep={activeStep} steps={steps} />

      {getStepContent(activeStep)}

      <FooterButton>
        <Button disabled={activeStep === 0} onClick={handleBack} title="Back" />

        {activeStep === 2 ? (
          <Button
            title="Download All"
            onClick={() => {
              var zip = new JSZip();
              var count = 0;

              mocks?.forEach(function (url, index) {
                var filename = `${artwork?.Name}${index}.png`;

                JSZipUtils.getBinaryContent(url, function (err, data) {
                  if (err) {
                    throw err;
                  }

                  zip.file(filename, data, { binary: true });
                  count++;

                  if (count == mocks.length) {
                    zip
                      .generateAsync({ type: "blob" })
                      .then(function callback(blob) {
                        saveAs(blob, `Mockups(${artwork?.Name}).zip`);
                      });
                  }
                });
              });
            }}
          />
        ) : (
          <Button
            title="Next"
            disabled={
              activeStep === 0
                ? !state?.colors?.length
                : activeStep === 1
                ? !artwork?.printAreas?.find((p) => p?.artworkId) &&
                  !artwork?.printAreas?.find(
                    (p) =>
                      p?.canvasJSON?.objects?.length &&
                      !p?.canvasJSON?.outOfBond
                  )
                : true
            }
            onClick={handleNext}
          />
        )}
      </FooterButton>
    </Container>
  );
};

export default ProductCreator;
