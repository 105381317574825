import styled, { css } from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  margin: 0 0 0 auto;
  min-height: 100vh;
  height: auto;
  right: 0;
  width: 90vw;
  overflow: auto;
  transition: all 1000ms ease-out !important;
  padding: 12px;
  position: relative;
  padding-top: 0;
  @media (min-width: 768px) {
    width: 50vw;
  }
  @media (min-width: 1200px) {
    width: 35vw;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  padding-top: 15px;
  padding-bottom: 10px;
  > svg {
    cursor: pointer;
  }
  > h1 {
    font-size: 16px;
    text-transform: uppercase;
    margin: 5px 0px 10px;
    font-weight: 600;
    color: ${COLORS.BODY_TEXT};
  }
`;
const Step = styled.div`
  margin: 13px 0;
`;
const Heading = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  margin: 15px 0px;
  font-weight: 600;
  color: ${COLORS.BODY_TEXT};
  text-align: center;
  cursor: pointer;
  span {
    color: ${COLORS.GREEN};
  }

  ${(props) =>
    !props.selected &&
    css`
      border: 1px solid rgb(243, 243, 243);
      padding: 5px 10px;
      margin: 1em auto;
      font-size: 13px;
      text-transform: capitalize;
      box-shadow: rgb(217, 217, 217) 4px 4px 10px,
        rgb(255, 255, 255) -4px -4px 10px;
    `};
`;

const CardWrapper = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 0.3fr));
  width: 100%;
  gap: 20px;
  grid-gap: 20px;
`;
const Card = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
  transition: all ease-out 0.25s;
  text-align: center;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  p {
    padding: 9px;
    margin: 0;
    font-weight: bold;
    color: ${COLORS.BODY_TEXT};
    transition: all ease-out 0.25s;
    display: grid;
    align-items: center;
    height: 100%;
  }
  :hover {
    transform: scale(1.1);
    p {
      color: ${COLORS.WHITE};
      background: ${COLORS.DARK_BLUE};
    }
  }

  ${(props) =>
    props.active &&
    css`
      p {
        color: ${COLORS.WHITE};
        background: ${COLORS.DARK_BLUE};
      }
    `};
`;

const SizeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  justify-items: center;
  justify-content: center;
  column-gap: 15px;
  row-gap: 15px;
  @media (min-width: 992px) {
    max-width: 60%;
    margin: 0 auto;
  }
`;

const Size = styled.div`
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
  cursor: pointer;
  border-radius: 4px;
  min-width: 100px;
  min-height: 100px;
  display: grid;
  align-items: center;
  justify-items: center;
  transition: all ease-out 0.25s;
  padding: 3px;
  font-weight: 700;

  ${(props) =>
    props.active &&
    css`
      background: ${COLORS.DARK_BLUE};
      color: ${COLORS.WHITE};
    `};
`;

const ColorWrapper = styled.ul`
  display: grid;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;
  padding-left: 0;
  grid-template-columns: repeat(auto-fit, minmax(50px, 50px));
  @media (min-width: 992px) {
    max-width: 60%;
    margin: 0 auto;
  }
  li {
    list-style: none;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all ease-out 0.25s;
    box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
    transition: all ease-out 0.25s;
    border-radius: 4px;
    :hover {
      border: 2px solid #adaeb0;
    }
  }
`;

const Selection = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #c7c8c9;
  margin-top: 20px;
  padding-top: 20px;
`;

export {
  Container,
  Step,
  Heading,
  CardWrapper,
  Card,
  SizeWrapper,
  Size,
  ColorWrapper,
  Selection,
  Header,
};
