import React from "react";
import { useSelector } from "react-redux";
import Button from "components/Button";
import TextField from "components/TextField";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import {
  DtgWrapper,
  ColorWrapper,
} from "styles/pages/order-details/itemsSummary";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const openCloudinaryWidget = (folder, clientAllowedFormats, cb) => {
  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "big-oven-tees-inc",
      show_powered_by: false,
      uploadPreset: "tnedst8q",
      sources: ["local", "camera", "url"],
      folder,
      clientAllowedFormats,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        cb(result?.info?.secure_url);
        myWidget.close();
      }
    }
  );
  myWidget.open();
};

const AOP = ({ setOrderLine, selectedOrderLine, aopVectorFile }) => {
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  return (
    <DtgWrapper>
      {!!selectedOrderLine?.templateUrl && (
        <Button
          title="Download Template File"
          onClick={(e) => {
            window.open(selectedOrderLine?.templateUrl);
          }}
        />
      )}
      <ColorWrapper>
        {aopVectorFile ? (
          <InsertDriveFileIcon style={{ color: "#5c92e2" }} />
        ) : (
          <ErrorOutlineIcon style={{ color: "orange" }} />
        )}

        <TextField
          fullWidth={true}
          label="Vector File (.eps) or PDF File (.pdf)"
          disabled={true}
          size="small"
          value={aopVectorFile || ""}
          onChange={(aopVectorFile) =>
            setOrderLine({
              ...selectedOrderLine,
              aopVectorFile,
            })
          }
        />
        <CloudUploadIcon
          onClick={() => {
            openCloudinaryWidget(
              `Shirtly/${emailid}/AOPMockFiles`,
              ["eps", "pdf"],
              (secureUrl) => {
                setOrderLine({
                  ...selectedOrderLine,
                  aopVectorFile: secureUrl,
                });
              }
            );
          }}
        />
      </ColorWrapper>
    </DtgWrapper>
  );
};

export default AOP;
