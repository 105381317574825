import React from 'react';
import Slider from 'react-slick';
import {
  Container,
  Wrapper,
  CarouselWrapper,
  CarouselImageWrapper,
  CarouselInfoWrapper,
  CarouselTitle,
  CarouselSubTitle,
  CarouselDescription,
} from 'styles/pages/home/fulfillment';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const FulfillMent = ({ data: d = {} }) => {
  const { components } = d;
  const { BackgroundColor = '', Cards = [], Title = '' } = components || {};

  return (
    <Container
      style={{ backgroundColor: BackgroundColor }}
      id='product-bundling'>
      <Wrapper>
        <Slider {...settings}>
          {Cards?.map((c, i) => (
            <CarouselWrapper key={i}>
              <CarouselImageWrapper>
                <img src='fulfillment' src={c?.CardImage} />
              </CarouselImageWrapper>
              <CarouselInfoWrapper>
                <CarouselTitle>{Title}</CarouselTitle>
                <CarouselSubTitle>
                  <div>{c?.CardTitle}</div>
                  <div>{c?.CardSubTitle}</div>
                </CarouselSubTitle>
                <CarouselDescription>{c?.CardDescription}</CarouselDescription>
              </CarouselInfoWrapper>
            </CarouselWrapper>
          ))}
        </Slider>
      </Wrapper>
    </Container>
  );
};

export default FulfillMent;
