import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import PatentNumber from 'pages/ip-program/PatentNumber';
import PatentProofLink from 'pages/ip-program/PatentProofLink';
import CountriesSelect from 'pages/ip-program/CountriesSelect';
import SubmitArtFiles from 'pages/ip-program/SubmitArtFiles';
import UploadCheckbox from 'pages/ip-program/UploadCheckbox';
import { AddArtWork, Fields } from 'styles/pages/ip-program';

const CustomDialog = ({ open, setOpen, reload }) => {
  const [state, setState] = useState(undefined);

  return (
    <Dialog open={open} onClose={setOpen} maxWidth='md' fullWidth>
      <ModalWrapper className='about-us'>
        <DialogTitle>Upload Artwork</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <AddArtWork>
              <Fields>
                <CountriesSelect state={state} setState={setState} />
                <PatentNumber state={state} setState={setState} />
              </Fields>
              <PatentProofLink state={state} setState={setState} />
              <UploadCheckbox state={state} setState={setState} />
              <SubmitArtFiles state={state} reload={reload} />
            </AddArtWork>
          </DialogContentText>
        </DialogContent>
      </ModalWrapper>
    </Dialog>
  );
};

export default CustomDialog;
