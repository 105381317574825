import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from 'components/Tabs';
import SubHeader from 'components/SubHeader';
import AddSection from 'pages/web-pages/AddSection';
import Dashboard from 'pages/web-pages/Dashboard';
import { getAllWebPages, getWebPage } from 'actions/webPages';
import { ROUTES } from 'shared/constants/routes';
import { Container } from 'styles/pages/web-pages';

const WebPages = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState(-1);
  const [webPageData, setWebPageData] = useState(null);

  useEffect(() => {
    const getWebPagesData = async () => {
      const data = await dispatch(getAllWebPages(token));

      setTabs(() => data);
      setTab(() => 0);
    };
    getWebPagesData();
  }, [dispatch]);

  const getWebPageData = async () => {
    const data = await dispatch(getWebPage({ page: tabs[tab]?.name }));
    setWebPageData(() => data);
  };

  useEffect(() => {
    if (tabs && tabs[tab]) {
      getWebPageData();
    }
  }, [tab, dispatch]);

  let tabsOptions = [];

  if (tabs?.length > 0) {
    for (let i = 0; i < tabs.length; i++) {
      tabsOptions.push({
        title: tabs[i].name,
        children:
          tabs[i].name === 'Dashboard' ? (
            <Dashboard data={webPageData} refresh={getWebPageData} />
          ) : (
            <AddSection data={webPageData} />
          ),
      });
    }
  }

  return (
    <Container>
      <SubHeader
        title={ROUTES.WEB_PAGES.TITLE}
        links={[
          {
            title: ROUTES.HOME.TITLE,
            to: ROUTES.HOME.LINK,
          },
        ]}
        currentPageTitle={ROUTES.WEB_PAGES.TITLE}
      />

      <Tabs tabs={tabsOptions} onTabChange={(v) => setTab(v)} />
    </Container>
  );
};

export default WebPages;
