import React, { useState } from 'react';
import _ from 'lodash';
import Button from 'components/Button';
import LoginDialog from 'pages/home/Header/LoginDialog';
import SignUpDialog from 'pages/home/Header/SignUpDialog';
import ForgotPasswordDialog from 'pages/home/Header/ForgotPassword';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {
  Container,
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
  RightMenu,
  LeftMenu,
} from 'styles/pages/home/header';

const Header = ({
  loginDialog,
  signUpDialog,
  forgotPasswordDialog,
  toggleLoginDialog,
  toggleSignUpDialog,
  toggleForgotPasswordDialog,
  data = {},
  showNavMenu = true,
}) => {
  const [click, setClick] = useState(false);
  const [ipProgram, setIpProgram] = useState(false);
  const handleClick = () => setClick(!click);

  const { components } = data;

  const { BackgroundColor, Buttons = [], Image } = components || {};

  if (!Image) {
    return null;
  }

  return (
    <Container style={{ backgroundColor: BackgroundColor }}>
      <Nav>
        <NavbarContainer>
          <LeftMenu>
            <NavLogo>
              <img src={Image} alt='logo' />
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <CloseIcon /> : <MenuIcon />}
            </MobileIcon>
          </LeftMenu>
          {showNavMenu ? (
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLink href='#products-pricing'>Products & Pricing</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='#whats-new'>What's new</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='#how-it-works'>How it Works</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='#key-features'>Key Features</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='#product-bundling'>Product Bundling</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setIpProgram(true);
                    toggleSignUpDialog();
                  }}>
                  IP Portal
                </NavLink>
              </NavItem>
            </NavMenu>
          ) : (
            <NavMenu />
          )}
          {showNavMenu && (
            <RightMenu>
              {Buttons?.map((b, i) =>
                b?.Show ? (
                  <Button
                    key={`button${i}`}
                    title={b?.ButtonName}
                    onClick={() => {
                      if (b?.Modal === 'SignIn') {
                        _.isFunction(toggleLoginDialog) && toggleLoginDialog();
                      } else if (b?.Modal === 'SignUp') {
                        _.isFunction(toggleSignUpDialog) &&
                          toggleSignUpDialog();
                      }
                    }}
                  />
                ) : null
              )}
            </RightMenu>
          )}
        </NavbarContainer>
      </Nav>

      {loginDialog && (
        <LoginDialog
          open={loginDialog}
          onClose={() => toggleLoginDialog()}
          showSignUpModal={(ip) => {
            setIpProgram(ip);
            toggleLoginDialog();
            toggleSignUpDialog();
          }}
          forgotPasswordDialog={() => {
            toggleLoginDialog();
            toggleForgotPasswordDialog();
          }}
        />
      )}
      {signUpDialog && (
        <SignUpDialog
          open={signUpDialog}
          ipProgram={ipProgram}
          onClose={() => toggleSignUpDialog()}
          showLoginModal={() => {
            toggleSignUpDialog();
            toggleLoginDialog();
          }}
        />
      )}

      {forgotPasswordDialog && (
        <ForgotPasswordDialog
          open={forgotPasswordDialog}
          onClose={() => toggleForgotPasswordDialog()}
        />
      )}
    </Container>
  );
};

export default Header;
