import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'components/Pagination';
import ArtworksLibraryItem from 'components/ArtWork/ArtworksLibraryItem';
import { getAdvancedDesigns, deleteAdvanceArtFiles } from 'actions/designer';
import {
  ArtworksLibrary,
  PaginationWrapper,
} from 'styles/components/ArtWork/browseLibrary';

const take = 20;

const AdvanceArtFiles = ({
  toggle,
  onDrop,
  email,
  viewOnly = false,
  scroll = true,
  verify = false,
  advancedLibraryFiles,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  const fetchData = async (skip = 0) => {
    const response = await dispatch(
      getAdvancedDesigns({
        user: email || emailid,
        Skip: skip,
        Take: take,
      })
    );

    setData(response);
  };

  useEffect(() => {
    fetchData((currentPage - 1) * take);
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (!advancedLibraryFiles?.artFiles) {
      fetchData();
    } else {
      setData({ artFiles: advancedLibraryFiles?.artFiles, artCount: 0 });
    }
  }, [advancedLibraryFiles]);

  const libraryFiles = data?.artFiles?.map((d) => ({
    ...d?.AdvanceArtData?.CloudinaryObject,
    FileName: d?.FileName,
  }));

  return (
    <>
      <ArtworksLibrary scroll={scroll}>
        <div>
          {!!libraryFiles?.length && (
            <ArtworksLibraryItem
              verify={verify}
              libraryFiles={libraryFiles || []}
              onSelect={(artwork, index) => {
                onDrop(data?.artFiles[index]?.AdvanceArtData?.CanvasJsonObject);
                if (!viewOnly) {
                  toggle();
                }
              }}
              viewOnly={viewOnly}
              onDeleteImage={async (publicId) => {
                const selectedArtwork = data?.artFiles?.find(
                  (d) =>
                    d?.AdvanceArtData?.CloudinaryObject?.public_id === publicId
                );
                const payload = {
                  ArtId: selectedArtwork?.ArtId,
                  user: emailid,
                };

                const artwork = await dispatch(deleteAdvanceArtFiles(payload));
                if (artwork.error) {
                  return;
                }
                fetchData(currentPage - 1);
              }}
            />
          )}
        </div>
      </ArtworksLibrary>
      <PaginationWrapper>
        <Pagination
          totalCount={data?.artCount}
          take={take}
          page={currentPage}
          onChange={(e, v) => setCurrentPage(v)}
        />
      </PaginationWrapper>
    </>
  );
};

export default AdvanceArtFiles;
