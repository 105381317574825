import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { showLoader, hideLoader } from "actions/loader";
import { fabric } from "fabric";
import ToolPrintAreas from "components/AIArtWork/ToolPrintAreas";
import ArtworkOptions from "components/AIArtWork/ArtworkOptions";
import ArtWorkAdditionalImages from "components/AIArtWork/AdditionalImages";
import LayersDrawer from "components/AIArtWork/LayersDrawer";
import {
  isSub,
  getMarketingImagePathFromProductId,
  isAOP,
} from "selectors/products";
import { default as ImageComponent } from "components/Image";
import {
  Wrapper,
  OuterEnvelope,
  InnerEnvelope,
  StyledCloseIcon,
  StyledVisibilityOffIcon,
  PrintableAreaAlign,
} from "styles/components/AIArtwork";
import {
  OutOfBondWarning,
  OutOfBondRed,
} from "styles/components/ArtWork/browseLibrary";

const onloadImageProcess = (src) =>
  new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });

const ArtWork = ({
  orderLine = {},
  setCoordinates,
  onPreview,
  setOrderLine,
  onFabricDrop,
  onFabricRemove,
  product,
  plus,
  dtgPlusProcess,
  printType,
  isAiGeneratedMockUps,
  colors,
  state,
  setState,
  onDrop,
}) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [canvas, setCanvas] = useState(null);
  const [artworkOutOfBond, setArtworkOutOfBond] = useState(false);
  const [artworkCompleteOutOfBond, setArtworkCompleteOutOfBond] =
    useState(false);
  const [layersDrawer, setLayersDrawer] = useState(false);
  const products = useSelector((state) => state?.products?.data);
  const printAreas = orderLine?.printAreas;
  const selectedPrintArea = printAreas?.find((p) => p?.selected);
  const designerSettings = selectedPrintArea?.designerSettings || {};
  const designerSettingsLength = Object.keys(designerSettings)?.length;
  const isProductAop = isAOP(products, orderLine.ProductID);
  const isProductSub = isSub(products, orderLine?.ProductID);

  const setObjSrc = (obj, url) =>
    new Promise(async (resolve) => {
      obj.setSrc(
        url,
        async () => {
          resolve();
        },
        { crossOrigin: "anonymous" }
      );
    });

  useEffect(() => {
    if (canvas) {
      canvas.dispose();
      setCanvas(undefined);
    }
  }, [plus, dtgPlusProcess, orderLine?.ID]);

  useEffect(() => {
    if (!canvas) {
      const canvas = new fabric.Canvas("canvas", {
        rotationCursor: false,
      });

      //drawGrid(canvas);

      canvas.on("object:modified", function () {
        const objects = canvas?.getObjects();
        const isPartiallyOnScreen = objects.filter(
          (o) => o?.isPartiallyOnScreen() && o?.type !== "group"
        );
        const isOnScreen = objects.filter(
          (o) => !o?.isOnScreen() && o?.type !== "group"
        );

        setArtworkCompleteOutOfBond(!!isOnScreen?.length);
        setArtworkOutOfBond(!!isPartiallyOnScreen?.length);

        onFabricDrop({
          ...canvas?.toJSON(),
          outOfBond: !!isOnScreen?.length,
        });
      });

      canvas.on("object:added", async (e) => {
        const obj = e?.target;

        const greyOut = product?.ArtworkSettings?.greyOut;
        const source = _.isFunction(obj?.getSrc) ? obj?.getSrc() : null;
        const text = obj?.text;

        if (obj?.type !== "group") {
          obj.transparentCorners = false;
          obj.cornerColor = "#5c92e2";
          obj.cornerStrokeColor = "#fff";
          obj.borderColor = "#5c92e2";
          obj.cornerStyle = "circle";
          obj.cornerSize = 10;
          obj.setControlsVisibility({
            tl: true,
            tr: true,
            br: true,
            bl: true,
            ml: false,
            mt: false,
            mr: false,
            mb: false,
            mtr: false,
          });
        } else {
          obj.selectable = false;
          obj.lockMovementX = false;
          obj.lockMovementY = false;
          obj.lockScalingX = false;
          obj.lockScalingY = false;
        }

        if (greyOut) {
          if (source) {
            dispatch(showLoader());
            const secureUrlSplit = source.split("/upload/");
            const url = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:aaa9ad/${secureUrlSplit[1]}`;
            obj._element.src = url;
            await setObjSrc(obj, url);
            canvas.requestRenderAll();
            dispatch(hideLoader());
          } else if (text) {
            obj.fill = "#aaa9ad";
          }
          canvas.requestRenderAll();
        } else if (
          !!plus &&
          !!dtgPlusProcess &&
          !!dtgPlusProcess?.Colors?.find((c) => c?.selected)
        ) {
          const selectedColor = dtgPlusProcess?.Colors?.find(
            (c) => c?.selected
          );

          if (source) {
            dispatch(showLoader());
            const c = document.createElement("canvas", { id: "canvasAtwork" });
            const ctx = c.getContext("2d");
            const secureUrlSplit = source.split("/upload/");
            const url = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;

            const img1 = await onloadImageProcess(url);
            const img2 = await onloadImageProcess(
              selectedColor?.ColorImagePath
            );
            c.width = img1.width;
            c.height = img1.height;
            ctx.fillStyle = ctx.createPattern(img2, "repeat");
            // fill canvas with pattern
            ctx.fillRect(0, 0, c.width, c.height);
            // use blending mode multiply
            ctx.globalCompositeOperation = "multiply";
            // draw sofa on top
            ctx.drawImage(img1, 0, 0, img1.width, img1.height);
            // change composition mode
            ctx.globalCompositeOperation = "destination-in";
            // draw to cut-out sofa
            ctx.drawImage(img1, 0, 0, img1.width, img1.height);
            const dataURL = c.toDataURL("image/png");
            const API_ENDPOINT =
              "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";
            const fileData = new FormData();
            fileData.append("file", dataURL);
            fileData.append("upload_preset", "tnedst8q");
            fileData.append("folder", `Shirtly/${emailid}/PlusArtFiles`);

            const data = await fetch(API_ENDPOINT, {
              method: "post",
              body: fileData,
            });

            const json = await data?.json();
            await setObjSrc(obj, json?.secure_url);
            canvas.requestRenderAll();
            dispatch(hideLoader());
          } else if (text) {
            fabric.util.loadImage(selectedColor?.ColorImagePath, (img) => {
              obj.set(
                "fill",
                new fabric.Pattern({
                  source: img,
                  repeat: "no-repeat",
                })
              );

              canvas.requestRenderAll();
            });
          }
        }

        const objs = canvas.getObjects();
        const selectedPrintArea = orderLine?.printAreas?.find(
          (o) => o?.selected
        );

        if (!selectedPrintArea?.previewImage) {
          canvas.setActiveObject(canvas.item(objs?.length - 1));
        }

        const isPartiallyOnScreen = objs.filter(
          (o) => o?.isPartiallyOnScreen() && o?.type !== "group"
        );
        const isOnScreen = objs.filter(
          (o) => !o?.isOnScreen() && o?.type !== "group"
        );

        setArtworkCompleteOutOfBond(!!isOnScreen?.length);
        setArtworkOutOfBond(!!isPartiallyOnScreen?.length);

        onFabricDrop(
          {
            ...canvas?.toJSON(),
            outOfBond: !!isOnScreen?.length,
          },
          obj?.Art_Url
            ? {
                productionFile: obj?.Art_Url,
                thumbnailUrl: obj?.Thumbnail_Url,
                chargeAmountInUSD: obj["Charge_Amount(USD)"],
                shirtlyChargeAmountInUsd: obj["Shirtly_Amount(USD)"],
                sku: obj["Art_SKU"],
                user: obj["user"],
                fileName: obj["FileName"],
              }
            : null
        );

        canvas.requestRenderAll();
      });

      canvas.on("object:removed", function () {
        const objects = canvas?.getObjects();
        const isPartiallyOnScreen = objects.filter(
          (o) => o?.isPartiallyOnScreen() && o?.type !== "group"
        );
        const isOnScreen = objects.filter(
          (o) => !o?.isOnScreen() && o?.type !== "group"
        );

        setArtworkCompleteOutOfBond(!!isOnScreen?.length);
        setArtworkOutOfBond(!!isPartiallyOnScreen?.length);

        onFabricDrop({
          ...canvas?.toJSON(),
          outOfBond: !!isOnScreen?.length,
        });
      });

      const selectedPrintArea = orderLine?.printAreas?.find((o) => o?.selected);

      if (selectedPrintArea?.canvasJSON) {
        canvas.clear();
        canvas.loadFromJSON(selectedPrintArea?.canvasJSON);
      }

      if (canvas) {
        canvas?.setWidth(designerSettings?.width);
        canvas?.setHeight(designerSettings?.height);
      }

      setCanvas(canvas);
      canvas?.requestRenderAll();
    }
  }, [canvas, plus, dtgPlusProcess, designerSettings]);

  useEffect(() => {
    if (orderLine?.aiGenerateImages && canvas) {
      const selctedPrintArea = orderLine?.printAreas?.find((a) => a?.selected);
      const artwork = selctedPrintArea?.aiTempArtwork;

      if (artwork) {
        artwork.type = "image";

        new fabric.Image.fromURL(
          artwork?.secure_url,
          function (img) {
            img.set({
              top: 10,
              left: 10,
              lockAspectRatio: true,
              ...artwork,
            });
            const { width: artworkWidth, height: artworkHeight } = artwork;
            const canvasWidth = canvas?.width;
            const canvasHeight = canvas?.height;
            const largeDimension =
              artworkWidth > artworkHeight
                ? "width"
                : artworkWidth < artworkHeight
                ? "height"
                : "width";
            if (artworkHeight > canvasHeight && largeDimension === "height") {
              img.scaleToHeight(canvasHeight - 45);
            }
            if (artworkWidth > canvasWidth && largeDimension === "width") {
              img.scaleToWidth(canvasWidth - 30);
            }
            canvas.add(img);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      }
    }
  }, [orderLine, canvas]);

  // useEffect(() => {
  //   if (designerSettings && canvas) {
  //     canvas?.setWidth(designerSettings?.width);
  //     canvas?.setHeight(designerSettings?.height);
  //   }
  // }, [designerSettings, canvas]);

  const json = canvas?.toJSON();
  let badQuality = false;

  if (json?.objects?.length > 0) {
    for (let i = 0; i < json?.objects.length; i++) {
      const o = json?.objects[i];
      const widthInPixel =
        (Number(o?.width) * o?.scaleX) /
        selectedPrintArea?.designerSettings?.multiplier;
      const v = selectedPrintArea?.designerSettings?.width;
      const widthInInches =
        o?.width < 1
          ? 255 / selectedPrintArea?.designerSettings?.multiplier
          : widthInPixel > v
          ? v
          : widthInPixel;

      const widthPercentage = parseInt(
        ((+o?.width / 255) * 100) / +widthInInches
      );

      if (o?.type === "image" && widthPercentage < 20) {
        badQuality = true;
        break;
      }
    }
  }

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30% 70%",
          width: "100%",
          marginTop: "50px",
        }}
      >
        <ArtworkOptions
          orderLine={orderLine}
          plus={plus}
          canvas={canvas}
          onFabricDrop={onFabricDrop}
          colors={colors}
          setOrderLine={setOrderLine}
          state={state}
          setState={setState}
          printType={printType}
          onDrop={onDrop}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Wrapper style={{ transform: "scale(1.2)" }} isSub={isProductSub}>
            <ImageComponent
              src={selectedPrintArea?.printAreaMarketingImage}
              alt="ArtWorkImage"
            />

            {!!designerSettingsLength && designerSettings?.width > 0 && (
              <OuterEnvelope
                isSub={isProductSub}
                style={{
                  top: `${designerSettings?.top}px`,
                  left: `${designerSettings?.left}px`,
                  right: `${designerSettings?.right}px`,
                  bottom: `${designerSettings?.bottom}px`,
                  width: `${designerSettings?.width}px`,
                  height: `${designerSettings?.height}px`,
                }}
              >
                {isProductAop ? null : (
                  <InnerEnvelope
                    previewImage={selectedPrintArea?.previewImage}
                    onClick={() => onPreview(false)}
                  >
                    <canvas id="canvas" />
                  </InnerEnvelope>
                )}

                {badQuality && (
                  <OutOfBondWarning>Poor Quality Object</OutOfBondWarning>
                )}

                {artworkOutOfBond && (
                  <OutOfBondWarning>Artwork out of bound</OutOfBondWarning>
                )}

                {artworkCompleteOutOfBond && (
                  <OutOfBondRed>Artwork out of bound</OutOfBondRed>
                )}

                {selectedPrintArea?.canvasJSON &&
                  !!selectedPrintArea?.canvasJSON?.objects?.length &&
                  !selectedPrintArea?.previewImage && (
                    <>
                      <StyledCloseIcon
                        onClick={() => {
                          onFabricRemove();
                          setArtworkOutOfBond(false);
                          setArtworkCompleteOutOfBond(false);
                          canvas.clear();
                        }}
                      />

                      <StyledVisibilityOffIcon
                        onClick={() => {
                          canvas.discardActiveObject().renderAll();
                          onPreview(true);
                        }}
                      />
                      <PrintableAreaAlign>
                        <div>
                          {designerSettings?.platenWidthInch}&nbsp;X&nbsp;
                          {designerSettings?.platenHeightInch}
                          &nbsp;Printable Area
                        </div>
                      </PrintableAreaAlign>
                    </>
                  )}
              </OuterEnvelope>
            )}
          </Wrapper>

          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <ToolPrintAreas
              orderLine={orderLine}
              setOrderLine={setOrderLine}
              canvas={canvas}
            />
          </div>
        </div>
      </div>

      {isProductSub && orderLine?.ColorID && orderLine?.ProductID && (
        <img
          src={getMarketingImagePathFromProductId(
            products,
            orderLine?.ProductID,
            orderLine?.ColorID
          )}
          alt="ArtWorkImage"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 100,
          }}
        />
      )}
      {!isAiGeneratedMockUps ? (
        <ArtWorkAdditionalImages orderLine={orderLine} />
      ) : null}

      {layersDrawer && (
        <LayersDrawer
          canvas={canvas}
          plus={plus}
          orderLine={orderLine}
          setOrderLine={setOrderLine}
          printArea={orderLine?.printAreas.find((p) => p?.selected)}
          open={layersDrawer}
          toggle={() => setLayersDrawer(false)}
          onApply={(json) => {
            canvas.clear();
            canvas.loadFromJSON(json);
          }}
        />
      )}
    </div>
  );
};

export default ArtWork;
