import React from 'react';
import moment from 'moment';
import { saveIpArtFiles } from 'actions/user';
import { sendEmail } from 'actions/orders';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from 'actions/loader';

const SubmitArtFiles = ({ state, reload }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const emailId = auth?.profile?.emailid;

  return (
    <div className='file-input'>
      <input
        type='file'
        disabled={
          !state?.patentNumber ||
          !state?.confirmed ||
          !state?.country ||
          !state?.patentProofLink
        }
        id='file'
        accept='image/png, image/jpeg, image/jpg'
        className='file'
        multiple
        onChange={async (e) => {
          const files = e?.target?.files;
          const formData = new FormData();
          const API_ENDPOINT =
            'https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload';

          let payload = [];

          dispatch(showLoader());

          for (let i = 0; i < files.length; i++) {
            let file = files[i];

            formData.append('file', file);
            formData.append('upload_preset', 'tnedst8q');
            formData.append('folder', `Shirtly/${emailId}/IPProgram_Art`);

            await fetch(API_ENDPOINT, {
              method: 'POST',
              body: formData,
            })
              .then((response) => response.text())
              .then((data) => {
                const result = JSON.parse(data);
                payload.push({
                  ...result,
                  emailId,
                  approved: false,
                  reviewing: true,
                  patentNumber: state?.patentNumber,
                  patentProofLink: state?.patentProofLink,
                  country: state?.country,
                  timestamp: moment().unix(),
                });
              });
          }

          await dispatch(saveIpArtFiles({ artFiles: payload }));
          await dispatch(
            sendEmail({
              user: 'harrys@shirtly.com',
              subject: `New IP Artwork Added (${emailId})`,
            })
          );
          dispatch(hideLoader());
          reload();
        }}
      />
      <label
        style={{
          opacity:
            !state?.patentNumber ||
            !state?.confirmed ||
            !state?.country ||
            !state?.patentProofLink
              ? 0.5
              : 1,
        }}
        for='file'>
        Select and Submit ArtFiles (only .png, .jpg and .jpeg allowed)
      </label>
    </div>
  );
};

export default SubmitArtFiles;
