import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import CryptoJS from "crypto-js";
import { useSelector, useDispatch } from "react-redux";
import { updateField } from "actions/common";
import { SET_USER_EMAIL, SET_USER_PASSWORD, USER_INITIATE } from "actions/user";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/Button";
import TextField from "components/TextField";
import { login, ipLogin } from "actions/auth";
import loginBanner from "assets/images/login.jpeg";
import CloseIcon from "assets/svg/CloseIcon";
import {
  Container,
  Header,
  Body,
  ForgetPassword,
  RightSection,
  LeftSection,
  ImageWrapper,
  CreateAccountWrapper,
  ActionButtons,
  IntellectualAccount,
} from "styles/pages/home/loginDialog";

const LoginDialog = ({
  open,
  onClose,
  showSignUpModal,
  forgotPasswordDialog,
}) => {
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(false);
  const user = useSelector((state) => state?.user);
  const email = user?.email;
  const password = user?.password;
  const disabled = !email?.valid || !password?.valid;

  const homeWebPage = useSelector((state) => state?.homeWebPage);
  const homeData = homeWebPage?.find((p) => p?.name === "Header") || {};
  const { components } = homeData;
  const { Image } = components || {};

  const loginSubmit = async (ipPortal) => {
    const request = !ipPortal
      ? await dispatch(
          login({
            email: email?.value,
            password: CryptoJS.AES.encrypt(
              password?.value,
              "shirtly"
            ).toString(),
          })
        )
      : await dispatch(
          ipLogin({
            email: email?.value,
            password: CryptoJS.AES.encrypt(
              password?.value,
              "shirtly"
            ).toString(),
          })
        );
    if (request?.error) {
      setLoginError(request?.message);
      return;
    }
  };

  // const onKeyDown = (e) => {
  //   if (e.keyCode === 13) {
  //     loginSubmit();
  //   }
  // };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      className="login-dialog"
      onClose={() => {
        dispatch(updateField(USER_INITIATE));
        _.isFunction(onClose) && onClose();
      }}
    >
      <Container>
        <CloseIcon className="close-icon" onClick={onClose} />

        <LeftSection>
          <h2>Transforming creative ideas with the power of Shirtly.</h2>
          <ImageWrapper>
            <img src={loginBanner} alt="loginBanner" />
          </ImageWrapper>
        </LeftSection>
        <RightSection>
          <Header>
            <img src={Image} alt="logo" />
            <h4>Welcome Back to Shirtly</h4>
          </Header>
          <Body>
            <TextField
              fullWidth
              size="small"
              label="Email"
              // onKeyDown={onKeyDown}
              error={!!(email?.value && !email?.valid) || !!loginError}
              helperText={
                email?.value && !email?.valid
                  ? "Enter valid email"
                  : loginError
                  ? loginError
                  : null
              }
              value={email?.value || ""}
              onChange={(v) => {
                dispatch(updateField(SET_USER_EMAIL, { email: v }));
                if (loginError) {
                  setLoginError(false);
                }
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Password"
              type="password"
              // onKeyDown={onKeyDown}
              error={!!(password?.value && !password?.valid) || !!loginError}
              helperText={
                password?.value && !password?.valid
                  ? `Password should be of minimum ${password.MINIMUM_PASSWORD_LENGTH} characters `
                  : loginError
                  ? loginError
                  : null
              }
              value={password?.value || ""}
              onChange={(v) => {
                dispatch(updateField(SET_USER_PASSWORD, { password: v }));
                if (loginError) {
                  setLoginError(false);
                }
              }}
            />

            <ActionButtons>
              <Button
                disabled={disabled}
                variant="contained"
                title="Login"
                onClick={() => loginSubmit(false)}
              />

              <Button
                disabled={disabled}
                variant="contained"
                title="Login to IP Portal"
                onClick={() => loginSubmit(true)}
              />
            </ActionButtons>

            <CreateAccountWrapper>
              <p>Don't have an account?</p>
              <p
                className="sign-up"
                onClick={() =>
                  _.isFunction(showSignUpModal) && showSignUpModal()
                }
              >
                Create an account
              </p>
            </CreateAccountWrapper>

            <IntellectualAccount>
              <p>Want to protect your Artwork?</p>
              <p
                className="sign-up"
                onClick={() =>
                  _.isFunction(showSignUpModal) && showSignUpModal(true)
                }
              >
                Create an IP (Intellectual Property) account
              </p>
            </IntellectualAccount>

            <ForgetPassword onClick={forgotPasswordDialog}>
              Forgot your Password?
            </ForgetPassword>
          </Body>
        </RightSection>
      </Container>
    </Dialog>
  );
};

LoginDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default LoginDialog;
