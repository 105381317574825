import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  technoInventoryCA,
  technoInventoryUSA,
  technoInventoryCAPaymentProfile,
  technoInventoryUSAPaymentProfile,
  technoInventoryCASubmitOrder,
  technoInventoryUSASubmitOrder,
  saveSSActiveWearCAStockOrderData,
  saveSSActiveWearUSAStockOrderData,
} from 'actions/inventory';
import OrderSubmitted from 'pages/order-stock/OrderSubmitted';
import { alphaInventoryCA, alphaInventoryUS } from 'actions/products';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import Select from 'components/Select';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const XMLParser = require('react-xml-parser');

const useChipStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: '20px 0',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& .MuiChip-colorPrimary': {
      backgroundColor: '#2280c1',
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#2280c1',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CustomDialog = ({
  open,
  setOpen,
  data,
  selectedCountrySupplier,
  options,
}) => {
  const chipStyle = useChipStyles();
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const [rows, setRows] = useState(undefined);
  const [inventory, setInventory] = useState(undefined);
  const [selectedRow, setRow] = useState(null);
  const [paymentProfiles, setPaymentProfiles] = useState(null);
  const [orderSubmitted, setOrderSubmitted] = useState(false);

  useEffect(() => {
    if (data && !rows) {
      setRows(data);
    }
  }, [data, dispatch]);

  useEffect(() => {
    const fetchPaymentProfiles = async () => {
      const isCad =
        selectedCountrySupplier === options[0] ||
        selectedCountrySupplier === options[1] ||
        selectedCountrySupplier === options[2];

      const response = isCad
        ? await dispatch(technoInventoryCAPaymentProfile())
        : await dispatch(technoInventoryUSAPaymentProfile());

      if (response?.error) {
        return;
      }
      setPaymentProfiles(response);
    };
    if (!paymentProfiles) {
      fetchPaymentProfiles();
    }
  }, [paymentProfiles, dispatch, options, selectedCountrySupplier]);

  const disabled =
    rows?.filter((r) => !r?.inventory?.gtin)?.length > 0 ||
    paymentProfiles?.profiles?.filter((p) => p?.selected)?.length === 0 ||
    paymentProfiles?.shippingProfile?.filter((p) => p?.selected)?.length ===
      0 ||
    rows?.filter((r) => !r?.warehouseAbbr)?.length > 0;

  return (
    <Dialog fullWidth={true} maxWidth='lg' open={open} onClose={handleClose}>
      <DialogTitle>
        <b>{selectedCountrySupplier}&nbsp;Orders</b>
      </DialogTitle>
      {paymentProfiles && (
        <div className={chipStyle?.root}>
          {paymentProfiles?.profiles?.map((p, i) => (
            <Chip
              key={`chip_${moment().unix() + i}`}
              style={{ cursor: 'pointer' }}
              color={p?.selected ? 'primary' : ''}
              icon={<CreditCardIcon />}
              label={p?.name}
              onClick={() => {
                setPaymentProfiles({
                  ...paymentProfiles,
                  profiles: paymentProfiles?.profiles.map((q, j) => ({
                    ...q,
                    selected: i !== j ? false : !q?.selected,
                  })),
                });
              }}
            />
          ))}
        </div>
      )}
      <DialogContent>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: 20 }}></StyledTableCell>
                <StyledTableCell style={{ width: 200 }}>
                  Product
                </StyledTableCell>
                <StyledTableCell style={{ width: 40 }}>Size</StyledTableCell>
                <StyledTableCell style={{ width: 100 }}>Color</StyledTableCell>
                <StyledTableCell style={{ width: 100 }}>Qty</StyledTableCell>
                <StyledTableCell style={{ width: 150 }}>
                  Supplier Name
                </StyledTableCell>
                <StyledTableCell style={{ width: 100 }}>
                  Supplier Code
                </StyledTableCell>
                <StyledTableCell style={{ width: 120 }}>
                  Product Variant
                </StyledTableCell>
                <StyledTableCell style={{ width: 100 }}>
                  Product GTIN
                </StyledTableCell>
                <StyledTableCell style={{ width: 150 }}>
                  Warehouse
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <StyledTableRow
                  style={{ cursor: 'pointer' }}
                  key={`StyledTableRow_${moment().unix() * index}`}
                  onClick={async () => {
                    if (row?.ID === selectedRow) {
                      return;
                    }
                    setInventory(undefined);
                    setRow(row?.ID);
                    const isCAD = row?.printProvider === 'CA';
                    const isUSA = row?.printProvider === 'US';

                    if (isCAD) {
                      const isSSActiveWear =
                        row?.supplier === 'S&S ACTIVE WEAR';
                      const isAlphaBroader = row?.supplier === 'ALPHA BRODER';
                      if (isSSActiveWear) {
                        const cadData = await dispatch(
                          await technoInventoryCA({
                            styleId: row?.productCode,
                          })
                        );
                        let data = [];
                        for (let i = 0; i < cadData?.length; i++) {
                          data.push({
                            ...cadData[i],
                            colorFrontImage: `http://cdn.ssactivewear.com/${cadData[i]?.colorFrontImage}`,
                          });
                        }

                        setInventory(data);
                      } else if (isAlphaBroader) {
                        const cadData = await dispatch(
                          await alphaInventoryCA({
                            styleId: row?.productCode,
                          })
                        );
                        const json = new XMLParser().parseFromString(cadData);
                        let data = [];
                        for (let i = 0; i < json?.children?.length; i++) {
                          const { attributes, children } = json?.children[i];
                          const styleCode =
                            attributes?.['style-code']?.toLowerCase();
                          const colorCode = attributes?.['color-code'];
                          if (styleCode && colorCode) {
                            data.push({
                              colorName: attributes?.description,
                              qty: children[0]?.value,
                              colorFrontImage: `https://www.alphabroder.ca/images/can/prodDetail/${styleCode}_${colorCode}_p.jpg`,
                            });
                          }
                        }
                        setInventory(data);
                      }
                    }

                    if (isUSA) {
                      const isSSActiveWear =
                        row?.supplier === 'S&S ACTIVE WEAR';

                      const isAlphaBroader = row?.supplier === 'ALPHA BRODER';

                      if (isSSActiveWear) {
                        const usaData = await dispatch(
                          await technoInventoryUSA({
                            styleId: row?.productCode,
                          })
                        );

                        let data = [];
                        for (let i = 0; i < usaData?.length; i++) {
                          data.push({
                            ...usaData[i],
                            colorFrontImage: `http://cdn.ssactivewear.com/${usaData[i]?.colorFrontImage}`,
                          });
                        }
                        setInventory(data);
                      } else if (isAlphaBroader) {
                        const usaData = await dispatch(
                          await alphaInventoryUS({
                            styleId: row?.productCode,
                          })
                        );

                        const json = new XMLParser().parseFromString(usaData);
                        let data = [];
                        for (let i = 0; i < json?.children?.length; i++) {
                          const { attributes, children } = json?.children[i];
                          const styleCode =
                            attributes?.['style-code']?.toLowerCase();
                          const colorCode = attributes?.['color-code'];

                          if (styleCode && colorCode) {
                            data.push({
                              colorName: attributes?.description,
                              qty: children[0]?.value,
                              colorFrontImage: `https://www.alphabroder.ca/images/can/prodDetail/${styleCode}_${colorCode}_p.jpg`,
                            });
                          }
                        }
                        setInventory(data);
                      }
                    }
                  }}>
                  <StyledTableCell>
                    <DeleteIcon
                      style={{ color: 'red' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setRows(_.remove(rows, (n) => n.ID !== row?.ID));
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row?.Product}</StyledTableCell>
                  <StyledTableCell>{row?.Size}</StyledTableCell>
                  <StyledTableCell>{row?.Color}</StyledTableCell>
                  <StyledTableCell>
                    {selectedRow === row?.ID ? (
                      <TextField
                        value={row?.OliQuantity?.toString()}
                        type='number'
                        size='small'
                        label='QTY'
                        variant='outlined'
                        onChange={(e) => {
                          const v = e.target.value;
                          setRows(
                            rows.map((p, j) => {
                              if (index !== j) {
                                return p;
                              }
                              return {
                                ...p,
                                OliQuantity: v <= 0 ? 1 : v,
                              };
                            })
                          );
                        }}
                      />
                    ) : (
                      row?.OliQuantity
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row?.supplier}</StyledTableCell>
                  <StyledTableCell>{row?.productCode}</StyledTableCell>
                  <StyledTableCell>
                    {selectedRow === row?.ID ? (
                      <Autocomplete
                        size='small'
                        options={
                          selectedRow === row?.ID
                            ? inventory
                            : [{ colorName: '', sizeName: '' }]
                        }
                        onChange={(e, v) => {
                          setRows(
                            rows.map((p) => {
                              if (
                                p?.Product !== row?.Product ||
                                row?.Size !== p?.Size ||
                                p?.Color !== row?.Color ||
                                p?.productCode !== row?.productCode
                              ) {
                                return p;
                              }

                              return {
                                ...p,
                                inventory: v,
                              };
                            })
                          );
                        }}
                        getOptionLabel={(option) =>
                          `${option.colorName}${
                            option.sizeName ? ` / ${option?.sizeName}` : ``
                          }`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Search'
                            variant='outlined'
                          />
                        )}
                      />
                    ) : (
                      <span>
                        {row?.inventory
                          ? `${row?.inventory?.colorName}${
                              row?.inventory?.sizeName
                                ? ` / ${row?.inventory?.sizeName}`
                                : ``
                            }`
                          : ''}
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row?.inventory?.gtin}</StyledTableCell>
                  <StyledTableCell>
                    {selectedRow === row?.ID ? (
                      <Select
                        disabled={!row?.inventory}
                        options={
                          selectedRow === row?.ID
                            ? row?.inventory?.warehouses
                            : [{ warehouseAbbr: '' }]
                        }
                        labelKey='warehouseAbbr'
                        valueKey='warehouseAbbr'
                        label='Warehouse'
                        value={{ warehouseAbbr: row?.warehouseAbbr || '' }}
                        onChange={(v) => {
                          setRows(
                            rows.map((p) => {
                              if (
                                p?.Product !== row?.Product ||
                                row?.Size !== p?.Size ||
                                p?.Color !== row?.Color ||
                                p?.productCode !== row?.productCode
                              ) {
                                return p;
                              }

                              return {
                                ...p,
                                warehouseAbbr: v,
                              };
                            })
                          );
                        }}
                      />
                    ) : (
                      <span>{row?.warehouseAbbr || ''}</span>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <div style={{ margin: 30, display: 'flex', gridGap: 30 }}>
        <Select
          options={paymentProfiles?.shippingProfile}
          labelKey='shippingCarrierName'
          valueKey='shippingCarrierName'
          label='Select Shipping Method'
          value={{
            shippingCarrierName:
              paymentProfiles?.shippingProfile.find((q) => q?.selected)
                ?.shippingCarrierName || '',
          }}
          onChange={(v) => {
            setPaymentProfiles({
              ...paymentProfiles,
              shippingProfile: paymentProfiles?.shippingProfile.map((q) => ({
                ...q,
                selected: q?.shippingCarrierName === v ? true : false,
              })),
            });
          }}
        />
      </div>
      <DialogActions>
        <Button
          onClick={handleClose}
          title='Close'
          onClick={handleClose}
          containerStyle={{
            backgroundColor: 'red',
            color: '#fff',
            fontWeight: 'bold',
            padding: 15,
            height: 40,
          }}
        />

        <Button
          onClick={handleClose}
          title='Add To Order Stock Cart'
          containerStyle={{
            backgroundColor: '#2280c1',
            color: '#fff',
            fontWeight: 'bold',
            padding: 15,
            height: 40,
            marginRight: 30,
            opacity: disabled ? 0.5 : 1,
          }}
          disabled={disabled}
          onClick={async () => {
            const shippingMethod = paymentProfiles?.shippingProfile
              ?.find((p) => p?.selected)
              ?.shippingCode?.toString();
            let payload = {
              shippingAddress: paymentProfiles?.shipTo,
              shippingMethod,
              shipBlind: false,
              // poNumber: 'Test',
              poNumber: `S ${moment().date()}/${
                moment().month() + 1
              } ${moment().unix()}${shippingMethod}`,
              testOrder: false,
              emailConfirmation: paymentProfiles?.emailConfirmation,
              autoselectWarehouse: false,
              paymentProfile: {
                email: paymentProfiles?.paymentProfileEmail,
                profileID: paymentProfiles?.profiles?.find((p) => p?.selected)
                  ?.profileID,
              },
              lines: [],
            };

            for (let i = 0; i < rows?.length; i++) {
              if (rows[i]?.inventory?.gtin) {
                payload.lines.push({
                  identifier: rows[i]?.inventory?.gtin,
                  qty: rows[i]?.OliQuantity,
                  warehouseAbbr: rows[i]?.warehouseAbbr,
                });
              }
            }

            const isCad =
              selectedCountrySupplier === options[0] ||
              selectedCountrySupplier === options[1] ||
              selectedCountrySupplier === options[2];

            const response = isCad
              ? await dispatch(technoInventoryCASubmitOrder(payload))
              : await dispatch(technoInventoryUSASubmitOrder(payload));

            if (response?.errors) {
              alert(response?.errors[0]?.message);
              return;
            }

            const data = isCad
              ? await dispatch(saveSSActiveWearCAStockOrderData(response))
              : await dispatch(saveSSActiveWearUSAStockOrderData(response));

            if (data?.error) {
              return;
            }
            setOrderSubmitted(true);
          }}
          title='Order Stock'
        />
      </DialogActions>
      {orderSubmitted && (
        <OrderSubmitted
          open={orderSubmitted}
          setOpen={() => {
            setOrderSubmitted(false);
            handleClose();
          }}
          selectedCountrySupplier={selectedCountrySupplier}
        />
      )}
    </Dialog>
  );
};

export default CustomDialog;
