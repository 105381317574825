import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={27}
      height={26}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#prefix__clip0_87:242)'>
        <path
          d='M22.847 17.554c-.59-.473-1.282-.766-1.98-.981V6.777c0-2.085-.749-3.844-2.165-5.084C17.456.6 15.786 0 14 0s-3.456.601-4.702 1.693c-1.416 1.24-2.164 2.999-2.164 5.084v9.796c-.7.215-1.39.508-1.981.981-1.144.917-1.7 2.32-1.7 4.29v5.5c0 .362.293.656.656.656H23.89a.656.656 0 00.657-.656v-5.5c0-1.97-.556-3.373-1.7-4.29zM8.446 6.777c0-3.754 2.88-5.465 5.554-5.465 1.468 0 2.83.486 3.837 1.368 1.123.984 1.717 2.4 1.717 4.097v9.456l-.05-.011c-1.877-.435-2.872-.735-3.002-2.22.35-.325.672-.719.959-1.177.974-1.556 1.49-3.806 1.49-6.507a.656.656 0 00-.606-.655 4.998 4.998 0 01-3.051-1.367.656.656 0 00-1.045.19 5.03 5.03 0 01-4.503 2.845H9.71a.653.653 0 00-.661.657c0 2.065.49 3.845 1.416 5.149a5.2 5.2 0 001.008 1.071c-.203 1.309-1.191 1.6-2.978 2.014l-.05.011V6.777zm7.903 5.352c-.666 1.063-1.544 1.648-2.475 1.648-1.606 0-3.307-1.805-3.495-5.166a6.363 6.363 0 004.645-2.834 6.32 6.32 0 002.606 1.116c-.08 2.747-.73 4.356-1.281 5.236zm-3.681 2.748c.39.14.796.212 1.206.212.498 0 .98-.102 1.435-.299a2.965 2.965 0 001.339 1.823c.428.27.921.456 1.435.605-.55 2.009-2.4 2.914-4.083 2.914-1.683 0-3.532-.905-4.083-2.914.514-.149 1.007-.335 1.435-.605.667-.422 1.101-.995 1.316-1.736zm10.567 11.81h-2.368v-3.525a.656.656 0 00-1.313 0v3.526H8.446v-3.526a.656.656 0 00-1.313 0v3.526H4.765v-4.844c0-3.332 1.781-3.822 3.878-4.309a5.056 5.056 0 001.893 2.806c.962.712 2.192 1.104 3.464 1.104 1.272 0 2.502-.392 3.464-1.104a5.056 5.056 0 001.893-2.806c2.097.487 3.878.977 3.878 4.31v4.843z'
          fill='#6C6C6C'
        />
      </g>
      <defs>
        <clipPath id='prefix__clip0_87:242'>
          <path fill='#fff' d='M0 0h28v28H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
