import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getColumns } from "pages/ip-program/table/columns";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#5c92e2",
    fontSize: 14,
    fontFamily: "Inter600",
  },
}))(TableCell);

const Head = () => {
  const emailId = useSelector((state) => state?.auth?.profile?.emailid);
  const isAdmin = emailId === "support@shirtly.com";
  const columns = getColumns(isAdmin);

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <StyledTableCell
            key={`${column.id}thead${index}`}
            align={column.align}
            style={{ minWidth: column.minWidth }}
          >
            {column.label}
          </StyledTableCell>
        ))}

        <StyledTableCell>Action</StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

export default Head;
