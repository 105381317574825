import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import { deleteOrder } from "actions/orders";
import { ROUTES } from "shared/constants/routes";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  Container,
  Wrapper,
  Heading,
  Panel,
  OrderInfo,
  Status,
  OrderAction,
  MachineStatusWrapper,
  BodySection,
} from "styles/pages/order-details/orderInformation";

const OrderInformation = ({ orderDetail = {}, machineStatus }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const status = orderDetail?.Status;

  const statusDetail =
    status === "PendingDetails"
      ? { class: "pending-details", title: "Pending Details" }
      : status === "AwaitingPayment"
      ? { class: "awaiting", title: "Awaiting Payment" }
      : status === "InProduction"
      ? { class: "awaiting-fulfillment", title: "In Production" }
      : status === "Shipped"
      ? { class: "shipped", title: "Shipped" }
      : { class: "cancelled", title: "Cancelled" };

  let retailerName = orderDetail.RetailerName;

  if (
    retailerName?.includes("Custom") &&
    (orderDetail?.Marketplace === "Etsy" ||
      orderDetail?.Marketplace === "Shopify" ||
      orderDetail?.Marketplace === "BigCommerce" ||
      orderDetail?.Marketplace === "WooCommerce" ||
      orderDetail?.Marketplace === "BigCartel")
  ) {
    retailerName = retailerName?.split("Custom")[0];
  }

  return (
    <Container>
      <Wrapper>
        <Heading>Order Information</Heading>
        <Panel>
          <BodySection>
            <OrderInfo>
              <li>
                <span>Retailer:</span>
                <span>{retailerName}</span>
              </li>
              <li>
                <span>Marketplace:</span>
                <span>{orderDetail.Marketplace}</span>
              </li>

              {(orderDetail?.Status === "InProduction" ||
                orderDetail?.Status === "Shipped") &&
                machineStatus &&
                (machineStatus?.shipped === "true" ||
                  orderDetail?.TrackingNumber) && (
                  <li>
                    <span>Ship Date:</span>
                    <span>
                      {machineStatus?.shipDate || orderDetail?.ShippedDate}
                    </span>
                  </li>
                )}

              {(orderDetail?.Status === "InProduction" ||
                orderDetail?.Status === "Shipped") &&
                machineStatus && (
                  <>
                    <li>
                      <span>Production Status:</span>
                      <span>
                        <MachineStatusWrapper>
                          {machineStatus?.barcodePrinted === "true" ||
                          orderDetail?.TrackingNumber ? (
                            <CheckCircleIcon style={{ color: "green" }} />
                          ) : (
                            <CheckCircleIcon style={{ color: "#ccc" }} />
                          )}
                          &nbsp;Bar Code Printed
                        </MachineStatusWrapper>
                        <MachineStatusWrapper>
                          {machineStatus?.barcodePrinted === "true" ||
                          orderDetail?.TrackingNumber ? (
                            <CheckCircleIcon style={{ color: "green" }} />
                          ) : (
                            <CheckCircleIcon style={{ color: "#ccc" }} />
                          )}
                          &nbsp;Under Process
                        </MachineStatusWrapper>
                        <MachineStatusWrapper>
                          {machineStatus?.printed === "true" ||
                          orderDetail?.TrackingNumber ? (
                            <CheckCircleIcon style={{ color: "green" }} />
                          ) : machineStatus?.shipped === "true" ? (
                            <CheckCircleIcon style={{ color: "green" }} />
                          ) : (
                            <CheckCircleIcon style={{ color: "#ccc" }} />
                          )}
                          &nbsp;Processed
                        </MachineStatusWrapper>
                        <MachineStatusWrapper>
                          {machineStatus?.shipped === "true" ||
                          orderDetail?.TrackingNumber ? (
                            <CheckCircleIcon style={{ color: "green" }} />
                          ) : (
                            <CheckCircleIcon style={{ color: "#ccc" }} />
                          )}
                          &nbsp;Quality Checked
                        </MachineStatusWrapper>
                        <MachineStatusWrapper>
                          {machineStatus?.shipped === "true" ||
                          orderDetail?.TrackingNumber ? (
                            <CheckCircleIcon style={{ color: "green" }} />
                          ) : (
                            <CheckCircleIcon style={{ color: "#ccc" }} />
                          )}
                          &nbsp;Shipped
                        </MachineStatusWrapper>
                      </span>
                    </li>
                  </>
                )}
            </OrderInfo>
            <OrderInfo>
              <li>
                <span>Order ID:</span>
                <span>{orderDetail.ReceiptID}</span>
              </li>
              <li>
                <span>Created:</span>
                <span>{orderDetail.DateCreated}</span>
              </li>
              {(orderDetail?.Status === "InProduction" ||
                orderDetail?.Status === "Shipped") &&
                machineStatus &&
                (machineStatus?.shipped === "true" ||
                  orderDetail?.TrackingNumber) && (
                  <li>
                    <span>Tracking:</span>
                    <span>
                      <a
                        href={
                          orderDetail?.PrintProvider == "AU"
                            ? machineStatus?.trackingUrl
                            : orderDetail?.PrintProvider == "EU"
                            ? `https://parcelsapp.com/en/tracking/${
                                machineStatus?.trackingNumber ||
                                orderDetail?.TrackingNumber
                              }`
                            : orderDetail?.PrintProvider == "CA"
                            ? `https://www.canadapost.ca/track-reperage/en#/details/${
                                machineStatus?.trackingNumber ||
                                orderDetail?.TrackingNumber
                              }`
                            : machineStatus?.trackingNumber?.length <= 22 ||
                              orderDetail?.TrackingNumber <= 22
                            ? `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${
                                machineStatus?.trackingNumber ||
                                orderDetail?.TrackingNumber
                              }`
                            : `https://webtrack.dhlglobalmail.com/?trackingnumber=${
                                machineStatus?.trackingNumber ||
                                orderDetail?.TrackingNumber
                              }`
                        }
                        target="_blank"
                      >
                        {machineStatus?.trackingNumber ||
                          orderDetail?.TrackingNumber}
                      </a>
                    </span>
                  </li>
                )}
            </OrderInfo>
          </BodySection>
          <BodySection>
            <OrderInfo>
              <li>
                <span>Status:</span>
              </li>
            </OrderInfo>
            <OrderInfo>
              <li>
                <span></span>
              </li>
            </OrderInfo>
            <OrderInfo>
              <li>
                <span>
                  <Status className={statusDetail.class}>
                    <p>{statusDetail.title}</p>
                  </Status>
                </span>
              </li>
            </OrderInfo>
            <OrderInfo>
              <li>
                <span>
                  {status !== "Shipped" &&
                    status !== "InProduction" &&
                    status !== "Cancelled" && (
                      <OrderAction>
                        <div>
                          <Button
                            variant="text"
                            title="Remove Order"
                            onClick={async () => {
                              await dispatch(
                                deleteOrder({
                                  user: emailid,
                                  orderId: orderDetail.ID,
                                })
                              );
                              history.push(ROUTES.ORDERS.LINK);
                            }}
                          />
                        </div>
                      </OrderAction>
                    )}
                </span>
              </li>
            </OrderInfo>
          </BodySection>
        </Panel>
      </Wrapper>
    </Container>
  );
};

export default OrderInformation;
