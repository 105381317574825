import { makeStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

const AccordionSummary = withStyles({
  root: {
    minHeight: 0,
    padding: 0,
    margin: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: '0 27px',
    display: 'block',
    margin: '0',
    fontSize: '14px',
    fontFamily: 'Inter400',
  },
})(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    border: 'none',
    borderRadius: '0',
  },
  heading: {
    fontSize: '17px',
    color: '#2789C0',
    padding: '0 27px',
    fontFamily: 'Inter600',
    lineHeight: '32px',
  },

  accordion: {
    boxShadow: 'none',
    borderRadius: '10px',
    marginBottom: '10px',
    borderTop: 0,
    padding: '12px 0',
    '&:first-child': {
      borderRadius: '10px',
    },
    '&:last-child': {
      borderRadius: '10px',
    },

    '&::before': {
      content: 'none',
    },
  },
}));

export { AccordionSummary, AccordionDetails, useStyles };
