import { getProductsByCategoryId } from 'selectors/inventory';
import { makeStyles } from '@material-ui/core/styles';

const getProductsOptions = (allProducts, categoryId) => {
  if (!categoryId) {
    return [];
  }
  const products = getProductsByCategoryId(allProducts, categoryId);
  let options = [];

  for (let i = 0; i < products?.length; i++) {
    if (products[i]?.Product?.PrintType !== 'AOP') {
      options.push(products[i]?.Product);
    }
  }

  return options;
};

const getSteps = () => ['Choose Product', 'Upload Artwork', 'Mock-Ups'];

export { getProductsOptions, getSteps };
