import React from 'react';
import TextField from 'components/TextField';

const PatentNumber = ({ state, setState }) => (
  <TextField
    label='Copyright / Trademark Number'
    fullWidth
    size='small'
    value={state?.patentNumber || ''}
    onChange={(patentNumber) => {
      setState((prevState) => ({
        ...prevState,
        patentNumber,
      }));
    }}
  />
);

export default PatentNumber;
