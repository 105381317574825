import React from "react";
import SubHeader from "components/SubHeader";
import { ROUTES } from "shared/constants/routes";

const Header = () => (
  <SubHeader
    isDark
    title={ROUTES.CATALOG.TITLE}
    links={[
      {
        title: "Home",
        to: "/",
      },
    ]}
    currentPageTitle={ROUTES.CATALOG.TITLE}
  />
);

export default Header;
