import React from 'react';
import CheckBox from 'components/CheckBox';

const UploadCheckbox = ({ state, setState }) => (
  <div className='checkbox'>
    <CheckBox
      label='I will upload all the ArtFiles related to this Copyright / Trademark Number'
      onChange={(confirmed) => {
        setState((prevState) => ({
          ...prevState,
          confirmed,
        }));
      }}
      checked={state?.confirmed || false}
    />
  </div>
);

export default UploadCheckbox;
