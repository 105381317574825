import styled from "styled-components";

export const Container = styled.div`
  padding: 0 20px 20px;
  background-color: #000;
  display: flex;
  flex-direction: column;
`;

export const VerifyImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const VerifiedImages = styled.div`
  margin: 30px;
`;

export const Title = styled.h2`
  margin: 40px 20px 20px;
  border-bottom: 1px solid #000;
  font-size: 16px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 19px 2px rgb(204 204 204);
  padding: 10px;
`;

export const Note = styled.p`
  padding: 0 20px;
  text-align: center;
  color: red;
  font-size: 14px;
  a {
    color: red;
  }
`;

export const AssetId = styled.p`
  text-align: center;
`;

export const Verified = styled.div`
  text-align: center;
  p {
    font-size: 20px !important;
  }
`;

export const UserDropDown = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  grid-gap: 20px;

  label,
  button,
  .MuiInput-input,
  .Mui-focused {
    color: #fff;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: #fff;
  }
`;
