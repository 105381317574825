import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "shared/constants/routes";
import SubHeader from "components/SubHeader";
import StoreTable from "pages/my-stores/stores/StoresTable";
import ShopifyLogo from "assets/images/shopify.png";
import BigCommerceLogo from "assets/images/big-commerce-lg-logo.png";
import WooCommerceLogo from "assets/images/woo-commerce.png";
import BigCartelLogo from "assets/images/big-cartel-logo.png";
import Etsy from "assets/images/etsy.png";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import StoresGrid from "pages/my-stores/stores/StoresGrid";
import AddStoreModal from "pages/my-stores/stores/AddStoreModal";
import {
  Container,
  LogoSection,
  LogoWrapper,
  StoresDetail,
  TitleSection,
  ViewButton,
  StoreSection,
} from "styles/pages/my-stores/stores";

const Stores = () => {
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [view, setView] = React.useState("grid");
  const [modal, setModal] = React.useState(false);
  const handleChange = (event, nextView) => setView(nextView);

  return (
    <Container>
      <SubHeader
        isDark
        title="My Store"
        links={[
          {
            title: ROUTES.DASHBOARD.TITLE,
            to: ROUTES.DASHBOARD.LINK,
          },
        ]}
        currentPageTitle={ROUTES.MY_STORES.TITLE}
        rightCornerButtons={[
          { title: "Add Store", onClick: () => setModal(true) },
        ]}
      />
      <LogoSection>
        <LogoWrapper>
          <img src={ShopifyLogo} alt="logo" />
          <img src={Etsy} alt="logo" />
          <img src={WooCommerceLogo} alt="logo" />
          <img src={BigCartelLogo} alt="logo" />

          <img src={BigCommerceLogo} alt="logo" />
        </LogoWrapper>
      </LogoSection>
      <StoresDetail>
        <TitleSection>
          <p>My Stores Detail</p>

          <ViewButton>
            <ToggleButtonGroup value={view} exclusive onChange={handleChange}>
              <ToggleButton value="list">
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton value="grid">
                <ViewModuleIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </ViewButton>
        </TitleSection>
        <StoreSection>
          {view === "grid" ? <StoresGrid /> : <StoreTable />}
        </StoreSection>
      </StoresDetail>

      {modal && <AddStoreModal open={modal} onClose={() => setModal(false)} />}
    </Container>
  );
};

export default Stores;
