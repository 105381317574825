import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "components/Pagination";
import SyncProductProgressModal from "pages/orders/SyncProductProgress";
import {
  getOrders,
  createOrder,
  getShopifyOrders,
  getShopifyOrdersCount,
  getOrderByID,
  getEtsyOrders,
  updateOrderStatus,
  duplicateOrder,
  getBigCommerceOrders,
  wooAllOrders,
  getShopifyMoreOrders,
  wooMoreOrders,
  bigCartelAllOrders,
  deleteOrder,
} from "actions/orders";
import ErrorModal from "pages/my-stores/sync-products/ErrorModal";
import CreateOrder from "pages/orders/CreateOrder";
import { ROUTES } from "shared/constants/routes";
import SubHeader from "components/SubHeader";
import SearchBar from "components/SearchBar";
import OrderTable from "pages/orders/table";
import OrderFilters from "pages/orders/OrderFilter";
import VoidOrderDialog from "pages/orders/VoidOrderDialog";
import RetrieveSuccessDialog from "pages/orders/RetrieveSuccessDialog";
import RetrieveErrorDialog from "pages/orders/RetrieveErrorDialog";
import RetrieveLatestOrder from "pages/orders/RetrieveLatestOrder";
import { AUTH_LOGOUT } from "actions/auth";
import { INITIAL_SYNC_PRODUCTS } from "actions/sync-products";
import { INITIATE_STORES } from "actions/stores";
import { checkUserStatus } from "actions/user";
import {
  Container,
  SearchWrapper,
  PaginationWrapper,
  OrderSection,
} from "styles/pages/orders";
import DuplicateOrderModal from "pages/orders/table/DuplicateOrderModal";
import OrderSubmittedModal from "pages/orders/OrderSubmittedModal";

const Orders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [createOrderModal, setCreateOrderModal] = useState(false);
  const [syncProductModal, setSyncProductModal] = useState(false);
  const [voidOrder, setVoidOrder] = useState(undefined);
  const [retrievalSuccessModal, setRetrievalSuccessModal] = useState(false);
  const [retrievalErrorModal, setRetrievalErrorModal] = useState(false);
  const [orderBySearch, setOrdersBySearch] = useState(false);
  const [orders, setOrders] = useState(undefined);
  const [category, setCategory] = useState("PendingDetails");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const auth = useSelector((state) => state?.auth);

  const [error, setError] = useState(false);
  const [ordersSubmitted, setOrdersSubmitted] = useState(false);
  const {
    profile: { emailid },
  } = auth;
  const [duplicateModalVisibility, setDuplicateModalVisibility] =
    useState(false);
  const prevCategory = useRef();

  const getOrdersData = async () => {
    let skip = currentPage;

    if (category !== prevCategory.current) {
      prevCategory.current = category;
      skip = 1;
    }

    const orders = await dispatch(
      getOrders({
        emailid,
        state: category,
        skip: (skip - 1) * 10,
      })
    );
    if (!orders.error) {
      setOrders(orders);
    }
  };

  useEffect(() => {
    getOrdersData();
  }, [dispatch, category, currentPage, emailid]);

  const payButtonVisibility = !!orders?.orders?.filter((p) => p?.checked)
    ?.length;

  return (
    <Container>
      <SubHeader
        isDark
        title="Orders"
        links={[
          {
            title: ROUTES.DASHBOARD.TITLE,
            to: ROUTES.DASHBOARD.LINK,
          },
        ]}
        currentPageTitle="Orders"
      />
      <SearchWrapper>
        <SearchBar
          isDark={false}
          searchOnEnter={true}
          fetchAllOrders={async () => {
            if (orderBySearch) {
              const orders = await dispatch(
                getOrders({
                  emailid,
                  state: "PendingDetails",
                  skip: 0,
                })
              );
              if (!orders.error) {
                setOrders(orders);
              }
              setCategory("PendingDetails");
              setOrdersBySearch(false);
            }
          }}
          searchById={async (orderId) => {
            if (orderId) {
              const orders = await dispatch(
                getOrderByID({
                  user: emailid,
                  id: orderId,
                })
              );

              if (orders?.error) {
                return;
              }

              setOrders(orders);
              setOrdersBySearch(true);
            }
          }}
        />

        <RetrieveLatestOrder
          retrieveShopifyOrders={async (store) => {
            let response;
            if (store?.MarketplaceType === "Etsy") {
              response = await dispatch(
                getEtsyOrders(emailid, store?.ShopID, store?.ID, 0)
              );

              if (!response?.success) {
                alert("Please re-connect your Etsy store.");
                return;
              }

              const totalCount = response?.count;
              const limit = 20;

              for (let i = limit; i < totalCount; i += limit) {
                response = await dispatch(
                  getEtsyOrders(emailid, store?.ShopID, store?.ID, i)
                );
              }
            } else if (store?.MarketplaceType === "Shopify") {
              setSyncProductModal({
                title: "Retrieving Latest Orders",
                totalOrders: 100,
                currentOrder: 0,
              });

              const count = await dispatch(
                getShopifyOrdersCount(emailid, store?.ShopID)
              );

              if (count?.errors) {
                setSyncProductModal(null);
                setError(true);
                return;
              }

              if (count?.count <= 100) {
                response = await dispatch(
                  getShopifyOrders(emailid, store?.ShopID)
                );
              } else {
                const loop = Math.ceil(count?.count / 100);
                const arr = _.range(loop);

                for await (const index of arr) {
                  setSyncProductModal({
                    title: "Retrieving Latest Orders",
                    totalOrders: arr?.length,
                    currentOrder: index,
                  });
                  await new Promise(async (resolve, reject) => {
                    if (index === 0) {
                      response = await dispatch(
                        getShopifyOrders(emailid, store?.ShopID)
                      );
                    } else if (index !== 0) {
                      response = await dispatch(
                        getShopifyMoreOrders(
                          emailid,
                          store?.ShopID,
                          response?.next_page_info
                        )
                      );
                    }
                    resolve();
                  });
                }
              }
              setSyncProductModal({
                title: "Retrieving Latest Orders",
                totalOrders: 100,
                currentOrder: 100,
              });
            } else if (store?.MarketplaceType === "BigCommerce") {
              response = await dispatch(
                getBigCommerceOrders(emailid, store?.ShopID)
              );
            } else if (store?.MarketplaceType === "WooCommerce") {
              response = await dispatch(wooAllOrders(emailid, store?.ShopID));

              if (response?.next_page_info) {
                let condition = true;

                while (condition) {
                  await new Promise(async (resolve) => {
                    response = await dispatch(
                      wooMoreOrders(
                        emailid,
                        store?.ShopID,
                        response?.next_page_info
                      )
                    );

                    if (!response?.next_page_info) {
                      condition = false;
                    }

                    resolve();
                  });
                }
              }
            } else if (store?.MarketplaceType === "BigCartel") {
              response = await dispatch(
                bigCartelAllOrders(emailid, store?.ShopID, 0)
              );

              const count = Number(response?.meta?.count) - 10;

              if (count >= 0) {
                const arr = _.range(count);
                if (arr?.length) {
                  for await (const index of arr) {
                    await dispatch(
                      bigCartelAllOrders(emailid, store?.ShopID, index + 1)
                    );

                    response = { success: true };
                  }
                }
              }
            }

            if (!response?.success || !response?.success) {
              setRetrievalErrorModal(
                response?.message?.errors || response?.data
              );
              return;
            }

            if (category === "PendingDetails" && currentPage === 1) {
              const orders = await dispatch(
                getOrders({
                  emailid,
                  state: category,
                  skip: (currentPage - 1) * 10,
                })
              );
              if (!orders.error) {
                setOrders(orders);
              }
            } else {
              setCategory("PendingDetails");
              setCurrentPage(1);
            }

            setSyncProductModal(null);
            setRetrievalSuccessModal(true);
          }}
          loggedInUser={true}
        />

        <OrderSection>
          <CreateOrder
            open={createOrderModal}
            show={async () => {
              const data = await dispatch(
                checkUserStatus(auth?.profile?.emailid)
              );
              if (data?.Status === "In-Active") {
                dispatch({ type: INITIATE_STORES });
                dispatch({ type: AUTH_LOGOUT });
                dispatch({ type: INITIAL_SYNC_PRODUCTS });
                return;
              }

              setCreateOrderModal(true);
            }}
            hide={() => setCreateOrderModal(false)}
            handleNext={() =>
              setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
            handleBack={() =>
              setActiveStep((prevActiveStep) => prevActiveStep - 1)
            }
            activeStep={activeStep}
            save={async (payload) => {
              setCreateOrderModal(false);
              const order = await dispatch(
                createOrder({
                  newOrder: payload,
                  user: emailid,
                })
              );

              if (order.error) {
                return;
              }

              history.push(`${ROUTES.ORDER_DETAILS.LINK}/${order.ID}`);
            }}
          />
        </OrderSection>
      </SearchWrapper>

      <div style={{ display: "flex", gap: "40px" }}>
        <OrderFilters
          updateCategory={(v) => {
            setCategory(v);
            setCurrentPage(1);
          }}
          selected={category}
          orderCounts={orders?.orderCounts}
          payButtonVisibility={payButtonVisibility}
        />
        <div style={{ maxWidth: "86vw" }}>
          <OrderTable
            orders={orders?.orders}
            category={category}
            removeOrder={async (order) => {
              await dispatch(
                deleteOrder({
                  user: emailid,
                  orderId: order.ID,
                })
              );

              getOrdersData();
            }}
            voidOrder={(order) => {
              setVoidOrder(order);
            }}
            copyOrder={(order) => {
              setDuplicateModalVisibility(order);
            }}
            toggleOrderForPayment={(index, checked) => {
              setOrders({
                ...orders,
                orders: orders?.orders?.map((order, j) => {
                  if (index === -1) {
                    return {
                      ...order,
                      checked,
                    };
                  }

                  if (index !== j) {
                    return order;
                  }

                  return {
                    ...order,
                    checked,
                  };
                }),
              });
            }}
          />
          <PaginationWrapper>
            <Pagination
              isDark
              totalCount={
                category === "PendingDetails"
                  ? orders?.orderCounts?.PendingDetails
                  : category === "AwaitingPayment"
                  ? orders?.orderCounts?.AwaitingPayment
                  : category === "InProduction"
                  ? orders?.orderCounts?.InProduction
                  : orders?.orderCounts?.Shipped
              }
              take={10}
              page={currentPage}
              onChange={(e, v) => setCurrentPage(v)}
            />
          </PaginationWrapper>
        </div>
      </div>

      <RetrieveSuccessDialog
        open={retrievalSuccessModal}
        setModal={() => setRetrievalSuccessModal(false)}
      />
      <RetrieveErrorDialog
        open={!!retrievalErrorModal}
        message={retrievalErrorModal}
        setModal={() => setRetrievalErrorModal(false)}
      />
      <VoidOrderDialog
        open={!!voidOrder}
        setModal={() => setVoidOrder(undefined)}
        onAgree={async () => {
          await dispatch(
            updateOrderStatus({
              user: emailid,
              orderId: voidOrder?.ID,
              status: "Void",
            })
          );
          setVoidOrder(undefined);
          if (currentPage === 1) {
            const orders = await dispatch(
              getOrders({
                emailid,
                state: category,
                skip: 0,
              })
            );
            if (!orders.error) {
              setOrders(orders);
            }
          } else {
            setCurrentPage(1);
          }
        }}
      />

      {duplicateModalVisibility && (
        <DuplicateOrderModal
          open={!!duplicateModalVisibility}
          setModal={async (v) => {
            const order = duplicateModalVisibility;
            setDuplicateModalVisibility(false);
            if (v?.value) {
              let orders = [];
              for (let i = 0; i < v?.value; i++) {
                const currentDate = moment().format("YYYY-MM-DD");
                const orderNumber = order?.ID + _.random(1, 999999999) + i;
                const orderLinesId =
                  order?.OrderLines[0]?.ID + _.random(1, 999999999) + i;
                const payload = {
                  ...order,
                  ID: orderNumber,
                  ReceiptID: `S-${orderNumber}`,
                  DateCreated: currentDate,
                  OrderLines: order?.OrderLines?.map((orderLine) => {
                    return {
                      ...orderLine,
                      ID: orderLinesId,
                      OrderID: orderNumber,
                      CreateDate: currentDate,
                      OrderLineItems: orderLine?.OrderLineItems?.map(
                        (orderLineItem) => {
                          return {
                            ...orderLineItem,
                            ID: orderLineItem?.ID + _.random(1, 999999999) + i,
                            OrderLineID: orderLinesId,
                            CreateDate: currentDate,
                          };
                        }
                      ),
                    };
                  }),
                };
                delete payload?._id;
                delete payload?.checked;
                orders.push(payload);
              }

              await dispatch(
                duplicateOrder({
                  orders,
                  user: emailid,
                })
              );

              if (currentPage === 1) {
                const orders = await dispatch(
                  getOrders({
                    emailid,
                    state: category,
                    skip: 0,
                  })
                );
                if (!orders.error) {
                  setOrders(orders);
                }
              } else {
                setCurrentPage(1);
              }
            }
          }}
        />
      )}
      {ordersSubmitted && (
        <OrderSubmittedModal
          open={ordersSubmitted}
          setModal={() => setOrdersSubmitted(false)}
        />
      )}

      {syncProductModal && (
        <SyncProductProgressModal
          open={!!syncProductModal}
          data={syncProductModal}
          setOpen={() => setSyncProductModal(null)}
        />
      )}
      {error && <ErrorModal open={error} setOpen={() => setError(false)} />}
    </Container>
  );
};

export default Orders;
