import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import LoaderSvg from 'assets/svg/loader.svg';
import COLORS from 'shared/constants/colors';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999,
    backgroundColor: 'rgba(255,255,255,0.4) !important',
    color: COLORS.WHITE,
  },
}));

const Loader = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <img src={LoaderSvg} alt='Shirtly Loader' />
    </Backdrop>
  );
};

Loader.propTypes = {
  open: PropTypes.bool,
};

export default Loader;
