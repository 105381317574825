import styled from 'styled-components';

const Description = styled.div`
  p {
    margin: 0;
    font-size: 14px;
    line-height: 32px;
    :first-child {
      margin-bottom: 15px;
      border-bottom: 1px solid #efefef;
      padding-bottom: 15px;
    }
  }
`;

export { Description };
