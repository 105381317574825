import React from 'react';
import Description from 'pages/product-detail/Description';
import ColorAvailable from 'pages/product-detail/ColorAvailable';
import AdditionalImages from 'pages/product-detail/AdditionalImages';
import SizePrice from './Size&Price';
import ShippingCost from './ShippingCost';
import { useStyles } from 'styles/pages/product-detail/accordion';

const Accordion = ({ data, onColorSelect, onImageSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Description data={data} />
      <AdditionalImages onImageSelect={onImageSelect} data={data} />
      <ColorAvailable onColorSelect={onColorSelect} data={data} />
      <SizePrice data={data} />
      <ShippingCost data={data} />
    </div>
  );
};

export default Accordion;
