import React, { useState } from 'react';
// import SyncedProducts from 'pages/my-stores/products/SyncedProducts';
import ProductCreator from 'pages/my-stores/products/product-creator';
import GeneratedMocks from 'pages/my-stores/products/GeneratedMocks';
import {
  Container,
  TabWrapper,
  Tab,
} from 'styles/pages/my-stores/products/productSection';

const ProductSection = () => {
  const [tab, setTab] = useState(0);

  return (
    <Container>
      <TabWrapper>
        <Tab selected={tab === 0} onClick={() => setTab(0)}>
          Mock-Up Generator
        </Tab>
        <Tab selected={tab === 1} onClick={() => setTab(1)}>
          Generated Mocks
        </Tab>
      </TabWrapper>

      {tab === 0 && <ProductCreator />}
      {tab === 1 && <GeneratedMocks />}
    </Container>
  );
};

export default ProductSection;
