import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getStyles } from 'selectors/tags';
import { imagesBaseURL } from 'shared/requests';
import {
  Container,
  Item,
  Title,
  Label,
  Img,
} from 'styles/pages/browse-catalog/stylesFilter';

const StylesFilter = ({ stylesFilter, updateStylesFilter }) => {
  const tags = useSelector((state) => state?.tags?.data) || [];
  const styles = getStyles(tags);
  return (
    <Container>
      {styles.map((style, index) => {
        const selected = !!_.filter(stylesFilter, (o) => o.Id === style.Id)
          ?.length;
        return (
          <Item
            selected={selected}
            key={`StylesFilter${index}`}
            onClick={() => updateStylesFilter(style)}>
            <Label>
              <Img
                alt={style.DisplayName}
                src={`${imagesBaseURL}/Images/styles/${style.DisplayName.replace(
                  ' ',
                  '_'
                )}.jpg`}
              />
              <Title>{style.DisplayName}</Title>
            </Label>
          </Item>
        );
      })}
    </Container>
  );
};

export default StylesFilter;
