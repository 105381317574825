import Slider from 'react-slick';
import { Container, Wrapper, CardWrapper } from 'styles/pages/home/reviews';

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  focusOnSelect: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const Card = ({ userName, description, club, image }) => (
  <CardWrapper>
    <p>{description}</p>
    <div className='userDes'>
      <div className='userImg'>
        <img src={image} alt='user' />
      </div>
      <h4>{userName}</h4>
      <span>{club}</span>
    </div>
  </CardWrapper>
);

const UserComments = ({ data = {} }) => {
  const { components } = data;
  const { Title, Cards = [] } = components || {};

  return (
    <Container>
      <Wrapper>
        <h2 className='heading'>{Title}</h2>

        <Slider {...settings}>
          {Cards?.map((c, i) => (
            <Card
              key={`card${i}`}
              userName={c?.CardTitle}
              description={c?.CardDescription}
              club={c?.CardSubTitle}
              image={c?.CardImage}
            />
          ))}
        </Slider>
      </Wrapper>
    </Container>
  );
};

export default UserComments;
