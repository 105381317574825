import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserInformation,
  yotiCheckStatus,
  yotiVerificationComplete,
} from "actions/user";
import Notifications from "pages/dashboard/Notifications";
import DashboardWebPage from "pages/web-pages/Dashboard";
import { updateField } from "actions/common";
import { SHOW_YOTI_MODAL } from "actions/yoti";
import ErrorMessage from "components/ErrorMessage";
import { getWebPage } from "actions/webPages";
import { getAllSubmittedOrders, getOrders } from "actions/orders";
import EmailConfirmationDialog from "pages/dashboard/EmailConfirmationDialog";
import CheckIcon from "@material-ui/icons/Check";
import Header from "pages/dashboard/Header";
import YotiVerificationStatusModal from "pages/dashboard/YotiVerificationStatusModal";
import { Container, ApprovedKyc } from "styles/pages/dashboard";
import BestSelllersImage from "assets/images/best-sellers-products.png";
import {
  DashboardWebPageWrapper,
  NotificationsWrapper,
} from "styles/pages/home";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [yotiModal, setYotiModal] = useState();
  const [dashboardData, setDashboardData] = useState();
  const emailId = useSelector((state) => state?.auth?.profile?.emailid);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const fullName = useSelector((state) => state?.auth?.profile?.FullName);
  const id = useSelector((state) => state?.auth?.profile?.ID);
  const [emailConfirmation, setEmailConfirmation] = useState(false);
  const [user, setUser] = useState(undefined);
  const [ordersCount, setOrdersCount] = useState(null);
  const isAdmin = emailId === "support@shirtly.com";
  const emailConfirmed = user?.User?.EmailConfirmed;

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!user) {
        const request = await dispatch(getUserInformation(emailId));
        if (request?.error) {
          return;
        }
        setUser(request);
      }
    };
    fetchUserDetails();
  }, [dispatch, emailId, user]);

  useEffect(() => {
    const fetchData = async () => {
      const request = await dispatch(getWebPage({ page: "Dashboard" }));
      if (request?.error) {
        return;
      }
      setDashboardData(request);
    };
    if (!dashboardData) fetchData();
  }, [dispatch, dashboardData]);

  useEffect(() => {
    const getOrdersCount = async () => {
      const request = isAdmin
        ? await dispatch(
            getAllSubmittedOrders(
              {
                skip: 0,
                take: 10,
              },
              token
            )
          )
        : await dispatch(
            getOrders({
              state: "PendingOrders",
              skip: 0,
              emailid: emailId,
            })
          );

      const data = isAdmin
        ? {
            TotalOrders: request?.orderCounts?.TotalOrders,
            Shipped: request?.orderCounts?.TotalShipped,
            InProduction: request?.orderCounts?.TotalInProduction,
            Cancelled: request?.orderCounts?.TotalCancelled,
          }
        : {
            TotalOrders: request?.orderCounts?.All,
            AwaitingPayment: request?.orderCounts?.AwaitingPayment,
            Shipped: request?.orderCounts?.Shipped,
            InProduction: request?.orderCounts?.InProduction,
            Cancelled: request?.orderCounts?.Cancelled,
            PendingDetails: request?.orderCounts?.PendingDetails,
            StoreConnected: request?.orderCounts?.StoreConnected,
            Voided: request?.orderCounts?.Voided,
          };

      setOrdersCount(data);
    };

    if (emailId) {
      getOrdersCount();
    }
  }, [dispatch, emailId]);

  const renderKYC = () => {
    return user?.YotiVerified ? (
      user?.YotiVerificationStatus === "Pending" ? (
        <ErrorMessage className="warning">
          <p>
            <a
              rel="noreferrer"
              href="https://www.yoti.com/blog/our-approach-to-security-and-privacy/"
              target="_blank"
            >
              FAQ’s and Security using Yoti
            </a>
            <br />
            <br />
            Your submited documents is under review by YOTI.&nbsp;
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={async () => {
                const data = await dispatch(
                  yotiCheckStatus({
                    user: emailId,
                    sessionId: user?.YotiSessionData?.sessionId,
                  })
                );

                const isVerified = !!(
                  data?.find((o) => o?.state === "DONE") &&
                  data?.find(
                    (o) => o?.report?.recommendation?.value === "APPROVE"
                  )
                );

                const isRejected = !!(
                  data?.find((o) => o?.state === "DONE") &&
                  data?.find(
                    (o) => o?.report?.recommendation?.value === "REJECT"
                  )
                );

                const isNotAvailable = !!(
                  data?.find((o) => o?.state === "DONE") &&
                  data?.find(
                    (o) => o?.report?.recommendation?.value === "NOT_AVAILABLE"
                  )
                );

                if (isVerified) {
                  await dispatch(
                    yotiVerificationComplete({
                      user: emailId,
                      YotiVerified: true,
                      YotiVerificationStatus: "Approve",
                    })
                  );
                  setUser(undefined);
                } else if (isRejected || isNotAvailable) {
                  await dispatch(
                    yotiVerificationComplete({
                      user: emailId,
                      YotiVerified: false,
                      YotiVerificationStatus: "Reject",
                    })
                  );
                  setUser(undefined);
                }

                setYotiModal(data);
              }}
            >
              Check Status
            </span>
            <br />
            Verification take upto 10-15 minutes to complete.
          </p>
        </ErrorMessage>
      ) : user?.YotiVerificationStatus === "Approve" ? (
        <ErrorMessage className="success">
          <ApprovedKyc>
            <CheckIcon />
            <b>KYC complete. Submitted documents has been approved.</b>
          </ApprovedKyc>
        </ErrorMessage>
      ) : null
    ) : (
      <ErrorMessage className="warning">
        <p>
          <a
            rel="noreferrer"
            href="https://www.yoti.com/blog/our-approach-to-security-and-privacy/"
            target="_blank"
          >
            FAQ’s and Security using Yoti
          </a>
          <br />
          <br />
          KYC (Know Your Customer). To start KYC&nbsp;
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => dispatch(updateField(SHOW_YOTI_MODAL))}
          >
            Click Here.
          </span>
        </p>
      </ErrorMessage>
    );
  };

  return (
    <Container>
      <Header />
      {renderKYC()}

      <h1
        style={{
          background: "#000",
          color: "#fff",
          textAlign: "center",
          padding: "10px 0",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          marginBottom: 0,
        }}
      >
        Top 10 Best Sellers
      </h1>

      <div
        style={{
          background: "#fff",

          textAlign: "center",
          padding: "10px 0 0",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <marquee scrollamount="12">
          <img src={BestSelllersImage} alt="best-seller" />
        </marquee>
      </div>

      {/* <ErrorMessage className="error">
        <ApprovedKyc>
          <ReportProblemIcon />
          <b>Shirtly is under maintenance, please try again after sometime.</b>
        </ApprovedKyc>
      </ErrorMessage> */}

      {/* {dashboardData && dashboardData?.length > 0 && (
        <DashboardWebPageWrapper style={{ maxWidth: "100%" }}>
          <DashboardWebPage data={dashboardData} isAdmin={false} />
          <NotificationsWrapper>
            <Notifications />
          </NotificationsWrapper>
        </DashboardWebPageWrapper>
      )} */}
      {/* <SubHeader
        title="Orders Information"
        rightCornerButtons={
          emailConfirmed
            ? null
            : [
                {
                  title: "Confirm Email",
                  onClick: async () => {
                    const request = await dispatch(
                      confirmEmail({
                        user: emailId,
                        fullName,
                        id,
                      })
                    );
                    if (request?.error) {
                      return;
                    }
                    setEmailConfirmation(true);
                  },
                },
              ]
        }
      /> */}

      {/* <Stats ordersCount={ordersCount} isAdmin={isAdmin} /> */}
      {/* <Tabs /> */}
      {/* <Welcome>Welcome to Shirtly !</Welcome>
      <Stepper />
      <OrdersChart /> */}

      {emailConfirmation && (
        <EmailConfirmationDialog
          open={emailConfirmation}
          setOpen={(v) => setEmailConfirmation(v)}
        />
      )}

      {!!yotiModal && (
        <YotiVerificationStatusModal
          open={!!yotiModal}
          data={yotiModal}
          setOpen={() => setYotiModal(null)}
        />
      )}
    </Container>
  );
};

export default Dashboard;
