import _ from 'lodash';
import Image from 'material-ui-image';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import COLORS from 'shared/constants/colors';

const useStyles = makeStyles({
  root: {
    color: COLORS.BLUE,
  },
});

const CustomImage = ({
  src,
  alt,
  aspectRatio = (1, 1),
  imageStyle,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Image
      imageStyle={imageStyle}
      src={src}
      alt={alt}
      aspectRatio={aspectRatio}
      style={{ backgroundColor: 'inherit' }}
      loading={<CircularProgress classes={classes} />}
      onClick={() => _.isFunction(onClick) && onClick()}
    />
  );
};

export default CustomImage;
