import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  margin: 15px 0;
  /* padding: 20px; */
  border-radius: 10px;
`;
const CategoriesWrapper = styled.div`
  display: flex;
  /* grid-gap: 20px; */
  flex-direction: column;
`;
const ProductColorWrapper = styled.div`
  > h3 {
    span {
      color: #5c92e2;
      cursor: pointer;
    }
  }
  > div {
    margin: 15px 0;
    > div {
      display: flex;
      gap: 20px;
      margin-bottom: 25px;
      > div {
        /* width: 130px;
        height: 150px; */
        position: relative;
        padding: 12px;
        background: #fff;
        border-radius: 10px;
        min-width: 190px;
        max-width: 190px;
        border: ${(props) => (props?.premium ? "2px solid #a1a1a1" : "none")};
      }
    }
    img {
      /* max-width: 120px; */
      cursor: pointer;
      width: 100% !important;
      max-width: 500px;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
    }
  }
`;
const FooterButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  grid-gap: 15px;
  > button {
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    text-transform: capitalize;
    font-family: "Inter500";
    border-radius: 6px;
    box-shadow: none;
    :hover {
      background-color: ${COLORS.BLUE};
      opacity: 0.8;
    }
    :disabled {
      opacity: 0.5;
      color: #fff;
      background-color: ${COLORS.BLUE};
    }
    :first-child {
      background-color: #616161;
      :disabled {
        background-color: #616161;
      }
    }
  }
`;

const ProductColors = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  display: grid;
  grid-gap: 20px;
`;

const CategoriesContainer = styled.div`
  /* grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  display: grid; */
  gap: 20px;
  display: flex;
  margin-bottom: 25px;
`;
const CategoryBox = styled.div`
  color: #18191f;
  background-color: #fff;
  padding: 25px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  min-width: 190px;
  .country-name {
    margin-bottom: 0;
  }
  img {
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
  }
  p {
    font-size: 16px;
    line-height: 32px;
    font-family: "Inter600";
    margin: 0;
    border-top: 1px solid #f0f0f0;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  z-index: 1;
  svg {
    color: #fff;
    width: 18px;
  }
`;
const ProductContainer = styled.div`
  gap: 20px;
  display: flex;
  margin-bottom: 25px;
`;
const ProductBox = styled.div`
  color: #18191f;
  background-color: #fff;
  padding: 25px 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  min-width: 190px;
  max-width: 190px;

  border: ${(props) => (props?.premium ? "2px solid #a1a1a1" : "none")};

  img {
    border-radius: 10px;
    overflow: hidden;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    font-family: "Inter600";
    margin: 0;
    border-top: 1px solid #f0f0f0;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    padding-top: 10px;
  }
`;

const Heading = styled.h6`
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  margin: 0 0 15px;
  span {
    font-size: 14px;
    color: #5c92e2;
    cursor: pointer;
    text-decoration: underline;
  }
`;
const ColorContainer = styled.div`
  p {
    margin: 0;
    text-align: center;
    font-family: "Inter600";
    border-top: 1px solid #f0f0f0;
    padding-top: 5px;
  }
`;
const ColorBox = styled.div``;
const ProductColorsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
`;
const GeneratedMockups = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 20px;
  > a {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    img {
      padding: 0;
    }
    .download-icon {
      color: #5c92e2;
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
`;
const BoxWrapper = styled.div`
  overflow: auto;
  width: calc(100vw - 55px);
`;

const DecoratingWrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  .title {
    margin: 0;
    padding-bottom: 3px;
    margin-bottom: 7px;
    border-bottom: 1px solid #ccc;
  }

  .decoration-techniques {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    margin-top: 10px;

    > div {
      flex: 1;
    }
    .color {
      display: flex;
      flex-direction: row;
      img {
        width: 40px;
        margin-right: 15px;
      }
    }
  }
`;

const SaveArtFile = styled.div`
  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px;
  }
  button {
    background-color: #5c92e2;
    color: #ffffff;
    text-transform: capitalize;
    font-family: "Inter500";
    border-radius: 6px;
    box-shadow: none;
    float: right;
    margin-bottom: 10px;
  }
`;

const Premium = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #a1a1a1;
  font-size: 12px;
  padding: 5px;
  font-style: italic;
  color: white;
  border-top-left-radius: 5px;
  font-weight: bolder;
  border-bottom-right-radius: 5px;
`;

const AiActivated = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 40px;
  }

  h1 {
    margin: 0;
    font-size: 14px;
    border: 1px solid black;
    background-color: #569f56;
    color: white;
    padding: 5px 20px;
  }
`;

export {
  Container,
  CategoriesWrapper,
  ProductColorWrapper,
  FooterButton,
  ProductColors,
  CategoriesContainer,
  CategoryBox,
  IconWrapper,
  ProductContainer,
  ProductBox,
  Heading,
  ColorContainer,
  ColorBox,
  ProductColorsBox,
  GeneratedMockups,
  BoxWrapper,
  DecoratingWrapper,
  SaveArtFile,
  Premium,
  AiActivated,
};
