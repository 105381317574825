import styled from "styled-components";

export const Container = styled.div`
  padding: 15px;
  background-color: #000;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  color: #5c92e2;

  h2 {
    margin: 0;
    color: #fff;
  }

  svg {
    cursor: pointer;
    color: #fff;
  }
`;

export const StyledBody = styled.div`
  margin: 20px 0;
`;

export const StyledRowWrapper = styled.div`
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const StyledRow = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);

  label,
  button,
  .MuiInput-input,
  .Mui-focused,
  .MuiInputBase-input {
    color: #fff !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
  }
`;

export const IconWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;

  img {
    width: 40px;
  }
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  button:nth-child(1) {
    background-color: #5c92e2;
    color: white;
    :disabled {
      opacity: 0.5;
    }
  }

  button:nth-child(2) {
    background-color: red;
    color: white;
    margin-left: 20px;
    :disabled {
      opacity: 0.5;
    }
  }
`;
