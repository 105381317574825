import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/routes';
import Button from 'components/Button';
import SubHeader from 'components/SubHeader';
import Etsy from 'assets/images/logo-etsy.png';
import Shopify from 'assets/images/logo-shopify.png';
import COLORS from 'shared/constants/colors';
import AddIcon from '@material-ui/icons/Add';
import EtsyModal from 'pages/my-stores/registration/EtsyModal';
import VerifierModal from 'pages/my-stores/registration/VerifierModal';
import {
  Container,
  Row1,
  Row2,
  Description,
  Store,
  Img,
} from 'styles/pages/my-stores/registration';

const Registration = () => {
  const history = useHistory();
  const [etsyModal, setEtsyModal] = useState(false);
  const [verifierModal, setVerifierModal] = useState(false);
  const [etsyToken, setEtsyToken] = useState(undefined);

  return (
    <React.Fragment>
      <SubHeader
        title={ROUTES.MY_STORES_REGISTRATION.TITLE}
        links={[
          {
            title: ROUTES.DASHBOARD.TITLE,
            to: ROUTES.DASHBOARD.LINK,
          },
          {
            title: ROUTES.MY_STORES.TITLE,
            to: ROUTES.MY_STORES.LINK,
          },
        ]}
        currentPageTitle={ROUTES.MY_STORES_REGISTRATION.TITLE}
      />

      <Container>
        <Row1>
          <h3>Add a Store - Choose Your Platform</h3>
          <Description>
            <p>
              Which marketplace are you hosting your store with? Pick one option
              below.
            </p>
            <p>
              After you connect your Store, orders made through your Store will
              automatically appear in Shirtly.
            </p>
            <p>
              You can add as many stores as you want and manage them all from
              one convenient location!
            </p>
          </Description>
        </Row1>
        <Row2>
          <Store>
            <h2>Etsy</h2>
            <Img src={Etsy} alt="Esty_logo" />
            <Button
              containerStyle={{
                fontSize: '14px',
                minWidth: '120px',
                borderRadius: '3px',
                padding: '7px 5px',
                lineHeight: '1.5',
                textTransform: 'inherit',
                color: COLORS.WHITE,
                backgroundColor: 'transparent',
              }}
              startIcon={<AddIcon />}
              variant="text"
              title="Connect to Etsy"
              onClick={() => setEtsyModal(true)}
            />
          </Store>
          <Store>
            <h2>Shopify</h2>
            <Img src={Shopify} alt="Shopify_logo" />
            <Button
              containerStyle={{
                fontSize: '14px',
                minWidth: '120px',
                borderRadius: '3px',
                padding: '7px 5px',
                lineHeight: '1.5',
                textTransform: 'inherit',
                color: COLORS.WHITE,
                backgroundColor: 'transparent',
              }}
              startIcon={<AddIcon />}
              variant="text"
              title="Connect to Shopify"
              onClick={() => history.push(ROUTES.CONNECT_TO_SHOPIFY.LINK)}
            />
          </Store>
        </Row2>
      </Container>
      {etsyModal && (
        <EtsyModal
          modal={etsyModal}
          setModal={(v) => setEtsyModal(v)}
          showVerifierModal={(data) => {
            setEtsyModal(false);
            setEtsyToken(data);
            setVerifierModal(true);
          }}
        />
      )}
      {verifierModal && (
        <VerifierModal
          token={etsyToken}
          modal={verifierModal}
          setModal={(v) => setVerifierModal(v)}
        />
      )}
    </React.Fragment>
  );
};

export default Registration;
