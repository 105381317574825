import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProductCard from "pages/sync-product/ProductCard";
import ArtWork from "components/ArtWork";
import Footer from "pages/sync-product/Footer";
import {
  getArtworkSettingsProductId,
  getPrintTypeFromProductId,
} from "selectors/products";
import { showLoader, hideLoader } from "actions/loader";
import { syncProduct, updateSyncedProduct } from "actions/products";
import { ROUTES } from "shared/constants/routes";
import { Wrapper, ProductSummary } from "styles/pages/sync-product";

const onloadImageProcess = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const ProductDetail = ({ product, setProduct, marketPlaceType, shopName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const allProducts = useSelector((state) => state?.products?.data);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const variants = product?.variants;
  const selectedVariant = variants?.find((v) => v?.selected);
  const printAreas = selectedVariant?.syncData?.printAreas;
  const selectedPrintArea = printAreas?.find((p) => p?.selected);

  const disabled = !!variants?.find(
    (v) =>
      !v?.syncData?.ProductID ||
      !v?.syncData?.sizeId ||
      !v?.syncData?.categoryId ||
      !v?.syncData?.ColorID ||
      !selectedPrintArea?.artwork ||
      (v?.syncData?.dtgPlus &&
        (!v?.syncData?.dtgVectorFile ||
          !v?.syncData?.dtgProcessName ||
          !v?.syncData?.dtgColorName ||
          !v?.syncData?.dtgPlusMockFile)) ||
      (v?.syncData?.plus &&
        (!v?.syncData?.dtgProcessName || !v?.syncData?.dtgColorName))
  );

  return (
    <Wrapper>
      <ProductSummary>
        {!!variants?.length &&
          variants?.map((v) => {
            const id = v?.id;
            return (
              <ProductCard
                key={id}
                setProduct={setProduct}
                marketPlaceType={marketPlaceType}
                variant={v}
                product={product}
              />
            );
          })}
        <Footer
          disabled={disabled}
          onSave={async () => {
            let payload = { ...product, marketPlaceType, emailid, shopName };

            payload = {
              ...payload,
              variants: payload?.variants?.map((v) => {
                const printType = getPrintTypeFromProductId(
                  allProducts,
                  v?.syncData?.ProductID
                );

                return {
                  ...v,
                  syncData: {
                    ...v?.syncData,
                    timestamp: moment().unix(),
                    PrintType: v?.syncData?.PrintType || printType,
                  },
                };
              }),
            };

            if (payload?.synced) {
              await dispatch(
                updateSyncedProduct({
                  variants: payload?.variants,
                  user: emailid,
                  id: payload?.id,
                })
              );
            } else {
              await dispatch(syncProduct(payload));
            }

            history.push(ROUTES.MY_STORES_SYNC_PRODUCTS.LINK);
          }}
        />
      </ProductSummary>
      {!!selectedPrintArea && (
        <ArtWork
          setOrderLine={(data) =>
            setProduct((prevState) => ({
              ...prevState,
              variants: prevState?.variants?.map((v) =>
                v?.id === selectedVariant?.id
                  ? {
                      ...v,
                      syncData: data,
                    }
                  : v
              ),
            }))
          }
          onFabricDrop={(canvasJSON) => {
            setProduct((prevState) => ({
              ...prevState,
              variants: prevState?.variants?.map((v) =>
                v?.id === selectedVariant?.id
                  ? {
                      ...v,
                      syncData: {
                        ...v?.syncData,
                        printAreas: v?.syncData?.printAreas?.map((p) =>
                          p?.selected
                            ? {
                                ...p,
                                canvasJSON,
                              }
                            : p
                        ),
                      },
                    }
                  : v
              ),
            }));
          }}
          onFabricRemove={() =>
            setProduct((prevState) => ({
              ...prevState,
              variants: prevState?.variants?.map((v) =>
                v?.id === selectedVariant?.id
                  ? {
                      ...v,
                      syncData: {
                        ...v?.syncData,
                        printAreas: v?.syncData?.printAreas?.map((p) =>
                          p?.selected
                            ? {
                                ...p,
                                canvasJSON: null,
                              }
                            : p
                        ),
                      },
                    }
                  : v
              ),
            }))
          }
          setCoordinates={(coordinates) =>
            setProduct((prevState) => ({
              ...prevState,
              variants: prevState?.variants?.map((v) =>
                v?.id === selectedVariant?.id
                  ? {
                      ...v,
                      syncData: {
                        ...v?.syncData,
                        printAreas: v?.syncData?.printAreas?.map((p) =>
                          p?.selected
                            ? {
                                ...p,
                                coordinates,
                              }
                            : p
                        ),
                      },
                    }
                  : v
              ),
            }))
          }
          orderLine={selectedVariant?.syncData}
          onPreview={(previewImage) =>
            setProduct((prevState) => ({
              ...prevState,
              variants: prevState?.variants?.map((v) =>
                v?.id === selectedVariant?.id
                  ? {
                      ...v,
                      syncData: {
                        ...v?.syncData,
                        printAreas: v?.syncData?.printAreas?.map((p) =>
                          p?.selected
                            ? {
                                ...p,
                                previewImage,
                              }
                            : p
                        ),
                      },
                    }
                  : v
              ),
            }))
          }
          onRemove={() =>
            setProduct((prevState) => ({
              ...prevState,
              variants: prevState?.variants?.map((v) =>
                v?.id === selectedVariant?.id
                  ? {
                      ...v,
                      syncData: {
                        ...v?.syncData,
                        printAreas: v?.syncData?.printAreas?.map((p) =>
                          p?.selected
                            ? {
                                ...p,
                                artwork: null,
                                artworkId: null,
                                coordinates: {},
                              }
                            : p
                        ),
                      },
                    }
                  : v
              ),
            }))
          }
          onDrop={async (artwork) => {
            const greyOut = getArtworkSettingsProductId(
              allProducts,
              selectedVariant?.ProductID
            )?.greyOut;

            let secureUrl = artwork.secure_url;

            if (greyOut) {
              const secureUrlSplit = secureUrl.split("/upload/");
              secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:aaa9ad/${secureUrlSplit[1]}`;

              dispatch(showLoader());
              await fetch(secureUrl);
              dispatch(hideLoader());
            } else if (
              selectedVariant?.plus &&
              !!selectedVariant?.dtgColorName
            ) {
              const selectedColor =
                selectedVariant?.dtgProcessName?.Colors?.find(
                  (c) =>
                    c?.ColorName === selectedVariant?.dtgColorName?.ColorName
                );

              if (secureUrl) {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const secureUrlSplit = secureUrl.split("/upload/");
                secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;

                dispatch(showLoader());

                const img1 = await onloadImageProcess(secureUrl);
                const img2 = await onloadImageProcess(
                  selectedColor?.ColorImagePath
                );

                canvas.width = img1.width * 0.5;
                canvas.height = img1.height * 0.5;

                ctx.fillStyle = ctx.createPattern(img2, "repeat");

                // fill canvas with pattern
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // use blending mode multiply
                ctx.globalCompositeOperation = "multiply";

                // draw sofa on top
                ctx.drawImage(img1, 0, 0, img1.width * 0.5, img1.height * 0.5);

                // change composition mode
                ctx.globalCompositeOperation = "destination-in";

                // draw to cut-out sofa
                ctx.drawImage(img1, 0, 0, img1.width * 0.5, img1.height * 0.5);

                const dataURL = canvas.toDataURL("image/png");
                const API_ENDPOINT =
                  "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

                const fileData = new FormData();
                fileData.append("file", dataURL);
                fileData.append("upload_preset", "tnedst8q");
                fileData.append("folder", `BOTApp/${emailid}/PlusArtFiles`);

                const data = await fetch(API_ENDPOINT, {
                  method: "post",
                  body: fileData,
                });

                dispatch(hideLoader());

                const json = await data?.json();
                secureUrl = json?.secure_url;
                artwork.public_id = json?.public_id;
              }
            }

            const designerSettings = selectedPrintArea?.designerSettings;

            const ArtWorkCoordinates = selectedPrintArea?.coordinates || {};

            const { width: fileWidth, height: fileHeight } = artwork;

            const { width: designedWidth, height: designedHeight } =
              designerSettings;

            if (designedWidth > designedHeight) {
              const aspectRatio = fileWidth / fileHeight;
              if (fileWidth > fileHeight) {
                if (fileWidth > designedWidth) {
                  ArtWorkCoordinates.height = Math.round(
                    designedWidth / aspectRatio
                  );
                  ArtWorkCoordinates.width = designedWidth;

                  if (ArtWorkCoordinates.height > designedHeight) {
                    ArtWorkCoordinates.height = designedHeight;
                  }
                } else {
                  ArtWorkCoordinates.height = designedHeight;
                  ArtWorkCoordinates.width = Math.round(
                    designedHeight * aspectRatio
                  );
                }
              } else {
                ArtWorkCoordinates.height = designedHeight;
                ArtWorkCoordinates.width = Math.round(
                  designedHeight * aspectRatio
                );
              }
            } else if (
              fileWidth <= designedWidth &&
              fileHeight <= designedHeight
            ) {
              ArtWorkCoordinates.width = fileWidth;
              ArtWorkCoordinates.height = fileHeight;
            } else if (fileWidth > fileHeight) {
              const aspectRatio = fileWidth / fileHeight;
              ArtWorkCoordinates.width = designedWidth;
              ArtWorkCoordinates.height = Math.round(
                designedWidth / aspectRatio
              );
            } else if (fileWidth < fileHeight) {
              const aspectRatio = fileWidth / fileHeight;

              if (designedHeight * aspectRatio > designedWidth) {
                ArtWorkCoordinates.width = designedWidth;
                ArtWorkCoordinates.height = Math.round(
                  designedWidth / aspectRatio
                );
              } else {
                ArtWorkCoordinates.height = designedHeight;
                ArtWorkCoordinates.width = Math.round(
                  designedHeight * aspectRatio
                );
              }
            } else if (fileWidth === fileHeight) {
              if (designedWidth > designedHeight) {
                ArtWorkCoordinates.height = designedHeight;
                ArtWorkCoordinates.width = designedHeight;
              } else {
                ArtWorkCoordinates.height = designedWidth;
                ArtWorkCoordinates.width = designedWidth;
              }
            }

            setProduct((prevState) => ({
              ...prevState,
              variants: prevState?.variants?.map((v) => ({
                ...v,
                syncData: {
                  ...v?.syncData,
                  printAreas: v?.syncData?.printAreas?.map((p) =>
                    p?.selected && v?.id === selectedVariant?.id
                      ? // ||
                        // (!p?.artwork && v?.id !== selectedVariant?.id)
                        {
                          ...p,
                          artwork: secureUrl,
                          artworkId: artwork.public_id,
                          coordinates: ArtWorkCoordinates,
                        }
                      : p
                  ),
                },
              })),
            }));
          }}
        />
      )}
    </Wrapper>
  );
};

export default ProductDetail;
