import SubHeader from "components/SubHeader";
import { ROUTES } from "shared/constants/routes";

const Breadcrumb = () => (
  <SubHeader
    isDark
    title="Sales Report"
    links={[
      {
        title: ROUTES.DASHBOARD.TITLE,
        to: ROUTES.DASHBOARD.LINK,
      },
    ]}
    currentPageTitle={ROUTES.USER_SALES_REPORT.TITLE}
  />
);

export default Breadcrumb;
