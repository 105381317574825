import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from 'pages/home/Header';
import { getWebPage } from 'actions/webPages';
import NewsCard from 'pages/news-room/NewsCard';
import { Container, Wrapper, NewsCards, Title } from 'styles/pages/news-room';

const NewsRoom = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getWebPageData = async () => {
      const data = await dispatch(getWebPage({ page: 'LandingPage' }));
      setData(() => data);
    };

    getWebPageData();
  }, []);
  return (
    <Container>
      <Header
        showNavMenu={false}
        data={data?.find((d) => d?.name === 'Header')}
      />
      <Wrapper>
        <Title>Updates and Press Releases</Title>
        <NewsCards>
          <NewsCard />
        </NewsCards>
      </Wrapper>
    </Container>
  );
};

export default NewsRoom;
