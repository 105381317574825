import React from 'react';
import _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from 'components/CheckBox';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/browse-catalog/accordion';
import { PopularFilters } from 'styles/pages/browse-catalog/sideBar.js';

const FulfillmentPlantFilter = ({ premiumFilter, updatePremiumFilter }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.heading}>
          Browse by Product Type
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PopularFilters>
          <Checkbox
            label="Premium Products"
            checked={
              premiumFilter?.length
                ? !!_.filter(premiumFilter, (o) => o === 'premium')?.length
                : false
            }
            onChange={() => updatePremiumFilter('premium')}
          />

          <Checkbox
            label="Standard Products"
            checked={
              premiumFilter?.length
                ? !!_.filter(premiumFilter, (o) => o === 'standard')?.length
                : false
            }
            onChange={() => updatePremiumFilter('standard')}
          />
        </PopularFilters>
      </AccordionDetails>
    </Accordion>
  );
};

export default FulfillmentPlantFilter;
