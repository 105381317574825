import React from 'react';
import _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import Checkbox from 'components/CheckBox';
import { getPromos } from 'selectors/tags';
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from 'styles/pages/browse-catalog/accordion';
import { PopularFilters } from 'styles/pages/browse-catalog/sideBar.js';

const TagsFilter = ({ promos, updatePromos }) => {
  const classes = useStyles();
  const tags = useSelector((state) => state?.tags?.data);
  const promosTags = getPromos(tags) || [];

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Browse by Tags</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PopularFilters>
          {promosTags.map((p) => (
            <Checkbox
              key={p.DisplayName}
              label={p.DisplayName}
              checked={
                promos?.length
                  ? !!_.filter(promos, (o) => o.Id === p.Id)?.length
                  : false
              }
              onChange={(v) => updatePromos(p)}
            />
          ))}
        </PopularFilters>
      </AccordionDetails>
    </Accordion>
  );
};

export default TagsFilter;
