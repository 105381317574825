import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { ColorBox } from 'styles/components/Color';

const Color = ({ title, onClick, code, selected }) => (
  <Tooltip
    TransitionComponent={Zoom}
    TransitionProps={{ timeout: 200 }}
    title={title}
    arrow>
    <ColorBox
      selected={selected}
      code={code}
      onClick={() => _.isFunction(onClick) && onClick()}
    />
  </Tooltip>
);

Color.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  code: PropTypes.string,
  selected: PropTypes.bool,
};

export default Color;
