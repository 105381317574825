import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  background: ${COLORS.WHITE};
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  width: 80vw;
`;
const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  transition: all ease-out 0.25s;
  position: relative;
  > div:nth-child(2) {
    box-shadow: inset 0 0 10px 0px rgb(0 0 0 / 35%);
  }
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 0.9fr;
  }
`;
const OrderPreview = styled.div`
  padding: 10px;
`;
const OrderDetail = styled.div`
  background: #f2f2f2;
  padding: 10px;
  h4 {
    border-bottom: 1px dashed #ccc;
    padding: 10px 8px;
    margin: 0;
  }

  span {
    color: ${COLORS.BLUE};
  }
`;
const OrderSummary = styled.div`
  padding: 10px;

  h4 {
    border-bottom: 1px dashed #ccc;
    padding: 10px 8px;
    margin: 0;
  }
  span {
    color: ${COLORS.BLUE};
  }
  .buttons {
    display: grid;
    grid-template-columns: auto auto auto;
  }
`;
const ProductOverview = styled.div`
  max-width: 40vw;
  margin-bottom: 40px;
  @media (max-width: 1100px) {
    max-width: 78vw;
  }
`;
const ImagePreview = styled.div`
  display: flex;
  margin-bottom: 30px;
  > img {
    width: 240px;
    height: 240px;
    object-fit: contain;
    box-shadow: inset 0 0 20px 0 #ccc;
    border: 1px solid #f3f3f3;
    transition: all ease-out 0.25s;
    background: #fff;
    margin: 0 auto;
  }
`;
const Details = styled.div`
  h4 {
    border-bottom: 1px dashed #ccc;
    padding: 10px 8px;
    margin: 0;
  }
  span {
    color: ${COLORS.BLUE};
  }
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const OrderPushedButton = styled.div`
  padding: 10px;
  border: 1px solid red;
  text-align: center;

  margin-top: 20px;
  color: red;
  font-weight: 900;
  cursor: pointer;
`;

const SendInvoice = styled.div`
  padding: 10px;
  border: 1px solid #2379b4;
  text-align: center;

  margin-top: 20px;
  color: #2379b4;
  font-weight: 900;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const CancelOrder = styled.div`
  padding: 10px;
  border: 1px solid red;
  text-align: center;

  margin-top: 20px;
  color: red;
  font-weight: 900;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const PrintAreasSlider = styled.div`
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 10px;
  }
  .slick-prev,
  .slick-next {
    display: block !important;
    z-index: 999;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #5c92e2;
    opacity: 1;
    font-size: 25px;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.5;
  }
`;

export {
  Container,
  Wrapper,
  OrderPreview,
  OrderDetail,
  OrderSummary,
  ProductOverview,
  ImagePreview,
  Details,
  OrderPushedButton,
  SendInvoice,
  CancelOrder,
  PrintAreasSlider,
};
