import React from "react";
import _ from "lodash";
import Accordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "components/CheckBox";
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from "styles/pages/browse-catalog/accordion";
import { PopularFilters } from "styles/pages/browse-catalog/sideBar.js";

const FulfillmentPlantFilter = ({
  fulfillmentPlants,
  updateFulfillmentPlants,
}) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.heading}>
          Browse by Fulfillment Plants
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PopularFilters>
          <Checkbox
            label="CA Fulfillment Plant"
            checked={
              fulfillmentPlants?.length
                ? !!_.filter(fulfillmentPlants, (o) => o === "CA")?.length
                : false
            }
            onChange={() => updateFulfillmentPlants("CA")}
          />

          <Checkbox
            label="USA Fulfillment Plant"
            checked={
              fulfillmentPlants?.length
                ? !!_.filter(fulfillmentPlants, (o) => o === "US")?.length
                : false
            }
            onChange={() => updateFulfillmentPlants("US")}
          />

          <Checkbox
            label="NY Phone Cases Fulfillment Plant"
            checked={
              fulfillmentPlants?.length
                ? !!_.filter(fulfillmentPlants, (o) => o === "NY")?.length
                : false
            }
            onChange={() => updateFulfillmentPlants("NY")}
          />

          <Checkbox
            label="EU Fulfillment Plant"
            checked={
              fulfillmentPlants?.length
                ? !!_.filter(fulfillmentPlants, (o) => o === "EU")?.length
                : false
            }
            onChange={() => updateFulfillmentPlants("EU")}
          />

          <Checkbox
            label="AU Fulfillment Plant"
            checked={
              fulfillmentPlants?.length
                ? !!_.filter(fulfillmentPlants, (o) => o === "AU")?.length
                : false
            }
            onChange={() => updateFulfillmentPlants("AU")}
          />
        </PopularFilters>
      </AccordionDetails>
    </Accordion>
  );
};

export default FulfillmentPlantFilter;
