import React from 'react';
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import { ModalContainer, Footer } from 'styles/pages/my-stores/registration';

const VoidOrderDialog = ({ open, setModal, onAgree }) => {
  return (
    <Dialog maxWidth='md' open={open} onClose={() => setModal(false)}>
      <ModalContainer>
        <h1>Order Void</h1>
        <p>
          Please confirm that you want this order to be voided and will not be
          fulfill by <b>Shirtly</b>.<br />
          <br />
          This can't be undone.
        </p>
        <Footer>
          <Button variant='text' title='Agree' onClick={() => onAgree()} />
        </Footer>
      </ModalContainer>
    </Dialog>
  );
};

export default VoidOrderDialog;
