import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEtsyLoginUrl } from "actions/etsy";
import Button from "components/Button";
import Dialog from "@material-ui/core/Dialog";
import { ModalContainer, Footer } from "styles/pages/my-stores/registration";

const getUrlParameter = (url, parameter) => {
  parameter = parameter.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?|&]" + parameter.toLowerCase() + "=([^&#]*)");
  var results = regex.exec("?" + url.toLowerCase().split("?")[1]);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const getParameterByName = (name, url) => {
  const decodeLoginUrl = decodeURIComponent(
    url.slice(url.indexOf("=") + 1, url.length)
  );
  return getUrlParameter(decodeLoginUrl, name);
};

const EtsyModal = ({ modal, setModal, showVerifierModal }) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  return (
    <Dialog maxWidth="md" open={modal} onClose={() => setModal(false)}>
      <ModalContainer>
        <h2>Authorize Your Etsy Account</h2>
        <p>
          The term 'Etsy' is a trademark of Etsy, Inc. This application uses the
          Etsy API but is not endorsed or certified by Etsy, Inc.
        </p>
        <Footer>
          <Button variant="text" title="Back" />
          <Button
            variant="text"
            title="Continue"
            onClick={async () => {
              window.open(
                `https://www.etsy.com/oauth/connect?response_type=code&client_id=3qsz4oisydriloew95xo5z28&redirect_uri=https://shirtly-app.herokuapp.com/etsyRedirect&scope=address_r%20billing_r%20cart_r%20email_r%20listings_r%20profile_r%20recommend_r%20shops_r%20transactions_r&state=${emailid}&code_challenge=DSWlW2Abh-cf8CeLL8-g3hQ2WQyYdKyiu83u_s7nRhI&code_challenge_method=S256`
              );
              setModal(false);

              return;

              const request = await dispatch(getEtsyLoginUrl());

              if (request?.error) {
                return;
              }

              const oauthToken = getParameterByName("oauth_token", request);
              const accessTokenSecret = getParameterByName(
                "oauth_token_secret",
                request
              );
              const url = decodeURIComponent(request.split("login_url=")[1]);

              if (!url) {
                setModal(false);
                return;
              }

              window.open(url);
              showVerifierModal({
                oauthToken,
                accessTokenSecret,
              });
            }}
          />
        </Footer>
      </ModalContainer>
    </Dialog>
  );
};

export default EtsyModal;
