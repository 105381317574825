import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ModalWrapper from 'components/ModalWrapper';
import DialogTitle from '@material-ui/core/DialogTitle';

const CustomDialog = ({ open, setOpen, data, triggerArtFileAction }) => (
  <Dialog open={open} onClose={setOpen}>
    <ModalWrapper className='about-us'>
      <DialogTitle>User Art File</DialogTitle>

      <DialogContentText>
        {data?.secure_url ? (
          <img src={data?.secure_url} alt='art-file' width='300' />
        ) : (
          <div style={{ margin: '0 20px' }}>
            There is no Art File attached with this Public Id
          </div>
        )}
      </DialogContentText>

      <DialogActions>
        {data?.secure_url && (
          <Button onClick={triggerArtFileAction} color='primary'>
            {data?.verified ? 'Un-Verify' : 'Verify'}
          </Button>
        )}

        <Button onClick={setOpen} color='primary'>
          Close
        </Button>
      </DialogActions>
    </ModalWrapper>
  </Dialog>
);

export default CustomDialog;
