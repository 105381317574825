import { post, get } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { requests } from 'shared/requests';

const createNotification = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.createNotification, data });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
    }
  };
};

const editNotification = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.editNotification, data });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
    }
  };
};

const getAllNotifications = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({ url: requests.getAllNotifications });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
    }
  };
};

const deleteNotification = (id) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.deleteNotification,
        data: { id },
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
    }
  };
};

export {
  createNotification,
  getAllNotifications,
  deleteNotification,
  editNotification,
};
