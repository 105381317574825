import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendIPConfirmationEmail } from 'actions/user';
import { getUserInformation } from 'actions/user';
import ErrorMessage from 'components/ErrorMessage';
import { ErrorMsgContainer } from 'styles/pages/ip-program';

const EmailConfirmedNote = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(undefined);
  const auth = useSelector((state) => state?.auth);
  const emailId = auth?.profile?.emailid;
  const emailConfirmed = user?.User?.EmailConfirmed;

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!user) {
        const request = await dispatch(getUserInformation(emailId, true));
        if (request?.error) {
          return;
        }
        setUser(request);
      }
    };
    fetchUserDetails();
  }, [dispatch, emailId, user]);

  return !emailConfirmed ? (
    <ErrorMsgContainer>
      <ErrorMessage className='warning'>
        Note: We sent a confirmation email to <b>{emailId}</b>. Please check
        your email and confirm. If you didn't receive email please&nbsp;
        <b
          className='link'
          onClick={async () => {
            await dispatch(
              sendIPConfirmationEmail({
                emailid: emailId,
                ID: auth?.profile?.ID,
              })
            );
            alert(`Email sent successfully to '${emailId}'`);
          }}>
          click here
        </b>
      </ErrorMessage>
    </ErrorMsgContainer>
  ) : null;
};

export default EmailConfirmedNote;
