import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  TableContainer,
  IconWrapper,
  TableWrapper,
} from 'styles/pages/notifications';
const columns = [
  { id: 'generateDate', label: 'Generated Date' },
  { id: 'image', label: 'Image' },
  { id: 'description', label: 'Description' },
  { label: '', id: 'actions' },
];

const NotificationsTable = ({ data = [], onDelete, onEdit }) => (
  <TableWrapper>
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, i) => (
            <TableRow hover role='checkbox' tabIndex={-1} key={`row${i}`}>
              {columns.map((column) => {
                const columnId = column.id;
                const value = row?.notification[columnId];

                if (!value && columnId !== 'actions') {
                  return <TableCell key={columnId} key={columnId} />;
                }

                return (
                  <TableCell key={columnId} key={columnId}>
                    {columnId === 'generateDate' ? (
                      moment(value).format('MMM DD,YYYY')
                    ) : columnId === 'image' ? (
                      <img src={value} alt='notification' width='100' />
                    ) : columnId === 'actions' ? (
                      <IconWrapper>
                        <Avatar>
                          <CreateIcon
                            className='edit-icon'
                            onClick={() => onEdit(row)}
                          />
                        </Avatar>
                        <Avatar>
                          <DeleteIcon
                            className='delete-icon'
                            onClick={() => onDelete(row?.id)}
                          />
                        </Avatar>
                      </IconWrapper>
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: value }} />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </TableWrapper>
);

export default NotificationsTable;
