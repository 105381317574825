import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "components/TextField";
import Switch from "components/Switch";
import CloudinaryWidget from "components/CloudinaryWidget";
import { updateSection } from "actions/webPages";
import FulfillmentClone from "pages/home/Fulfillment";
import {
  Card,
  CardTitle,
  AddSectionButton,
  AddSectionHeader,
  SectionNavbar,
  TwoBoxWrapper,
  FourBoxWrapper,
} from "styles/pages/web-pages";

const Fulfillment = ({ data }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    if (data && data?.find((d) => d?.name === "Fulfillment Done Differently")) {
      const d = data?.find((d) => d?.name === "Fulfillment Done Differently");
      setBannerData(() => d);
    }
  }, [data]);

  const { components, navVisibility, name } = bannerData || {};
  const { Title = "", BackgroundColor = "", Cards = [] } = components || {};

  return (
    <Card>
      <div>
        <FulfillmentClone data={bannerData || {}} />
      </div>

      <SectionNavbar>
        <CardTitle style={{ border: "none" }}>{name}</CardTitle>
        <Switch
          handleChange={(e) =>
            setBannerData({
              ...bannerData,
              navVisibility: e.target.checked,
            })
          }
          checked={navVisibility || false}
        />
      </SectionNavbar>

      <TwoBoxWrapper>
        <TextField
          label="Background Color"
          size="small"
          value={BackgroundColor}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                BackgroundColor: v,
              },
            })
          }
        />
        <TextField
          label="Title"
          size="small"
          value={Title}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Title: v,
              },
            })
          }
        />
      </TwoBoxWrapper>

      <CardTitle
        style={{
          marginTop: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Cards Settings
      </CardTitle>
      {Cards?.map((r, i) => (
        <FourBoxWrapper key={`button${i}`}>
          <TextField
            label="Card Title"
            size="small"
            value={r?.CardTitle || ""}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardTitle: v } : b
                  ),
                },
              })
            }
          />
          <TextField
            label="Card SubTitle"
            size="small"
            value={r?.CardSubTitle || ""}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardSubTitle: v } : b
                  ),
                },
              })
            }
          />
          <TextField
            label="Card Description"
            size="small"
            value={r?.CardDescription || ""}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardDescription: v } : b
                  ),
                },
              })
            }
          />

          <CloudinaryWidget
            label="Card Image"
            value={r?.CardImage || ""}
            setValue={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardImage: v } : b
                  ),
                },
              })
            }
            folderPath="Shirtly/Web_Pages/LandingPage/Fulfillment"
          />
        </FourBoxWrapper>
      ))}

      <AddSectionHeader>
        <AddSectionButton
          title="Update"
          containerStyle={{
            backgroundColor: "#5c92e2",
            color: "#fff",
            fontWeight: "bold",
            marginTop: 20,
          }}
          onClick={async () => {
            const payload = {
              ...bannerData,
              page: "LandingPage",
            };

            await dispatch(updateSection({ data: payload, token }));
          }}
        />
      </AddSectionHeader>
    </Card>
  );
};

export default Fulfillment;
