import _ from 'lodash';
const columns = [
  { id: 'artFile', label: 'Art File', minWidth: 90 },
  { id: 'emailId', label: 'Email ID', minWidth: 90 },
  { id: 'patentNumber', label: 'Copyright / Trademark Number', minWidth: 90 },
  { id: 'country', label: 'Copyright / Trademark Country', minWidth: 90 },
  { id: 'status', label: 'Status', minWidth: 90 },
  { id: 'createdAt', label: 'Created Date', minWidth: 90 },
  {
    id: 'patentProofLink',
    label: 'Proof Link',
    minWidth: 90,
  },
];

const getColumns = (isAdmin) => {
  if (!isAdmin) {
    return columns;
  }
  const newColumns = _.clone(columns);
  newColumns.splice(3, 0, {
    id: 'patentSearch',
    label: 'Copyright / Trademark Search',
    minWidth: 90,
  });
  return newColumns;
};

export { columns, getColumns };
