import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllDTGPlus } from "actions/products";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditDtgPlusDialog from "pages/dtg-plus/EditDtgPlusDialog";
import SubHeader from "components/SubHeader";

import Image from "components/Image";
import {
  Container,
  ItemGroup,
  Item,
  ProductLink,
  ProductImage,
  Description,
  Text,
  HeaderWrapper,
} from "styles/pages/browse-catalog/details.js";

const DtgPlus = () => {
  const [selectedDtgPlusProcess, setDtgPlusProcess] = useState(undefined);
  const [dtgPlusProducts, setDtgPlusProducts] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const request = await dispatch(getAllDTGPlus());

      if (request?.error) {
        return;
      }
      setDtgPlusProducts(request?.data);
    };
    fetchData();
  }, [dispatch, setDtgPlusProducts]);

  return (
    <>
      <Container>
        <HeaderWrapper>
          <SubHeader title="Edit DTG Plus" isDark />
          <AddCircleIcon
            style={{
              float: "right",
              cursor: "pointer",
              color: "#5c92e2",
              fontSize: 30,
            }}
            onClick={() => setDtgPlusProcess({})}
          />
        </HeaderWrapper>

        <ItemGroup>
          {dtgPlusProducts?.map((item, index) => {
            return (
              <Item
                key={`productsCatalog${index}`}
                onClick={() => setDtgPlusProcess(item)}
              >
                <ProductLink>
                  <ProductImage>
                    <Image src={item?.MarketingImage} alt={item?.DisplayName} />
                  </ProductImage>
                  <Description>
                    <Text>{item?.DisplayName}</Text>
                  </Description>
                </ProductLink>
              </Item>
            );
          })}
        </ItemGroup>
      </Container>
      {selectedDtgPlusProcess && (
        <EditDtgPlusDialog
          open={!!selectedDtgPlusProcess}
          setModal={async (visibility, reload) => {
            setDtgPlusProcess(visibility);
            if (reload) {
              const request = await dispatch(getAllDTGPlus());

              if (request?.error) {
                return;
              }
              setDtgPlusProducts(request?.data);
            }
          }}
          dtgPlus={selectedDtgPlusProcess}
        />
      )}
    </>
  );
};

export default DtgPlus;
