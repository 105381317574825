import styled from 'styled-components';

const Container = styled.div`
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 6px 0 20px;
  justify-items: start;
`;
const ProductLoop = styled.div`
  display: flex;
  column-gap: 10px;
  padding-left: 10px;
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: flex-end;
    padding-left: 0;
  }
`;
export { Container, ProductLoop };
