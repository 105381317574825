import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import TextField from 'components/TextField';
import { DashboardAddSection } from 'styles/pages/web-pages';

const CustomDialog = ({ open, setOpen, addSection }) => {
  const [sectionName, setSectionName] = useState('');
  return (
    <Dialog open={open} onClose={setOpen}>
      <ModalWrapper>
        <DialogTitle>Add Section</DialogTitle>
        <DialogContent>
          <DashboardAddSection>
            <TextField
              label="Section Name"
              value={sectionName}
              onChange={(v) => setSectionName(v)}
            />
          </DashboardAddSection>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => addSection(sectionName)}
            disabled={!sectionName}
          >
            Save
          </Button>
        </DialogActions>
      </ModalWrapper>
    </Dialog>
  );
};

export default CustomDialog;
