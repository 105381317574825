import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CourierVendors from "components/ShippingDetails/CourierVendors";
import Button from "components/Button";
import ShippingCost from "components/ShippingDetails/ShippingCost";
import USAFlag from "assets/images/usa-flag.png";
import CADFlag from "assets/images/canada-flag.png";
import EUFlag from "assets/images/eu-flag.png";
import AUFlag from "assets/images/aus-flag.jpeg";
import TextField from "components/TextField";
import { getCountries } from "actions/countries";
import Select from "components/Select";
import { getShipstationCarriers, getShipstationRates } from "actions/products";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  State,
  ButtonStyles,
  FlagStyles,
  activeButtonStyles,
  ZipCodeWrapper,
  ShippingCarriersWrapper,
  ShippingCarrier,
  Error,
  Description,
} from "styles/components/ShippingDetails";

const ShippingDetails = ({ data }) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state?.countries?.data);
  const [selectedPrintProvider, setPrintProvider] = useState(undefined);
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [selectedVendor, setVendor] = useState(undefined);
  const [shippingCarriers, setShippingCarriers] = useState([]);
  const { PrintProviders, Product } = data;
  const { weight } = Product;
  const selectedShippingCarrier = shippingCarriers?.find((s) => s?.selected);
  const isIntl = selectedVendor?.ShippingDestination === "Intl";

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  useEffect(() => {
    const p = PrintProviders[selectedPrintProvider];

    if (
      p &&
      weight &&
      p?.ProviderName !== "EU" &&
      p?.ProviderName !== "AU" &&
      p?.ProviderName !== "NY"
    ) {
      (async () => {
        const response = await dispatch(getShipstationCarriers());
        const carrierCollection = response?.find(
          (s) => s?.PrintProvider === p?.ProviderName
        )?.carrierCollection;

        const data = carrierCollection?.map((c, i) => ({
          ...c,
          selected: i === 0,
        }));

        if (data?.length) {
          setShippingCarriers(data?.length === 1 ? [data[0]] : [data[1]]);
        }
      })();
    }
  }, [selectedPrintProvider, PrintProviders, weight, dispatch]);

  const calculateShipCost = async () => {
    const payload = {
      printProvider: PrintProviders[selectedPrintProvider]?.ProviderName,
      carrierCode: selectedShippingCarrier?.code,
      toCountry:
        selectedVendor?.ShippingDestination === "Intl"
          ? country
          : selectedVendor?.ShippingDestination,
      toPostalCode: zipCode?.replace(/\s/g, ""),
      totalWeight: weight,
    };

    const response = await dispatch(getShipstationRates(payload));

    if (response?.ExceptionMessage) {
      setVendor({
        ...selectedVendor,
        shipmentCost: 0,
        error:
          "Provided Zip/Postal code does not match with destination country",
      });
      return;
    }

    setVendor({
      ...selectedVendor,
      shipmentCost: response?.shipmentCost + response?.otherCost,
      serviceName: response?.serviceName,
      error: null,
    });
  };

  const selectedPrintProviderName =
    PrintProviders[selectedPrintProvider]?.ProviderName;

  return (
    <>
      <h3>Select Fulfillment Plant</h3>

      <State>
        {PrintProviders?.map((printProvider, index) => (
          <Button
            key={`printProvider${index}`}
            endIcon={<KeyboardArrowDownIcon />}
            containerStyle={
              selectedPrintProvider === index
                ? {
                    ...ButtonStyles,
                    ...activeButtonStyles,
                  }
                : ButtonStyles
            }
            variant="text"
            title={`${printProvider.ProviderName} Fulfillment Plant`}
            onClick={() => {
              setPrintProvider(index);
              setVendor(undefined);
            }}
            startIcon={
              <img
                src={
                  printProvider?.ProviderName === "CA"
                    ? CADFlag
                    : printProvider?.ProviderName === "US" ||
                      printProvider?.ProviderName === "NY"
                    ? USAFlag
                    : printProvider?.ProviderName === "EU"
                    ? EUFlag
                    : AUFlag
                }
                alt="ProviderFlag"
                style={FlagStyles}
              />
            }
          />
        ))}
      </State>
      {selectedPrintProvider >= 0 && (
        <>
          <h3>Select Destination Country</h3>
          <CourierVendors
            vendors={PrintProviders[selectedPrintProvider]?.ShippingCosts}
            selectedVendor={selectedVendor}
            setVendor={(v) => setVendor(v)}
          />
        </>
      )}

      {!!selectedVendor &&
        !!weight &&
        selectedPrintProviderName !== "EU" &&
        selectedPrintProviderName !== "AU" &&
        selectedPrintProviderName !== "NY" && (
          <>
            <h3>Select Shipping Method</h3>
            <ShippingCarriersWrapper>
              {shippingCarriers?.map((s, i) => (
                <ShippingCarrier
                  key={s?.nickname || s?.name}
                  selected={s?.selected}
                  onClick={() => {
                    setShippingCarriers(
                      shippingCarriers?.map((w, j) => ({
                        ...w,
                        selected: i !== j ? false : !w?.selected,
                      }))
                    );
                    setVendor({
                      ...selectedVendor,
                      shipmentCost: undefined,
                      error: undefined,
                    });
                  }}
                >
                  {selectedPrintProviderName === "US"
                    ? "USPS"
                    : s?.nickname || s?.name}
                  {/* {s?.nickname || s?.name} */}
                  {/* USPS */}
                </ShippingCarrier>
              ))}
            </ShippingCarriersWrapper>

            {!!selectedShippingCarrier && (
              <>
                <h3>Enter Destination Zip / Postal Code</h3>
                <ZipCodeWrapper>
                  {isIntl && (
                    <Select
                      label="Country"
                      options={countries}
                      labelKey="name"
                      valueKey="code2"
                      value={{ code2: country }}
                      onChange={(c) => setCountry(c)}
                    />
                  )}

                  <TextField
                    label="Zipcode / Postcode"
                    size="small"
                    value={zipCode}
                    onChange={(v, e) => setZipCode(v)}
                    onKeyDown={async (e) => {
                      if (e.keyCode === 13) calculateShipCost();
                    }}
                  />

                  <Button
                    title="Find Shipping Cost"
                    disabled={isIntl ? !zipCode || !country : !zipCode}
                    onClick={calculateShipCost}
                  />
                </ZipCodeWrapper>
                {/* <Error>{selectedVendor?.error}</Error> */}
              </>
            )}
          </>
        )}

      {selectedVendor &&
        (!weight ||
          selectedVendor?.error ||
          selectedPrintProviderName === "EU" ||
          selectedPrintProviderName === "AU" ||
          selectedPrintProviderName === "NY") && (
          <ShippingCost cost={selectedVendor} selectedVendor={selectedVendor} />
        )}

      {!!selectedVendor && !!selectedVendor?.shipmentCost && weight && (
        <Description>
          <h3>
            Shipping Cost (
            {selectedVendor?.serviceName || selectedShippingCarrier?.name})
          </h3>
          <h3 style={{ fontSize: 30 }}>
            ${selectedVendor?.shipmentCost?.toFixed(2)}
          </h3>
        </Description>
      )}
    </>
  );
};

export default ShippingDetails;
