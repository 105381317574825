import * as React from 'react';

const Icon = ({ color }) => (
  <svg width={24} height={24} fill='none'>
    <path
      d='M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.384 12-12S18.617 0 12 0zm0 22.141C6.409 22.141 1.859 17.592 1.859 12S6.409 1.859 12 1.859 22.141 6.409 22.141 12c0 5.592-4.549 10.141-10.141 10.141z'
      fill={color}
    />
    <path
      d='M16.648 10.987H12.93V7.269a.93.93 0 10-1.86 0v3.718H7.352a.93.93 0 100 1.859h3.718v3.718a.93.93 0 101.86 0v-3.718h3.718a.93.93 0 100-1.86z'
      fill={color}
    />
  </svg>
);

export default Icon;
