/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import UsersTable from "pages/admin-users/UsersTable";
import { login } from "actions/auth";
import { useHistory } from "react-router-dom";
import { INITIAL_SYNC_PRODUCTS } from "actions/sync-products";
import UserOrdersTable from "pages/admin-users/UserOrdersTable";
import GeneratedMocks from "pages/my-stores/products/GeneratedMocks";
// import ReSaleCertificates from 'pages/admin-users/ReSaleCertificates';
import {
  getAllSubmittedUsers,
  getSearchedSubmittedUsers,
  updateUserStatus,
  getAllSubmittedUsersCSV,
} from "actions/user";
import Tabs from "components/Tabs";
import StoreTable from "pages/my-stores/stores/StoresTable";
import { CSVLink } from "react-csv";
import {
  Container,
  TabsDataWrapper,
  UsersTableWrapper,
  UserDetails,
} from "styles/pages/admin-users";

const take = 10;

const AdminUsers = () => {
  const csvEl = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [allUsers, setAllUsers] = useState(undefined);
  const [userAutomation, setUserAutomation] = useState(false);
  const [searchUser, setSearchUser] = useState(undefined);
  const [currentUsersPage, setCurrentUsersPage] = useState(1);
  const [allSubmittedUsers, setAllSubmittedUsers] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(async () => {
    const skip =
      (currentUsersPage === 0 ? currentUsersPage : currentUsersPage - 1) * take;

    const users = searchUser
      ? await dispatch(
          getSearchedSubmittedUsers({
            searchUser: searchUser,
            skip,
            take,
          })
        )
      : await dispatch(
          getAllSubmittedUsers(
            {
              skip,
              take,
              automation: userAutomation,
            },
            token
          )
        );

    if (users.error) {
      return;
    }
    setAllSubmittedUsers(users);
  }, [dispatch, currentUsersPage, searchUser]);

  return (
    <Container>
      <UsersTableWrapper>
        <UsersTable
          users={allSubmittedUsers}
          selectedUser={selectedUser}
          take={take}
          currentPage={currentUsersPage}
          loginToUser={async (data) => {
            dispatch({ type: INITIAL_SYNC_PRODUCTS });
            const request = await dispatch(
              login({
                email: data?.User,
                password: data?.UserData?.User?.PasswordHash,
              })
            );
            if (request?.error) {
              return;
            }
            history.replace("/");
          }}
          updateUserStatus={async (payload) => {
            const users = await dispatch(updateUserStatus(payload));
            if (users.error) {
              return;
            }
            setAllSubmittedUsers({
              ...allSubmittedUsers,
              users: allSubmittedUsers?.users.map((item) =>
                item?.User === payload?.user
                  ? {
                      ...item,
                      UserData: {
                        ...item?.UserData,
                        Status: payload?.status,
                      },
                    }
                  : item
              ),
            });
          }}
          searchUser={async (searchUser) => {
            setSelectedUser(null);
            setCurrentUsersPage(0);
            setSearchUser(searchUser);
          }}
          fetchAllUser={async () => {
            setSelectedUser(null);
            setSearchUser(null);
            if (currentUsersPage != 1) {
              setCurrentUsersPage(1);
              return;
            }
            const users = await dispatch(
              getAllSubmittedUsers(
                {
                  skip: 0,
                  take,
                  automation: userAutomation,
                },
                token
              )
            );
            if (users.error) {
              return;
            }
            setAllSubmittedUsers(users);
          }}
          setCurrentPage={(v) => {
            setSelectedUser(null);
            setCurrentUsersPage(v);
          }}
          setSelectedUser={(user) => {
            setSelectedUser(null);
            setTimeout(() => setSelectedUser(user), 0);
          }}
          getAllUsers={async () => {
            const users = await dispatch(getAllSubmittedUsersCSV());

            if (users.error) {
              return;
            }

            let data = [["First Name", "Last Name", "Email"]];

            for (let i = 0; i < users?.length; i++) {
              const name = users[i]?.first_name.split(" ");
              data.push([
                name[0],
                name?.length > 1 ? name.splice(1).join(" ") : "",
                users[i]?.email,
              ]);
            }
            setAllUsers(data);
            setTimeout(() => {
              csvEl.current.link.click();
            }, 10);
          }}
          userAutomation={userAutomation}
          setUserAutomation={async (v) => {
            setUserAutomation(v);
            const users = await dispatch(
              getAllSubmittedUsers(
                {
                  skip: 0,
                  take,
                  automation: v,
                },
                token
              )
            );
            if (users.error) {
              return;
            }
            setAllSubmittedUsers(users);
          }}
        />
      </UsersTableWrapper>

      <TabsDataWrapper>
        {selectedUser && (
          <div>
            <UserDetails>
              <div>{selectedUser?.UserData?.User?.FullName}</div>
              <div>{selectedUser?.User}</div>
            </UserDetails>
            <Tabs
              dark
              tabs={[
                {
                  title: "Orders",
                  children: <UserOrdersTable user={selectedUser} />,
                },
                {
                  title: "Stores",
                  children: <StoreTable userEmail={selectedUser?.User} />,
                },
                {
                  title: "Generated Mocks",
                  children: <GeneratedMocks userEmail={selectedUser?.User} />,
                },
                // {
                //   title: 'Re-sale Certificates',
                //   children: <ReSaleCertificates user={selectedUser} />,
                // },
              ]}
            />
          </div>
        )}
      </TabsDataWrapper>

      {allUsers && (
        <CSVLink
          style={{ display: "none" }}
          ref={csvEl}
          data={allUsers}
          filename={`Shirtly_Users_List.xls`}
        />
      )}
    </Container>
  );
};

export default AdminUsers;
