import React from 'react';
import OutlineTShirt from 'assets/svg/OutlineTShirt';
import OutlineTape from 'assets/svg/OutlineTape';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import OutlineSpec from 'assets/svg/OutlineSpec';
import Image from 'components/Image';
import { ROUTES } from 'shared/constants/routes';
import {
  Container,
  ProductImage,
  DownloadResources,
  ResourceLink,
  Detail,
  Name,
  Download,
  StyledLink,
} from 'styles/pages/product-detail/productSummary';

const ProductSummary = ({ data }) => (
  <Container>
    <ProductImage>
      <Image
        src={data.Product?.MarketingImagePath}
        alt={`${data?.Product?.Name}`}
      />
    </ProductImage>
    <DownloadResources>
      <ResourceLink onClick={() => window.open(data.Product?.MockupPath)}>
        <Detail>
          <OutlineTShirt />
          <Name>Mockup Template</Name>
        </Detail>
        <Download>Download Template</Download>
      </ResourceLink>

      {/* <ResourceLink>
        <CenterFocusWeakIcon fontSize='large' />
        <StyledLink to={ROUTES.MY_STORES_PRODUCTS.LINK}>
          <Name>Mock-Up Generator</Name>
          <Download>Easy to Use</Download>
        </StyledLink>
      </ResourceLink> */}

      <ResourceLink onClick={() => window.open(data.Product?.SizeChartPath)}>
        <Detail>
          <OutlineTape />
          <Name>Size Chart</Name>
        </Detail>
        <Download>Download Size Chart</Download>
      </ResourceLink>
      <ResourceLink onClick={() => window.open(data.Product?.ArtworkSpecsPath)}>
        <Detail>
          <OutlineSpec />
          <Name>Get Artwork Specs</Name>
        </Detail>
        <Download>Download Artwork Specs</Download>
      </ResourceLink>
    </DownloadResources>
  </Container>
);

export default ProductSummary;
