import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Button from 'components/Button';
import CloudinaryWidget from 'components/CloudinaryWidget';
import RichTextEditor, {
  getHtml,
  initiateEditorState,
} from 'components/RichTextEditor';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import CancelIcon from '@material-ui/icons/Cancel';

const CreateNotification = ({ open, toggle, onCreate, data }) => {
  const [image, setImage] = useState(data?.notification?.image);
  const [description, setDescription] = useState(
    initiateEditorState(data?.notification?.description)
  );

  const descriptionHtml = getHtml(description);

  return (
    <Dialog open={open} onClose={toggle}>
      <ModalWrapper className='create-notification'>
        <CancelIcon className='close-icon' onClick={toggle} />
        <DialogTitle>Create Notification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <CloudinaryWidget
              label='Notification Image'
              value={image}
              setValue={(v) => setImage(() => setImage(v))}
              folderPath='Shirtly/Notifications'
            />

            <RichTextEditor
              value={description}
              onChange={(v) => setDescription(() => v)}
              title='Description'
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={descriptionHtml === '<p></p>\n'}
            onClick={() => {
              const payload = {
                notification: {
                  image,
                  generateDate: moment().format('YYYY-MM-DD'),
                  description: descriptionHtml,
                },
                id: data
                  ? data?.id
                  : _.random(0, 999999999) + moment().milliseconds(),
              };

              onCreate(payload, data?.id);
            }}
            title='Save'
          />
        </DialogActions>
      </ModalWrapper>
    </Dialog>
  );
};

export default CreateNotification;
