import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div``;
const ProductImage = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  border-radius: 10px;
  margin-bottom: 25px;
`;

const DownloadResources = styled.div`
  column-gap: 10px;
  border-radius: 4px;
  grid-template-columns: 1fr;
  display: grid;
  @media (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    background: none;
  }
`;
const ResourceLink = styled.div`
  background: none;
  max-width: 100%;
  justify-content: flex-start;
  display: flex !important;
  min-height: 40px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  @media (min-width: 650px) {
    background: none;
    max-width: 100%;
    padding: 0;
  }
`;
const Detail = styled.div`
  background: #cfeeff;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  width: 100%;
`;

const StyledLink = styled(Link)`
  margin-left: 5px;
  text-decoration: none;
`;

const Name = styled.div`
  margin: 0;
  font-size: 13px;
  font-family: 'Inter600';
  color: #333333;
  line-height: 19px;
`;
const Download = styled.a`
  font-size: 12px;
  font-family: 'Inter500';
  color: #2789c0;
  line-height: 27px;
  text-decoration: none;
`;

export {
  Container,
  ProductImage,
  DownloadResources,
  ResourceLink,
  Detail,
  Name,
  Download,
  StyledLink,
};
